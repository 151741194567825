import React, { useRef } from "react"
import { useRecordContext, useTranslate } from "react-admin"
import { makeStyles, Tooltip, Typography, useTheme } from "@material-ui/core"

import ListCard from "../../common/cards/ListCard"
import { isAuthorized } from "../../common/Authorize"

import { AddPoolButton } from "../pools/PoolModal"
import { PoolGrid } from "../pools/PoolGrid"
import { PoolIcon } from "../icons"
import { useGroupingType } from "../scheduleSettings"

const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: 750,
  },
}));

export default (props) => {
  const schedule = useRecordContext();
  const theme = useTheme();
  const translate = useTranslate();
  const listCardRef = useRef(null);
  const classes = useStyles();
  const { isGroups, isPools } = useGroupingType(schedule?.id);

  const canAddPools = isPools && isAuthorized(schedule, 'pools', 'create');

  return <Tooltip
    title={isGroups
      ? <Typography variant="body2" display="inline">
        <div dangerouslySetInnerHTML={{ __html: `${translate("resources.pools.tooltips.pools_unavailable")}${translate('resources.officeSettings.tooltips.groupingType')}` } } />
      </Typography>
      : ''}
    placement="center"
    classes={{ tooltip: classes.tooltip }}
  >
    <div ref={listCardRef}>
      <ListCard
        title="resources.pools.labels.card.title"
        icon={<PoolIcon />}
        reference="pools"
        target="scheduleId"
        sort={{
          field: 'name',
          order: 'ASC'
        }}
        collapsable
        canOpen={isPools}
        addButton={canAddPools && <AddPoolButton initialValues={{ scheduleId: schedule?.id }} /> }
        componentProps={{ style: { color: isGroups ? theme.palette.text.disabled : undefined } }}
        {...props}
      >
        <PoolGrid rowOptions={{ isRowSelectable: () => isPools }} />
      </ListCard>
    </div>
  </Tooltip>
}
