import React from 'react';
import { Datagrid, DatagridBody, DatagridRow, ReferenceField, TextField, useRecordContext, useResourceContext, useTranslate } from 'react-admin';
import { Tooltip, Typography, makeStyles, styled } from '@material-ui/core'
import { MergeType as MergeTypeIcon, Home as HomeIcon, UpdateOutlined } from '@material-ui/icons';

import { useRichTranslate } from '../../common/useRichTranslate';
import DateField from '../../common/fields/DateField';
import TimeRangeField from '../../common/fields/TimeRangeField';
import FunctionField from '../../common/fields/FunctionField';

import { ScheduleField } from '../schedules/ScheduleField';
import { GroupField } from '../groups/GroupField';
import { TeamField } from '../teams/TeamField';
import { TeamsField } from '../teams/TeamsField';
import { SurfaceField } from '../surfaces/SurfaceField';
import { StatusField, GameStatusField } from '../gameStatus';
import { CategoryField } from '../categories/CategoryField';
import { GameStatusIcon } from '../games/GameNumberField';
import { useGroupRounds, useIsHomeFirst, useShowAssignments, useShowFlags, useShowGameInfo } from './EventViewSettings';
import { AssignmentsRow, FlagsRow } from './EventGroupedGrid';
import { DraftGameActions } from '../draftgames/DraftGameActions';
import { useScheduleTeamsWithPools } from '../schedules/dialogs/generator/GeneratorContext';
import { useGroupingType } from '../scheduleSettings';

const useStyles = makeStyles(theme => ({
  root: {
    '& .column-startTime': {
      width: theme.spacing(15),
    },
    '& .column-scheduleId': {
      width: theme.spacing(25),
    },
    '& .column-arenaId': {
      width: theme.spacing(40),
    },
    '& .column-status': {
      width: theme.spacing(10),
      textAlign: 'right',
    },
  },
  crossTooltip: {
    verticalAlign: '-15%'
  }
}))

const Home = styled(HomeIcon)(({ theme }) => ({
  fontSize: '90%',
  color: theme.palette.grey[500],
  marginBottom: 4,
  verticalAlign: 'middle',
}))

const Type = styled('span')({
  fontSize: '85%',
  fontVariant: 'small-caps',
})

const Number = styled('span')({
  fontWeight: 500,
})

const Details = styled('div')(({ theme }) => ({
  padding: theme.spacing(.25, 1),
}))

const Pools = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  maxWidth: theme.spacing(80),
  '@media(max-width: 900px)': {
    maxWidth: 'calc(100% - 64px)',
  }
}))

const Teams = styled('div')(({ theme, spacing = 1 }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: theme.spacing(80),
  gap: theme.spacing(spacing),
  '@media(max-width: 900px)': {
    maxWidth: 'calc(100% - 64px)',
  }
}))

const Team = styled('span')(({ textAlign }) => ({
  flex: 1,
  textAlign,
}))

const Versus = styled('span')(({ theme }) => ({
  fontSize: '80%',
  fontVariant: 'small-caps',
  verticalAlign: 'text-top',
}))

const TruncatedTypography = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  maxWidth: theme.spacing(80),
}))

export const rowClick = (id, basePath, record) => {
  if (!record) return;
  if (!record.type || record.type === 'Game') return `/games/${id}/show`
  if (record.type === 'Practice') return `/practices/${id}/show`
  return `/activities/${id}/show`
}

const SeperatorDot = () => <span>•</span>

const Time = props => {
  const record = useRecordContext(props)
  if (!record?.startTime) return null;
  return <>
    <SeperatorDot />
    <TimeRangeField startSource="startTime" endSource="endTime" />
  </>
}

const Date = props => {
  const record = useRecordContext(props)
  if (!record?.date) return null;
  return <>
    <SeperatorDot />
    <DateField source="date" />
  </>
}

const Group = props => {
  const record = useRecordContext(props)
  if (!record?.groupId || record?.crossGroupId || record?.crossScheduleId) return null;
  return <>
    <SeperatorDot />
    <GroupField source="groupId" />
  </>
}

const Round = props => {
  const translate = useTranslate();
  const record = useRecordContext(props);
  const [ groupRounds ] = useGroupRounds();

  if (record?.round == null || groupRounds) return null
  return ` • ${translate('resources.draftGames.fields.round')} ${record.round}`
}

const Schedule = props => {
  const record = useRecordContext(props)
  if (!record?.scheduleId || record?.crossGroupId || record?.crossScheduleId) return null;
  return <>
    <SeperatorDot />
    <ScheduleField source="scheduleId" />
  </>
}

const Category = props => {
  const record = useRecordContext(props)
  if (!record?.categoryId) return null;
  return <>
    <SeperatorDot />
    <CategoryField source="categoryId" />
  </>
}

const useIconStyles = makeStyles(theme => ({
  icon: {
    verticalAlign: 'middle'
  }
}))

const TootipGameDetails = ({ ...props }) => {
  const details = [
    <TimeRangeField startSource="startTime" endSource="endTime" showDate variant="inherit" />,
    <SurfaceField source="arenaId" link={false} variant="inherit" />,
  ].flatMap(e => [e, ' • ']).slice(0, -1)

  return <ReferenceField basePath="/games" resource="games" reference="games" link={false} variant="inherit" {...props}>
    <FunctionField source="name" render={game => {
      return <span>
        {game.number}
        <br />{details}
      </span>
    }} />
  </ReferenceField>
}

const UpdateGameIcon = ({ game }) => {
  const classes = useIconStyles()
  const translate = useRichTranslate()

  if (!game?.updatedGameId) return null;

  return <Tooltip
    title={translate('resources.draftGames.alerts.update_game', {
      game: <TootipGameDetails source="updatedGameId" record={game} />
    })}
  >
    <UpdateOutlined fontSize="small" className={classes.icon} />
  </Tooltip>
}

const CrossSchedulingTooltip = props => {
  const translate = useTranslate();
  const classes = useStyles()
  const record = useRecordContext(props);
  const { groupId, crossScheduleId, crossGroupId } = record || {}
  if (!crossGroupId && !crossScheduleId) return null;

  const CrossSchedulingDetails = <span>
    {translate('resources.draftGames.labels.main_schedule')}: <ScheduleField source="scheduleId" link={false} variant="inherit" />
    {groupId && <><br />{translate('resources.draftGames.labels.main_group')}: <GroupField source="groupId" link={false} variant="inherit" /></>}
    {crossScheduleId && <><br />{translate('resources.draftGames.labels.cross_schedule')}: <ScheduleField source="crossScheduleId" link={false} variant="inherit" /></>}
    {crossGroupId && <><br />{translate('resources.draftGames.labels.cross_group')}: <GroupField source="crossGroupId" link={false} variant="inherit" /></>}
  </span>

  return <Tooltip title={CrossSchedulingDetails} placement="top" className={classes.crossTooltip}>
    <MergeTypeIcon fontSize="small" />
  </Tooltip>
}

const PoolsField = ({ isHomeFirst, homeTeamPool, awayTeamPool }) => {
  if (!homeTeamPool && !awayTeamPool) return null;
  return <Pools>
    <Typography variant="caption" color="textSecondary">{isHomeFirst ? homeTeamPool : awayTeamPool}</Typography>
    <Typography variant="caption" color="textSecondary">{isHomeFirst ? awayTeamPool : homeTeamPool}</Typography>
  </Pools>
}

export const EventNameField = props => {
  const record = useRecordContext(props)

  if (!record?.name) return null
  return <div>{record.name}</div>
}

export const EventCommentsField = props => {
  const record = useRecordContext(props)

  if (!record?.comments) return null
  return <TruncatedTypography variant="body2" color="textSecondary">{record.comments.split('\n')[0]}</TruncatedTypography>
}

export const EventTeamsField = ({ team, showPools = false, ...props }) => {
  const record = useRecordContext(props)
  const isHomeFirst = useIsHomeFirst();
  const { isPools } = useGroupingType(record?.scheduleId);

  const { data: scheduleTeams } = useScheduleTeamsWithPools(record?.scheduleId, showPools && isPools);

  if (!record) return null;
  if (record.type === 'Practice') {
    if (team && record.teamIds.length === 1) return null;
    return <TeamsField {...props} source="teamIds" includeCategory="inline" />
  }

  if (['Training', 'Meeting', 'Meal', 'Accomodation'].includes(record.type)) {
    if (team) return null;
    return <TeamField {...props} source="teamId" includeCategory="inline" />
  }

  const homeTeamPool = scheduleTeams?.find(t => t.teamId === record?.homeTeamId)?.pool?.name;
  const awayTeamPool = scheduleTeams?.find(t => t.teamId === record?.awayTeamId)?.pool?.name;

  if (team) { // viewing a team schedule
    const isHome = record.homeTeamId === team?.id;
    const source = isHome ? 'awayTeamId' : 'homeTeamId'
    return <>
      {showPools && <PoolsField isHomeFirst={isHomeFirst} homeTeamPool={homeTeamPool} awayTeamPool={awayTeamPool} />}
      <Teams>
        <Versus>{isHome ? ' vs ' : ' @ '}</Versus>
        <Team><TeamField {...props} source={source} /> {!isHome && <Home />}</Team>
      </Teams>
    </>
  }

  // general game list
  return <>
    {showPools && <PoolsField isHomeFirst={isHomeFirst} homeTeamPool={homeTeamPool} awayTeamPool={awayTeamPool} />}
    <Teams spacing={2}>
      <Team>{isHomeFirst && <Home />} <TeamField {...props} source={isHomeFirst ? "homeTeamId" : "awayTeamId"} /></Team>
      <Versus>{isHomeFirst ? 'vs' : '@'}</Versus>
      <Team textAlign="right"><TeamField {...props} source={isHomeFirst ? "awayTeamId" : "homeTeamId"} /> {!isHomeFirst && <Home />}</Team>
    </Teams>
  </>
}

const useDescriptionStyles = makeStyles(theme => ({
  header: {
    paddingBottom: props => props.padHeader ? theme.spacing(1.5) : undefined,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(0.25)
  }
}))

export const DescriptionField = ({ team, showTime = false, hideNumber = false, showDate = false, padHeader = false, ...props }) => {
  const translate = useTranslate();
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const [ showGameInfo ] = useShowGameInfo();
  const classes = useDescriptionStyles({ padHeader });

  if (!record) return null;

  if (['Practice', 'Training', 'Meeting', 'Meal', 'Accomodation'].includes(record.type)) {
    const type = translate(`resources.activities.values.type.${record.type}`, { _: record.type });

    let header = <>{type}</> // eslint-disable-line react/jsx-no-useless-fragment
    if (record.name) {
      header = <div className={classes.header}>
        <Type>{type}</Type>
        {' '}
        {record.name}
      </div>
    }

    return <>
      {header}
      <Details>
        <EventTeamsField team={team} {...props} link="show" />
      </Details>
      <EventCommentsField />
    </>
  }

  const isDraftGame = resource === 'draftGames' || record?.type === 'Draft Game';
  let typeToDisplay = isDraftGame ? translate(`resources.draftGames.name`, 1) : translate(`resources.games.name`, 1);

  if (record?.gameBracket) {
    typeToDisplay = translate('resources.games.labels.bracket_game');
  }

  const numberToDisplay = isDraftGame ? record.id : record.number

  return <>
    <div className={classes.header}>
      <div>
        <Type>{typeToDisplay}</Type>
        {!hideNumber && ' '}
        {!hideNumber && <Number>{numberToDisplay}</Number>}
      </div>
      <Group />
      <CrossSchedulingTooltip />
      <Round />
      {showGameInfo && <>
        <Category />
        <Schedule />
      </>}
      {showDate && <Date />}
      {showTime && <Time />}
      <GameStatusIcon game={record} />
      <UpdateGameIcon game={record} />
    </div>
    <EventTeamsField team={team} {...props} link="show" authMethod="gameTeams" />
    <EventCommentsField />
  </>
}

export const LocationField = ({ includeAttributes = false, includeCity = 'full', ...props }) => {
  const record = useRecordContext(props);
  if (!record) return null;
  if (['Training', 'Meeting', 'Meal', 'Accomodation'].includes(record.type)) return <TextField {...props} source="location" />
  return <SurfaceField {...props} source="arenaId" includeAttributes={includeAttributes} includeCity={includeCity} />
}

export const StatusesField = props => {
  const record = useRecordContext(props);
  if (!record) return null;
  if (record.type && record.type !== 'Game') return <StatusField {...props} />;
  return <GameStatusField {...props} source="id" label="" />
}

const EventDatagridRow = ({ columns, ...props }) => {
  const event = useRecordContext(props);
  const [ showAssignments ] = useShowAssignments();
  const [ showFlags ] = useShowFlags();
  return <>
    <DatagridRow {...props} />
    {showAssignments && <AssignmentsRow event={event} columns={columns} offset={2} />}
    {showFlags && <FlagsRow event={event} columns={columns} offset={2} />}
  </>
}

const EventDatagridBody = ({ columns, ...props }) => <DatagridBody {...props} row={<EventDatagridRow columns={columns} />} />
const EventDatagrid = ({ hideAssignments, columns, ...props }) => {
  if (hideAssignments) return <Datagrid {...props} />
  return <Datagrid {...props} body={<EventDatagridBody columns={columns} />} />
}

export const EventGrid = ({ hideGroup = false, hideArena = false, hideStatus = false, hideNumber = false, showSchedule = false, showDraftActions = false, showPools, includeVenue = true, team, hideAssignments, component, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles()
  const columns = 3 + (showSchedule ? 1 : 0) + (hideArena ? 0 : 1) + (hideStatus ? 0 : 1);
  return <EventDatagrid size="medium" rowClick={rowClick} className={classes.root} columns={columns} hideAssignments={hideAssignments} {...props}>
    <DateField source="date" label="ra.date.name" emptyText={`(${translate('resources.drafts.labels.no_date')})`} />
    <TimeRangeField source="startTime" startSource="startTime" endSource="endTime" label="ra.date.time" emptyText={`(${translate('resources.drafts.labels.no_time')})`} />
    <DescriptionField source="number" label="resources.events.labels.details" team={team} hideGroup={hideGroup} hideNumber={hideNumber} showPools={showPools} />
    {showSchedule && <ScheduleField source="scheduleId" />}
    {!hideArena && <LocationField source="arenaId" label="resources.events.labels.location" emptyText={`(${translate('resources.drafts.labels.no_venue')})`} includeVenue={includeVenue} />}
    {!hideStatus && <StatusesField source="status" label="" />}
    {showDraftActions && <DraftGameActions />}
  </EventDatagrid>
}
