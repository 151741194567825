import english from 'ra-language-english';

import { appName } from '../../flags';

const TYPES = {
  Exhibition: 'Exhibition',
  Placement: 'Placement',
  League: 'League',
  Playoffs: 'Playoffs',
  Tournament: 'Tournament',
  Championship: 'Championship',
  Cup: 'Cup',
}

const STATUSES = {
  Active: 'Active',
  Rescheduled: 'Rescheduled',
  'Rink Changed': 'Surface Changed',
  Cancelled: 'Cancelled',
  Postponed: 'Postponed',
  Conflict: 'Conflict',
  Forfeited: 'Forfeited',
  DoubleForfeit: 'Double Forfeit',
  Completed: 'Completed',
  Certified: 'Certified',
}

const ACTIVITY_TYPES = {
  Practice: 'Practice',
  Training: 'Training',
  Meeting: 'Meeting',
  Meal: 'Meal',
  Accomodation: 'Accomodation',
}

const REGIONS = {
  Canada: 'Canada',
  'United States': 'United States',
  Europe: 'Europe',
}

const messages = {
  ra: {
    ...english.ra,
    action: {
      ...english.ra.action,
      ok: 'Ok',
      yes: 'Yes',
      no: 'No',
      back: 'Back',
      ignore: 'Ignore',
      change: 'Change',
      register: 'Register',
      continue: 'Continue',
      subscribe: 'Subscribe',
      learn: 'Learn more',
      analyze: 'Analyze',
      accept: 'Accept',
      summary: 'Summary',
      switch: 'Switch',
      swap: 'Swap',
      delegate: 'Delegate',
      undelegate: 'Undelegate',
      generate: 'Generate',
      publish: 'Publish',
      finish: 'Finish',
      select: 'Select',
      discard: 'Discard',
      decline: 'Decline',
      cancel: 'Cancel',
      next: 'Next',
      skip: 'Skip',
      new: 'New',
      replace: 'Replace',
      request: 'Request',
      keep: 'Keep',
      send: 'Send',
      sign: 'Sign',
      directions: 'Directions',
      approve: 'Approve',
      assign: 'Assign',
      assign_anyway: 'Assign Anyway',
      reassign: 'Reassign',
      accept_other: 'Accept Other',
      click_here: 'Click here',
      click_to_verify: 'Click to verify',
      send_verification: 'Send Verification',
      copy_to_clipboard: 'Copy to clipboard',
      confirm_removal: 'Confirm Removal',
      tap_for_more: 'Tap for more',
      repeat: 'Repeat',
      invite: 'Invite',
      send_invite: 'Send Invite',
      retry: 'Retry',
      open_full_page: 'Open as full page',
      clear: 'Clear',
      clear_filters: 'Clear Filters',
      update: 'Update',
      import: 'Import',
      apply: 'Apply',
      save: 'Save',
      open: 'Open',
      upload: 'Upload',
      select_columns: 'Select Columns',
      review: 'Review',
      view: 'View',
      share: 'Share',
      unshare: 'Unshare',
      fix: 'Fix',
      search_in: 'Search in',
      open_in_id: 'Open in Spordle ID',
      certify: 'Certify',
      pend: 'Pend',
      undo: 'Undo',
      start: 'Start',
      respond: 'Respond',
      reset: 'Reset',
      load_more: 'Load More',
      click_to_refresh: 'Click Here to Refresh',
      show: 'Show',
      hide: 'Hide',
      manage: 'Manage',
      recalculate: 'Recalculate',
    },
    auth: {
      ...english.ra.auth,
      login: 'Login',
      login_error: 'Unable to login at this time',
      login_error_retry: 'Unable to login at this time. Try again in a few minutes.',
      unauthorized: 'Unauthorized',
      unauthorized_long: 'Your account (%{user}) does not have any permissions at this time.',
      recovery_email: 'Send Recovery Email',
      your_username: 'This will be your username',
      youre_logged_in_as: "You're logged in as %{user}",
    },
    page: {
      ...english.ra.page,
      loading: `${english.ra.page.loading}...`,
      error_try_again: `${english.ra.page.error}, try again later`,
      error_try_again_refresh: `${english.ra.page.error}, try refreshing or try again later`,
      create: 'New %{name}',
      edit: `Edit %{name}`,
      editing: 'Editing %{name}',
      show: `%{name}`,
      not_available: 'N/A',
    },
    message: {
      ...english.ra.message,
      created: 'Created',
      updated: 'Updated',
      draft: 'Draft',
      not_published: 'Not Published',
      not_applicable: 'Not Applicable',
      delete_title: 'Confirm Deletion',
      delete_content: 'Are you sure you want to delete this %{name}?',
      discard_changes: 'Are you sure you want to discard your changes?',
      error: `This error has been automatically sent to ${appName}. You may need to go back or try again later.`,
      version_error: `Looks like you're running an older version of ${appName}. You'll need to clear your cache then try again.`,
      version_mismatch: `Looks like you're running an older version of ${appName}. Please refresh your browser.`,
      report_details: 'Report Details',
      digits: 'This is about %{number} digits',
      redirecting: 'Redirecting',
      optional: 'Optional',
      no_options: 'No options',
      none: 'None',
      unverified: 'Unverified',
      verified: 'Verified',
      unverified_email: 'Unverified email',
      verified_email: 'Verified email',
      verification_email: 'A verification email has been sent',
      available: 'Available',
      unavailable: 'Unavailable',
      unknown: 'Unknown',
      show_more: 'Show %{number} more...',
      no_results: 'No results',
      no_results_filtered: 'No results (%{smart_count} filtered)',
      no_rows: 'No rows',
      filtered: '%{smart_count} filtered',
      filter_by_attributes: 'Filter by attributes',
      filter_by_list: 'Filter by list',
      filter_by_name: 'Filter by name',
      filter_by_group: 'Filter by group',
      filter_by_pool: 'Filter by pool',
      filter_by_category: 'Filter by category',
      no_group: 'No Group',
      all: 'All',
      total: 'Total',
      settings: 'Settings',
      default: 'Default',
      select_columns: 'Select your columns below to match the data from your file.',
      no_valid_rows: 'This file does not contain any valid rows to import.',
      managed_by_id: 'Some fields are managed by Spordle ID',
      synced_with_id: 'Synced from Spordle ID',
      unsupported: 'Currently Unsupported',
      shared_slot: "Shared"
    },
    input: {
      ...english.ra.input,
      image: {
        upload_several: 'Drop some pictures to upload, or click to select one.',
        upload_single: 'Drop a picture to upload, or click to select it.',
      },
      field: {
        name: 'Name',
        email: 'Email',
        password: 'Password',
      },
      file: {
        ...english.ra.input.file,
        upload_several: 'Drop some files to upload, or click to select one.',
        upload_single: 'Drop a file to upload, or click to select it.',
        upload: 'Upload File',
        uploading: 'Uploading...',
        upload_csv: 'Upload a CSV file',
        download_template: 'Download Template',
        only_csv: 'Only CSV files are supported at this time.',
        error_parsing: 'There was an error parsing your file.',
        invalid_value: 'Some rows have an invalid value',
        enter_new_title: 'Enter a new title',
        confirm_remove_attachment: 'Are you sure you want to remove this attachment?',
        import: 'Import',
        importing: 'Importing...',
        columns: 'Columns',
        error_too_large: 'File exceeds size limit',
        drop: {
          logo: 'Drag & drop a new logo here',
          image: 'Drag & drop a new image here',
        }
      },
      autocomplete: {
        ...english.ra.input.autocomplete,
        type_to_search: 'Type to search...',
        none: 'None'
      },
      password: {
        ...english.ra.input.password,
        forgot: 'Forgot Password?',
        change: 'Change Password',
        change_success: 'Password successfully updated',
        old: 'Current Password',
        new: 'New Password',
        confirm: 'Confirm Password',
        invalid: 'Incorrect current password',
        too_long: 'New password is too long',
        toggle_visibility: 'Toggle password visibility'
      },
      adornments: {
        seconds: 'secs',
        minutes: 'mins',
        kilometers: 'km'
      },
      i18n: {
        label: 'Translation',
        values: {
          en: 'EN',
          fr: 'FR',
        }
      }
    },
    notification: {
      ...english.ra.notification,
      updated: 'Updated |||| %{smart_count} updated',
      created: 'Created',
      deleted: 'Deleted |||| %{smart_count} deleted',
      bad_item: 'Incorrect item',
      item_doesnt_exist: 'Item does not exist',
      cancelled: 'Cancelled',
      logged_out: 'Your authentication has expired, please log in again.',
    },
    validation: {
      ...english.ra.validation,
      email: 'Must be a valid email',
      invalid: 'Invalid',
      invalid_number: 'Invalid number',
      invalid_number_format: 'Invalid number format',
      invalid_email_format: 'Invalid email format',
      different: 'You cannot use the same password',
      match: 'This does not match the new password you entered',
      incorrect_email_password: 'Incorrect email or password',
      values_not_unique: 'Some values are not unique',
      spaces_not_allowed: 'Spaces are not allowed',
      more_characters: '%{smart_count} more character |||| %{smart_count} more characters',
      positive: 'Must be positive',
      positive_leave_empty: 'Must be postive. Leave empty for always.',
      negative: 'Must be negative',
      whole: 'Must be whole',
      longer_than: 'Cannot be longer than %{smart_count} characters (%{current_length})',
      greater_than_zero: 'Must be greater than 0',
      greater_than_or_equal_zero: 'Must be greater than or equal to 0',
      greater_than_zero_leave_empty: 'Must be greater than 0. Leave empty for always.',
      greater_than_zero_leave_empty_limit: 'Must be greater than 0. Leave empty for no limit.',
      title_too_long: 'Title must be less than 80 characters',
      min: 'Min',
      max: 'Max',
      absence: 'Must be unset',
      length: 'Invalid length',
      numericality: 'Must be a number',
      inclusion: 'Invalid selection',
      exclusion: 'Invalid selection',
      format: 'Invalid format',
      uniqueness: 'Must be unique',
      date: 'Date is not valid',
      already_exists: 'Already exists in the system',
      duplicate: 'Duplicate',
      end_after_start: 'End Date must be after Start Date',
      end_before_start: 'End Time must always be after Start Time',
      unauthorized: 'Unauthorized',
      unable_to_delete: 'Unable to delete',
      claim_maximum_amount: 'Must not be over the maximum amount: %{amount}',
      too_high: 'Too high',
    },
    date: {
      name: 'Date',
      minute: 'Minute |||| Minutes',
      hour: 'Hour |||| Hours',
      day: 'Day |||| Days',
      week: 'Week |||| Weeks',
      month: 'Month |||| Months',
      range: '%{date1} to %{date2}',
      time: 'Time',
      phrase: {
        minute: '%{smart_count} minute |||| %{smart_count} minutes',
        hour: 'an hour |||| %{smart_count} hours',
        week: '%{smart_count} week |||| %{smart_count} weeks',
      },
      lexical: {
        today: 'Today',
        all_day: 'All day',
        until_time: 'until %{time}',
        after_time: 'after %{time}',
        before: 'Before',
        between: 'Between',
        after: 'After',
      },
      days: {
        short: {
          All: 'All',
          Sun: 'Sun',
          Mon: 'Mon',
          Tue: 'Tue',
          Wed: 'Wed',
          Thu: 'Thu',
          Fri: 'Fri',
          Sat: 'Sat',
        },
        long: {
          All: 'All Days',
          Sun: 'Sunday',
          Mon: 'Monday',
          Tue: 'Tuesday',
          Wed: 'Wednesday',
          Thu: 'Thursday',
          Fri: 'Friday',
          Sat: 'Saturday',
        }
      },
    },
    function: {
      and: 'and',
      for: 'for',
      is: 'is',
      was: 'was',
      a: 'a',
      youre: "You're",
      by: 'by',
      or: 'or',
    }
  },
  resources: {
    categories: {
      name: 'Category |||| Categories',
      fields: {
        name: 'Name',
        divisionId: 'Division',
        gender: 'Gender Identity',
        classId: 'Class',
        officeId: 'Organization',
      },
      values: {
        gender: {
          Male: 'Male',
          Female: 'Female',
          Integrated: 'Mixed',
        },
      },
      actions: {
        add: 'Add Category',
        edit: 'Edit Category',
        scheduleCategory: 'Use Schedule Category',
      },
      labels: {
        card: {
          title: 'Categories',
        },
        edit: 'Add Category',
        add: 'New Category',
      },
    },
    divisions: {
      name: 'Division |||| Divisions',
      fields: {
        name: 'Name',
        shortName: 'Short Name',
        parentId: 'Parent Division',
        officeId: 'Organization',
      },
      actions: {
        add: 'Add Division',
        edit: 'Edit Division',
      },
      labels: {
        card: {
          title: 'Divisions',
        },
        edit: 'Add Division',
        add: 'New Division',
      },
    },
    classes: {
      name: 'Class |||| Classes',
      fields: {
        name: 'Name',
        shortName: 'Short Name',
        officeId: 'Organization',
      },
      actions: {
        add: 'Add Class',
        edit: 'Edit Class',
      },
      labels: {
        card: {
          title: 'Classes',
        },
        edit: 'Add Class',
        add: 'New Class',
      },
    },
    surfaces: {
      name: 'Surface |||| Surfaces',
      fields: {
        name: 'Name',
        city: 'City',
        country: 'Country',
        address: 'Address',
        alias: 'Alias',
        timezone: 'Timezone',
        sports: 'Sports',
        venueId: 'Venue',
        fraction: 'Split Name',
        type: 'Type',
        size: 'Size',
        parentId: 'Parent',
        split: 'Split'
      },
      values: {
        sports: {
          Hockey: 'Hockey',
          Soccer: 'Soccer',
          Baseball: 'Baseball'
        },
        type: {
          Ice: 'Ice',
          Grass: 'Grass',
          Synthetic: 'Synthetic',
          Wood: 'Wood',
          Gym: 'Gym',
          Clay: 'Clay',
          Dirt: 'Dirt',
          'Grass/Clay': 'Grass/Clay',
          'Grass/Dirt': 'Grass/Dirt',
        },
        size: {
          all_sizes: 'View all sizes',
          XL: 'Extra Large',
          L: 'Large',
          M: 'Medium',
          S: 'Small',
          XS: 'Extra Small'
        },
      },
      actions: {
        add: 'Add Surface',
        edit: 'Edit Surface',
      },
      labels: {
        card: {
          title: 'Surfaces',
          splitTitle: 'Surface Splits',
          splitSubtitle: 'A surface can be split to allow multiple events at the same time',
        },
        alerts: {
          message: "This surface is a split of"
        },
        tabs: {
          summary: 'Summary',
          games: 'Games',
          time_slots: 'Time Slots',
        },
        edit: 'Update Surface',
        add: 'New Surface',
        street_address: 'Street Address',
        name: 'Surface Name',
        availability: {
          card: {
            title: 'Availability',
            subtitle: 'Select the dates and times that are available, or block out times that are unavailable',
          },
          title: 'Organization Availability',
          note: 'Availability note',
          updated: 'Availability updated!',
        },
      },
      messages: {
        surfaces: 'surfaces',
        availability: {
          available: 'This surface %{input} be available',
        }
      },
      helpers: {
        alias: 'Optional - used for searching',
        fraction: 'Optional - defaults to a whole surface',
        availability: {
          note: 'This note will be visible when scheduling games during this time.',
        }
      }
    },
    venues: {
      name: 'Venue |||| Venues',
      fields: {
        name: 'Name',
        city: 'City',
        region: 'Region',
        country: 'Country',
        address: 'Address',
        alias: 'Alias',
        timezone: 'Timezone',
      },
      actions: {
        add: 'Add Venue',
        edit: 'Edit Venue',
      },
      labels: {
        name: 'Venue Name',
        street_address: 'Street Address',
        my: 'My Venues',
      },
      messages: {
        add_venues: 'Add venues',
        search_venue: 'Search by venue name or city',
        enter_name_city: 'Enter a name or city to start searching',
      },
      alerts: {
        loading_venues: 'Loading venues...',
      }
    },
    activities: {
      name: 'Activity |||| Activities',
      fields: {
        name: 'Name',
        teamId: 'Team',
        arenaId: 'Surface',
        officeId: 'Organization',
        location: 'Location',
        type: 'Type',
        status: 'Status',
        recurrence: 'Recurrence',
        startTime: 'Start Time',
        endTime: 'End Time',
        pastEvents: 'Past Activities',
        comments: 'Comments',
      },
      filters: {
        startTime: 'Scheduled Date',
        endTime: 'End Date',
        pastEvents: 'Show past activities',
      },
      actions: {
        add: 'Add Activity',
      },
      labels: {
        card: {
          name: 'Activity',
        },
        add: 'New Activity',
        edit: 'Update Activity',
        frequency: 'Frequency',
        interval: 'Interval',
        weekdays: 'Weekdays',
        end_date: 'End Date',
      },
      messages: {
        schedule_no_occurences: 'The recurrence schedule specified does not result in any activities',
        event_repeat: 'This recurrence schedule will result in %{smart_count} activity |||| This recurrence schedule will result in %{smart_count} activities',
        additional_instances: '... %{smart_count} additional activity ... |||| ... %{smart_count} additional activities ...',
      },
      helpers: {
        frequency: 'All other instances will be removed',
      },
      values: {
        type: ACTIVITY_TYPES,
        status: STATUSES,
        frequency: {
          Once: 'Does not repeat',
          Daily: 'Daily',
          Weekly: 'Weekly',
          Monthly: 'Monthly',
        }
      },
      alerts: {
        activity_explanation: 'Activities are solely off-surface events, not to be confused with games or practices.'
      }
    },
    practices: {
      name: 'Practice |||| Practices',
      fields: {
        name: 'Name',
        teamIds: 'Teams',
        arenaId: 'Surface',
        officeId: 'Organization',
        location: 'Location',
        type: 'Type',
        status: 'Status',
        recurrence: 'Recurrence',
        startTime: 'Start Time',
        endTime: 'End Time',
        pastEvents: 'Past Practices',
        comments: 'Comments',
      },
      filters: {
        startTime: 'Scheduled Date',
        endTime: 'End Date',
        pastEvents: 'Show past practices',
      },
      actions: {
        add: 'Add Practice',
        edit: 'Edit Practice',
      },
      labels: {
        card: {
          name: 'Practice',
        },
        add: 'New Practice',
        edit: 'Update Practice',
        frequency: 'Frequency',
        interval: 'Interval',
        weekdays: 'Weekdays',
        end_date: 'End Date',
        tbd: 'TBD',
        practice_status: 'Practice Status',
        set_practice_status: 'Set Practice Status',
        change_practice_status: 'Change Status',
      },
      messages: {
        schedule_no_occurences: 'The recurrence schedule specified does not result in any practices',
        event_repeat: 'This recurrence schedule will result in %{smart_count} practice |||| This recurrence schedule will result in %{smart_count} practices',
        additional_instances: '... %{smart_count} additional practice ... |||| ... %{smart_count} additional practices ...',
      },
      helpers: {
        frequency: 'All other instances will be removed',
        conflicts: 'Resolve conflicts or status will be enforced as Conflict',
      },
      values: {
        status: STATUSES,
        frequency: {
          Once: 'Does not repeat',
          Daily: 'Daily',
          Weekly: 'Weekly',
          Monthly: 'Monthly',
        }
      },
      notifications: {
        practice_status_changed: 'Practice status changed',
        change_practice_status: 'Changed %{smart_count} practice status to %{status} |||| Changed %{smart_count} practice statuses to %{status}',
        change_practice_status_error: 'Unable to change practice status  |||| Unable to change practice statuses',
      },
      validations: {
        conflicts: 'Resolve conflicts or change to an inactive status',
      }
    },
    events: {
      name: 'Event |||| Events',
      fields: {
        arenaId: 'Surface',
        status: 'Status',
        startTime: 'Start Time',
        endTime: 'End Time',
      },
      labels: {
        card: {
          title: 'Schedule',
        },
        details: 'Details',
        location: 'Location',
        upcoming_schedule: 'Upcoming Schedule',
        team_events: 'Team Events',
        show_calendar: 'Show calendar',
      },
    },
    games: {
      name: 'Game |||| Games',
      fields: {
        id: 'ID',
        number: 'Game Number',
        seasonId: 'Season',
        scheduleId: 'Schedule',
        scheduleType: 'Type',
        crossScheduleId: 'Cross Schedule',
        categoryId: 'Category',
        homeTeamId: 'Home Team',
        awayTeamId: 'Away Team',
        date: 'Date',
        startTime: 'Start Time',
        endTime: 'End Time',
        arenaId: 'Surface',
        status: 'Status',
        comments: 'Comments',
        isApproved: 'Approved',
        officeId: 'Organization',
        groupId: 'Group',
        crossGroupId: 'Cross Group',
        pastEvents: 'Past Games',
        timezone: 'Timezone',
        effectiveSurfaces: 'Surface',
        resetAssigner: 'Reset Assigner',
      },
      filters: {
        startTime: 'Scheduled Date',
        endTime: 'End Date',
        scheduleOffices: 'Schedule Organization',
        teamOffices: 'Teams Organization',
        homeTeamOffices: 'Home Team Organization',
        awayTeamOffices: 'Away Team Organization',
        surfaceOffices: 'Surface Organization',
        assignOffices: 'Assigning Organization',
        isApproved: 'Completed',
        isCertified: 'Certified',
        pastEvents: 'Show past games',
        conflictsOnly: 'Show conflicts only',
        bracketGamesOnly: 'Show bracket games only',
      },
      actions: {
        add: 'Add Game',
        edit: 'Edit Game',
        edit_completed: "Game cannot be modified since it's already marked as completed or certified",
        internal: 'Internal Actions',
        applyAssignRules: 'Apply Assign Rules',
        recalculateStats: 'Recalculate stats',
        recalculateScoresheet: 'Recalculate scoresheet',
        recalculateTransactions: 'Recalculate transactions',
      },
      labels: {
        card: {
          title: 'Games',
        },
        tabs: {
          summary: 'Summary',
          officials: 'Officials',
          scoresheet: 'Scoresheet',
          bracket: 'Bracket',
          events: 'Events',
        },
        edit: 'Update Game',
        add: 'New Game',
        time: 'Game Time',
        report: 'Game Report',
        tbd: 'TBD',
        bracket_game: 'Bracket Game',
        quick_assign_dialog_title: 'Game %{gameNumber} - %{position}',
        game_number: 'Game %{number}',
        game_number_officials: 'Game %{number} Officials',
        game_number_lineup: 'Game %{number} Lineup',
        game_number_certify: 'Game %{number} Certification',
        editing_game_number: 'Editing Game %{number}',
        team_lineups: 'Team Lineups',
        all_games: 'All Games',
        scorekeeping: 'Scorekeeping',
        forfeiting_team: 'Losing Team by forfeit',
        forfeit: 'Forfeit Game',
        score: 'Score',
        forfeitWin: 'Forfeit Win',
        forfeitLoss: 'Forfeit Loss',
        forfeitTie: 'Double Forfeit',
        home: 'Home',
        away: 'Away',
        game_number_history: 'Game %{number} History',
        notes: 'Note |||| Notes',
        scoresheet_notes: 'Game Notes',
        admin_notes: 'Official Notes',
        select_team: 'Select team',
        certify_game: 'Certify Game',
        view_scoresheet: 'View Scoresheet',
        active: 'Active',
        inactive: 'Inactive',
        confirm_changes: 'Confirm Changes',
        game_status: 'Game Status',
        set_game_status: 'Set Game Status',
        change_game_status: 'Change Status',
        view_options: 'View Options',
        draftUpdates: {
          create: 'Create Draft',
          request: 'Request Changes',
          card: {
            title: 'Draft Updates',
            subtitle: 'The following games are unpublished draft games. Once published, the changes will be applied to this game.',
          },
        },
        cross_scheduling_add: 'Add Cross Scheduling',
        cross_scheduling_remove: 'Remove Cross Scheduling',
        main_group: 'Main Group',
        lineup: {
          name: 'Lineup',
          approved: 'Lineup Approved',
          incomplete: 'Incomplete Lineup',
          game: 'Game Lineup',
        },
        assignment: {
          history: 'History',
          confirm: 'Confirm Assignment',
          decline: 'Decline Assignment',
          confirm_reassignment: 'Confirm Reassignment',
          confirm_request: 'Confirm Request',
          expired_qualification: 'Expired Qualification',
          flagged_official: 'Flagged Official',
          discard_changes: 'Discard Changes',
          request_game: 'Request this game',
          this_game: 'This Game',
          delegate: 'Assignment Delegation',
          swap_assignment: 'Swap Assignment',
          swap_assignment_official: "Swap this official's assignment",
          search_names: 'Search names',
          search_attributes: 'Search attributes',
          unassigned: 'Unassigned',
          hidden: 'Hidden',
          adhoc: 'Volunteer',
          update_availability: 'Update Availability',
          game_officials: 'Game Officials',
          assign_officials: 'Assign Officials',
          team_officials: 'Team Officials',
          assigners: 'Assigners',
          show_attributes: 'Show attributes',
          hide_attributes: 'Hide attributes',
          undo_no_show: 'Undo No Show',
          delegate_pay: 'Delegate pay also',
          status: {
            ready: 'Ready',
            confirmed: 'Confirmed',
            pending: 'Pending',
            declined: 'Declined',
            requested: 'Requested',
            no_show: 'No show',
            removed: 'Removed',
            expired: 'Expired',
            ineligible: 'Ineligible',
            conflicts: 'Conflicts',
            schedule: 'Schedule',
            travel: 'Travel',
            assigned_today: 'Assigned Today',
            available: 'Available',
            unavailable: 'Unavailable',
            account: 'Account',
            assigned: 'Assigned',
            local: 'Local',
          }
        },
        certification: 'Certification',
        general: 'General',
        summary: 'Summary',
        flags: {
          'Referee Entered Result': 'Referee entered the results',
          'Coach Entered Result': 'Coach entered the results',
          'Unfinished': 'Game did not finish',
          'Lineup Max Limit': 'Too many members',
          'Lineup Min Limit': 'Not enough members',
          'Extra': 'Extra member |||| Extra members',
          'Serving': 'Suspended member serving |||| Suspended members serving',
          'Secondary Team': 'Secondary team member |||| Secondary team members',
          'Not Rostered': 'Member missing from lineup |||| Members missing from lineup',
          'Not Serving': 'Suspended member who played |||| Suspended members who played',
          'Missing Suspended Member': 'Suspended member missing from lineup',
          'Low Severity Penalties': 'Low Severity Penalty |||| Low Severity Penalties',
          'High Severity Penalties': 'High Severity Penalty |||| High Severity Penalties',
          'Yellow': 'Yellow card |||| Yellow cards',
          'Indirect Red': 'Indirect red card |||| Indirect red cards',
          'Direct Red': 'Direct red card |||| Direct red cards',
          'Notes': 'Game notes',
          'Unvalidated Members': 'Unvalidated Member |||| Unvalidated Members',
          'Faulty Players': 'Faulty Player |||| Faulty Players',
          'Transferred': 'Transferred',
          'Suspension': 'Suspension |||| Suspensions',
        },
        flagsDetails: {
          'Missing Suspended Member': 'A suspended member must be in the lineup',
          'Not Rostered': 'A member played without being added to the lineup prior to game start',
          'Extra': 'This member is not rostered to this team',
          'Transferred': 'This member has been transferred from another organization',
          'Secondary Team': "This team is not the member's primary team",
          'Unvalidated Members': 'This member needs to be validated in Spordle ID',
          'Faulty Players': {
            'hasInningsRest': 'This member was not allowed to pitch due to a rest caused by the innings pitched',
            'hasThrowsRest': 'This member was not allowed to pitch due to a rest caused by the throws pitched',
            'consecutiveDays': 'This member pitched too many consecutive days',
            'dailyInnings': 'This member pitched too many innings this day',
            'weeklyInnings': 'This member pitched too many innings this week',
          }
        }
      },
      messages: {
        forfeit_help: 'Forfeiting a game will complete the scoresheet. Select the team that forfeited the game.',
        show_assignments: 'Show assignments',
        show_flags: 'Show flags',
        reverse_home_away: 'Reverse teams',
        show_more_game_info: 'Show more game info',
        group_games_by_arena: 'Group games by surface',
        group_games_by_round: 'Group games by round',
        group_games_by_date: 'Group games by date',
        confirm_delegate: 'Games that do not have the selected position or have already assigned the position to an official will be ignored. If no position is selected, the games assigning organization will be changed instead.',
        and_more: 'And %{smart_count} more...',
        game_status: 'This will update the schedule and replace the current status and comments of the game.',
        calendar: {
          subscribe: 'Subscribe to Calendar',
          subscribe_description: 'You can subscribe to your personal calendar feed in most calendar apps.',
          subscribe_description_extra: 'This includes your <strong>official assignments only</strong>. Scheduling changes may be delayed in your calendar app.',
        },
        apply_assign_rules: 'Update the assign settings for this game by applying the assign rules.',
        assignment: {
          accept_game_assignment: 'Accept game assignment',
          decline_game_assignment: 'Decline game assignment',
          are_you_sure_accept_assignment: "Are you sure you want to accept this assignment? You'll be unable to accept any assignment at this time until you decline this assignment.",
          are_you_sure_accept_assignment_disabled: "Are you sure you want to accept this assignment? You'll be unable to decline this assignment and accept any other assignment at this time.",
          accepting_assignment: 'Accepting this assignment will also decline the following assignments:',
          you_cannot_accept_assignment: 'You cannot accept this assignment because you have other conflicting assignments:',
          consecutive_game: 'Consecutive Game',
          accept_consecutive_assignments: 'Accepting this assignment will also accept any consecutive assignments.',
          decline_consecutive_assignments: 'Declining this assignment will also decline any consecutive assignments.',
          already_assigned: '%{name} is already assigned to this position.',
          continuing_replacement: 'By continuing, %{name1} will be removed from this game and %{name2} will be assigned in their place.',
          invalid_qualification: '%{name} does not have a valid qualification at this time.',
          verified_eligibility: 'Ensure that you have verified eligibility before assigning.',
          unable_accept_assignment: '%{name} may be unable to accept this assignment due to reasons listed in the Summary tab.',
          assign_official_to_game: 'Assign official to this game',
          reason_for_declining: 'Reason for Declining',
          both_assignments: 'both assignments',
          assignments: 'this assignment |||| all %{smart_count} assignments',
          decline_as_unavailable: 'Decline %{assignments} as unavailable',
          decline_as_available: 'Decline %{assignments} as available',
          contact_assigner: 'This game requires you to contact an assigner to be removed.',
          decline_assignment: "If you decline this assignment, you'll be able to change your mind until you're removed by an assigner.",
          other_assignments: 'You also have other assignments at this time that conflict with this game.',
          accept_another_assignment: 'Accept another assignment at this time instead',
          removed_from_game: '%{name} will be removed from this game',
          request_removed: 'Your request will be removed',
          remove_request_from_game: 'Remove your request for this game',
          remove_official_from_assignment: 'Remove official from this assignment',
          assigners_will_be_notified: "Assigners will be notified that you have requested this game. You'll still have to confirm your assignment if you've been assigned to this game.",
          select_new_assignment: 'Select the new assignment for %{name}',
          swap_choice: '%{name} will be switched to %{position}',
          swap_current_position: 'This is the current position',
          swap_delegated: 'This assignment is delegated to another assigner',
          swap_settings_help: 'You can also change the available positions by changing the officiating system in the game settings below.',
          position_unassigned: 'This position is unassigned.',
          official_already_assigned: 'An official is already assigned to this position.',
          select_official_to_view_availability: 'Select an official to view their availability.',
          hidden_by_filter: 'officials are hidden by filters',
          official_from: 'official from',
          scorekeeper_from: 'scorekeeper from',
          contact_game_assigners_officials: "Contact this game's assigners and officials",
          all_assignments_confirmed: 'All assignments confirmed.',
          draft_status_description: 'Not visible to officials',
          assigned_by: `Assigned by %{name}`,
          time_display: `All times are shown in <strong>%{timezone}</strong>`,
          time_display_local: `Times are in your local timezone (%{timezone})`,
          time_display_other: `Times are in %{timezone}`,
          delegate: 'This position can be delegated to another assigner. As the game assigner, you can change or remove delegation at any time.',
          update_official_presence_status: "Update official's presence status",
          mark_as_no_show: 'Mark %{name} as no show',
          undo_no_show: "This will restore %{name}'s status as confirmed."
        }
      },
      alerts: {
        loading_game_error: 'Error loading game history. You may need to refresh or try again later.',
        no_changes_made: 'No changes have been made to this game.',
        no_edit_permission: 'You do not have access to edit this game.',
        no_change_date_permission: "The scheduling organization has disabled changing the date for shared draft games.",
        loading: {
          assignments: 'Loading assignments...',
          assignment_history: 'Loading assignment history...',
          availability: 'Loading availability...',
        },
        status: {
          completed: 'This game has been completed.',
          assigning_not_configured: 'Assigning is not configured for this game.',
          integration: `This game is being assigned outside of ${appName}.`,
          conflict: 'This game has a scheduling conflict',
          cancelled: 'This game is cancelled',
          postponed: 'This game is postponed',
          incomplete: 'This is an incomplete game in the past',
          rescheduled: 'This game has been rescheduled',
          rink_changed: "This game's rink has changed",
        },
        assigning_reason: {
          no_office: 'Assigning is not possible for this game because no "assigner determination" could be resolved.',
          no_system: "Assigning is not possible for this game because %{name} or any of its parent organizations does not have any assign systems for this category.",
          assign_settings_disabled: 'Assigning is not possible for this game because assigning is disabled.',
        },
        assignment: {
          load_assignment_panel_error: 'Unable to load assignment panel',
          load_assignments_error: 'Unable to load assignments',
          status_of_assignments: 'Assignments are currently in %{status} status. Officials will not be notified yet.',
          official_history_error: 'Failed to load official history.',
          no_assignment: 'No assignments this season.',
          this_position_already_assigned: 'This position is already assigned to someone else.',
          already_assigned_to_game: "You're already assigned to this game. You'll need to decline your current assignment before you can request this.",
          official_details_error: 'Unable to load official details.',
          load_official_schedule_error: 'Failed to load official schedule.',
          no_game_and_availability: 'This official has no games and availability on this day.',
          missing_settings: 'Missing settings',
          no_pay_structure: 'This game does not have a pay structure configured. This can be changed in the settings below.',
          no_pay_structure_delegated: 'This delegated assignment does not have a pay structure configured.',
          assigned_by: 'This position is assigned by %{office} and paid by %{payOffice}',
          assigned_by_delegated: 'This position is delegated to be assigned by %{office} and paid by %{payOffice}',
          suggestions: {
            title: 'Suggestions',
            title_alt: 'No Suggestions',
            no_requests_or_ready_officials: 'There are no requests or ready officials for this game',
            officials_requested_this_game_already: 'Some officials have requested this game or are already at this venue',
            no_request_officials_ready: 'No requests, but some officials are ready',
            no_ready_officials: 'There are no ready officials for this game',
            officials_already_arena: 'Some officials are already at this venue',
            no_officials_this_arena: 'No officials are already at this venue, but some are ready',
          },
          no_officials_required: {
            title: 'No officials are required for this game',
            assign_official: 'To assign officials, change the Officiating System setting below',
          },
          assigner: {
            ready: {
              title: 'Ready',
              officiate_game: 'This official is able to officiate this game',
            },
            confirmed: {
              title: 'Confirmed',
              accepted: 'This official has accepted this position',
              accepted_other: 'This official has accepted another position for this game',
            },
            pending: {
              title: 'Pending',
              unconfirmed: 'This official has been assigned to this position and has not yet accepted',
              unconfirmed_other: 'This official has been assigned to another position for this game and has not yet accepted',
            },
            declined: {
              title: 'Declined',
              change_response: 'This official has <strong>declined</strong> this position. They can still change their mind and accept until you remove them',
              change_response_other: 'This official has <strong>declined</strong> another position for this game. They can still change their mind and accept until you remove them',
            },
            no_show: {
              title: 'No show',
              removed: 'The scorekeeper removed this official from the scoresheet as they did not show',
            },
            requested: {
              title: 'Requested',
              requested: 'This official requested this game',
            },
            removed: {
              title: 'Removed',
              removed: 'Official was removed after they previously %{message} this game',
            },
            expired: {
              title: 'Expired',
              expired: "Official's qualification has <strong>expired</strong> at this time",
            },
            suspended: {
              title: 'Suspended',
              suspended: "This official is <strong>suspended</strong> as a %{positionGroup} since %{date} %{duration}",
              duration: 'for %{smart_count} game |||| for %{smart_count} games',
              duration_expiry: ' or until %{date}',
              duration_indefinite: 'for an indefinite period'
            },
            eligibility: {
              title: 'Ineligible',
              minimum_level: '<strong>Minimum %{position} level</strong> has not been met for this game',
              no_grade: 'A grade has not yet been entered for this official',
              minimum_grade: '<strong>Minimum %{position} grade</strong> has not been met for this game',
              minimum_age: '<strong>Minimum Age</strong> has not been met for this game',
              no_age: 'Official has not specified their birthdate yet',
              limit_reached: 'Official has reached the daily limit of %{smart_count} game |||| Official has reached the daily limit of %{smart_count} games',
            },
            conflicts: {
              title: 'Conflicts',
              team_conflict: 'Official has a <strong>team conflict</strong> for this game',
              rostered: 'Official is <strong>rostered</strong> to a team playing this game',
            },
            schedule: {
              title: 'Schedule',
              roster_conflict: 'Official is <strong>rostered</strong> to a team <strong>playing another game</strong> at this time',
              affiliate_conflict: 'Official is an <strong>affiliated player</strong> on a team <strong>playing another game</strong>',
              game_conflict: 'Official has <strong>accepted another assignment</strong> at this time',
              game_overlap: 'Official has another assignment at this time but has not yet responded',
            },
            travel: {
              title: 'Travel',
              title_assigned_today: 'Assigned Today',
              another_venue: 'This official will be <strong>at another venue</strong> within 1 hour of this game',
              this_venue: 'This official is assigned to this venue within 3 hours of this game',
              assigned_today: 'This official is assigned <strong>today</strong>',
            },
            available: {
              title: 'Available',
              assigned: "Official has indicated that they're available during this time",
            },
            unavailable: {
              title: 'Unavailable',
              unavailable: 'Official made themselves <strong>unavailable</strong> at this time',
              no_availability: 'Official has not set any availability at this time',
              no_availability_office: 'Official has not included this organization in their availability settings',
              no_availability_arena: 'Official has not included this venue in their availability settings',
            },
            account: {
              title: 'Account',
              unverified: 'This official has no verified contact emails and <strong>will not receive assignment notifications</strong>',
              invited: 'This official does not have an account and is <strong>unable to respond to assignments</strong>. An invite was sent to this official',
              unregistered: 'This official does not have an account and is <strong>unable to respond to assignments</strong>',
            },
          },
          assignee: {
            ready: {
              title: 'Ready',
              officiate_game: "You're able to officiate this game",
            },
            confirmed: {
              title: 'Confirmed',
              accepted: "You've accepted this game assignment",
            },
            pending: {
              title: 'Pending',
              confirm_conflict: "You've been assigned to this game - accept or decline to acknowledge the scheduling change",
              confirm: "You've been assigned to this game - accept to confirm attendance",
            },
            declined: {
              title: 'Declined',
              change_response: 'You are still assigned and can change your response until an assigner removes you from this game',
              change_response_other: 'You have declined <strong>declined</strong> another position for this game. You can still change your mind and accept until an assigner removes you from this game',
            },
            no_show: {
              title: 'No show',
              removed: 'The scorekeeper removed you from the scoresheet for not showing up',
            },
            requested: {
              title: 'Requested',
              requested: "You've requested this game",
            },
            removed: {
              title: 'Removed',
              removed: "You've been removed after you %{message} this game",
            },
            expired: {
              title: 'Expired',
              expired: "Your officiating qualification has <strong>expired</strong> at this time",
            },
            suspended: {
              title: 'Suspended',
              suspended: "You've been <strong>suspended</strong> as a %{positionGroup} since %{date} %{duration}",
            },
            eligibility: {
              title: 'Ineligible',
              minimum_level: "You haven't met the <strong>minimum %{position} level</strong> for this game",
              no_grade: 'A grade has not yet been entered for you',
              minimum_grade: "You haven't met the <strong>minimum %{position} grade</strong> for this game",
              minimum_age: "You haven't met the <strong>Minimum Age</strong> for this game",
              no_age: 'You have not set your birthday yet. Please update your profile',
              limit_reached: 'You have reached the daily limit of %{smart_count} game |||| You have reached the daily limit of %{smart_count} games',
            },
            conflicts: {
              title: 'Conflicts',
              team_conflict: 'You have a <strong>team conflict</strong> for this game',
              rostered: "You're <strong>rostered</strong> to a team playing this game",
            },
            schedule: {
              title: 'Schedule',
              roster_conflict: "You're rostered to a team <strong>playing another game</strong> at this time",
              game_conflict: "You've <strong>accepted another assignment</strong> at this time",
              game_overlap: "You have another assignment at this time that you haven't responded to yet",
            },
            travel: {
              title: 'Travel',
              title_assigned_today: 'Assigned Today',
              another_venue: "You're <strong>assigned to another venue</strong> within 1 hour of this game",
              this_venue: "You're assigned to this venue within 3 hours of this game",
              assigned_today: "You're assigned <strong>today</strong>",
            },
            available: {
              title: 'Available',
              assigned: "Your calendar indicates that you're available at this time and venue",
            },
            unavailable: {
              title: 'Unavailable',
              unavailable: "You've made yourself <strong>unavailable</strong> at this time",
              no_availability: "You haven't set any availability at this time",
              no_availability_office: "You haven't included this assigner in your availability settings",
              no_availability_arena: "You haven't included this venue in your availability settings",
            },
            account: {
              title: 'Account',
              unverified: 'You need to <strong>verify your contact email</strong> to receive assignment notifications',
              invited: "You don't have an account and will be unable to respond to assignment. An invite was sent to you",
              unregistered: "You don't have an account and will be unable to respond to assignment",
            }
          }
        }
      },
      helpers: {
        split: 'Optional - use to split surface',
        number: 'Generated by draft',
        team: 'Optional - TBD if no team is specified',
        comments: 'Optional - displayed on public schedule',
        conflicts: 'Resolve conflicts or status will be enforced as Conflict',
        reset_assigner: 'Replace the existing assigner based on the assigner determination rules',
        assignment: {
          delegated_tooltip: 'Delegated to %{office}',
          pay_delegated_tooltip: 'Paid by %{office}',
          notification_will_be_sent: "A notification will be sent to this game's %{recipients} and assigned game officials.",
          note_for_accepted_game: 'Optional - This note will be used for your accepted game.',
          delegated_pay_office: 'Pay will be handled by %{office}',
        },
        scoresheet_notes: 'Optional - these notes will be publicly visible on the scoresheet',
        admin_notes: 'Optional - these notes will be private to scorekeepers, officials, and administators only',
        using_slot: 'Using a time slot',
        use_next_sequence: 'Use next available game sequence number',
      },
      notifications: {
        assignment: {
          accepted_game: 'Accepted game %{number}',
          declined_game: 'Declined game %{number}',
          requested_game: 'Requested game %{number}',
          official_assigned_to_game: 'Official assigned to game %{number}',
          remove_official_from_game: 'Removed official from game %{number}',
          official_set_to_no_show: 'Official marked as no show',
          request_removed_from_game: 'Your request has been removed from game %{number}',
          status: {
            assignee: {
              requested: "You've requested this game",
              pending: 'Pending assignment, waiting for your response.',
              confirmed: 'Your assignment is confirmed.',
              declined: "You've declined this assignment.",
            }
          },
          errors: {
            accept_overlaps: 'Cannot accept due to overlapping assignment',
            accept_consecutive: 'Cannot accept due to consecutive assignment',
            accept_consecutive_overlaps: 'Cannot accept due to consecutive overlapping assignment',
            decline_consecutive: 'Cannot decline due to consecutive assignment'
          }
        },
        recalculating: 'Recalculating...',
        game_status_changed: 'Status Changed',
        change_game_status: 'Changed %{smart_count} game status to %{status} |||| Changed %{smart_count} game statuses to %{status}',
        change_game_status_error: 'Unable to change game status  |||| Unable to change game statuses',
        delegated_games: 'Delegated %{smart_count} game successfully |||| Delegated %{smart_count} games successfully',
        delegated_games_error: 'Unable to delegate game |||| Unable to delegate games',
        changed_assigner_games: 'Changed assigner for %{smart_count} game successfully |||| Changed assigner for %{smart_count} games successfully',
        changed_assigner_games_error: 'Unable to change game assigner |||| Unable to change game assigners',
        certified_games: 'Certified %{smart_count} game |||| Certified %{smart_count} games',
        certified_games_error: 'Something went wrong while certifying the game |||| Something went wrong while certifying games',
      },
      validations: {
        invalid_date: 'Invalid date',
        invalid_time: 'Invalid time',
        must_have_date: 'Must always have a date if there is a time entered',
        must_have_date_both_times: 'Must always have both a Start Time and End Time if there is a time entered',
        end_before_start: 'End Time must always be after Start Time',
        invalid_time_check_am_pm: 'Invalid time - check AM/PM',
        invalid_must_be_same_day: 'Must be on the same day',
        spaces_not_allowed: 'Spaces are not allowed',
        after_start_time: 'Must be after start time',
        overlaps: 'This game overlaps with an existing game',
        number_exists: 'Already exists in this schedule',
        conflicts: 'Resolve conflicts or change to an inactive status',
        before_schedule_date: 'Before schedule start date',
        after_schedule_date: 'After schedule end date',
        team_not_in_cross_scheduling_groups: 'Team is not in any of the selected groups',
        team_in_both_cross_scheduling_groups: 'Team is in both selected groups',
        teams_in_same_cross_scheduling_group: 'Home and away team are both in one of the selected groups',
        unavailable_slot: 'This time slot is unavailable',
        overlapping_slot: 'Overlapping time slot',
        limit_date_change: 'You are unauthorized to change the date'
      },
      values: {
        status: STATUSES,
        assignment: {
          status: {
            Active: 'Active',
            Draft: 'Draft',
          },
          position: {
            Referee: 'Referee',
            Linesperson: 'Linesperson',
            LinespersonTwo: 'Linesperson 2',
            LinespersonThree: 'Linesperson 3',
            Official: 'Official',
            Scorekeeper: 'Scorekeeper',
            Timekeeper: 'Timekeeper',
            Supervisor: 'Supervisor',
            Participant: 'Participant',
          },
          position_short: {
            Referee: 'R',
            Linesperson: 'L',
            LinespersonTwo: 'L2',
            LinespersonThree: 'L3',
            Official: 'O',
            Scorekeeper: 'SK',
            Timekeeper: 'TK',
            Supervisor: 'S',
          }
        }
      },
      tooltips: {
        forfeited: 'This game was forfeited by %{team}',
      }
    },
    gameEvents: {
      name: 'Game Event |||| Game Events',
      fields: {
        eventType: 'Type',
        event: 'Event',
        meta: 'Metadata',
      },
      labels: {
        card: {
          title: 'Game Events',
        },
        event_id: 'Event ID',
        created: 'Created',
        account: 'Account',
        device: 'Device',
        transaction: 'Transaction',
        received: 'Received',
        game_number: 'Game Number',
        start_time: 'Start Time',
        end_time: 'End Time',
        signatory: 'Signatory',
        signature: 'Signature',
        scorekeeper: 'Scorekeeper',
        timekeeper: 'Timekeeper',
        position: 'Position |||| Positions',
        notes: 'Notes',
        game_time: 'Game Time',
        emails: 'Emails',
        system: 'System',
        history: 'History',
        homeScore: 'Home Score',
        awayScore: 'Away Score',
        goal: {
          id: 'Goal ID',
          scorer: 'Scorer',
          assists: 'Assist(s)',
          type: 'Type',
          powerplay: 'Powerplay',
          shorthanded: 'Shorthanded',
          empty_net: 'Empty Net',
          penalty_shot: 'Penalty Shot',
          own_goal: 'Own Goal'
        },
        penalty: {
          id: 'Penalty ID',
          infraction: 'Infraction',
          served_by: 'Served By',
        },
        goalie_change: {
          id: 'Goalie Change ID',
          goalie: 'Goalie',
          new_goalie: 'New goalie put in',
        },
        periods: {
          1: '1st Period',
          2: '2nd Period',
          3: '3rd Period',
          OT: 'OT Period',
          SO: 'Shootouts',
        },
        delete_events: 'Delete Game Event |||| Delete Game Events'
      },
      messages: {
        offline_for_duration: 'Offline for %{duration}',
        time_skew_duration: 'Time skewed by %{duration}',
        game_cancelled: 'Game cancelled',
        game_completed: 'Scoresheet completed',
        game_disapproved: 'Scoresheet disapproved',
        game_certified: 'Scoresheet certified',
        game_decertified: 'Scoresheet decertified',
        game_deleted: 'Game has been deleted',
        game_reset: 'Scoresheet reset',
        requested: 'requested %{position}',
        assigned_as: 'assigned as %{position}',
        accepted_assignment: 'accepted assignment',
        declined_assignment: 'declined assignment',
        added_as: 'added as %{position}',
        changed_to: 'changed to %{position}',
        removed: 'removed',
        confirm_delete_events: 'Are you sure you want to delete this game event? |||| Are you sure you want to delete these game events?'
      },
      values: {
        eventType: {
          gameCreated: 'Game Created',
          gameRescheduled: 'Game Rescheduled',
          gameUpdated: 'Game Updated',
          gameDeleted: 'Game Deleted',
          gameForfeited: 'Game Forfeited',
          gameStarted: 'Game Started',
          gameEnded: 'Game Ended',
          assignSettingsUpdated: 'Assigning Settings Updated',
          lineupUpdated: 'Lineup Updated',
          lineupApproved: 'Lineup Approved',
          lineupExtraAdded: 'Lineup Extra Added',
          lineupExtraRemoved: 'lineup Extra Removed',
          officialDelegated: 'Official Delegated',
          officialRequested: 'Official Requested',
          officialAssigned: 'Official Assigned',
          officialAccepted: 'Official Accepted',
          officialDeclined: 'Official Declined',
          officialAdded: 'Official Added',
          officialUpdated: 'Official Updated',
          officialRemoved: 'Official Removed',
          keepersUpdated: 'Keepers Updated',
          goalScored: 'Goal Scored',
          goalEdited: 'Goal Edited',
          goalDeleted: 'Goal Deleted',
          penaltyStarted: 'Penalty Started',
          penaltyEnded: 'Penalty Ended',
          penaltyEdited: 'Penalty Edited',
          penaltyDeleted: 'Penalty Deleted',
          goalieChanged: 'goalie Changed',
          goalieChangeDeleted: 'Goalie Change Deleted',
          goalieChangeEdited: 'Goalie Change Edited',
          settingsUpdated: 'Settings Updated',
          notesUpdated: 'Notes Updated',
          goalieShotsUpdated: 'Goalie Shots Updated',
          scoreUpdated: 'Score Updated',
          scoresheetApproved: 'Scoresheet Completed',
          scoresheetDisapproved: 'Scoresheet Disapproved',
          throwsInningsUpdated: 'Throws and Innings Pitched Updated',
          certificationFlagsUpdated: 'Flags Updated',
          scoresheetCertified: 'Scoresheet Certified',
          scoresheetDecertified: 'Scoresheet Decertified',
          scoresheetReset: 'Scoresheet Reset'
        }
      },
      notifications: {
        deleted: '%{smart_count} game event deleted |||| %{smart_count} game events deleted',
        delete_error: 'Could not delete game events'
      }
    },
    gameAffiliates: {
      name: 'Affiliate |||| Affiliates',
      fields: {
        gameId: 'Game',
        teamId: 'Team',
        participantId: 'Participant',
        positions: 'Positions',
        number: 'Number',
        officeId: 'Organization'
      },
      actions: {
        add: 'Add Affiliate',
      },
      labels: {
        card: {
          title: 'Affiliates'
        },
        edit: 'Update Affiliate',
      }
    },
    participants: {
      name: 'Person |||| People',
      fields: {
        participantId: 'Person',
        fullName: 'Name',
        firstName: 'First Name',
        lastName: 'Last Name',
        name: 'Name',
        HCRId: 'Member ID',
        email: 'Email Address',
        accountEmail: 'Account Email',
        phone: 'Phone Number',
        isPrimary: 'Primary',
      },
      actions: {
        edit: 'Edit Participant',
        invite_sso: 'SMA Invite'
      },
      labels: {
        card: {
          title: 'Participant',
        },
        tabs: {
          summary: 'Summary',
          profile: 'Profile',
          schedule: 'Schedule',
          registrations: 'League',
          officiating: 'Officiating',
          settings: 'Settings',
        },
        tooltips: {
          create_participant: 'Participant will be created',
          picture_update_in_id: 'To change the picture, please update in Spordle ID'
        },
        add: 'New Participant',
        account_invitation: 'Account Invitation',
        account_invitation_sso: 'Spordle My Account Invitation',
        availability: {
          card: {
            title: 'Availability',
            subtitle: 'Select the dates and times that you are available to officiate games, or block out times that you are unavailable. Open Settings to select your assigners and venues.',
          },
          title: 'My Availability',
          updated: 'Availability updated!',
          saved: 'Saved',
          saving: 'Saving...',
          note: 'Availability note',
          add_time: 'Add time',
          multiple: 'Multiple',
          settings: 'Availability Settings',
          add_associations: 'Add associations',
          my_assigners: 'My Assigners',
        },
        status: {
          roles_title: 'Missing roles',
          roles_body: "This participant cannot create an account until they're rostered to a team, are qualified as an official, or have an administrative role.",
          roles_unauthorized: "This participant cannot use their account until they're rostered to a team, are qualified as an official, or have an administrative role.",
          account_title: 'No account',
          account_body: "This participant hasn't registered an account yet. You can invite them to register.",
          invited_title: 'Invited',
          invited_body: 'An invite has been sent to this participant. You can resend the invitation if necessary.',
          contacts_title: 'No contact',
          contacts_body: 'This participant cannot receive notifications until a contact is added and verified. This does not impact the ability to use their account.',
          unverified_title: 'Not receiving notifications',
          unverified_body: 'This participant will not receive notifications until they have a verified contact with notifications enabled. This does not impact the ability to use their account.',
          roles: 'Roles',
          types: {
            Admin: 'Admin',
            League: 'League',
            Assigner: 'Assigner',
            Staff: 'Staff',
            Player: 'Player',
            Official: 'Official',
            Coach: 'Coach',
            Scorekeeper: 'Scorekeeper',
            Monitor: 'Monitor',
          }
        },
      },
      messages: {
        search_by_name: 'Enter a name to start searching',
        send_link: 'This will send a link which may be used to create a new account or connect this profile to an existing account.',
        link_sso: 'This will create a new Play account and link it to Spordle My Account',
        create_sma: 'Do you want to also create a new Spordle My Account user with this email?',
        availability: {
          placeholder: `<strong>Pick a date on the calendar to <br /> view and update availability.</strong>
          <p>Select <em>Multiple</em> to set and copy the same times across multiple dates.</p>`,
          empty_slot: "You haven't added any time yet",
          save_for: 'Save for ',
          number_dates: '%{number} dates',
          available: 'I %{input} be available',
          thing_will: 'will',
          thing_will_not: 'will not',
          person_will: 'will',
          person_will_not: 'will not',
          to: 'to',
          select_venues_travel_to: "Select the venues that you are willing to regularly travel to. If you don't select any, you'll be available for all venues.",
          search_by_association: 'Search by association name',
          enter_name_search: 'Enter a name to start searching',
          select_associations: 'Select the associations to make yourself available to officiate games for.',
        },
      },
      errors: {
        no_sma_user_found: 'No Spordle My Account user found',
        failed_sma_creation: 'Unable to create Spordle My Account user',
        failed_to_get_user: 'Failed to get Spordle My Account user',
        failed_sma_retrieval: 'Unable to retrieve Spordle My Account entity',
        email_exists: 'Email is already registered in Play',
        already_linked: 'A Play account is already linked to this participant',
        sma_exists: 'This Spordle My Account is already linked',
      },
      alerts: {
        search_by_name_hockeyid: 'Enter a name or Member ID above to start searching...',
        availability: {
          loading_associations: 'Loading associations...',
          selection: {
            title: 'Available to all venues',
            available_to_all_arenas: "Search above if you would like to optionally limit which venues you're available to.",
          },
          no_associations: {
            title: 'No associations selected',
            search_make_yourself_available: 'Search above to make yourself available to an association.',
            not_available_to_assigners: 'You will not be available to any assigners or able to view any games until you select an association.',
          },
        }
      },
      helpers: {
        note: 'Optional - will be included in the invitation',
        availability: {
          note: 'This note will be visible in the assignment panel for games during this time.',
        }
      },
      notifications: {
        invitation_sent: 'Invitation sent!',
        sso_created_and_linked: 'Play Account created and linked to Spordle My Account successfully',
        sma_created_and_linked: 'Spordle My Account and Play account created and linked successfully'
      }
    },
    qualifications: {
      name: 'Qualification |||| Qualifications',
      fields: {
        participantId: 'Participant',
        level: 'Level',
        grade: 'Grade',
        grades: {
          Referee: 'Referee Grade',
          Linesperson: 'Linesperson Grade',
        },
        qualificationCategory: {
          types: 'Qualification'
        },
        officeId: 'Organization',
        seasonId: 'Season',
        expiry: 'Expires',
        age: 'Age',
        qualificationCategoryId: 'Category',
      },
      actions: {
        add: 'Add Qualification',
      },
      labels: {
        card: {
          title: 'Qualifications',
        },
        edit: 'Update Qualification',
        grades_file: 'Grades File',
        qualifications_file: 'Qualifications File',
        import_qualifications: 'Import Qualifications',
        import_grades: 'Import Grades',
        official_level: 'Official Level',
        qualification_category: 'Qualification Category',
      },
      messages: {
        drop_qualifications_csv: 'Drop your qualifications file (.csv) here',
        drop_grades_csv: 'Drop your grades file (.csv) here',
        import_tool_grades: "To use this import tool, upload a CSV file with columns for the official's unique Member ID, Referee Grade, and Linesperson Grade.",
        import_tool_qualifications: "To use this import tool, upload a CSV file with columns for the official's unique Member ID, First Name, Last Name, Official Level, Referee Grade (optional), Linesperson Grade (optional), and Expiry.",
      },
      alerts: {
        create_new_participants: '<strong>Use with caution.</strong> This import tool will create new participants that do not match existing Member IDs.',
      },
      helpers: {
        select_columns: "You'll be asked to select columns after selecting your file",
        valid_grades: '%{number}/%{total} rows have valid grades',
        valid_qualifications: '%{number}/%{total} rows have valid qualifications',
        participant_not_matched: 'Participants will be ignored if a qualification is not found under this organization.',
        match_existing_participant: 'This Member ID is used to match an existing participant or create a new participant for this qualification.',
      },
      notifications: {
        grades_updated: '%{smart_count} official grade was updated. |||| %{smart_count} official grades were updated.',
        qualifications_updated: '%{smart_count} official qualification was updated. |||| %{smart_count} official qualifications were updated.',
        import_grades_error: 'Unable to import grades.',
        import_qualifications_error: 'Unable to import qualifications.',
      },
      validations: {
        invalid_grade: 'Some rows have an invalid grade',
        level: 'Must be greater than or equal to 0.',
        invalid_date: 'Some values do not include a valid date (YYYY-MM-DD)',
      },
      values: {
        level: {
          zero: '0',
          level_zero: 'Level 0',
        },
      },
    },
    qualificationCategories: {
      values: {
        types: {
          Official: 'Official',
          Scorekeeper: 'Scorekeeper'
        }
      }
    },
    officialOffices: {
      filters: {
        officialName: 'Official Name'
      },
      fields: {
        types: 'Types',
      }
    },
    officials: {
      name: 'Official |||| Officials',
      fields: {
        participantId: 'Participant',
        officeId: 'Organization',
        payOfficeId: 'Pay Organization',
        seasonId: 'Season'
      },
      actions: {
        add: 'Add Official',
      },
      labels: {
        card: {
          title: 'Officials',
          subtitle: 'Available officials for assigning games.'
        },
        edit: 'Update Official',
        official_id: 'Official ID',
        position: 'Position',
        team: 'Team',
        file: 'Officials File',
        import: 'Import Officials',
      },
      messages: {
        drop_csv: 'Drop your officials file (.csv) here',
        import_tool: "To use this import tool, upload a CSV file with columns for the official's unique Member ID.",
      },
      filters: {
        unavailable: 'Unavailable officials',
        ineligible: 'Ineligible officials',
        expired: 'Expired officials',
        unregistered: 'Unregistered officials',
        new: 'New officials',
        local: 'Local officials',
        ignore_arena: 'Ignore venue availability',
        match_all: 'Match all',
        match_any: 'Match any',
      },
      helpers: {
        match_existing_participant: 'Participants will be ignored if they are not a qualified official.',
      },
      notifications: {
        added: '%{smart_count} official was added. |||| %{smart_count} officials were added.',
        unqualified: '%{smart_count} failed because they were unqualified.',
        import_error: 'Unable to import qualifications.',
      },
    },
    scorekeepers: {
      name: 'Scorekeeper |||| Scorekeepers',
      labels: {
        card: {
          title: 'Scorekeepers',
          subtitle: 'Available scorekeepers for assigning games.'
        }
      }
    },
    officialConflicts: {
      name: 'Conflict |||| Conflicts',
      fields: {
        seasonId: 'Season',
        participantId: 'Participant',
        targetId: 'Conflict',
      },
      actions: {
        add: 'Add Conflict',
      },
      labels: {
        card: {
          title: 'Conflicts',
          subtitle: "The following games are scheduled at the same time with the same teams or venue. If no active games are listed, this game's status may be changed to Active.",
        },
        edit: 'Update Conflict',
        add_team: 'Add Team',
      }
    },
    offices: {
      name: 'Organization |||| Organizations',
      fields: {
        officeId: 'Organization',
        name: 'Name',
        type: 'Type',
        HCRId: 'Organization ID',
        parentId: 'Parent Organization',
        logo: 'Logo',
        timezone: 'Timezone',
      },
      actions: {
        add: 'Add Organization',
        edit: 'Edit Organization',
        internal: 'Internal Actions',
        syncOffices: 'Sync organizations',
        syncTeams: 'Sync teams',
        syncOfficials: 'Sync qualifications',
        syncRegistrations: 'Sync registrations',
      },
      labels: {
        card: {
          title: 'Organizations',
          sub_offices_title: 'Sub Organizations',
        },
        tabs: {
          summary: 'Summary',
          teams: 'Teams',
          surfaces: 'Surfaces',
          schedules: 'Schedules',
          people: 'People',
          assigning: 'Assigning',
          scheduler: 'Scheduler',
          rulebook: 'Rulebook',
          settings: 'Settings',
        },
        add: 'New Organization',
        edit: 'Update Organization',
        members: 'Organization Members',
        availability: {
          card: {
            title: 'Availability',
            subtitle: 'Select the dates and times that are available, or block out times that are unavailable',
          },
          title: 'Organization Availability',
          note: 'Availability note',
          updated: 'Availability updated!',
        },
        analyzeSchedules: 'Analyze Schedules'
      },
      messages: {
        availability: {
          available: 'This organization %{input} be available',
        }
      },
      helpers: {
        name: 'Organizations should generally not contain the division.',
        availability: {
          note: 'This note will be visible when scheduling games during this time.',
        }
      },
      validations: {
        same_office: 'Parent cannot be the same organization',
      },
      values: {
        type: {
          Branch: 'Branch',
          Region: 'Region',
          District: 'District',
          Association: 'Association',
          Administrative: 'Administrative',
          Historical: 'Historical',
          League: 'League',
          Tournament: 'Tournament',
          Zone: 'Zone',
          Organization: 'Federation',
          Cup: 'Cup',
        },
        scheduleType: TYPES,
      }
    },
    officeMembers: {
      name: 'Organization Member |||| Organization Members',
      fields: {
        officeId: 'Organization',
      },
      actions: {
        add: 'Add Organizations',
      },
      labels: {
        card: {
          title: 'Organization Members',
        },
        edit: 'Update Member',
      }
    },
    branchOfficeSettings: {
      name: 'Branch Organization Setting |||| Branch Organization Settings',
      fields: {
        certifiedOfficialChanges: 'Allow Change Officials on Certified Games',
        certifiedOfficialChanges_off: "Disabled",
        certifiedOfficialChanges_on: "Enabled",
        ff_suspensions: 'Suspensions',
        ff_suspension_disciplines: 'Suspension Disciplines',
        ff_incidents: 'Incidents'
      },
      labels: {
        assignments: 'Official Assignments',
        tabs: {
          general: 'General',
          featureFlags: 'Feature Flags',
        },
        card: {
          title: 'Branch Settings'
        }
      },
      helpers: {
        certifiedOfficialChanges: 'Allow all organizations under this branch to change officials even if the game is certified by the league.',
        ff_suspensions: 'Allow the management of suspensions in games',
        ff_suspension_disciplines: 'Allow the management of additional discipline on suspensions (Discipline Committee)',
        ff_incidents: 'Allow incident reports in games'
      },
      actions: {
        edit: 'Edit Branch Settings'
      }
    },
    officeSettings: {
      name: 'Organization Setting |||| Organization Settings',
      fields: {
        skaterStatsHiddenDivisionIds: 'Player Stats Hidden Divisions',
        goalieStatsHiddenDivisionIds: 'Goalie Stats Hidden Divisions',
        standingsHiddenDivisionIds: 'Standings Hidden Divisions',
        boxScoreHiddenDivisionIds: 'Box Score Hidden Divisions',
        sportsmanshipPoints: 'Sportsmanship Points',
        contactsVisibility: 'Contacts Visibility',
        adminReports: 'Administrative Reports',
        teamReports: 'Team Reports',
        syncAttributes: 'HCR Sync Attributes',
        updateRosterFromLineups: 'Update Team Roster From Scoresheet',
        gameLength: 'Default Surface Reservation Time',
        surfaceOfficeId: "Surface Organization",
        conflictDrafts: 'Include draft games during enforcement',
        conflictLegacyDrafts: 'Include legacy draft games during enforcement',
        availabilityEnforcement: 'Availabilities',
        conflictEnforcement: 'Games',
        constraintEnforcement: 'Constraints',
        language: 'Default Language',
        homeTeamScorekeeping: 'Home Team Scorekeeping',
        awayTeamScorekeeping: 'Away Team Scorekeeping',
        scoringMode: 'Scoring Mode',
        penaltyMode: 'Penalty Mode',
        requireCertification: 'Game Certification',
        requireIncidentReports: 'Incident Reports',
        suspensionActivation: 'Suspension Activation',
        includeRegistrations: 'Include Registrations',
        allowExtras: 'Allow Extras',
        homeChangeStatus: 'Home Team Scheduling',
        maxScoreDiff: 'Max Score Differential',
        points: {
          Team: {
            win: 'Win',
            loss: 'Loss',
            tie: 'Tie',
            soWin: 'Shootout Win',
            soLoss: 'Shootout Loss',
            otWin: 'Overtime Win',
            otLoss: 'Overtime Loss',
            forfeitWin: 'Forfeit Win',
            forfeitLoss: 'Forfeit Loss',
            forfeitTie: 'Double Forfeit',
            sportsmanship: 'Sportsmanship',
          },
          Player: {
            goal: 'Goal',
            assist: 'Assist'
          },
          preview: `Win: %{win} Loss: %{loss} Tie: %{tie}`
        },
        enforcements: {
          preview: {
            conflictEnforcement: `Games: %{enforcementValue}`,
            availabilityEnforcement: `Availabilities: %{enforcementValue} `,
            constraintEnforcement: `Constraints: %{enforcementValue}`,
          }
        },
        deferDraftConflict: 'Defer conflict enforcement in drafts',
        deferLegacyDraftConflict: 'Defer conflict enforcement in legacy drafts',
        limitDateChange: 'Limit date change on shared draft games',
        rescheduleRequestsEnabled: 'Reschedule Requests',
        requiredRescheduleApprovals: 'Required Reschedule Approvals',
        groupingType: 'Team Grouping Type',
      },
      actions: {
        edit: 'Edit Settings',
      },
      labels: {
        card: {
          title: 'Organization Settings',
        },
        tabs: {
          general: 'General',
          scheduling: 'Scheduling',
          team_stats: 'Team Stats',
          player_stats: 'Player Stats',
          scoresheet: 'Scoresheet',
          advanced: 'Advanced',
        },
        schedule_stats: 'Schedule Stats',
        game_stats: 'Game Stats',
        stats_schema: {
          team: 'Team Stats Schema',
          skater: 'Player Stats Schema',
          goalie: 'Goalie Stats Schema',
        },
        standings_order: {
          team: 'Team Standings Order',
          skater: 'Player Standings Order',
          goalie: 'Goalie Standings Order',
          wins: 'Wins',
          losses: 'Losses',
          otl: 'Overtime Losses',
          ties: 'Ties',
          diff: 'Points Differential',
          points: 'Points',
          pointsPct: 'Points Percentage',
          pointsPerGame: 'Points Per Game',
          gamesPlayed: 'Games Played',
          goalAgainst: 'Goals Against',
          goalAgainstPerGame: 'Goals Against Per Game',
          goalFor: 'Goals For',
          goalForPerGame: 'Goals For Per Game',
          shotFor: 'Shots For',
          shotAgainst: 'Shots Against',
          sportsmanship: 'Sportsmanship Points',
          gaa: 'Goals Against Average',
          shutouts: 'Shutouts',
          gamesStarted: 'Games Started',
          minutesPlayed: 'Minutes Played',
          savePercentage: 'Save Percentage',
          goals: 'Goals',
          assists: 'Assists',
          gameTyingGoals: 'Game-Tying Goals',
          gameWinningGoals: 'Game-Winning Goals',
          powerplayGoals: 'Powerplay Goals',
          powerplayPoints: 'Powerplay Points',
          powerplayAssists: 'Powerplay Assists',
          shorthandedGoals: 'Shorthanded Goals',
          shorthandedPoints: 'Shorthanded Points',
          shorthandedAssists: 'Shorthanded Assists',
          pim: 'Penalty Minutes',
          pimPerGame: 'Penalty Minutes Per Game',
          penaltyOccurrences: 'Penalty Occurrences',
          throws: 'Pitch Count',
          inningsPitched: 'Innings Pitched',
        },
        standings_type: {
          'All Games': 'All Games',
          'Head-to-head': 'Head-to-head',
        },
        standings_subject: {
          team: 'Team',
          skater: 'Player',
          goalie: 'Goalie',
        },
        hidden_stats: {
          team: 'Hidden Team Stats',
          skater: 'Hidden Player Stats',
          goalie: 'Hidden Goalie Stats',
        },
        reports: 'Reports',
        contacts: 'Contacts',
        points: 'Points',
        standings: 'Standings',
        rosters: 'Rosters',
        scheduling: 'Scheduling',
        conflict_enforcement: 'Conflict Enforcement',
        public: 'Public Display',
        admin: 'Admin',
        integration: 'Integration',
        game_length: 'Default Game Length',
        teamScorekeeping: 'Scorekeeping',
        certification: 'Certification',
        suspensions: 'Suspensions',
        scorekeeping: 'Scorekeeping',
        periods: 'Default Periods',
        drafts: 'Drafts',
        points_system: 'Team Points System',
        admin_reports: 'Administrative Reports',
        team_reports: 'Team Reports',
        edit_office: 'Edit Organization Settings',
      },
      messages: {
        load_error: 'Unable to load the organization settings',
      },
      alerts: {
        new_schedules_only: 'These are defaults for newly created schedules. Existing schedules will not be affected.',
        advanced: 'These are advanced integration settings that determine how syncing is handled.',
      },
      helpers: {
        adminReports: 'Affects all administrative roles except Admin',
        teamReports: 'Team officials will only see team-based reports',
        sportsmanshipPoints: 'Additional points based on penalty infractions',
        statsVisibility: 'This setting determines which schedules will have the stats hidden from public pages',
        standingsVisibility: 'This setting determines which schedules will have the standings hidden from public pages',
        boxScoreVisibility: 'This setting determines which schedules will have the box score hidden from public pages',
        hiddenStats: 'Hide specific stat columns from public pages',
        updateRosterFromLineups: 'Submitting a game lineup will update jersey numbers on the team roster',
        gameLength: 'Optional - Total duration (in minutes) reserved on a playing surface for all games of that schedule',
        surfaceOfficeId: "Optional - Surfaces will come from this organization instead of the home team's organization in the draft scheduler",
        conflictDrafts: 'Draft games are excluded when searching for games to enforce against unless this setting is enabled',
        conflictLegacyDrafts: 'Legacy draft games are excluded when searching for games to enforce against unless this setting is enabled',
        homeTeamScorekeeping: 'Home team officials will have full access to edit the scoresheet until game completion',
        awayTeamScorekeeping: 'Away team officials will have full access to edit the scoresheet until game completion',
        scoringMode: 'How goals are entered for a game',
        penaltyMode: 'How penalties are entered for a game',
        certification: 'Games will require league certification after completion by game officials',
        incidentReports: 'Games will require incident reports for all major penalties prior to completion',
        suspensionActivation: 'When should a suspension automatically become active',
        includeRegistrations: "Registered players based on the team's association, division and season will be included as lineup options.",
        allowExtras: 'Scorekeepers and team officials will be allowed to add players that are not registered to their team roster. This setting does not affect league administrators/managers.',
        homeChangeStatus: "Allow the home team's organization to reschedule games",
        language: 'Default contact language for notifications',
        deferDraftConflict: 'Conflicts will only be enforced in drafts once published',
        deferLegacyDraftConflict: 'Conflicts will only be enforced in legacy drafts once published',
        limitDateChange: 'Associations will not be able to change the date on shared drafts games',
        rescheduleRequestsEnabled: 'Allow teams to request game changes',
        requiredRescheduleApprovals: 'Required parties to approve reschedule requests',
        required_approvals: {
          'Team Staff': 'Requires approval from both teams',
          'Team Organization': 'Requires approval from both team organizations',
          'Schedule Organization': 'Requires approval from the schedule organization',
        },
        groupingType: 'The type of grouping used for teams in newly created schedules',
      },
      tooltips: {
        groupingType: `
          <div>
            <ul>
              <li>
                <strong>Groups:</strong> Also known as Flights or Tiers. They gives the ability to split schedules into multiple sub schedules within a league or category to group teams based on skill level. Groups are defined at the organization level and can have permissions tied to them. Groups live on games and schedule teams and split the display of games and stats of the schedule.
              </li>
              <li>
                <strong>Pools:</strong> Group teams for round-robin purposes directly in schedules. Pools live on schedule teams only and are used for scheduling and the display of ranking.
              </li>
            </ul>
          </div>
        `
      },
      values: {
        sportsmanship_points: {
          none: 'None',
          PCAHA: 'PCAHA Regulations',
          VIAHA: 'VIAHA Regulations',
        },
        sync_attributes: {
          position: 'Position only',
          all: 'Position and jersey numbers',
        },
        contacts: {
          profiles: 'Profiles only',
          schedules: 'Schedules and profiles',
          teams: 'Teams, schedules, and profiles',
        },
        reports: {
          penalty: 'Penalty',
          scoring: 'Scoring',
          ap: 'AP',
        },
        stats: {
          name: 'Stats',
          skater: 'Player stats',
          goalie: 'Goalie stats',
        },
        enforcement: {
          none: 'Do nothing',
          conflict: 'Save as Conflict',
          validation: 'Show a validation error',
        },
        suspension_activation: {
          manual: 'Manual',
          approval: 'On Game Approval',
          certification: 'On Game Certification',
        },
        scoring_mode: {
          detailed: 'Detailed',
          score: 'Score Only',
        },
        penalty_mode: {
          detailed: 'Detailed',
        },
        required_approvals: {
          'Team Staff': 'Team Staff',
          'Team Organization': 'Team Organization',
          'Schedule Organization': 'Schedule Organization',
        },
        groupingType: {
          Pools: 'Pools',
          Groups: 'Groups',
        }
      }
    },
    leagues: {
      name: 'League |||| Leagues',
      fields: {
        officeId: 'League',
        name: 'Name',
        type: 'Type',
        HCRId: 'League ID',
        parentId: 'Organization',
        logo: 'Logo',
      },
      actions: {
        add: 'Add League',
        edit: 'Edit League',
      },
      labels: {
        card: {
          title: 'Leagues',
        },
        tabs: {
          summary: 'Summary',
          teams: 'Teams',
          surfaces: 'Surfaces',
          settings: 'Settings',
        },
        add: 'New League',
        edit: 'Update League',
        editing: 'Editing %{name}',
        availability: {
          card: {
            title: 'Availability',
            subtitle: 'Select the dates and times that are available, or block out times that are unavailable',
          },
          title: 'League Availability',
          note: 'Availability note',
          updated: 'Availability updated!',
        },
      },
      messages: {
        availability: {
          available: 'This league %{input} be available',
        }
      },
      helpers: {
        name: 'Leagues should generally not contain the division.',
        availability: {
          note: 'This note will be visible when scheduling games during this time.',
        }
      },
    },
    tournaments: {
      name: 'Tournament |||| Tournaments',
      fields: {
        officeId: 'Tournament',
        name: 'Name',
        type: 'Type',
        HCRId: 'Tournament ID',
        parentId: 'Organization',
        logo: 'Logo',
      },
      actions: {
        add: 'Add Tournament',
        edit: 'Edit Tournament',
      },
      labels: {
        card: {
          title: 'Tournaments',
        },
        tabs: {
          summary: 'Summary',
          teams: 'Teams',
          surfaces: 'Surfaces',
          settings: 'Settings',
        },
        add: 'New Tournament',
        edit: 'Update Tournament',
        editing: 'Editing %{name}',
        availability: {
          card: {
            title: 'Availability',
            subtitle: 'Select the dates and times that are available, or block out times that are unavailable',
          },
          title: 'Tournament Availability',
          note: 'Availability note',
          updated: 'Availability updated!',
        },
      },
      messages: {
        availability: {
          available: 'This tournament %{input} be available',
        }
      },
      helpers: {
        name: 'Tournament organizations should generally not contain the division.',
        availability: {
          note: 'This note will be visible when scheduling games during this time.',
        }
      },
    },
    cups: {
      name: 'Cup |||| Cups',
      fields: {
        officeId: 'Cup',
        name: 'Name',
        type: 'Type',
        HCRId: 'Cup ID',
        parentId: 'Organization',
        logo: 'Logo',
      },
      actions: {
        add: 'Add Cup',
        edit: 'Edit Cup',
      },
      labels: {
        card: {
          title: 'Cups',
        },
        tabs: {
          summary: 'Summary',
          teams: 'Teams',
          surfaces: 'Surfaces',
          settings: 'Settings',
        },
        add: 'New Cup',
        edit: 'Update Cup',
        editing: 'Editing %{name}',
        availability: {
          card: {
            title: 'Availability',
            subtitle: 'Select the dates and times that are available, or block out times that are unavailable',
          },
          title: 'Cup Availability',
          note: 'Availability note',
          updated: 'Availability updated!',
        },
      },
      messages: {
        availability: {
          available: 'This cup %{input} be available',
        }
      },
      helpers: {
        name: 'Cup organizations should generally not contain the division.',
        availability: {
          note: 'This note will be visible when scheduling games during this time.',
        }
      },
    },
    members: {
      name: 'Member |||| Members',
      fields: {
        participantId: 'Participant',
        teamId: 'Team',
        positions: 'Positions',
        number: 'Number',
        batter: 'Batter',
        extra: 'Extra',
        isCaptain: 'Captain',
        isAlternate: 'Alternate',
        isAffiliate: 'Affiliate',
        isOverage: 'Over-age',
        isUnderage: 'Under-age',
        isReleased: 'Released',
        isStarter: 'Starter',
        status: 'Status',
        abbreviations: {
          isCaptain: 'C',
          isAlternate: 'A',
          isAffiliate: 'AP',
          isOverage: 'OA',
          isUnderage: 'UA',
          isReleased: 'Rls',
          isStarter: 'S',
        }
      },
      actions: {
        add: 'Add Member',
      },
      labels: {
        edit: 'Update Member',
        starter_goalie: 'Starter Goalie',
        suspension: 'Suspension',
        suspension_abbreviation: 'SUS',
        suspended: 'Suspended',
        position: 'Position',
        jersey: 'Jersey',
        battingOrder: 'Batting Order',
        current_game: 'Current Game',
        total_games: 'Total Games',
        player: 'Player',
        staff: 'Staff',
      },
      messages: {
        has_been_added: '%{name} has been added',
      },
      helpers: {
        isCaptain: 'Team Captain',
        isAlternate: 'Team Alternate Captain',
      },
      validations: {
        must_have_number: 'Players must have a number',
        missing_jersey_number: 'Missing jersey number',
      },
      values: {
        positions: {
          F: 'F',
          C: 'C',
          D: 'D',
          G: 'G',
          Manager: 'Manager',
          'Head Coach': 'Head Coach',
          'Assistant Coach': 'Assistant Coach',
          'Goaltending Coach': 'Goaltending Coach',
          'Safety Person': 'Safety Person',
          Trainer: 'Trainer',
        }
      }
    },
    schedules: {
      name: 'Schedule |||| Schedules',
      fields: {
        name: 'Name',
        seasonId: 'Season',
        startDate: 'Start Date',
        endDate: 'End Date',
        teamIds: 'Teams',
        officeId: 'Organization',
        categoryId: 'Category',
        type: 'Type',
        isPublic: 'Public',
        scheduleType: 'Schedule Type',
        pastEvents: 'Past Schedules',
        gameFormat: 'Format',
        comments: 'Comments',
        brackets: {
          totalTeams: 'Number of teams',
          format: 'Format',
          orientation: 'Orientation',
          bracketId: 'Bracket',
          groupId: 'Group'
        },
      },
      filters: {
        pastEvents: 'Show past schedules',
      },
      actions: {
        add: 'Add Schedule',
        edit: 'Edit Schedule',
        show: 'Show Schedule',
        internal: 'Internal Actions',
        recalculateStats: 'Recalculate stats',
      },
      labels: {
        card: {
          title: 'Schedules',
        },
        tabs: {
          summary: 'Summary',
          teams: 'Teams',
          games: 'Games',
          bracket: 'Bracket',
          scheduler: 'Scheduler',
          stats: 'Stats',
          standings: 'Standings',
          contacts: 'Contacts',
          settings: 'Settings',
        },
        steps: {
          schedule_info: 'Schedule Information',
          team_select: 'Select Teams',
          add_sequences: 'Add Game Number Sequences',
          schedule_settings: 'Schedule Settings'
        },
        matrix: {
          show_organisation: 'Show Teams Organization',
          show_full_names: 'Show Full Team Names',
          compact_mode: 'Compact Table',
        },
        brackets_viewer: {
          open_in_viewer: 'Open in viewer | Download',
          open_new_window: 'Open in a new window',
          download: 'Download',
          close: 'Close',
          zoom_in: 'Zoom in',
          zoom_out: 'Zoom out',
          no_preview: {
            text: 'No preview available',
            no_data: 'No data',
          }
        },
        add: 'New Schedule',
        generate: 'Generate Schedule',
        generate_legacy: 'Generate Schedule (Legacy)',
        upload: 'Upload Schedule',
        upload_legacy: 'Upload Schedule (Legacy)',
        draft: 'Draft Schedule',
        draft_legacy: 'Draft Schedule (Legacy)',
        league_contacts: 'League Contacts',
        dates: 'Dates',
        competitive: 'Competitive',
        recreational: 'Recreational',
        accredited_school: 'Accredited School',
        add_to_group: 'Add to group',
        add_to_pool: 'Add to pool',
        bracket_schedule: 'Bracket Schedule',
        bracket_reseed: 'Reseed Bracket Games',
        bracket_reseed_info: "Add or update teams to bracket games with seeded positions according to their group team order",
        group_bracket_reseed: "Group bracket to reseed",
        reseed_bracket: 'Reseed a bracket',
        bracket_info: 'Create games using a bracket schedule',
        create_bracket: 'Create Bracket Schedule',
        brackets_info: 'As games are completed, teams will automatically advance to subsequent games based on results.',
        brackets_na: 'No matching bracket templates',
        preview: 'Preview',
        analysis: 'Analysis',
        matchups: 'Matchups',
        current_matchups: 'Current Matchups',
        new_matchups: 'Draft Matchups',
        office_matchups: 'Organization Matchups',
        include_drafts: 'Include draft games',
        byes: 'Byes',
        team: 'Team',
        group: 'Group',
        pool: 'Pool',
        opponents: 'Opponents',
        conflicts: 'Conflicts',
        home: 'Home',
        away: 'Away',
        total: 'Total',
        week: 'Week',
        scheduled_events: 'Full Schedule',
        not_public: 'Not Public',
      },
      messages: {
        no_byes: 'No byes',
        no_matchups: 'No matchups',
        too_many_matchups: 'Too many matchups to display',
        unable_to_load: 'Unable to load analysis',
        additional_games: '+ %{smart_count} more',
      },
      notifications: {
        created: 'Schedule created',
        updated: "Schedule updated",
        recalculating: 'Recalculating...',
        bracket_games_created: 'Bracket games created',
        bracket_games_reseeded: 'Bracket games reseeded',
        before_schedule_date: 'Cannot schedule game before schedule start date (%{startDate})',
        after_schedule_date: 'Cannot schedule game after schedule end date (%{endDate})'
      },
      alerts: {
        missing_sequence: 'The game number sequence has not been set for this schedule',
      },
      tooltips: {
        hidden_to_public: 'This schedule is not publicly visible',
      },
      helpers: {
        cohorts: 'Cohorts can be created as groups under the league settings to keep everything in the same schedule.',
        gameFormat: 'Optional',
        isPublic: 'By activating this option, the schedule will be publicly available on Spordle PAGE'
      },
      validations: {
        end_after_start: 'End Date must be after Start Date',
      },
      errors: {
        brackets: {
          existing_brackets: 'Brackets already exist in this group',
          missing_seeded_games: 'No bracket games with seeded positions found',
          missing_schedule_teams: 'No teams found in this group for reseeding',
          missing_sequence: 'No sequence found for game numbering',
        },
      },
      values: {
        type: TYPES,
        orientations: {
          Portrait: 'Portrait',
          Landscape: 'Landscape',
        },
        gameFormat: {
          '3x3': '3x3',
          '4x4': '4x4',
          '5x5': '5x5',
          '6x6': '6x6',
          '7x7': '7x7',
          '9x9': '9x9',
          '11x11': '11x11',
        }
      },
    },
    scheduleSettings: {
      name: 'Setting |||| Settings',
      fields: {
        gameLength: 'Default Surface Reservation Time',
        surfaceOfficeId: "Surface Organization",
        surfaceSizes: 'Surface Sizes',
        includeRegistrations: 'Include Registrations',
        allowExtras: 'Allow Extras',
        sportsmanshipPoints: 'Sportsmanship Points',
        disableSportsmanshipCarry: 'Limit sportsmanship points to same group',
        maxScoreDiff: 'Max Score Differential',
        scoringMode: 'Scoring Mode',
        penaltyMode: 'Penalty Mode',
        points: {
          Team: {
            win: 'Win',
            loss: 'Loss',
            tie: 'Tie',
            soWin: 'Shootout Win',
            soLoss: 'Shootout Loss',
            otWin: 'Overtime Win',
            otLoss: 'Overtime Loss',
            forfeitWin: 'Forfeit Win',
            forfeitLoss: 'Forfeit Loss',
            forfeitTie: 'Double Forfeit',
            sportsmanship: 'Sportsmanship',
          },
          Player: {
            goal: 'Goal',
            assist: 'Assist'
          },
          preview: `Win: %{win} Loss: %{loss} Tie: %{tie}`
        },
        groupingType: 'Team Grouping Type',
      },
      actions: {
        edit: 'Edit Settings',
      },
      labels: {
        tabs: {
          scheduling: 'Scheduling',
          scoresheet: 'Scoresheet',
          team_stats: 'Team Stats',
          player_stats: 'Player Stats',
          advanced: 'Advanced',
        },
        schedule_stats: 'Schedule Stats',
        game_stats: 'Game Stats',
        stats_schema: {
          team: 'Team Stats Schema',
          skater: 'Player Stats Schema',
          goalie: 'Goalie Stats Schema',
        },
        standings_order: {
          team: 'Team Standings Order',
          skater: 'Player Standings Order',
          goalie: 'Goalie Standings Order',
        },
        points_system_ranking: {
          property: 'Property',
          order: 'Order',
          type: 'Type',
        },
        hidden_stats: {
          team: 'Hidden Team Stats',
          skater: 'Hidden Player Stats',
          goalie: 'Hidden Goalie Stats',
        },
        points: 'Points',
        edit_schedule: 'Edit Schedule Settings',
        game_length: 'Default Game Length',
        include_registrations: 'Registrations',
        allow_extras: 'Allow Lineup Extras',
        points_system: 'Team Points System',
        standings: 'Standings',
        scheduling: 'Scheduling',
        lineups: 'Lineups',
        public: 'Public Display',
        scorekeeping: 'Scorekeeping',
      },
      messages: {
        load_error: 'Unable to load the schedule settings',
        included: 'Included',
        allow_extras_default: 'League administrators & team officials',
        standard_description: 'W = 2, T = 1, OTL = 1',
        '3-2-1_description': 'Extra point awarded for wins during regulation time or forfeit',
        high_low: 'High to low',
        low_high: 'Low to high',
      },
      alerts: {
        advanced: 'These are advanced integration settings that determine how syncing is handled.',
        new_scoresheets_only: 'These are defaults for newly created scoresheets. Existing scoresheets will not be affected.',
      },
      validations: {
        game_length: 'Has to be more than 0',
        scoring_mode_change: 'Cannot change scoring mode while there are completed games',
        penalty_mode_change: 'Cannot change penalty mode while there are completed games'
      },
      helpers: {
        gameLength: 'Optional - Total duration (in minutes) reserved on a playing surface for all games of that schedule',
        surfaceOfficeId: "Optional - Surfaces will come from this organization instead of the home team's organization in the draft scheduler",
        surfaceSizes: 'Optional - Determines the default set of sizes filtered when scheduling',
        includeRegistrations: "Registered players based on the team's association, division and season will be included as lineup options.",
        allowExtras: 'Scorekeepers and team officials will be allowed to add players that are not registered to their team roster. This setting does not affect league administrators/managers.',
        sportsmanshipPoints: 'Additional points based on penalty infractions',
        disableSportsmanshipCarry: 'When a team is moved from one group to another, as applicable, sportsmanship points will be accumulated from all groups unless this setting is enabled.',
        schema: 'Schema required',
        statsVisibility: 'This setting determines which schedules will have the stats hidden from public pages',
        scoringMode: 'How goals are entered for a game',
        penaltyMode: 'How penalties are entered for a game',
        groupingType: 'The type of grouping used for teams in newly created schedules',
      },
      values: {
        includeRegistrations: 'Rostered players & extras only',
        allowExtras: 'League administrators only',
        disableSportsmanshipCarry: 'Sportsmanship points are limited to same group',
        ranking_type: {
          'All Games': 'All Games',
          'Head-to-head': 'Head-to-head'
        },
        ranking_order: {
          DESC: 'Descending',
          ASC: 'Ascending',
        },
      }
    },
    schedulingConstraints: {
      fields: {
        resource: 'Resource',
        periodType: 'Period Type',
        limit: 'Maximum games',
        categoryIds: "Categories",
        scheduleTypes: 'Schedule Types',
        period: {
          days: ' Total days',
          weekdays: 'Weekdays',
          dateRange: 'Date Range',
          startDate: 'Start Date',
          endDate: 'End Date'
        }
      },
      values: {
        scheduleTypes: TYPES,
        Team: 'Team',
        Schedule: 'Schedule',
        Arena: 'Arena'
      },
      actions: {
        add_constraint: 'Add Constraint',
        edit_constraint: 'Edit Constraint'
      },
      labels: {
        card: {
          title: 'Constraints',
          subtitle: 'Set constraints to limit the number of games played. This will mark games as conflicts if the constraints are not followed.'
        },
        restrictions: 'Restrictions',
        period: 'Period'
      },
      helpers: {
        schedules: 'Optional - All schedules if empty',
        limit: `Exceeds %{smart_count} game limit |||| Exceeds %{smart_count} game limit`,
        period: `on the same day |||| within a %{smart_count} day period.`
      },
      messages: {
        summary: `The %{resource}s in this organization are not to %{verb} more than %{smart_count} game |||| The %{resource}s in this organization are not to %{verb} more than %{smart_count} games`,
        days: `on the same day. |||| within a %{smart_count} day period.`,
        host: 'host',
        play: 'play',
        have: 'have',
        between: ' between %{start} - %{end}.',
        on: ' on %{weekdays}.'
      },
      validations: {
        end_after_start: 'End Date must be after Start Date',
        greater_than_zero: 'Must be greater than 0'
      },
    },
    teams: {
      name: 'Team |||| Teams',
      fields: {
        teamId: 'Team',
        name: 'Name',
        shortName: 'Short Name',
        HCRId: 'Team ID',
        seasonId: 'Season',
        categoryId: 'Category',
        officeId: 'Organization',
        logo: 'Logo',
        arenaId: 'Surface',
      },
      actions: {
        add: 'Add Team',
        edit: 'Edit Team',
        internal: 'Internal Actions',
        sync: 'Sync',
      },
      labels: {
        card: {
          title: 'Teams',
        },
        calendar: {
          teamNames: 'Team Names'
        },
        tabs: {
          summary: 'Summary',
          roster: 'Roster',
          schedule: 'Schedule',
          settings: 'Settings',
        },
        edit: 'Update Team',
        add: 'New Team',
        contacts: 'Team Contacts',
        team_roster: 'Team Roster',
        import_roster: 'Import Roster',
        import_profiles: 'Import Profiles',
        roster_file: 'Roster File',
        profile_file: 'Profile File',
        availability: {
          card: {
            title: 'Availability',
            subtitle: 'Select the dates and times that are available, or block out times that are unavailable',
          },
          title: 'Team Availability',
          note: 'Availability note',
          updated: 'Availability updated!',
        },
      },
      messages: {
        home_team_hcr_id: 'Home Team ID',
        valid_members: '%{number}/%{total} rows have valid members',
        drop_roster_file: 'Drop your roster file (.csv) here',
        drop_profile_file: 'Drop your profile file (.csv) here',
        import_tool_roster: "To use this import tool, upload a CSV file with columns for the participant's unique Member ID, first name, last name, position and jersey number.",
        import_tool_profile: "To use this import tool, upload a CSV file with columns for the participant's unique Member ID, birthdate, height (ft), weight (lbs), hometown, and dexterity.",
        import_tool_warning: 'This import tool will create new participants that do not match existing Member IDs.',
        availability: {
          available: 'This team %{input} be available',
        },
        no_teams: 'No teams selected'
      },
      helpers: {
        select_columns: "You'll be asked to select columns after selecting your file",
        valid_profiles: '%{number}/%{total} rows have valid profiles',
        participant_not_matched: 'Participants will be ignored if not rostered to this team.',
        match_existing_participant: 'This Member ID is used to match an existing participant or create a new participant for this roster.',
        availability: {
          note: 'This note will be visible when scheduling games during this time.',
        }
      },
      alerts: {
        caution: 'Use with caution',
      },
      notifications: {
        import_roster_success: 'Roster successfully imported. Refresh to see changes.',
        import_roster_error: 'Unable to import your roster',
        import_profiles_error: 'Unable to import your profiles',
        profiles_updated: '%{smart_count} profile was updated. |||| %{smart_count} profiles were updated.',
      },
      values: {
        types: {
          home: 'Home Team',
          away: 'Away Team',
        }
      }
    },
    teamArenas: {
      name: 'Home Surface |||| Home Surfaces',
      fields: {
        order: 'Order',
        arenaId: 'Surface',
        slot: 'Time Slot',
        day: 'Day',
        startTime: 'Start Time',
        endTime: 'End Time',
      },
      actions: {
        edit: 'Edit Home Surfaces'
      },
      labels: {
        card: {
          title: 'Home Surfaces',
          subtitle: 'Team home surfaces will be used when drafting a schedule.'
        },
        not_set: 'Not set',
      },
      messages: {
        load_error: 'Unable to load the team settings',
      },
      validations: {
        end_before_start: 'End Time must be after Start Time',
        invalid_time_check_am_pm: 'Invalid time - check AM/PM',
      },
      values: {
        order: {
          '1': 'Primary'
        }
      }
    },
    scheduleteams: {
      name: 'Team |||| Teams',
      fields: {
        scheduleId: 'Schedule',
        dates: 'Dates',
        groupId: 'Group',
        officeId: 'Organization',
        teamId: 'Team',
        teamIds: 'Teams',
        type: 'Type',
        categoryId: 'Category',
        order: 'Order'
      },
      actions: {
        add: 'Add Teams',
        edit: 'Edit Order'
      },
      labels: {
        card: {
          title: 'Schedules & Contacts',
        },
        edit: 'Update Team',
        coach: 'Coach',
        manager: 'Manager',
        rank: 'Rank',
        import_teams: 'Select Teams',
        replace_existing_teams: 'Replace Existing Teams'
      },
      messages: {
        replace_existing_teams: "You've selected more teams than the total number of teams that you've configured for your draft. Do you want to replace the teams already selected in the draft with this selection?",
        team_order: 'This order will be used when generating games and have no impact on stats.',
        synced_by_id: 'This schedule is synced by Spordle ID. To add new teams, approve them via Spordle ID.'
      },
    },
    schedulesequences: {
      name: 'Game Number Sequence |||| Game Number Sequences',
      fields: {
        scheduleId: 'Schedule',
        groupId: 'Group',
        prefix: 'Prefix',
        start: 'Starting Number',
        suffix: 'Suffix',
        current: 'Current',
        max: 'End Number',
      },
      labels: {
        starting_sequence: 'Starting Sequence',
        edit: 'Edit Game Number Sequence',
        add: 'Add Game Number Sequence',
      },
      actions: {
        add: 'Add Sequence',
        go_to: 'Go to Sequence',
      },
      messages: {
        starting_sequence: 'Starting Sequence: %{sequence}',
      },
      alerts: {
        sequence_in_use: 'This game number sequence is currently in use and cannot be edited',
        sequence_info: "Game number sequences are used to automatically number games as they're created and when drafts are published.",
      },
      validations: {
        too_many_characters: 'Too many characters',
        cannot_contain_consecutive_dashes: 'Cannot contain consecutive dashes',
        cannot_contain_special_characters: 'Cannot contain special characters or spaces',
        must_contain_a_number: 'Must contain a number',
        sequence_in_use: 'Cannot edit a game number sequence that is being used',
        delete_sequence_in_use: 'Cannot delete a sequence that is being used',
        delete_scheduleteam: 'Cannot delete a team that is scheduled in games or draft games',
        unique_prefix_suffix: 'Can only have one sequence prefix/suffix pattern per schedule',
        prefix_cant_end_in_number: 'Prefix cannot end in a number',
        greater_than_current: 'Must be greater than the current number',
        schedule_has_sequence: 'This schedule already has a game number sequence',
        group_has_sequence: 'This group already has a game number sequence',
        sequence_overlaps: 'This game number sequence overlaps with an existing sequence range',
        sequence_exhausted: 'The sequence used to generate the game number is exhausted',
        excessive_pad_length: "End Number exceeds Starting Number format"
      }
    },
    arenaoffices: {
      name: 'Organization |||| Organizations',
      fields: {
        arenaId: 'Surface',
        officeId: 'Organization',
        isPrimary: 'Primary',
        leagueIds: 'Leagues',
        divisionIds: 'Divisions'
      },
      labels: {
        restrictions: 'Restrictions',
      },
    },
    arenaslots: {
      name: 'Time Slot |||| Time Slots',
      fields: {
        name: 'Name',
        arenaId: 'Surface',
        startTime: 'Start Time',
        endTime: 'End Time',
        categoryIds: 'Categories',
        officeId: 'Organization',
        comments: 'Comments',
        types: 'Types',
        leagueIds: 'Leagues',
        isShared: 'Share this slot to all organizations'
      },
      values: {
        types: {
          Practice: 'Practice',
          ...TYPES,
        },
      },
      labels: {
        card: {
          title: 'Time Slots',
          subtitle: 'Record your surface reservations to see your available time',
          game_slots: 'Game Time Slots',
          practice_slots: 'Practice Time Slots',
        },
        tooltips: {
          slot_replace: `This slot overlaps with an existing slot:
          %{timeRange1} |||| This slot overlaps with existing slots:
          %{timeRange1}
          %{timeRange2}`,
        },
        restrictions: 'Restrictions',
        recurrence: 'Recurrence',
        import_slots: 'Import Slots',
        slots_file: 'Slots File',
        slot_splits: 'Slot Splits',
        slot_duration: 'Slot Duration',
        break_duration: 'Break Duration',
        edit: 'Update Time Slot',
        frequency: 'Frequency',
        interval: 'Interval',
        weekdays: 'Weekdays',
        end_date: 'End Date',
        delete_slots: 'Delete Time Slot |||| Delete Time Slots',
        slots_restrictions: 'Time Slots Restrictions',
        set_slots_restrictions: 'Set restrictions',
        restricted: 'Restricted',
        Type: 'Type',
        League: 'League',
        Category: 'Category'
      },
      messages: {
        drop_slots_csv: 'Drop your slots file (.csv) here',
        import_tool_slots: "To use this import tool, upload a CSV file with columns for the slot's Start Time and End Time.",
        import_slot_warning: 'Rows that overlap with existing slots will not be imported.',
        schedule_no_occurences: 'The recurrence schedule specified does not result in any time slots',
        event_repeat: 'This recurrence schedule will result in %{smart_count} day |||| This recurrence schedule will result in %{smart_count} days',
        slot_splits: 'This will result in %{smart_count} time slot on %{date} between %{startTime} - %{endTime} |||| This will result in %{smart_count} time slots on %{date} between %{startTime} - %{endTime}',
        additional_instances: '... %{smart_count} additional time slot ... |||| ... %{smart_count} additional time slots ...',
        confirm_delete: 'Are you sure you want to delete this time slot? |||| Are you sure you want to delete these %{smart_count} time slots?',
        available_slots: '%{smart_count} available slot |||| %{smart_count} available slots',
        slots: '%{smart_count} slot |||| %{smart_count} slots',
        home_available_slots: '%{smart_count} home slot |||| %{smart_count} home slots',
        away_available_slots: '%{smart_count} away slot |||| %{smart_count} away slots'
      },
      helpers: {
        categoryIds: 'Optional - All categories if empty',
        types: 'Optional - All types if empty',
        leagueIds: 'Optional - All leagues if empty',
      },
      notifications: {
        import_slots_success: '%{number} time slot imported. |||| %{number} time slots were imported.',
        import_slots_error: 'Unable to import slots.',
        delete_slots_success: 'Deleted %{smart_count} time slot |||| Deleted %{smart_count} time slots',
        delete_slots_error: 'Something went wrong while deleting the time slot |||| Something went wrong while deleting time slots',
        update_restrictions_success: 'Updated restrictions for %{smart_count} time slot |||| Updated restrictions for %{smart_count} time slots',
        update_restrictions_error: 'Something went wrong while updating the time slot restrictions |||| Something went wrong while updating the time slots restrictions',
      },
      actions: {
        add: 'Add Time Slots',
      },
      validations: {
        import_slots_overlaps: 'Some rows overlapping times',
        overlaps: 'This time slot overlaps with an existing time slot',
        import_slots_end_before_start: 'Some rows have end time before start time',
        end_before_start: 'End Time must be after Start Time',
        duration_exceeds_range: 'Duration cannot exceed time range',
        total_duration: 'Total duration results in an uneven amount of slots',
        include_start_day: 'Must include %{day}'
      },
      alerts: {
        bulk_slots_restrictions: 'Check the restrictions you want to change. A checked restriction with no value will clear the current value.',
        overlaps_title: 'Overlaps',
        overlaps: 'This timeslot range has some overlaps. Modify the start and end times and try again.'
      }
    },
    availabilities: {
      name: 'Availability |||| Availabilities',
      fields: {
        officeId: 'Organization',
        isAvailable: 'Status',
      },
      values: {
        isAvailable: {
          available: 'Available',
          notAvailable: 'Not available',
        }
      },
      labels: {
        available: 'Available',
        unavailable: 'Unavailable',
        conflicts: `%{smart_count} conflict |||| %{smart_count} conflicts`,
        team_conflicts: 'Team Conflicts',
        surface_conflicts: 'Surface Conflicts',
        constraints: 'Constraints',
        slots: 'Time Slots',
        more: '+ %{smart_count} more |||| + %{smart_count} more'
      },
    },
    blockedSurfaces: {
      name: 'Blocked by',
    },
    accounts: {
      name: 'Account |||| Accounts',
      fields: {
        email: 'Email Address',
        password: 'Password',
        participantId: 'Person'
      },
      actions: {
        add: 'Add Account',
        sma_reset: 'Reset Password',
      },
      labels: {
        card: {
          title: 'Accounts',
          subtitle: 'Participants may be linked to one or more accounts through identities.'
        },
        my: 'My Account',
        edit: 'Update Account',
      },
      messages: {
        enter_email: 'Enter an email address to start searching',
      },
      alerts: {
        enter_email_above: 'Enter an email address above to start searching...',
        sma_reset_title: 'Spordle My Account Password Reset',
        sma_reset: "The temporary password given is valid for 14 days. When the user logs in with the temporary password, they'll be asked to enter a new password. Afterwards the new password becomes the account's password.",
      },
      helpers: {
        password: 'Optional unless new account',
      },
      validations: {
        must_be_length: 'Must be at least %{length} characters',
        must_have_number: 'Must contain at least one number',
        must_have_uppercase: 'Must contain at least one uppercase letter',
        must_have_lowercase: 'Must contain at least one lowercase letter',
        must_have_special: 'Must contain at least one special character',
      },
      notifications: {
        sma_reset_success: 'Password reset successfully',
        sma_reset_error: 'Failed to reset password',
      }
    },
    permissions: {
      name: 'Permission |||| Permissions',
      fields: {
        principalType: 'Principal Type',
        principalId: 'Participant',
        roleId: 'Role',
        targetType: 'Resource Type',
        targetId: 'Organization / Schedule / Team',
        categoryIds: 'Categories',
        groupIds: 'Groups',
        expiry: 'Expiry',
        reportsDisabled: 'Disallow Reports',
        scorekeepingDisabled: 'Disallow Scoresheet Editing',
      },
      actions: {
        add: 'Add Role',
      },
      labels: {
        card: {
          title: 'Roles',
          subtitles: {
            office: 'Organization roles grant access to resources in and under this organization. You can further restrict access using groups.',
            schedule: 'Schedule roles grant access to resources related to this schedule only. You can further restrict access with groups.',
            team: 'Team roles grant access to this team only.',
          }
        },
        edit: 'Update Role',
        roles: 'Roles',
        legacy: 'Administrative Roles (Legacy)',
        administrative: 'Administrative Roles',
        expired: 'Expired',
      },
      messages: {
        roles: 'Administrative roles are now managed on the participant.',
      },
      helpers: {
        principalId: 'This is a legacy role - link this account to a person to convert it',
        categoryIds: 'Optional - All categories if empty',
        groupIds: 'Optional - All groups if empty',
        expiry: 'Optional - Access will be revoked as of this date',
      },
      values: {
        targetType: {
          Participant: 'Participant',
          Office: 'Organization',
          Schedule: 'Schedule',
          Team: 'Team',
        },
        context: {
          Administrative: 'Administrative',
          League: 'League',
          Officiating: 'Officiating',
        },
      },
      validations: {
        role_not_activated: 'This role has not been activated for the selected organization',
      }
    },
    profiles: {
      name: 'Profile |||| Profiles',
      fields: {
        id: 'Participant',
        birthdate: 'Birthdate',
        height: 'Height (ft)',
        weight: 'Weight (lbs)',
        hometown: 'Hometown',
        dexterity: 'Handed',
        isPublic: 'Public Profile',
      },
      actions: {
        edit: 'Edit Profile',
      },
      labels: {
        card: {
          title: 'Profile',
        },
        tabs: {
          summary: 'My Profile',
          officiating: 'Officiating',
          league: 'League',
          schedule: 'Schedule',
        },
        edit: 'Update Profile',
        picture: 'Picture',
        personal_calendar_feed: 'Personal Calendar Feed',
      },
      messages: {
        description: 'Name, birthdate, and more',
      },
      alerts: {
        public_profile: 'This profile will publicly appear on league websites where stats are shown. You can make this information private by editing the profile.',
        private_profile: 'This profile will only appear to authorized league and association administrators, and will not appear on league websites.',
      },
      helpers: {
        isPublic: 'Optional - Allow profile information to be shown publicly on league websites',
      },
      validations: {
        invalid_birthdate: 'Some values do not include a valid date (YYYY-MM-DD)',
        invalid_height: 'Some rows have an invalid height',
        invalid_weight: 'Some rows have an invalid weight',
        invalid_hometown: 'Some rows have an invalid hometown',
        invalid_dexterity: 'Some rows have an invalid handedness'
      },
      values: {
        dexterity: {
          Right: 'Right',
          Left: 'Left',
        }
      }
    },
    contacts: {
      name: 'Contact |||| Contacts',
      fields: {
        type: 'Type',
        value: 'Value',
        isPrimary: 'Primary',
        isEmergency: 'Emergency',
        isNotification: 'Receive notifications',
        isVerified: 'Verified',
        notes: 'Notes',
        participantId: 'Person',
        language: 'Language'
      },
      actions: {
        add: 'Add Contact',
      },
      labels: {
        card: {
          title: 'Address Book',
        },
        edit: 'Update Contact',
        unverified: 'Unverified Contact',
        information: 'Additional information',
        notifications: 'Notifications',
      },
      messages: {
        contacts: 'Contacts may be used to send you game notifications and are available to help authorized users reach you, but do not affect your account login.',
        description: 'Emails, phone numbers, and addresses',
        already_received_email: 'If you have already received a verification email, you will need to click on that link to verify, or click below to resend. The link will expire after 1 week.',
        only_participant_verify: 'Only the participant can verify their email.',
        has_not_been_verified: '%{contact} has not been verified.',
        unverified_explain: 'To receive notifications, this contact must be verified. Verification is optional and does not limit the account.',
        error_verify: 'Unable to verify your email',
      },
      alerts: {
        contact_accounts: 'Contacts do not affect accounts',
        create_via_invite_button: 'To create a new account, use the "Invite" button on the profile page.',
        change_login: 'To change the username, see the accounts section on the profile page.',
        add_contact_email: 'Please add a contact email below to receive notifications.',
        verify_contact_email: 'Please verify your contact email to receive notifications.',
      },
      notifications: {
        not_registered: 'Participant needs to be registered before this contact can be verified'
      },
      helpers: {
        value: 'A notification will be sent to validate this address',
        isPrimary: 'Notifications will go to this contact by default',
        isEmergency: 'Use this contact in case of emergency',
        language: 'Language for notifications',
        isNotification: 'Use this contact to receive notifications',
      },
      tooltips: {
        isPrimary: 'Primary contact',
        isEmergency: 'Emergency contact',
        isVerified: 'Verified',
        notificationsActive: 'Receiving notifications',
        notificationsDisabled: 'Notifications disabled',
      },
      values: {
        type: {
          Email: 'Email',
          Mobile: 'Mobile',
          Home: 'Home',
        },
        language: {
          en: 'English',
          fr: 'French'
        }
      }
    },
    addresses: {
      name: 'Address |||| Addresses',
      fields: {
        label: 'Label',
        address: 'Address',
        address2: 'Address 2',
        city: 'City',
        province: 'Province',
        postal: 'Postal Code',
        type: 'Type',
        isPrimary: 'Primary'
      },
      actions: {
        add: 'Add Address',
      },
      labels: {
        card: {
          title: 'Addresses',
        },
        edit: 'Update Address',
      },
      helpers: {
        label: 'Optional - Nickname for this address',
        address2: 'Optional - Unit/Suite/etc.',
      },
      values: {
        type: {
          Home: 'Home',
          Work: 'Work',
          Other: 'Other',
        },
        province: {
          AB: 'Alberta',
          BC: 'British Columbia',
          MB: 'Manitoba',
          NB: 'New Brunswick',
          NL: 'Newfoundland & Labrador',
          NT: 'Northwest Territories',
          NS: 'Nova Scotia',
          NU: 'Nunavut',
          ON: 'Ontario',
          PEI: 'Prince Edward Island',
          QC: 'Quebec',
          SK: 'Saskatchewan',
          YT: 'Yukon',
        }
      }
    },
    notifications: {
      name: 'Notification |||| Notifications',
      fields: {
        participantId: 'Person',
        contactId: 'Destination Contact',
        email: 'Email (Legacy)',
        targetId: 'Resource',
        categoryIds: 'Categories',
        groupIds: 'Groups',
        gameReport: 'Game Reports',
        majorPenalty: 'Major Penalties',
        suspension: 'Suspensions',
        assigning: 'Assigning',
        scheduling: 'Scheduling',
        activities: 'Activities',
        drafts: 'Draft Sharing'
      },
      actions: {
        add: 'Add Notification',
      },
      labels: {
        card: {
          title: 'Notifications',
          subtitles: {
            office: "Organization notifications apply to all games in and under this organization. Notifications can be added to anyone, even if they don't have a role.",
            schedule: "Schedule notifications apply to all games in this schedule. Notifications can be added to anyone, even if they don't have a role.",
            team: 'Team notifications apply to games played by this team.',
          }
        },
        sources: 'Notification Sources',
        update_source: 'Update Notification Source',
        types: 'Types',
      },
      helpers: {
        participantId: 'Reminder: Must have at least one contact in profile to receive emails',
        contactId: 'Optional - Notifications will go to contacts with notifications enabled, unless overridden by this option',
        email: 'This is a legacy notification. Please connect it to a participant above.',
        scheduling: 'A digest email is sent when there have been any game changes in the past hour',
        activities: 'A digest email is sent when there are new or updated activities for this team',
        drafts: 'An email is sent when access to a draft has been shared',
        assigning: 'An email is immediately sent when an assignment is declined',
        gameReport: 'Game report are sent after game completion',
        majorPenalty: 'Scoresheets with major penalties will be sent after game completion',
        suspension: 'Suspensions will be sent after game completion and on discipline committee changes',
        categoryIds: 'Optional - All categories if empty',
        groupIds: 'Optional - All groups if empty',
        default: 'Default notifications are set due to having an administrative role or being rostered on a team.'
      },
    },
    draftGames: {
      name: 'Draft Game |||| Draft Games',
      fields: {
        id: 'ID',
        round: 'Round',
        seasonId: 'Season',
        scheduleId: 'Schedule',
        crossScheduleId: 'Cross Schedule',
        categoryId: 'Category',
        homeTeamId: 'Home Team',
        awayTeamId: 'Away Team',
        date: 'Date',
        startTime: 'Start Time',
        endTime: 'End Time',
        arenaId: 'Surface',
        status: 'Status',
        comments: 'Comments',
        requestReason: 'Reason for Request',
        officeId: 'Organization',
        groupId: 'Group',
        crossGroupId: 'Cross Group',
        timezone: 'Timezone',
        isShared: 'Shared',
        sharedAt: 'Shared At',
        sharedUntil: 'Shared Until',
        isPublished: 'Published',
        publishedAt: 'Published At',
        requested_by: 'Requested by',
      },
      filters: {
        startTime: 'Scheduled Date',
        endTime: 'End Date',
      },
      actions: {
        create: 'Create',
        request: 'Request Changes',
        add: 'Add Draft Game',
        generate: 'Generate Schedule',
        upload: 'Upload File',
        set_date: 'Set Date',
        clear_date: 'Clear Date',
        change_status: 'Change Status',
        delete_draft_game: 'Delete Draft Game |||| Delete Draft Games'
      },
      labels: {
        card: {
          title: 'Draft Games'
        },
        edit: 'Update Draft Game',
        edit_number: 'Update Draft Game %{number}',
        draft_request: 'Draft Game Request %{number}',
        generate_automatically: 'Generate automatically',
        method: 'Method',
        type: 'Type',
        round_type: 'Round Type',
        games_per_team: 'Total Games',
        total_rounds: 'Total Rounds',
        limit_byes: 'Limit Round Byes',
        weekdays: 'Days of the week',
        weekdays_type: 'Distribution Mode',
        start_date: 'Start Date',
        start_round: 'Start Round',
        breaks: 'Breaks',
        total_weeks: 'Total Weeks',
        no_round: 'No round',
        no_date: 'No date',
        no_time: 'No time',
        no_venue: 'No surface',
        drafts_file: 'Draft Games File',
        import_drafts: 'Import Drafts',
        date_format: 'Date Format',
        publish: 'Publish',
        share: 'Share',
        home: 'Home',
        away: 'Away',
        total: 'Total',
        main_schedule: 'Main Schedule',
        main_group: 'Main Group',
        cross_schedule: 'Cross Schedule',
        cross_group: 'Cross Group',
        cross_team: 'Cross Team',
        add_cross_team: 'Add Cross Group Team',
        steps: {
          matchups: 'Matchups',
          matrix: 'Matrix',
          scheduling: 'Scheduling',
          preview: 'Preview',
        },
        number_of_teams: 'Total Teams',
        updateExistingGames: 'Update Existing Games',
        confirm_publish_with_conflicts: 'Confirm Publish with Conflicts',
      },
      messages: {
        drop_drafts_csv: 'Drop your draft games file (.csv) here',
        generate_single_double: 'Generate a single or double rotation schedule',
        no_results: 'No draft games',
        no_teams_alert: 'Teams need to be added to the schedule before games can be generated.',
        one_team_alert: 'The schedule only contains one team.',
        gaps_alert: 'Gaps were found in the orders of the teams in the schedule. This may result in unexpected balancing.',
        no_teams_group_alert: 'Teams need to be added to the schedule group before games can be generated.',
        one_team_group_alert: 'The schedule group only contains one team.',
        gaps_group_alert: 'Gaps were found in the team orders of the schedule group. This may result in unexpected balancing.',
        no_teams_pool_alert: 'Teams need to be added to the schedule pools before games can be generated.',
        one_team_pool_alert: 'The schedule pools only contains one team.',
        gaps_pool_alert: 'Gaps were found in the team orders of the schedule pools. This may result in unexpected balancing.',
        unslotted_matchups_alert: 'Due to a combination of Double Rotation and an odd number of teams, some teams will have to play twice on the same day. These games will be generated without dates.',
        match_columns: 'Select the corresponding teams and surfaces to match with your selected columns.',
        match_teams: 'Selected teams not found in the schedule will be added to the schedule. All unmatched teams will be marked as TBD on games.',
        import_tool_drafts: 'To use this import tool, upload a file containing draft games and then select the schedule group (if applicable).',
        publish_help: 'Publishing a draft will make the game public and available for scorekeeping. Published games require a date, time and surface to be set and must have a sequence available for game numbering.',
        share_help: 'Sharing a draft will make the game available for the home team organization. Shared games require both teams to be allocated',
        confirm_clear_date: 'Are you sure you want to clear the date on %{smart_count} selected draft game? This will also reset the time and surface. |||| Are you sure you want to clear the date on %{smart_count} selected draft games? This will also reset the time and surface on these draft games.',
        confirm_delete: 'Are you sure you want to delete %{smart_count} draft game? |||| Are you sure you want to delete %{smart_count} draft games?',
        schedule_dates: 'Schedule Dates: %{startDate} to %{endDate}',
        change_dates_dialog: 'Changing the date will reset time and surface on these draft games.',
        teams_to_import: 'Importing these draft games will add %{teams} teams to the schedule',
        confirm_publish_with_conflicts: 'Some of the games you are about to publish have conflicts. Are you sure you want to continue?',
      },
      alerts: {
        update_game: 'Publishing this draft game will update %{game}',
      },
      helpers: {
        round: 'Optional',
        comments: 'Optional - displayed on public schedule',
        request_reason: 'Only visible to approvers and will not be displayed on public schedule',
        limit_byes: 'Each round may have a team play twice when there are an odd number of teams.',
        start_round: 'Optional - specify if continuing an existing schedule',
        latest_round: 'Round %{smart_count} is the latest round from previous generated draft games',
        round_type: {
          'Home Slots': 'Games will be scheduled using time slots from the home team settings (if available)',
          'Weekdays': 'Games will be spread within the week (Monday, Tuesday, Wednesday of week 1, and so on)',
        },
        weekdays_type: {
          'Rotating': 'Games will be alternating on weekdays (Monday week 1, Tuesday week 2, Wednesday week 3, and so on)',
          'Consecutive': 'Games will be spread within the week (Monday, Tuesday, Wednesday of week 1, and so on)',
        },
        weekdays: 'Weekdays will alternate each week',
        weekdays_double: 'Weekdays will alternate within each week',
        start_date: 'New rounds will start on %{date}s',
        date_format: 'Select the date format used in your file',
        updateExistingGames: 'Update existing games in the schedule by matching on game numbers'
      },
      validations: {
        must_start_on_day: 'Must start on a %{day}',
        must_be_after: 'Must be after %{date}',
        must_be_before_schedule_end_date: 'Must be before the schedule end date (%{date})',
        max_weekdays: 'Max %{smart_count} days of the week for Double Rotation',
        even_number: 'Must be an even amount',
        invalid_date_format: 'Some rows do not include a valid date (%{format})',
        start_without_end: 'Some rows have start time without end time',
        end_without_start: 'Some rows have end time without start time',
        date_without_date: 'Some rows have time without date',
        end_before_start: 'Some rows have end time before start time',
        manual_odd_numbers: 'Number of teams and number of games per team are both odd',
        same_group: 'Must be a team from a different group then the main group',
        matrix_team_duplicate: 'Team already in the matrix',
      },
      notifications: {
        added: '%{smart_count} draft game generated |||| %{smart_count} draft games generated',
        not_added: 'Unable to generate drafts',
        uploaded: '%{smart_count} draft game uploaded |||| %{smart_count} draft games uploaded',
        uploaded_teams_error: '%{smart_count} draft uploaded but failed to add teams to schedule |||| %{smart_count} drafts uploaded but failed to add teams to schedule',
        not_uploaded: 'Unable to import the draft games',
        slow_upload_warning: 'This process might take a while. Please wait patiently.',
        generator_load_error: 'Unable to load generator',
        published_games: 'Published %{smart_count} game successfully |||| Published %{smart_count} games successfully',
        published_games_error: 'Unable to publish game |||| Unable to publish games',
        publish_failed: 'Unable to publish %{smart_count} game |||| Unable to publish %{smart_count} games',
        missing_sequence: 'No sequence found for game numbering',
        invalid_games: 'The game is invalid |||| %{smart_count} games are invalid',
        sequence_exhausted: 'Not enough numbers left in the game number sequence to publish',
        shared_games: 'Shared %{smart_count} game successfully |||| Shared %{smart_count} games successfully',
        shared_games_error: 'Unable to share game |||| Unable to share games',
        set_date: 'Date on %{smart_count} draft game successfully set |||| Date on %{smart_count} draft games successfully set',
        set_date_error: 'Unable to set date on %{smart_count} draft game |||| Unable to set date on %{smart_count} draft games',
        delete_draft: '%{smart_count} draft game successfully deleted |||| %{smart_count} draft games successfully deleted',
        delete_draft_error: 'Error deleting %{smart_count} draft game |||| Error deleting %{smart_count} draft games',
        clear_date: 'Date cleared on %{smart_count} draft game |||| Date cleared on %{smart_count} draft games',
        clear_date_error: 'Error clearing date on %{smart_count} draft game |||| Error clearing date on %{smart_count} draft games',
        change_status: 'Status changed on %{smart_count} draft game |||| Status changed on %{smart_count} draft games',
        change_status_error: 'Status change error',
      },
      values: {
        methods: {
          'Games per Team': 'Games per Team',
          'Total Rounds': 'Total Rounds'
        },
        types: {
          'Single Rotation': 'Single Rotation',
          'Double Rotation': 'Double Rotation',
          'Matrix': 'Matrix',
        },
        round_types: {
          'Home Slots': 'Home Slots',
          'Weekdays': 'Weekdays',
        },
        weekdays_types: {
          'Rotating': 'Rotating',
          'Consecutive': 'Consecutive'
        },
        dates: {
          'YYYY-MM-DD': 'YYYY-MM-DD',
          'MM-DD-YYYY': 'MM-DD-YYYY',
          'DD-MM-YYYY': 'DD-MM-YYYY',
          'MM/DD/YYYY': 'MM/DD/YYYY',
          'DD/MM/YYYY': 'DD/MM/YYYY'
        }
      },
      steps: {
        match_columns: 'Match Columns',
      },
    },
    draftGameApprovals: {
      name: 'Approval |||| Approvals',
      labels: {
        approvals: 'Approvals',
        approve_request: 'Approve Request',
        decline_request: 'Decline Request',
      },
      messages: {
        request_reschedule: 'Once all required teams and organizations approve this request, the game will be automatically rescheduled with the draft update.',
        confirm_approve_request: 'Are you sure you want to approve these changes?',
        confirm_decline_request: 'Are you sure you want to decline these changes?',
      },
      alerts: {
        publish_game: 'Approving this request will automatically reschedule %{game}',
        request_changes: 'This request will need to be approved by all required parties in order to be rescheduled',
        cancel_draft: 'Declining this request will mark the draft game as cancelled',
      },
      helpers: {
        approval_comments: 'Optional - displayed on approvals list'
      },
      notifications: {
        approved_and_published: 'Request approved and game rescheduled',
        declined_and_cancelled: 'Request declined and draft game cancelled',
        request_approved: 'Request approved',
        request_declined: 'Request declined',
        respond_error: 'Error responding to request',
      },
      values: {
        Approved: 'Approved',
        Declined: 'Declined',
        Pending: 'Pending',
      }
    },
    drafts: {
      name: 'Draft |||| Drafts',
      name_legacy: 'Legacy Draft |||| Legacy Drafts',
      fields: {
        scheduleId: 'Schedule',
        groupId: 'Group',
        startDate: 'Start Date',
        totalWeeks: 'Total Weeks',
        totalRounds: 'Total Rounds',
        file: 'Schedule File',
        isPublished: 'Published',
        updatedAt: 'Updated At',
        publishedAt: 'Published At',
        options: {
          type: 'Type',
          limitByes: 'Limit round byes',
          crossGroup: 'Cross-group',
          roundType: 'Round Type',
          method: 'Method',
          weekdays: 'Days of the week',
          startDate: 'Start Date',
          totalWeeks: 'Total Weeks',
          totalRounds: 'Total Rounds',
          gamesPerTeam: 'Games Per Team',
          startNumber: 'Starting Number',
          breaks: 'Breaks',
          previousDraftId: 'Previous Draft',
        },
        columns: {
          homeTeamId: 'Home Team',
          awayTeamId: 'Away Team',
          arenaId: 'Surface',
          date: 'Date',
          startTime: 'Start Time',
          endTime: 'End Time',
          status: 'Status',
          number: 'Game Number',
          comments: 'Comments',
        }
      },
      actions: {
        add: 'New Draft',
      },
      labels: {
        card: {
          title: 'Saved Drafts',
          title_legacy: 'Saved Drafts (Legacy)'
        },
        analysis: 'Analysis',
        current_matchups: 'Current Matchups',
        new_matchups: 'New Matchups',
        office_matchups: 'Organization Matchups',
        byes: 'Byes',
        team: 'Team',
        group: 'Group',
        opponents: 'Opponents',
        conflicts: 'Conflicts',
        home: 'Home',
        away: 'Away',
        total: 'Total',
        shuffle: 'Shuffle',
        swap_matchups: 'Swap Matchups',
        set_active: 'Set Active',
        set_conflict: 'Set Conflict',
        generate_automatically: 'Generate automatically',
        generate_automatically_legacy: 'Generate automatically (Legacy)',
        manual_entry: 'Manual entry',
        previous_schedule: 'Previous schedule',
        add_team: 'Add Team',
        import_teams: 'Select Teams',
        previous_draft_alert: 'Restarting schedule',
        cross_group_alert: 'Cross-group Scheduling',
        replace_teams_alert: 'Replace Teams',
        use_previous_teams: 'Use Previous Draft Teams',
        unused_breaks: 'Unused Breaks',
        unscheduled_games: 'Unscheduled Games',
        changed_games: 'Changed Games',
        new_games: 'New Games',
        columns: 'Columns',
        round: 'Round |||| Rounds',
        week: 'Week',
        no_round: 'No round',
        no_date: 'No date',
        no_time: 'No time',
        no_venue: 'No surface',
        set_date: 'Set Date',
        clear_date: 'Clear Date',
        saved: 'Saved',
        shared: 'Shared',
        conflict_resolution: {
          title: 'Resolve Draft Conflict',
          subtitle: {
            modified: 'Someone else made changes at the same time as you. Do you want to accept their changes?',
            removed: 'Someone else removed the game you made changes to. Do you want to accept their changes?',
            added: 'Someone else made changes to the game you removed. Do you want to accept their changes?'
          },
          keep_mine: 'Keep mine',
          accept_theirs: 'Accept theirs',
          accept_delete: 'Accept delete',
          keep_delete: 'Keep delete',
        },
        steps: {
          info: 'Schedule Info',
          teams: 'Select Teams',
          games: 'Review Games',
          review: 'Publish',
        },
        types: {
          scheduler: 'Scheduler',
          schedule_upload: 'Schedule Upload',
          draft_schedule: 'Draft Schedule',
        },
        general: 'General',
        matchups: 'Matchups',
        scheduling: 'Scheduling',
      },
      messages: {
        no_byes: 'No byes',
        show_conflicts_only: 'Show conflicts only',
        games_selected: 'One game selected |||| %{smart_count} games selected',
        generate_single_double: 'Generate a single or double rotation schedule',
        draft_multiple_games: 'Individually draft multiple games at once',
        generate_new_schedule: 'Generate new schedule?',
        draft_already_has_games: 'This draft already has games. Generating a new schedule will replace these.',
        draft_already_has_games_share: 'This draft already has games. Generating a new schedule will replace these and unshare the draft.',
        want_generate_new_schedule: 'Do you want to generate a new schedule?',
        drop_your_schedule_file: 'Drop your schedule file (.csv) here',
        drop_another_schedule_file: '%{num} rows parsed. Drop another schedule file (.csv) here to replace.',
        replace_existing_games: 'Replace existing games?',
        draft_already_has_games_uploader: "This draft already has games. If you've made changes to the schedule or teams, you should replace the existing games.",
        want_replace_existing_games: 'Do you want to replace the existing games?',
        number_of_play: '%{smart_count} round of play |||| %{smart_count} rounds of play',
        number_of_games_per_team: '%{smart_count} game per team |||| %{smart_count} games per team',
        number_of_break: '%{smart_count} week of break |||| %{smart_count} weeks of break',
        publish_schedule: 'Publish schedule?',
        same_game_number_be_updated: 'Schedule changes described in the summary will be applied. Any existing games with the same game number will be updated.',
        discard_draft: 'Discard draft?',
        discard_unsaved_draft: 'Leaving will discard this unsaved draft.',
        save_changes: 'Save changes?',
        save_changes_before_leaving: 'Would you like to save changes before leaving?',
        number_teams_added: '%{number} teams will be added',
        number_existing_games: '%{number} out of %{total} existing games will be changed',
        number_games_added: '%{number} new games will be added',
        no_changes_number_games: 'No changes to %{number} existing games',
        no_changes_number_teams: 'No changes to %{number} existing teams',
        no_additional_games: 'No additional games will be added',
        previous_draft_alert: 'Another draft was previously published but not selected. This schedule will not continue from the last round in the previous draft.',
        cross_group_alert: 'Do you want to create a cross-group schedule?',
        replace_teams_alert: "Do you want to replace the teams you've already selected in this draft?",
        use_previous_teams: "Do you want to replace this draft's teams with the teams from the previously selected draft?",
        unused_breaks: 'Some of your breaks will be after when games will be scheduled to end. Breaks after %{date} will be ignored.',
        unscheduled_games: 'This draft will generate more games than can be scheduled prior to the schedule end date. Games generated after %{date} will not have dates assigned as a result.',
        unbalanced_schedule: {
          title: 'Unbalanced Schedule',
          message: "With %{totalTeams} and %{totalRoundsOrGamesPerTeam}, this schedule will result in:",
          games_per_total_teams: '%{totalTeams} with %{totalGames}',
          suggestions: "To schedule an even amount of games per team, set the <strong>total number %{method} to %{suggestion}</strong>, or use an even number of teams.",
          round: 'of round |||| of rounds',
          game_per_team: 'of game per team |||| of games per team',
        },
        review_errors: {
          title: 'Review Errors',
          message: 'The following games have validation errors that must be corrected:',
        },
        share: {
          title: 'Confirm Share',
          title_shared: 'Draft Shared',
          messages: {
            list: 'This draft will be shared with the following associations:',
            list_shared: 'This draft has already been shared with the following associations:',
            info: 'Associations will only be able to edit times and surfaces for their own games and will not be able to publish the draft.'
          }
        },
        renumber_games: {
          title: 'Renumber Games',
          actions: {
            renumber_games: 'Renumber Games',
          },
          messages: {
            description: 'Games will be renumbered by start time and any game number sequence gaps will be eliminated.',
          }
        }
      },
      alerts: {
        dated_in_past: '%{smart_count} game is dated in the past |||| %{smart_count} games are dated in the past',
      },
      helpers: {
        limitByes: 'Each round may have a team play twice when there are an odd number of teams.',
        crossGroup: 'The matches will be generated between the teams of the groups.',
        roundType: {
          'Home Slots': 'Games will be scheduled using time slots from the home team settings (if available)',
          'Weekdays': 'Games will be scheduled using a weekly rotation based on weekdays',
        },
        weekdays: 'Weekdays will alternate each week',
        weekdays_double: 'Weekdays will alternate within each week',
        startNumber: 'Warning: Previous schedule includes %{number}',
        totalWeeks: '%{smart_count} week |||| %{smart_count} weeks',
        totalGames: '%{smart_count} game |||| %{smart_count} games',
        totalRounds: '%{smart_count} game per team |||| %{smart_count} games per team',
        totalRounds_odd: '%{smart_count} if odd number of teams',
        previousDraftId: 'New schedule will start on round %{round}',
        startDate: 'New rounds will start on %{date}s',
        startDate_previous: 'Previous rounds started on %{date}s',
        previousDraftId_optional: 'Optional - use when continuing a schedule',
        no_team_time_slots: 'No team time slot'
      },
      validations: {
        must_contain_a_number: 'Must contain a number',
        must_start_on_day: 'Must start on a %{day}',
        must_be_after: 'Must be after %{date}',
        must_be_before: 'Must be before %{date}',
        must_be_before_schedule_end_date: 'Must be before the schedule end date (%{date})',
        break_last_week: 'Cannot break in the last week',
        max_weeks: 'Max %{smart_count} week (%{date}) |||| Max %{smart_count} weeks (%{date})',
        max_weekdays: 'Max %{smart_count} days of the week for Double Rotation',
        valid_date: 'Some values do not include a valid date (YYYY-MM-DD)',
        valid_time: 'Some values do not include a valid time (HH:mm)',
        same_column_start_time: 'Cannot use the same column as the start time',
        cross_group_teams: 'Cross-group require teams in 2 groups',
        same_game_number: 'This game number already exists within this draft'
      },
      notifications: {
        generating_schedule: 'Generating schedule...',
        unable_generate_schedule: 'Unable to generate schedule',
        unable_to_load_draft: 'Unable to load draft',
        unable_to_share_draft: 'Unable to share draft',
        unable_to_publish_draft: 'Unable to publish draft',
        unable_to_publish_draft_games: 'Unable to publish draft due to invalid games',
        schedule_published: 'Schedule published',
        draft_shared: 'Draft Shared',
      },
      values: {
        type: {
          Upload: 'Upload',
          Generated: 'Generated',
          Manual: 'Manual',
        },
        draft_type: {
          'Single Rotation': 'Single Rotation',
          'Double Rotation': 'Double Rotation',
        },
        round_type: {
          'Home Slots': 'Home Slots',
          'Weekdays': 'Weekdays'
        }
      }
    },
    officeAssignSettings: {
      name: 'Setting |||| Settings',
      fields: {
        officeId: 'Assigning Organization',
        declineReason: 'Reason required',
        declineHours: 'Minimum Hours',
        visibleDaysEnabled: 'Allow officials to view unassigned games',
        visibleDays: 'Visible Days',
        dailyLimit: 'Daily Limit',
        requestsEnabled: 'Allow officials to request games',
        declineEnabled: 'Allow officials to decline assignments',
        declineConfirmedDisabled: 'Disable once confirmed',
        removeAfterDecline: 'Remove officials when they decline',
        removeAfterRescheduled: 'Remove officials when a game is rescheduled',
        removeOnDateChange: 'Remove officials only if the date changed',
        assignmentVisibility: 'Assignment Visibility',
        assignerVisible: 'Show assigner name to officials',
        supervisorVisible: "Show supervisor to officials",
        claimableDriver: 'Driver',
        claimablePassenger: 'Passenger',
        claimableBreakfast: 'Breakfast',
        claimableLunch: 'Lunch',
        claimableDinner: 'Dinner',
        driverAmount: 'Rate ($/km)',
        passengerAmount: 'Rate ($/km)',
        breakfastAmount: 'Breakfast Amount',
        lunchAmount: 'Lunch Amount',
        dinnerAmount: 'Dinner Amount',
        driverExcludedDistance: 'Excluded Distance',
        passengerExcludedDistance: 'Excluded Distance',
        enforceConsecutive: 'Enforce consecutive assignments',
        allowEarlyClaims: 'Allow early claims',
        effectiveOfficialsList: 'Show officials lists from sub organizations'
      },
      actions: {
        edit: 'Edit Settings',
      },
      labels: {
        card: {
          title: 'Assigning Settings',
        },
        edit_assigning: 'Edit Assigning Settings',
        general: 'General',
        requests: 'Requests',
        declines: 'Declines',
        travel_claimables: 'Travel Expenses',
        per_diem_claimables: 'Per Diem Expenses',
        claims: 'Claims',
      },
      messages: {
        load_error: 'Unable to load the assigning settings',
        visible_days_disabled: 'Officials cannot view unassigned games',
        visible_same_day: 'Officials can only view same day games',
        visible_days_on: 'Officials can view up to %{number} days in future',
        visible_days_off: 'All active games visible',
        requests_enabled_on: 'Enabled',
        requests_enabled_off: 'Assignments only',
        decline_enabled_on: 'Decline before %{number} hours',
        decline_enabled_on_anytime: 'Decline anytime',
        decline_enabled_off: 'Disabled',
        reason_required: 'reason is required',
        reason_not_required: 'reason not required',
        disabled_after_confirmation: 'disabled after confirmation',
      },
      alerts: {
        assigning_not_enabled: 'Assigning is not enabled for this organization',
        visibleDaysEnabled: 'Visible days must be enabled for officials to request games'
      },
      helpers: {
        declineReason: 'Require officials to specify a reason when declining',
        declineHours: 'Number of hours prior to the game start time that an official is able to decline',
        visibleDaysEnabled: 'If disabled, officials will not be able to view unassigned games',
        visibleDays: 'Number of advance days that officials will be able to view active games',
        dailyLimit: 'Maximum number of games an official can be assigned to in a day',
        requestsEnabled: 'If disabled, officials will be able to view active games but not request',
        declineEnabled: 'If disabled, officials will be instructed to contact an assigner to be removed',
        declineConfirmedDisabled: 'Officals will be unable to decline after they accept',
        removeAfterDecline: 'Officials will skip the declined state and be immediately removed',
        removeAfterRescheduled: 'Changes to the date, time or surface will remove all officials',
        removeOnDateChange: 'Overrides the setting above to remove officials only if date has changed. Changes to time or surface only will not remove officials',
        assignmentVisibility: "Visibility of an assigned official's name and status in the assigning panel",
        assignerVisible: "Officials will be able to see who assigned them",
        supervisorVisible: "Officials will be able to see the supervisor assignment",
        excludedDistance: 'Distance that will be calculated at a rate of $0/km',
        enforceConsecutive: 'Officials will be required to either accept or decline all assignments within an hour from each other',
        allowEarlyClaims: 'Officials will be authorized to enter claims early prior to the end of the game',
        effectiveOfficialsList: 'Officials lists from all the sub organizations will be able to be selected and shown from this organization'
      },
      values: {
        assignmentVisibility: {
          Visible: 'Name and Status',
          Status: 'Status Only',
          Hidden: 'Hidden'
        }
      }
    },
    officeClaimsSettings: {
      fields: {
        name: 'Name',
        type: 'Type',
        amount: 'Amount',
        rate: 'Rate',
        excludedDistance: 'Excluded Distance'
      },
      labels: {
        name: 'Claims Settings'
      },
      helpers: {
        amount: 'Maximum amount for an expense or flat rate for per diem',
        rate: 'Rate ($) per KM',
        excludedDistance: 'Excluded distance for a travel claim',
        no_options: 'No Claim Available - Contact your Assigner'
      },
      actions: {
        add: 'Add Claim Setting'
      },
      values: {
        secondaryText: {
          'Travel': 'Rate per KM',
          'Per Diem': 'Amount',
          'Expense': 'Maximum Amount'
        }
      }
    },
    gameAssignSettings: {
      name: 'Setting |||| Settings',
      fields: {
        gameId: 'Game',
        systemId: 'Officiating System',
        officeId: 'Assigning Organization',
        feesId: 'Pay Structure',
        minReferee: 'Minimum Referee Level',
        minLinesperson: 'Minimum Linesperson Level',
        minOfficial: 'Minimum Official Level',
        minScorekeeper: 'Minimum Scorekeeper Level',
        minTimekeeper: 'Minimum Timekeeper Level',
        minGrade: 'Minimum Official Grade',
        minRefereeGrade: 'Minimum Referee Grade',
        minLinespersonGrade: 'Minimum Linesperson Grade',
        minAge: 'Minimum Age',
        fees: 'Fees',
        status: 'Status',
      },
      labels: {
        referee: 'Referee Level',
        linesperson: 'Linesperson Level',
        official: 'Official Level',
        scorekeeper: 'Scorekeeper Level',
        timekeeper: 'Timekeeper Level',
        grade: 'Official Grade',
        referee_grade: 'Referee Grade',
        linesperson_grade: 'Linesperson Grade',
        set_assigning_settings: 'Set Assigning Settings',
        set_assigner: 'Set Assigner',
        assigning_settings: 'Assigning Settings',
        change_assigner: 'Change Assigner',
      },
      helpers: {
        status_draft: "Changing status from active to draft will notify assigned officials that they've been removed from the game"
      },
      messages: {
        change_assigner: 'To change the assigner for all positions, select the organization of the new assigner and do not select a position.',
        change_assigner_partial: 'To partially delegate assignments to another assigner, select the organization and position. Games that do not have the selected position or have already assigned the position to an official will be ignored.',
      },
      notifications: {
        updated_game_settings: 'Updated settings for %{smart_count} game |||| Updated settings for %{smart_count} games',
        updating_settings_error: 'Something went wrong while updating settings',
        errors: {
          invalid_fees: 'Invalid pay structure',
        }
      },
      alerts: {
        bulk_assign_settings: 'Check the settings you want to change. A checked setting with no value will clear the current value.'
      }
    },
    assignFees: {
      name: 'Pay Structure |||| Pay Structures',
      fields: {
        name: 'Name',
        officeId: 'Organization',
        Referee: 'Referee',
        Linesperson: 'Linesperson',
        LinespersonTwo: 'Linesperson 2',
        LinespersonThree: 'Linesperson 3',
        Official: 'Official',
        Scorekeeper: 'Scorekeeper',
        Timekeeper: 'Timekeeper',
        Supervisor: 'Supervisor',
        fees: 'Fees',
        isArchived: 'Archived',
      },
      actions: {
        add: 'Add Pay Structure',
      },
      labels: {
        card: {
          title: 'Pay Structures',
          subtitle: 'Some pay structures may also come from the parent organization.',
        },
        edit: 'Update Pay Structure',
      },
      values: {
        isArchived: {
          archived: 'Archived',
          notArchived: 'Active'
        }
      },
      validations: {
        assign_fee_in_use: 'The pay structure is being used. Create a new structure to make modifications',
        delete_assign_fee_in_use: 'Cannot delete a pay structure that is being used',
        cannot_archive: 'Assign rules must be removed before archiving this pay structure'
      },
      alerts: {
        cannot_update_amounts: 'In order to modify the amounts, you must create a new pay structure. You can then archive this pay structure after moving its assign rules to the new pay structure.'
      }
    },
    assignRules: {
      name: 'Assign Rule |||| Assign Rules',
      fields: {
        categoryIds: 'Categories',
        types: 'Schedule Types',
        groupIds: 'Groups',
        attributes: {
          duration: 'Game Length',
          days: 'Days',
          time: 'Before Time (24h)',
          effectiveDate: 'Effective Date'
        },
        order: 'Order',
        officeId: 'Organization',
        value: 'Value',
        type: 'Type',
        stopInheritance: 'Stop rule inheritance'
      },
      options: {
        Active: 'Active',
        Draft: 'Draft',
        Arena: 'Venue',
        'Arena Primary': "Venue's Primary Organization",
        Schedule: 'Schedule',
        Home: 'Home Team Organization',
        Office: 'Organization',
        'Home Parent': 'Home Team Parent Organization',
        Zone: 'Zone',
        systemId: 'Assign System',
        feesId: 'Pay Structure',
        officeId: 'Assigned by',
        minReferee: 'Referee Level',
        minLinesperson: 'Linesperson Level',
        minOfficial: 'Official Level',
        minScorekeeper: 'Scorekeeper Level',
        minTimekeeper: 'Timekeeper Level',
        minRefereeGrade: 'Referee Grade',
        minLinespersonGrade: 'Linesperson Grade',
        minAge: 'Minimum Age',
        assignType: 'Assigned via',
        status: 'Initial status',
      },
      actions: {
        add: 'Add Assign Rule',
      },
      labels: {
        card: {
          title: 'Game Specifications',
          subtitle: "When a game is created or updated, these rules are processed to determine the official requirements."
        },
        alt_card: {
          title: 'Assigner Determination',
          subtitle: "When a game is created or updated, these rules are processed to determine which organization will assign the games."
        },
        edit: 'Update Assign Rule',
        rule: 'Rule',
        default_rules: 'Default Rules',
        criteria: 'Game Criteria',
        duration: 'Duration: >= %{duration} min',
        days: 'Days: %{days}',
        time_before: 'Time: Before %{time}',
        effective: 'Effective: %{date}',
        rules: {
          feesId: 'Fees',
          officeId: 'Assigned by',
          minReferee: 'Referee Level: %{value}',
          minLinesperson: 'Linesperson Level: %{value}',
          minOfficial: 'Official Level: %{value}',
          minScorekeeper: 'Scorekeeper Level: %{value}',
          minTimekeeper: 'Timekeeper Level: %{value}',
          minGrade: 'Official Grade: %{value}',
          minRefereeGrade: 'Referee Grade: %{value}',
          minLinespersonGrade: 'Linesperson Grade: %{value}',
          minAge: 'Minimum Age: %{value}',
          assignType: 'Assigned via %{value}',
          status: 'Initial status: %{value}',
        },
        apply_rules: 'Apply Assigning Rules',
        applied_settings: 'Applied Settings',
        assignOffices: 'Apply Game Assign Settings',
        scheduleOffices: "Update Game Officials Assigners"
      },
      helpers: {
        assignType: 'The assigner will be automatically determined based on the related organization',
        assignTypes: {
          Arena: "The assigner will be automatically determined based on the related venue's organization",
          'Arena Primary': "The assigner will be automatically determined based on the venue's primary organization",
          Schedule: 'The assigner will be automatically determined based on the schedule organization',
          Home: 'The assigner will be automatically determined based on the home teams organization',
          'Home Parent': 'The assigner will be automatically determined based on the home temas parent organization',
          Office: 'The assigner will be automatically determined based on the games organization',
          Zone: 'The assigner will be automatically determined based on the zone',
        },
        officeId: 'Games will be assigned by the selected organization',
        scheduledBy: 'This rule applies to games scheduled by or under this organization',
        assignedBy: 'This rule applies to games assigned by or under this organization',
        categoryIds: 'Optional - All categories if empty',
        types: 'Optional - All schedule types if empty',
        groupIds: 'Optional - All groups if empty',
        duration: 'Optional - In minutes',
        stopInheritance: 'If enabled, this rule will supersede any matching rules in sub organizations',
        applied_settings: 'Optional - select a subset of settings to apply, otherwise if none are selected, all settings will be applied'
      },
      values: {
        types: TYPES,
      },
      validations: {
        conflicts: 'Change order to resolve overlapping criteria',
        inheritance: 'Criteria overlaps with a higher preceding rule that prevents overriding',
        archived_fee: 'Cannot add or edit an assign rule in an archived pay structure',
      },
      alerts: {
        existing_games: 'Select the games to apply assigning rules for. Existing settings will be overwritten.',
        scheduleOffices: 'This will redetermine the assigner based on game details, and determine assign settings based on that assigner.',
        assignOffices: 'This will determine settings based on the existing assigner.',
        new_games_only_title: 'Assigning rules are only applied when games are created or rescheduled',
        new_games_only: 'To reapply assigning rules on existing games, click <strong>Apply Assigning Rules</strong> in the actions menu.',
        determined_by: 'Assigning organization was determined by "%{rule}" from %{office}',
      },
      notifications: {
        apply_rules_success: 'Assigning rules have been applied to %{smart_count} game. |||| Assigning rules have been applied to %{smart_count} games.'
      },
      tooltips: {
        determine_via: `
          <div>
            <h3>The assigner can be determined via the following game properties:</h3>
            <ul>
              <li>
                <strong>Venue</strong>
                <ul>
                  <li>Based on the venue organizations.</li>
                  <li>If the home team is any of these organizations, that team’s organization will become the assigner.</li>
                  <li>If not, it'll look for an organization from the league restrictions of the venue organization.</li>
                  <li>Otherwise, the primary venue organization will become the assigner.</li>
                </ul>
              </li>
              <li>
                <strong>Venue Primary Organization</strong>
                <ul>
                  <li>The venue's primary organization will become the assigner.</li>
                  <li>Otherwise, no assigner will be chosen.</li>
                </ul>
              </li>
              <li>
                <strong>Schedule</strong>
                <ul>
                  <li>The schedule organization will become the assigner.</li>
                </ul>
              </li>
              <li>
                <strong>Home Team Organization</strong>
                <ul>
                  <li>The home team’s organization will become the assigner.</li>
                  <li>If TBA/TBD, this falls back to Venue assigner determination above.</li>
                </ul>
              </li>
              <li>
                <strong>Home Team Parent Organization</strong>
                <ul>
                  <li>Same as Home Team Organization, but based on the parent organization of the home team organization.</li>
                </ul>
              </li>
              <li>
                <strong>Zone</strong>
                <ul>
                  <li>Based on the venue organizations, if a Zone organization is available it’ll be used as the assigner.</li>
                  <li>Otherwise, this falls back to the primary venue organization.</li>
                </ul>
              </li>
            </ul>
            <p>
              If the determined organization is a League, Tournament or Cup organization, from any of these rules, the parent organization will be selected instead.
            </p>
          </div>
        `
      }
    },
    assignSettings: {
      name: 'Setting |||| Settings',
      labels: {
        required_level: 'Required Level',
        required_grade: 'Required Grade',
        required_age: 'Required Age',
      },
      messages: {
        assigned_by: 'Assigned by %{name}',
      },
      helpers: {
        systemId: 'Current assignments do not match the available positions',
      },
      validations: {
        invalid_level: 'Invalid Level',
        invalid_grade: 'Invalid Grade',
        invalid_age: 'Invalid Age',
        min_setting_range: 'Must be greater than or equal to 0.',
      },
    },
    assignSystems: {
      name: 'Assign System |||| Assign Systems',
      fields: {
        name: 'Name',
        referees: 'Referees',
        linespersons: 'Linespersons',
        linespersonsTwo: 'Linespersons 2',
        linespersonsThree: 'Linespersons 3',
        officials: 'Officials',
        scorekeepers: 'Scorekeepers',
        timekeepers: 'Timekeepers',
        supervisors: 'Supervisors',
      },
      actions: {
        add: 'Add Assign System',
      },
      labels: {
        card: {
          title: 'Assign Systems',
          subtitle: 'Assign Systems determine how many officials are needed for a game.',
        },
        edit: 'Update Assign System',
      },
    },
    assignTargets: {
      name: 'Assigner Override |||| Assigner Overrides',
      fields: {
        targetType: 'Type',
        targetId: 'Source',
        groupIds: 'Groups',
        officeId: 'Assigner',
      },
      actions: {
        add: 'Add Assigner Override',
      },
      labels: {
        card: {
          title: 'Assigner Overrides',
          subtitle: 'Override assigning rules to use a specific assigner for a schedule or instead of another assigner, optionally limited by groups.',
        },
        edit: 'Update Assigner Override',
        add_override: 'Add Override',
      },
      validations: {
        missing_type: 'Missing Type',
        unique_target: 'An override is already defined',
        unique_group: 'An override is already defined with the same group',
      }
    },
    pages: {
      name: 'Page |||| Pages',
      fields: {
        title: 'Title',
        slug: 'Slug',
        order: 'Order',
        content: 'Content',
        sectionId: 'Section',
        coverId: 'Cover Image',
        officeId: 'Organization',
      },
      actions: {
        edit: 'Edit Page',
      },
      labels: {
        add: 'New Page',
      },
      helpers: {
        order: 'Optional - defaults to alphabetical order',
      },
    },
    posts: {
      name: 'Post |||| News',
      fields: {
        title: 'Title',
        slug: 'Slug',
        date: 'Date',
        content: 'Content',
        coverId: 'Cover Image',
        officeId: 'Organization',
      },
      actions: {
        edit: 'Edit Post',
      },
      labels: {
        add: 'New Post',
      },
      messages: {
        start_writing_here: 'Start writing here...',
      }
    },
    registrations: {
      name: 'Registration |||| Registrations',
      fields: {
        seasonId: 'Season',
        divisionId: 'Division',
        participantId: 'Participant',
        officeId: 'Organization',
      },
      actions: {
        add: 'Add Registration',
      },
      labels: {
        card: {
          title: 'Registrations',
        },
        edit: 'Update Registration',
        import: 'Import Registrations',
        file: 'Registrations File',
      },
      messages: {
        drop_registrations_csv: 'Drop your registrations file (.csv) here',
        use_import_tool: "To use this import tool, upload a CSV file with columns for the participant's unique Member ID, first name, last name, and registered division.",
      },
      alerts: {
        create_new_participants: '<strong>Use with caution.</strong> This import tool will create new participants that do not match existing Member IDs.',
      },
      helpers: {
        select_columns: "You'll be asked to select columns after selecting your file",
        valid_registrations: '%{number}/%{total} rows have valid registrations',
        match_existing_participant: 'This Member ID is used to match an existing participant or create a new participant for this registration.',
      },
      notifications: {
        registrations_updated: '%{number} registrations were updated.',
        import_registrations_error: 'Unable to import registrations.',
      },
    },
    sections: {
      name: 'Section |||| Sections',
      fields: {
        name: 'Name',
        order: 'Order',
        officeId: 'Organization',
      },
      actions: {
        add: 'Add Section',
      },
      labels: {
        card: {
          title: 'Sections',
          subtitle: 'Website header sections to organize pages.',
        },
        edit: 'Update Section',
      }
    },
    webevents: {
      name: 'Event |||| Events',
      fields: {
        title: 'Title',
        content: 'Content',
        startTime: 'Start Time',
        endTime: 'End Time',
        allDay: 'All Day',
        location: 'Location',
        officeId: 'Organization'
      },
      actions: {
        edit: 'Edit Event',
      },
      labels: {
        card: {
          title: 'Events',
        },
        add: 'New Event',
        dates: 'Dates',
      },
    },
    announcements: {
      name: 'Announcement |||| Announcements',
      fields: {
        title: 'Title',
        seasonId: 'Season',
        type: 'Type',
        content: 'Content',
        targetType: 'Recipients Type',
        targetId: 'Recipients',
        participantId: 'Author',
        officeId: 'Organization',
      },
      actions: {
        edit: 'Edit Announcement',
      },
      labels: {
        officiating: 'Officiating Announcements',
        add: 'New Announcement',
        edit: 'Update Announcement',
      },
      messages: {
        send_email_officials: 'Send an email to officials',
        recipients: {
          all_team_staff: 'all team staff',
          all_available_officials: 'all available officials',
          all_team_staff_all_available_game_officials: 'all team staff & available game officials',
          all_list_members: 'all list members',
        }
      },
      helpers: {
        destination_sent: 'An email will be sent to %{recipient} that have a verified contact',
        destination_not_sent: 'Notifications are not sent for updates.'
      },
      values: {
        type: {
          General: 'General',
          League: 'League',
          Officiating: 'Officiating',
        },
        targetType: {
          Office: 'Organization',
          List: 'List',
        },
      }
    },
    lists: {
      name: 'List |||| Lists',
      fields: {
        name: 'List Name',
        officeId: 'Organization',
        members: 'Members'
      },
      actions: {
        add: 'Add list',
        edit: 'Edit list',
      },
      labels: {
        card: {
          title: 'Lists',
          subtitle: 'Assigners can use lists as a filter when assigning games.',
        },
        official: 'Official Lists',
        import_list_members: 'Import List Members',
        list_members_file: 'List Members File',
      },
      messages: {
        drop_list_members_csv: 'Drop your list members file (.csv) here',
        import_tool_list_members: "To use this import tool, upload a CSV file with a column for the official's unique Member ID.",
      },
      validation: {
        already_exists: 'Already in this list',
      },
      helpers: {
        match_existing_participant: 'Participants will be ignored if they are not a qualified official.',
        create_list: 'A list needs to be created prior to importing members.',
        quick_assign_tooltip: 'This filter is designated for assigning. Select an assigning organization to use it.',
      },
      notifications: {
        list_members_added: '%{smart_count} official was added to the list. |||| %{smart_count} officials were added to the list.',
        import_list_members_error: 'Unable to import list members.',
      },
    },
    infractions: {
      name: 'Infraction |||| Infractions',
      fields: {
        seasonId: 'Season',
        officeId: 'Organization',
        ruleId: 'Rule',
        code: 'Code',
        typeId: 'Type',
        severity: 'Severity',
        parentId: 'Parent Infraction',
        externalId: 'External ID',
        legacyName: 'Legacy Name',
        legacyDuration: 'Legacy Duration'
      },
      actions: {
        add: 'Add Infraction',
      },
      labels: {
        card: {
          title: 'Infractions',
          subtitle: 'A penalty is the result of an infraction.',
        },
        add: 'New Infraction',
        edit: 'Update Infraction',
        parent_code: 'Parent Code',
        parent_office: 'Parent Organization'
      },
      helpers: {
        parentId: 'Optional - Link this infraction with a parent organization infraction',
      },
      values: {
        severity: {
          Low: 'Low',
          Medium: 'Medium',
          High: 'High',
          Severe: 'Severe',
        },
      }
    },
    infractionTypes: {
      name: 'Infraction Type |||| Infraction Types',
      fields: {
        officeId: 'Organization',
        name: 'Name',
        abbreviation: 'Abbreviation',
        description: 'Description',
        duration: 'Duration',
        isShorthanded: 'Team Shorthanded',
        isEjection: 'Offender Ejected',
        order: 'Display Order',
        servingOrder: 'Serving Order',
      },
      helpers: {
        isShorthanded: 'Whether the team becomes shorthanded or a substitution is allowed',
        isEjection: 'Penalty results in the offending player being ejected from the game',
      },
      actions: {
        add: 'Add Type',
      },
      labels: {
        card: {
          title: 'Infraction Types',
          subtitle: 'Types determine the behaviour of penalties.',
        },
        add: 'New Type',
        edit: 'Update Type',
      }
    },
    ruleSections: {
      name: 'Rule Section |||| Rule Sections',
      fields: {
        seasonId: 'Season',
        officeId: 'Organization',
        name: 'Name',
        code: 'Code',
        order: 'Display Order',
      },
      actions: {
        add: 'Add Rule Section',
      },
      labels: {
        card: {
          title: 'Playing Rules',
          subtitle: 'Set of rules detailing infractions and corresponding rule options available for scorekeeping.',
        },
        add: 'New Section',
        edit: 'Update Section',
      },
    },
    rules: {
      name: 'Rule |||| Rules',
      fields: {
        seasonId: 'Season',
        officeId: 'Organization',
        sectionId: 'Section',
        name: 'Name',
        code: 'Code',
        order: 'Display Order',
      },
      actions: {
        add: 'Add Rule',
      },
      labels: {
        add: 'New Rule',
        edit: 'Update Rule',
      },
    },
    ruleOptions: {
      name: 'Rule Option |||| Rule Options',
      fields: {
        seasonId: 'Season',
        officeId: 'Organization',
        ruleId: 'Rule',
        members: 'Members',
        parentId: 'Parent Option',
        order: 'Display Order',
      },
      actions: {
        add: 'Add Rule Option'
      },
      labels: {
        card: {
          title: 'Rule Options',
          subtitle: 'The choices available when scorekeeping.',
        },
        add: 'New Rule Option',
        edit: 'Update Rule Option',
      },
    },
    ruleOptionMembers: {
      name: 'Option Member |||| Option Members',
      fields: {
        optionId: 'Rule Option',
        infractionId: 'Infraction',
        order: 'Serving Order',
      },
      actions: {
        add: 'Add Infraction'
      },
      labels: {
        add: 'Add Rule Option',
        edit: 'Update Rule Option',
      },
    },
    infractionAccumulations: {
      name: 'Automatic Infraction |||| Automatic Infractions',
      fields: {
        seasonId: 'Season',
        officeId: 'Organization',
        name: 'Name',
        count: 'Accumulation Count',
        members: 'Accumulating Infractions',
        infractionId: 'Resulting Infraction',
        isReplacement: 'Replacement'
      },
      actions: {
        add: 'Add Automatic Infraction Rule'
      },
      labels: {
        card: {
          title: 'Automatic Infraction Rules',
          subtitle: 'Accumulation rules to trigger an infraction when conditions are met within the same game.',
        },
        add: 'New Automatic Infraction Rule',
        edit: 'Update Automatic Infraction Rule',
      },
      helpers: {
        isReplacement: 'If enabled, the resulting infraction will replace the original penalty instead of creating a new one.'
      }
    },
    infractionAccumulatiomMembers: {
      name: 'Accumulation Member |||| Accumulation Members',
      fields: {
        accumulationId: 'Accumulation',
        infractionId: 'Infraction',
      },
      actions: {
        add: 'Add Infraction'
      },
      labels: {
        add: 'New Member',
        edit: 'Update Member',
      },
    },
    rulebooks: {
      name: 'Rulebook |||| Rulebooks',
      fields: {
        name: 'Name',
        infractions: 'Infractions',
        ejections: 'Automatic Game Ejections',
        doubleminors: 'Double Minors',
        variances: 'Variances',
        officeId: 'Organization',
        seasonId: 'Season',
        category: 'Category',
        categoryIds: 'Categories',
        code: 'Code',
        choices: 'Choices',
        durations: 'Durations',
        duration: 'Duration',
        isStick: 'Stick infraction',
        isArchived: 'Archived',
        description: 'Short Description',
        length: 'Length (seconds)',
        requireIncidentReport: 'Require Incident Report',
        timed: 'Timed Duration',
        lineup_limits: 'Lineup Limits',
        scheduleTypes: "Schedule Types",
        pitcherLimits: 'Pitcher Limits',
        throwsLimits: "Throws Rests",
        inningsPitchedLimits: "Innings Pitched Limits",
        inningsPitchedRests: "Innings Pitched Rests",
        limitType: 'Limit Type',
        restingDays: "Rest",
        startDate: "Start Date",
        endDate: "End Date",
      },
      actions: {
        add: 'Add Rulebook',
        add_infraction: 'Add Infraction',
        add_duration: 'Add Duration',
        add_variance: 'Add Variance',
        add_limits: "Add Limits",
      },
      labels: {
        card: {
          title: 'Lineup Limits',
          baseball_title: 'Lineup / Pitching Limits',
          subtitle: 'Policies to enforce the minimum / maximum members allowed on games.',
          baseball_subtitle: 'Policies to enfore pitching limits and the minimum / maximum members allowed on games.',
        },
        add: 'New Rulebook',
        add_limits: 'Add Limits',
        edit: 'Update Rulebook',
        edit_infraction: 'Update Infraction',
        edit_duration: 'Update Duration',
        edit_variance: 'Edit Variance',
        edit_limits: 'Edit Limits',
        position: 'Position',
        players: 'Players',
        skaters: 'Skaters',
        goalies: 'Goalies',
        staff: 'Staff',
        durations: 'Durations',
        infractions: 'Infractions',
        lineup_limits: 'Lineup Limits',
        pitcher_limits: 'Pitcher Limits',
        variances: 'Variances',
        additional_policies: 'Additional Policies',
        throws_limits: "Throws Rests",
        innings_pitched_limits: "Innings Pitched Limits",
        innings_pitched_rests: "Innings Pitched Rests"
      },
      alerts: {
        durations: 'Durations are used to create Infractions',
        infractions: 'Infractions in rulebooks from parent organizations will be inherited (but are not shown here). This organization may replace them by using the same infraction name.',
        lineup_limits: 'Default is unlimited unless specified otherwise or inherited from the parent organization(s).',
        pitcher_limits: 'Default is unlimited unless specified otherwise or inherited from the parent organization(s).',
        additional_policies: 'Additional policies may be left blank to be inherited from parent organization(s) where applicable.',
        rulebook_independence: 'If no rules are set at the branch level, games will inherit the rules from the federation.'
      },
      helpers: {
        ejections: 'A Game Ejection infraction will be automatically added after 3 penalties of the selected types',
        name: 'Note: Name cannot be changed once rulebook is saved',
        abbreviation: 'Replaces the time duration of penalties in the Official Game Report',
        requireIncidentReport: 'An incident report will be required for penalties of this duration.',
        timed: 'Penalties with timed durations take into consideration the serving time of the player',
        isStick: 'Infraction will be counted towards stick game ejection policy, if applicable',
        isArchived: 'Archived infractions are not shown when adding a penalty. Use this to retire an infraction instead of deleting.',
      },
      validation: {
        min: "Min must be lower than max",
        max: "Max must be higher than min",
        startDate: "Start Date must be before End Date",
        endDate: "End Date must be after Start Date",
      },
      values: {
        lineupLimits: {
          between: 'Between %{min} and %{max} %{position}',
          min: 'At least %{min} %{position}',
          max: 'Up to %{max} %{position}',
        },
        pitcherLimits: {
          scheduleTypes: {
            ...TYPES,
            League: 'Regular Season'
          },
          limitType: {
            Daily: 'Daily',
            Weekly: 'Weekly',
          },
          throwsLimits: {
            between: 'Between %{min} and %{max} throw(s) = %{restingDays} resting day(s)',
          },
          inningsPitchedLimits: {
            always: '%{limitType}: %{limit} inning(s) max',
            from: '%{limitType}: %{limit} inning(s) max from %{startDate}',
            between: '%{limitType}: %{limit} inning(s) max between %{startDate} and %{endDate}',
            until: "%{limitType}: %{limit} inning(s) max until %{endDate}",
          },
          inningsPitchedRests: {
            between: 'Between %{min} and %{max} inning(s) = %{restingDays} resting day(s)',
          },
        },
        ejections: {
          None: 'None',
          Contact: 'Head Contact & Stick Infractions',
          All: 'All Infractions',
        },
        severity: {
          low: 'Low',
          medium: 'Medium',
          high: 'High',
          severe: 'Severe',
        }
      }
    },
    help: {
      name: 'Article |||| Help',
      fields: {
        title: 'Title',
        content: 'Content',
        category: 'Category',
        roles: 'Applies To',
        isPublished: 'Published',
        updatedAt: 'Last Updated',
      },
      actions: {
        edit: 'Edit Article',
      },
      labels: {
        add: 'New Article',
        documentation: 'Documentation',
        help_center: 'Help Center',
      },
      alerts: {
        article_not_published_yet: 'This article has not yet been published.',
      },
      helpers: {
        roles: 'Article will only be visible to the selected roles',
      },
      values: {
        roles: {
          'super-admin': 'Super Admins',
          admin: 'Admins',
          registrar: 'Registrars',
          director: 'League Directors',
          manager: 'League Managers',
          organizer: 'Schedule Organizers',
          'assigning-manager': 'Assigning Managers',
          assigner: 'Assigners',
          official: 'Officials',
          player: 'Players',
          coach: 'Team Staff',
          assistant: 'Team Assistants',
          association: 'Associations',
          editor: 'Website Editors',
          scorekeeper: 'Scorekeepers',
        }
      }
    },
    reports: {
      name: 'Report |||| Reports',
      messages: {
        empty: 'Your league has not made any reports available.',
        error: 'Unable to load your reports (%{error}). You may need to try again later.',
      },
      values: {
        scheduleType: TYPES,
        types: {
          AP: "AP",
          Admin: "Admin",
          Assigning: "Assigning",
          Penalty: "Penalty",
          Scoring: "Scoring"
        },
      }
    },
    seasons: {
      name: 'Season |||| Seasons',
      fields: {
        seasonId: 'Season',
      },
      alerts: {
        viewing_inactive_season: "You are currently managing %{season} season, which is not the current season.",
      },
      values: {
        seasonId: {
          '2025-26': '2025-26',
          '2024-25': '2024-25',
          '2023-24': '2023-24',
          '2022-23': '2022-23',
          '2021-22': '2021-22',
          '2020-21': '2020-21',
          '2019-20': '2019-20',
          '2018-19': '2018-19',
        }
      }
    },
    identities: {
      name: 'Identity |||| Identities',
      fields: {
        accountId: 'Account',
        participantId: 'Participant',
        isPrimary: 'Primary',
      },
      actions: {
        add: 'Add Identity',
      },
      labels: {
        edit: 'Update Identity',
      },
      helpers: {
        isPrimary: 'The primary identity is the default participant the account will see after logging in.',
      }
    },
    groups: {
      name: 'Group |||| Groups',
      fields: {
        seasonId: 'Season',
        name: 'Name',
        type: 'Type',
        categoryIds: 'Categories',
      },
      actions: {
        add: 'Add Group',
      },
      labels: {
        card: {
          title: 'Groups',
          subtitle: "Split schedules into multiple cohorts within this league. When adding teams or games to a schedule, you'll be asked to select a group.",
        },
        edit: 'Update Group',
      },
      messages: {
        all_groups: 'All Groups',
      },
      helpers: {
        categoryIds: 'Optional - All categories if empty',
      },
      values: {
        type: {
          ...TYPES,
          Jamboree: 'Jamboree',
          Challenge: 'Challenge',
          Invitational: 'Invitational',
          'Program Of Excellence': 'Program Of Excellence',
          'Evaluation Camp': 'Evaluation Camp',
        },
      },
    },
    suspensions: {
      name: 'Suspension |||| Suspensions',
      fields: {
        penaltyId: 'Penalty',
        teamId: 'Team',
        participantId: 'Participant',
        requiredGames: 'Games To Serve',
        positionGroup: 'Position',
        code: 'Code',
        gameId: 'Game',
        status: 'Status',
        durationType: 'Duration Type',
        expiry: 'Expiry Date',
        additionalPurgingTeamId: 'Additional Team Eligible To Purge',
        disciplineStatus: 'Discipline Status',
        isAutomatic: 'Automatic',
      },
      labels: {
        edit: 'Edit Suspension',
        playing: 'Playing',
        played: 'Played',
        purging: 'Purging',
        purged: 'Purged',
        not_serving: 'Not serving',
        suspended: 'Suspended',
        not_suspended: 'Not suspended',
        admin: 'Administrative',
        indefinite: 'Indefinite',
        manual_direct: 'Other',
        type: 'Type',
        manual: 'Manual Suspension',
        automatic: 'Automatic Suspension',
        details: 'Details',
        participant_history: 'Participant Suspensions History',
        requiredGames: 'Initial Game(s)',
        disciplineAdditionalGames: 'Additional Game(s)',
        effectiveRequiredGames: 'Total Game(s)'
      },
      actions: {
        add: 'Add Suspension',
      },
      messages: {
        playing: 'Playing while suspended',
        unpurgeable: 'Serving, not purging due to different team',
        required_games: '%{smart_count} Game |||| %{smart_count} Games'
      },
      alerts: {
        automatic: {
          title: 'Automatic Suspension',
          message: 'This suspension has been automatically assessed by a sanction rule and cannot be modified. Supplemental games can be added by the discipline committee or adding a new suspension onto the same participant and game.',
        },
      },
      values: {
        positionGroup: {
          Player: 'Player',
          Staff: 'Staff'
        },
        code: {
          'Game Red': '2nd Game Caution',
          'Game Red Direct': 'Game Ejection',
          'Game Ejection': 'Game Ejection',
          'Schedule Yellow': '3rd Schedule Caution',
          supplemental: 'Supplemental',
          supplemental_long: 'Supplemental Suspension'
        },
        status: {
          Pending: 'Pending',
          Appealed: 'Appealed',
          Active: 'Active',
          Completed: 'Completed',
          Cancelled: 'Cancelled'
        },
        durationType: {
          Definite: 'Definite',
          Indefinite: 'Indefinite'
        }
      }
    },
    suspensionDisciplines: {
      name: 'Discipline |||| Disciplines',
      fields: {
        suspensionId: 'Suspension',
        additionalGames: 'Additional Games',
        status: 'Status',
        durationType: 'Duration Type',
        comments: 'Comments',
      },
      labels: {
        edit: 'Edit Discipline',
        confirm_trigger: 'Trigger Discipline Committee',
        discipline_committee: 'Discipline Committee',
      },
      messages: {
        confirm_trigger: 'This will trigger the discipline committee to review this suspension and act on it. Are you sure you want to do this?',
      },
      alerts: {
        additionalGames: 'Additonal games are only applied when status is set to <strong>Active</strong>. To reduce the total number of games, use a negative value.',
      },
      actions: {
        add: 'Additional Discipline',
        trigger: 'Trigger Discipline Committee',
      },
      values: {
        status: {
          Pending: 'Pending',
          Active: 'Active',
          Inactive: 'Inactive',
        },
        durationType: {
          Definite: 'Definite',
          Indefinite: 'Indefinite'
        }
      },
      validations: {
        must_be_empty: 'This field must be empty when duration type is indefinite',
        must_be_greater_than_zero: 'Total games must be greater than 0',
      },
      notifications: {
        trigger_success: 'The discipline committee has been triggered',
        trigger_error: 'Unable to trigger the discipline committee',
      },
      helpers: {
        totalGames: 'Total games: %{totalGames}',
      }
    },
    sanctions: {
      name: 'Sanction |||| Sanctions',
      fields: {
        name: 'Name',
        code: 'Code',
        order: 'Order',
        sectionId: 'Section',
        parentId: 'Parent Sanction',
        accumulationCount: 'Accumulation Count',
        positionGroups: 'Position Groups',
        isActive: 'Active',
      },
      labels: {
        card: {
          title: 'Sanction Rules',
          subtitle: 'Accumulation rules to trigger an automatic suspension when conditions are met across one or multiple games.'
        },
        edit: 'Edit Sanction',
        parent_code: 'Parent Code',
        parent_office: 'Parent Organization'
      },
      actions: {
        add: 'Add Sanction',
      },
      helpers: {
        parentId: 'Optional - Link this sanction with a parent organization sanction'
      },
      values: {
        positionGroup: {
          Player: 'Player',
          Staff: 'Staff'
        },
        durationType: {
          Definite: 'Definite',
          Indefinite: 'Indefinite'
        }
      }
    },
    sanctionMembers: {
      name: 'Sanction Member |||| Sanction Members',
      fields: {
        sanctionId: 'Sanction',
        optionId: 'Rule Option',
        infractionId: 'Infraction'
      },
      labels: {
        card: {
          title: 'Accumulations',
          subtitle: 'Rule options or infractions that will trigger an offense.'
        },
        edit: 'Edit Rule',
        rule: 'Rule',
        code: 'Code',
      },
      actions: {
        add: 'Add Rule',
      },
    },
    sanctionOffenses: {
      name: 'Sanction Offense |||| Sanction Offenses',
      fields: {
        offense: 'Offense Number',
        sanctionId: 'Sanction',
        durationType: 'Duration Type',
        games: 'Games',
        lastMinutesGames: 'Last Minute Games',
        injuryGames: 'Injury Additional Games',
        injuryDays: 'Injury Additional Days',
        days: 'Days',
        lastMinutesDays: 'Last Minutes Days',
        requireDisciplineCommittee: 'Requires Discipline Committee',
      },
      labels: {
        card: {
          title: 'Offenses',
          subtitle: 'Number of games on the suspension generated.'
        },
        edit: 'Edit Offense',

      },
      actions: {
        add: 'Add Offense',
      },
      helpers: {
        games: 'Number of games on the suspension generated.',
        days: 'Number of days to calculate the expiry date on the suspension generated.',
        lastMinutesGames: 'Number of games on the suspension generated if the infraction occurred in the last %{minutes} minutes of the game.',
        lastMinutesDays: 'Number of days to calculate the expiry date on the suspension generated if the infraction occurred in the last %{minutes} minutes of the game.',
        injuryGames: 'Number of additional games on the suspension generated if an injury occurred during the infraction.',
        injuryDays: 'Number of additional days to calculate the expiry date on the suspension generated if an injury occurred during the infraction.'
      },
      values: {
        durationType: {
          Definite: 'Definite',
          Indefinite: 'Indefinite'
        }
      }
    },
    officialClaimDocuments: {
      name: 'Claim Documents',
      fields: {
        gameId: 'Game',
      },
      labels: {
        delete: 'Delete'
      },
      notifications: {
        deleted: 'This document has been deleted'
      },
      messages: {
        info_creating_claim: 'To add documents, please complete, save, and open your claim.'
      },
    },
    officialClaims: {
      name: 'Claims',
      fields: {
        participantId: 'Participant',
        gameId: 'Game',
        officeId: 'Organization',
        amount: 'Amount',
        distance: 'Distance',
        type: 'Type',
        note: 'Note',
        status: 'Status',
        roundtrip: 'Roundtrip',
        addressId: 'Address',
        createdAt: 'Claimed on',
        addressFrom: 'Address From',
        addressTo: 'Address To',
        intermediateStop: 'Intermediate Stop',
        hasIntermediateStop: 'Intermediate Stop',
        calculateDistance: 'Calculate Distance',
        claimSettingId: 'Claim Setting',
      },
      labels: {
        card: {
          title: 'Claims'
        },
        edit: 'Edit Claim |||| Edit Claims',
        'Pending': 'Pend Claim |||| Pend Claims',
        'Approved': 'Approve Claim |||| Approve Claims',
        'Declined': 'Decline Claim |||| Decline Claims',
      },
      values: {
        type: {
          'Driver': 'Driver',
          'Passenger': 'Passenger',
          'Breakfast': 'Breakfast',
          'Lunch': 'Lunch',
          'Dinner': 'Dinner',
          'Travel': 'Travel',
          'Expense': 'Expense',
          'Per Diem': 'Per Diem'
        },
        status: {
          'Approved': 'Approved',
          'Pending': 'Pending',
          'Declined': 'Declined',
        }
      },
      actions: {
        add: 'Add Claim',
        'Pending': 'Pend',
        'Approved': 'Approve',
        'Declined': 'Decline'
      },
      notifications: {
        'Pending': 'Claim pending |||| Claims pending',
        'Approved': 'Claim approved |||| Claims approved',
        'Declined': 'Claim declined |||| Claims declined',
        pending_error: 'Unable to make claim pending |||| Unable to make claims pending',
        approved_error: 'Unable to approve the claim |||| Unable to approve claims',
        declined_error: 'Unable to decline the claim |||| Unable to decline claims'
      },
      helpers: {
        'Pending': 'Are you sure you want to make this claim pending?',
        'Approved': 'Approving a claim will create a corresponding transaction.',
        'Declined': 'Are you sure you want to decline this claim?',
        no_claim_types: 'No available claim types for this assigner',
        distance: 'Enter full distance; first %{smart_count} km are automatically excluded',
        excluded_amount: 'after %{distance} km',
      },
      validations: {
        claim_approved: 'Cannot edit an approved claim',
      },
      messages: {
        claim_unavailable: 'Claims can only be made after the game has ended'
      }
    },
    officialTransactions: {
      name: 'Transactions',
      fields: {
        participantId: 'Participant',
        gameId: 'Game',
        officeId: 'Organization',
        date: 'Date',
        type: 'Type',
        subtype: 'Subtype',
        amount: 'Amount',
        payPeriodId: 'Pay Period',
        description: 'Description',
        gameScheduleId: 'Schedule',
      },
      values: {
        type: {
          "Base": 'Base',
          "Travel": 'Travel',
          "Per Diem": 'Per Diem',
          "Evaluation": 'Evaluation',
          "Exhibition Game": 'Exhibition Game',
          "Internship": 'Internship',
          "Withhold": 'Payment Withhold',
          "Other": 'Other',
        },
        subtype: {
          'Driver': 'Driver',
          'Passenger': 'Passenger',
          'Breakfast': 'Breakfast',
          'Lunch': 'Lunch',
          'Dinner': 'Dinner',
          'Travel': 'Travel',
          'Expense': 'Expense',
          'Per Diem': 'Per Diem'
        }
      },
      messages: {
        select_all: 'Select all',
        unselect_all: 'Unselect all',
        group_by_game: 'Group by game',
        group_by_participant: 'Group by participant',
      },
      filters: {
        startTime: 'Start Date',
        endTime: 'End Date',
      },
      notifications: {
        assigned_pay_period: 'Transactions added to the pay period',
        assign_pay_period_error: 'Unable to assign the pay period',
        pay_period_removed: 'Pay period removed',
        remove_pay_period_error: 'Unable to remove the pay period',
      },
      actions: {
        add: 'Add Transaction',
        edit: 'Update Transaction',
        assign_pay_period: 'Assign Pay Period',
        remove_pay_period: 'Remove Pay Period',
      },
      validations: {
        from_pay_period_closed: "Can't move transaction out of a closed pay period",
        pay_period_closed: "Can't move transaction into a closed pay period"
      }
    },
    payPeriods: {
      name: 'Pay Period |||| Pay Periods',
      fields: {
        officeId: 'Organization',
        name: 'Name',
        startDate: 'Start Date',
        endDate: 'End Date',
        isClosed: 'Closed',
        closedAt: 'Closing Date',
      },
      labels: {
        add_transactions: 'Add Transactions',
        close_period: 'Close Period',
        dates: 'Dates',
        card: {
          title: 'Pay Periods',
        },
        status: 'Status',
        active: 'Active',
        closed: 'Closed (%{date})',
      },
      actions: {
        add: 'Add Pay Period',
        edit: 'Edit Pay Period',
        add_transactions: 'Add Transactions',
      },
      filters: {
        isClosed: 'Closed',
      },
      alerts: {
        period_closed: 'Closed Period',
        period_active: 'Active Period',
        period_closed_message: 'This period was closed on %{date} and can no longer be modified.',
        period_active_message: 'Once all transactions have been added, close the period to prevent changes.',
      },
      helpers: {
        close_period: 'Closing the period is irreversible and transactions will be locked.',
      },
      notifications: {
        pay_period_close: 'Period locked',
        close_pay_period_error: 'Unable to lock period'
      },
      validations: {
        pay_period_closed: "Can't make changes to a closed pay period"
      },
      values: {
        type: {
          "Base": 'Base',
          "Travel": 'Travel',
          "Per Diem": 'Per Diem',
          "Evaluation": 'Evaluation',
          "Exhibition Game": 'Exhibition Game',
          "Internship": 'Internship',
          "Withhold": 'Payment Withhold',
          "Other": 'Other',
        },
        subtype: {
          'Driver': 'Driver',
          'Passenger': 'Passenger',
          'Breakfast': 'Breakfast',
          'Lunch': 'Lunch',
          'Dinner': 'Dinner',
        }
      },
    },
    gameIncidents: {
      name: 'Incident Reports',
      fields: {
        penaltyId: 'Penalty',
        description: 'Description',
        injured: 'Injured',
        returnedToGame: 'Returned to game',
        status: 'Status',
        createdAt: 'Created',
        updatedAt: 'Updated'
      },
      labels: {
        participantId: 'Participant',
        edit: 'Edit Incident',
        injury: 'Injury',
        returned: 'Returned',
        notInjured: 'Not Injured',
      },
      actions: {
        add: 'Add Incident',
      },
    },
    gamePurges: {
      name: 'Game Purges',
      fields: {
        gameId: 'Game',
        teamId: 'Team',
        participantId: 'Participant',
        suspensionId: 'Suspension'
      },
      labels: {
        games_purged: 'Games Purged'
      },
      messages: {
        no_results: 'No games purged'
      }
    },
    gamepenalties: {
      name: 'Penalty |||| Penalties',
      fields: {
        gameId: 'Game',
        teamId: 'Team',
        participantId: 'Participant',
        infraction: 'Infraction',
        gameTime: 'Game Time',
      },
    },
    gameMessages: {
      name: 'Message |||| Messages',
      fields: {
        type: 'Type',
      },
      labels: {
        delete: 'Delete message',
      },
      messages: {
        no_messages: 'No messages yet',
      },
      helpers: {
        notification_sent_to: "A notification will be sent to this game's %{recipients} and assigned game officials.",
      },
      notifications: {
        send_messages_error: 'Unable to send message',
        deleted: 'Message deleted',
        delete_error: 'Unable to delete message',
      },
      values: {
        type: {
          League: 'League',
          Officiating: 'Officiating',
        }
      }
    },
    scoresheets: {
      name: 'Scoresheet |||| Scoresheets',
      fields: {
        gameId: 'Game',
        scoresheet: 'Scoresheet',
      },
      actions: {
        add_penalties: 'Add Penalties',
        add_goals: 'Add Goals',
      },
      labels: {
        print: 'Print',
        print_roster: 'Print Roster',
        add_extra: 'Add Extra',
        add_extra_same_office: 'Same Organization',
        photos: 'Photos',
        suspended: 'Suspended',
        summary: 'Summary',
        transferred: 'Transferred',
        add_penalties: 'Add Penalties',
        add_goals: 'Add Goals',
        edit_penalty: 'Edit Penalty',
        edit_goal: 'Edit Goal',
        penalties: 'Penalies',
        goals: 'Goals',
        players: 'Players',
        goalies: 'Goalies',
        coach: 'Coach',
        bench_staff: 'Bench Staff',
        official: 'Official',
        complete: 'Complete',
        undo_approval: 'Disapprove',
        sign_and_approve: 'Sign & Approve Lineup',
        start_game: 'Start Game',
        approve_game: 'Approve Game',
        undo_certify: 'Decertify',
        certify: 'Certify Game |||| Certify Games',
        more_info: 'More info',
        less_info: 'Less info',
        signed: 'Signed',
        start: 'Start',
        ended: 'Ended',
        period: 'Period',
        official_attendance: 'Official Attendance',
        additional_emails: 'Additional Emails',
        clock: 'Clock',
        reset_scoresheet: 'Reset Scoresheet',
        home: {
          lineup: 'Home Lineup',
          goal: 'Home Goal',
          penalty: 'Home Penalty',
          goalie: 'Home Goalie',
        },
        away: {
          lineup: 'Away Lineup',
          goal: 'Away Goal',
          penalty: 'Away Penalty',
          goalie: 'Away Goalie',
        },
        penalty: {
          offender: 'Offender',
          served_by: 'Served By',
          infraction: 'Infraction',
          duration: 'Duration',
          game_time: 'Game Time',
          start_time: 'Start Time',
          inning: 'Inning',
          half: 'Half',
          delete: 'Delete Penalty',
          delete_additional: 'Delete Penalties',
          accumulation: 'Accumulation Rule',
        },
        goal: {
          type: 'Type',
          scored_by: 'Scored By',
          assisted_by: 'Assisted By',
          game_time: 'Game Time',
          delete: 'Delete Goal',
        },
        edit_score: 'Edit Score',
        add_notes_title: 'Add notes',
        add_notes: 'Add Notes',
        edit_notes_title: 'Edit Notes',
        edit_notes: 'Edit Notes',
        scoresheet_notes: 'Game Notes',
        admin_notes: 'Official Notes',
        period_settings: 'Periods',
        periods: {
          1: '1st',
          2: '2nd',
          3: '3rd',
          OT: 'OT',
        },
      },
      messages: {
        your_team_roster: 'your team roster',
        lineup_change_apply: 'Any changes to the lineup will only apply to this game, and will not affect',
        lineup_change_apply_part_2: 'or other scoresheets.',
        select_all: 'Select all',
        unselect_all: 'Unselect all',
        show_more: 'Show more',
        show_registrations: 'Show registrations',
        minimum_not_met: 'Minimum not met',
        over_limit: 'Over limit',
        lineup_approved_by: 'Lineup approved by',
        start_game: 'Are you sure you want to start the game?',
        end_game: 'At what time in the match did the game end at?',
        confirm_sign: 'By signing, I confirm that my lineup is complete and conforms to regulations.',
        confirm_sign_game: 'By approving, I confirm that the game has been completed correctly.',
        additional_emails: 'Scoresheets are automatically sent to team staff, league administrators and registered game officials',
        confirm_certify: 'Are you sure you want to certify this game? |||| Are you sure you want to certify these games?',
        undo_approval: {
          title: 'Disapprove scoresheet',
          message: 'The scoresheet will be reverted to incomplete and scorekeepers will be able to make changes.',
        },
        undo_certify: {
          title: 'Decertify scoresheet',
          message: 'This will allow changes to be made to the scoresheet by the league and allow the official to undo approval.',
        },
        reset_scoresheet: 'This will revert the scoresheet back to its starting state. Officials and scheduling will not be affected.',
        put_in_goalie: 'Put in',
        empty_net: 'Empty net',
        assist_abbr: 'Asst',
        served_abbr: 'Serv',
        shg: 'SHG',
        ppg: 'PPG',
        eng: 'ENG',
        psg: 'PENALTY SHOT',
        og: 'OWN GOAL',
        successful_game_end: 'Game ended',
        successful_game_approve: 'Game approved',
        successful_game_undo_approval: 'Game disapproved',
        successful_game_undo_certified: 'Game decertified',
        successful_game_certify: 'Game certified',
        successful_game_start: 'Game started',
        successful_save_flags: 'Flags saved',
        successful_save_score: 'Score saved',
        successful_save_notes: 'Notes saved',
        successful_update_settings: 'Settings updated',
        loading_flags: 'Loading flags',
        penalty: {
          added: `Penalty added |||| %{smart_count} penalties added`,
          not_added: 'No penalties were added',
          updated: `Penalty updated |||| %{smart_count} penalties updated`,
          not_updated: 'Penalty was not updated',
          delete_confirm: 'Are you sure you want to delete this penalty?',
          delete_additional: '%{smart_count} additional penalty will be removed. |||| %{smart_count} additional penalties will be removed.',
          subsequent_penalties: 'Existing subsequent penalties will be affected',
          subsequent_penalties_edit: 'Only the time can be edited for subsequent penalties'
        },
        goal: {
          added: `Goal added |||| %{smart_count} goals added`,
          not_added: 'No goals were added',
          updated: `Goal updated |||| %{smart_count} goals updated`,
          not_updated: 'Goal was not updated',
          delete_confirm: 'Are you sure you want to delete this goal?'
        }
      },
      alerts: {
        early_lineup_entry: "This lineup cannot be entered until the prior game is completed or after %{date}. You may print your full roster lineup in advance.",
        duplicate_batting_order: "Some players have the same batting order",
        unselected_suspended_players: 'Suspended members must be selected',
        cannot_remove_members: 'Cannot remove members that have goals, penalties or new suspensions',
        lineup: 'To enter the scoresheet, lineups must be completed.',
        start: 'Game has not been started yet.',
        approve: 'Game has not been completed yet.',
        completed: 'This scoresheet has been completed.',
        not_certified: 'This scoresheet has not been certified.',
        certified: 'This scoresheet has been certified.',
        missing_incidents: 'There are major penalties that are missing incident reports',
        not_authorized: 'You are not authorized to manage lineups for this game.',
        load_error: 'Unable to load scoresheet',
        lineup_change_post_game_started: 'Lineup cannot be changed once game has started, except by the scorekeeper.',
        unauthorized_manage_lineup: 'You are not authorized to manage this lineup.',
        team_has_no_members: 'This team has no members',
        or_registrations: 'or registrations',
        no_members_match_filters: 'No members match your filter',
        cannot_reset: 'Completed scoresheets cannot be reset',
        lineup_throwsinnings: "To enter the innings and pitch counts, this team's lineup must be completed and signed.",
        lineup_throwsinnings_certified: "Innings and pitch counts cannot be modified after game has been certified.",
        missingIncidents: {
          title: 'Missing Incident Reports',
          message: 'Some penalties require an incident report which must be submitted before the game can be completed.',
        },
        loading: {
          scoresheet: 'Loading scoresheet...',
          validatingMembers: 'Validating members'
        },
        requirements: {
          title: 'Lineup Requirements',
          players: {
            min: 'At least %{min} players',
            max: 'Up to %{max} players',
            missing_jersey_number: 'Some players are missing a jersey number',
          },
          skaters: {
            min: 'At least %{min} players',
            max: 'Up to %{max} players',
          },
          goalies: {
            min: 'At least %{min} goalies',
            max: 'Up to %{max} goalies',
          },
          staff: {
            min: 'At least %{min} bench staff members',
            max: 'Up to %{max} bench staff members',
          }
        },
      },
      values: {
        periods: {
          1: '1',
          2: '2',
          3: '3',
          OT: 'Overtime',
          SO: 'Shootout',
        },
        half: {
          first: 'First half',
          second: 'Second half',
        }
      },
      validations: {
        too_many_assists: 'Too many assists',
        out_of_range: 'Time not within range',
        invalid_extra: 'Extra minutes is an invalid number',
        start_time_before_game_time: 'Start Time is before the Off Time',
        confirm: 'I understand this action cannot be undone'
      },
      helpers: {
        scoresheet_notes: 'These notes will be publicly visible on the scoresheet',
        admin_notes: 'These notes will be private to scorekeepers, officials, and administators only',
      },
      notifications: {
        reset: 'Scoresheet has been successfully reset',
        reset_error: 'Could not reset scoresheet',
        errors: {
          cannot_reset: 'Completed scoresheets cannot be reset',
        }
      }
    },
    gameDocuments: {
      name: 'Document |||| Documents',
      fields: {
        name: 'Name',
        type: 'Type'
      },
      values: {
        type: {
          Lineup: 'Lineup',
          Scoresheet: 'Scoresheet',
          Other: 'Other',
        }
      },
      labels: {
        file: 'File',
        delete: 'Delete Document'
      },
      actions: {
        add: 'Add Document',
        edit: 'Edit Document',
      },
      messages: {
        max_size: 'Maximum upload file size: 10 MB'
      },
      notifications: {
        deleted: 'Document deleted',
        delete_error: 'Unable to delete document',
      }
    },
    gameBrackets: {
      name: 'Bracket',
      labels: {
        edit: 'Modify seeds'
      },
      actions: {
        edit: 'Modify seeds'
      },
      messages: {
        edit_seeds: 'Modifying the seeds will update the game comments to match the new positions.'
      },
      fields: {
        bracket: {
          view: 'View Bracket',
          link: 'Bracket',
        },
        seeds: {
          title: 'Seeds',
          away: 'Away Team',
          home: 'Home Team'
        }
      }
    },
    attributeTypes: {
      name: 'Attribute Type |||| Attribute Types',
      fields: {
        name: 'Name',
        officeId: 'Organization',
        type: 'Attribute Type',
        targetType: 'Target Type',
        valueType: 'Value Type',
        options: 'Options',
        min: 'Minimum',
        max: 'Maximum',
      },
      helpers: {
        valueType: {
          has_data: "Cannot change value type when there is bound data",
        },
        optional: "Optional"
      },
      validation: {
        min: "Minimum must be lower than maximum",
        max: "Maximum must be higher than minimum",
        no_dropdown_options: "At least 1 option is required",
      },
      type: {
        Officiating: 'Officiating',
        League: 'League',
      },
      targetType: {
        Participant: 'Participant',
        Venue: 'Venue',
        Surface: 'Surface',
        Team: 'Team',
      },
      valueType: {
        Dropdown: 'Dropdown',
        Number: 'Number',
        Text: 'Text',
      },
      actions: {
        add: 'Add Attribute Type',
      },
      labels: {
        card: {
          title: 'Attributes',
          subtitle: "Assigners can use attributes as a filter when assigning games.",
        },
        edit: 'Update Attribute Type',
      },
    },
    attributeValues: {
      name: 'Attribute |||| Attributes',
      fields: {
        attributeId: 'Attribute',
        officeId: 'Organization',
        value: 'Value',
      },
      actions: {
        add: 'Add Attribute',
      },
      labels: {
        card: {
          title: 'Attributes',
          subtitle: "",
        },
        edit: 'Update Attribute',
        attributes_file: 'Attributes File',
        import_attributes: 'Import Attributes'
      },
      validations: {
        types: {
          Number: 'Some rows have invalid number values',
          Dropdown: 'Some rows have values that are not in the dropdown options'
        }
      },
      helpers: {
        match_existing_participant: 'This Member ID is used to match an existing participant.',
      },
      messages: {
        drop_attributes_csv: 'Drop your attributes file (.csv) here',
        import_tool_attributes: "To use this import tool, upload a CSV file with columns for the official's unique Member ID, and the respective organization Attribute Types.",
        import_tool_warning: 'This import tool will update existing official attribute values in your organization.',
      },
      alerts: {
        caution: 'Use with caution',
      },
      notifications: {
        import_attributes_error: 'Unable to import attributes',
        attributes_updated: '%{smart_count} attribute was updated. |||| %{smart_count} attributes were updated.',
      }
    },
    externalProviders: {
      name: 'External Providers',
      fields: {
        service: 'Service',
        provider: 'Provider Name',
        link: 'Link',
        externalId: 'External ID',
      },
      actions: {
        add: 'Add Provider',
      },
      labels: {
        edit: 'Edit Provider',
        services: {
          Streaming: 'Streaming',
          Ticketing: 'Ticketing',
          Stats: 'Stats',
        }
      },
      messages: {
        no_results: 'No providers found',
        deleted: 'Provider deleted',
      }
    },
    pools: {
      name: 'Pool |||| Pools',
      fields: {
        name: 'Name',
      },
      actions: {
        add: 'Add Pool',
      },
      labels: {
        card: {
          title: 'Pools',
        },
        edit: 'Edit Pool',
      },
      messages: {
        all_pools: 'All Pools',
        select_highlight: 'Select a pool to highlight it in the matrix',
      },
      tooltips: {
        pools_unavailable: 'The organization management have decided to use groups therefore pools are not available.',
        groups_unavailable: 'The organization management have decided to use pools therefore groups are not available.'
      }
    }
  },
  components: {
    calendar: {
      values: {
        views: {
          Day: 'Day',
          Week: 'Week',
          Month: 'Month',
          Season: 'Season',
        },
        game_list_filters: {
          drafts_only: 'Draft games only',
          drafts_no_dates: 'Draft games without dates',
          drafts_no_times: 'Draft games without times',
          all_published_games: 'All published games',
          all_games: 'All games'
        }
      },
      labels: {
        unschedule: 'Unschedule Game',
        selected_game: 'Selected Game',
        remove_date: 'Remove Date',
      },
      settings: {
        arena_slots: 'Show Time Slots',
        away_arena_slots: 'Show Away Time Slots',
        team_names: 'Show Team Names',
        condensed_view: 'Show Condensed View'
      },
      messages: {
        select_game: 'Select a game from the list on your left',
        no_home_surface: 'No home surfaces have been configured for this team',
        no_result: 'No surfaces found with the selected surface size filter',
        schedule_game: 'A date has not been scheduled for the selected game',
        unschedule_game: 'Are you sure you want to unschedule this game?',
        remove_time_field: 'Are you sure you want to remove the time and surface from this game?',
        remove_date: 'Are you sure you want to remove the date from this game?',
        no_schedule_teams: 'No teams found in this schedule',
      },
      tooltips: {
        scheduled_games: '%{count} Games scheduled',
        not_scheduled_games: '%{count} Games not scheduled',
        scheduled_away_games: '%{count}/%{totalCount} Away games scheduled',
        scheduled_home_games: '%{count}/%{totalCount} Home games scheduled',
        schedule: 'Schedule Game',
        unschedule: 'Unschedule Game',
        both_teams: 'Both Teams',
        home_team: 'Home Team',
        away_team: 'Away Team',
        draft_game: 'Draft Game',
        game: 'Game',
      },
      validations: {
        end_before_start: 'End date must be after start date',
        start_after_end: 'Start date must be before end date',
      }
    },
    search: {
      title: 'My Searches',
      save: 'Save current search',
      remove: 'Remove saved search',
      specify_name: 'Specify a name for your search',
      loading_error: "Something went wrong loading your searches",
    },
    login: {
      scorekeeper: 'Scorekeeper',
      member: 'Member',
      admin: 'Admin',
      edit: 'Edit',
      messages: {
        im_a: "I'm a...",
        unauthorized: 'Please contact your league administrator for further information.',
        redirect: "If you're not redirected to the scoresheet, you can ",
        helper: {
          scorekeeper: "Use the game number and home team's ID to access the scoresheet without an account",
          member: "Use your account login to manage your game lineups, team rosters, assignments, and more...",
        },
        offline: {
          you: "You're Offline",
          reconnect: "Once you reconnect your device online, you'll be able to login here.",
        },
        storage: {
          unavailable: 'Storage unavailable',
          cookies_disabled: 'Looks like your cookies may be disabled.',
          use_browser_storage: `${appName} uses browser storage for scoresheet integrity and authentication.`,
          enable_cookies: 'To continue, please enable cookies/storage in your browser settings then try again.',
        },
        sso: {
          link_error: 'Something went wrong while linking your account',
          link_success: 'Account successfully linked'
        }
      },
      validation: {
        invalid_game_number: 'Game Number could not be found',
        invalid_game_number_long: 'Game Number could not be found. Please check the schedule to confirm.',
        invalid_team_id: 'Incorrect home team ID',
        invalid_team_id_long: 'Incorrect home team ID. Make sure you have the right game number and team.',
        missing_home_or_away_team: 'Missing a home or away team on the game',
        missing_home_or_away_team_long: 'Missing a home or away team on the game.',
        game_cancelled: 'Game cancelled',
        game_cancelled_long: 'This game has been cancelled. Please check the schedule to confirm.',
        unsupported_sport: 'Unsupported sport',
        unsupported_sport_long: 'Scorekeeping is only available for Hockey.',
        game_already_completed: 'Game already completed',
        game_already_completed_long: 'This game has already been completed. For corrections, please contact your association.',
      },
      alerts: {
        sso_link: 'Link Spordle My Account',
      },
      actions: {
        link_account: 'Link Account',
      },
      chip: {
        sso: 'Spordle My Account'
      }
    },
    register: {
      participant: 'Participant Member ID',
      hockey_identity: 'Your Identity',
      registration: `${appName} Registration`,
      review: 'Review',
      link_invitation: 'Link Invitation',
      messages: {
        link: "You're linking a participant to the following account:",
        correct_account: 'If this is not the correct account, you can log out below.',
        email_login: `Your email will be your login to access ${appName}.`,
      },
      validation: {
        account_exists: 'You already have an account. You may reset your password below or contact your league or assigner',
        already_linked: 'An account is already linked to this participant. You may need to contact your league or assigner',
        participant_invalid: 'Unable to find you by this ID. You may need to try again later',
        participant_mismatch: 'Unable to match your ID and name. You may need to contact your league or assigner',
        ineligible: 'Only officials and team staff are eligible to register',
        token_invalid: 'Your invitation is invalid. Please request a new invitation from your league or assigner',
      },
    },
    reset_password: {
      messages: {
        invalid_reset_link: 'Your password reset link is no longer valid.',
        check_email: 'Check your email inbox',
        check_email_error: "If you don't receive an email shortly, you may need to try a different email address or contact your league for assistance.",
      },
    },
    goalie_shots_against: {
      name: 'Goalie Shots Against',
      labels: {
        goalie: 'Goalie',
        shots: 'Shots',
        edit_shots: 'Edit Shots',
        periods: {
          1: '1st Period',
          2: '2nd Period',
          3: '3rd Period',
          OT: 'Overtime',
        }
      },
      messages: {
        enter_shots: 'Enter the shots against each goalie that has played this game. All games played must be entered for the SV% statistic to show to avoid incorrectly skewing stats.',
        blank: 'Periods may be left blank if the goalie did not play in that period.',
        error_loading_goalies: 'Something went wrong while loading goalies.',
      },
      notifications: {
        updated: 'Shots updated',
      }
    },
    throws_innings: {
      name: 'Throws and innings pitched',
      labels: {
        player: 'Player',
        throws: 'Throws',
        innings: 'Innings Pitched',
        edit: 'Edit throws and innings pitched',
        throws_today: 'TPD',
        throws_weekly: 'TP7D',
        innings_today: 'IPD',
        innings_weekly: 'IP7D',
        innings_remaining: '⚾ %{count} inning(s) remaining',
        cannot_throw: '🚫 Cannot throw this game',
        resting: '💤 Resting',
        consecutive_days: 'Over 2 consecutive days',
        over_innings_limit: 'Over innings limit by'
      },
      messages: {
        error_saving: 'Something went wrong while saving throws and innings pitched.',
      },
      notifications: {
        updated: 'Throws and innings pitched updated',
      }
    },
    office_selector: {
      labels: {
        title: 'Select Organization',
      },
      actions: {
        open: 'Select Organization',
      },
    },
    exporter: {
      actions: {
        details_csv: 'Details as CSV',
        details_xlsx: 'Details as Excel',
        summary_csv: 'Summary as CSV',
        summary_xlsx: 'Summary as Excel',
        export_csv: 'Export as CSV',
        export_xlsx: 'Export as Excel'
      },
      transactions_exporter: {
        labels: {
          transactions: 'Transactions',
        },
        reports: {
          details: {
            title: 'Transaction Details',
            columns: {
              pay_period: 'Pay Period',
              game_number: 'Game Number',
              game_time: 'Game Time',
              surface: 'Surface',
              schedule_name: 'Schedule Name',
              schedule_office_name: 'League',
              first_name: 'First Name',
              last_name: 'Last Name',
              member_id: 'Member ID',
              position: 'Position',
              assigner: 'Assigner',
              transaction_date: 'Transaction Date',
              type: 'Type',
              subtype: 'Subtype',
              amount: 'Amount'
            }
          },
          summary: {
            title: 'Transaction Summary',
            columns: {
              first_name: 'First Name',
              last_name: 'Last Name',
              member_id: 'Member ID',
              travel_fees: 'Travel Fees',
              officiating_fees: 'Officiating Fees',
              other_fees: 'Other Fees',
              total_fees: 'Total Fees'
            }
          }
        },
      },
      penalties_exporter: {
        labels: {
          penalties: 'Penalties',
        },
        reports: {
          summary: {
            title: 'Penalties Summary',
            columns: {
              first_name: 'First Name',
              last_name: 'Last Name',
              member_id: 'Member ID',
              team_name: 'Team Name',
              team_id: 'Team ID',
              schedule_name: 'Schedule Name',
              game_number: 'Game Number',
              game_date: 'Game Date',
              infraction_code: 'Code',
              infraction: 'Infraction',
              game_time: 'Game Time',
              start_time: 'Start Time',
              end_time: 'End Time',
              served_by_name: 'Served By',
              served_by_id: 'Served By (Member ID)',
            }
          }
        },
      }
    },
    feature_flags: {
      title: 'Feature Flags',
      alerts: {
        branch_level: 'Spordle Play also has Feature Flags at the branch level. These flags can be found in the Branch Settings of a branch organization.',
      }
    },
    roles_and_permissions: {
      title: 'Roles and Permissions',
      labels: {
        scopes: 'Scopes',
        automatic: 'Automatic',
      },
      alerts: {
        account_permissions: "Account permissions are made up of a principal (participants), target (organization, schedule, or team) and a role. All three parts are necessary to be considered when evaluating access. For example, if you have access to manage schedules, you cannot manage all schedules, only the schedules within your permission’s target.",
      }
    },
    scheduleStats: {
      titles: {
        ParticipantScheduleStats: 'Player Stats',
        TeamScheduleStats: 'Team Standings',
      },
      labels: {
        type: 'Type',
        ranking: 'Rank',
        ranking_og: 'Original Rank',
        poolRanking: 'Rank',
        poolRanking_og: 'Original Rank',
        manualRanking: 'Manual Rank',
        manualPoolRanking: 'Manual Rank',
        standingsType: 'Standings Type',
        no_pools: 'Not in Pool',
      },
      values: {
        type: {
          Skater: 'Skaters',
          Goalie: 'Goalies',
        },
        standingsType: {
          Overall: 'Overall',
          Pools: 'Pools',
        }
      },
      alerts: {
        manual_standings: 'Using manual standings will replace the regular standings displayed on public pages.'
      },
      actions: {
        edit_manual_standings: 'Edit Manual Standings',
      },
      notifications: {
        manual_standings_updated: 'Manual standings updated',
        manual_pool_standings_updated: 'Manual pool standings updated',
        manual_standings_error: 'Unable to update manual standings',
        manual_pool_standings_error: 'Unable to update manual pool standings',
      }
    }
  },
  common: {
    regions: REGIONS,
  },
}

export default messages;
