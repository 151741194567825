import React, { useEffect, useState } from 'react';
import { NumberField, useListContext, useRecordContext, useTranslate } from 'react-admin'

import { FF_SCHEDULE_TEAMS_ORDER, FF_SCHEDULE_TEAMS_DISABLE_ADD_SYNCED } from "@hisports/common/featureFlags";

import { SyncAlertCustomMessage, SyncedIndicator, useRecordSynced } from '../../../common/SyncAlert';
import ListCard from '../../../common/cards/ListCard';
import { useFlag, useScopes } from '../../../http';
import { isAuthorized } from '../../../common/Authorize';

import { TeamField } from '../../teams/TeamField';
import { GroupField } from '../../groups/GroupField';
import { GroupInput } from '../../groups/GroupInput';
import { PoolField } from '../../pools/PoolField';
import { PoolInput } from '../../pools/PoolInput';

import { ScheduleTeamGrid as ScheduleTeamDatagrid } from '../../scheduleteams/ScheduleTeamModal';
import { AddTeamsButton } from '../../scheduleteams/AddTeamsModal';
import { TeamImportButton } from '../../scheduleteams/TeamImportModal';
import { EditOrderButton } from '../../scheduleteams/EditOrderModal';
import { useGroupingType } from '../../scheduleSettings';
import { TeamIcon } from '../../icons';

const ScheduleTeamField = ({ ...props }) => {
  const scheduleTeam = useRecordContext();
  return <>
    <TeamField {...props} />
    <SyncedIndicator record={scheduleTeam} />
  </>
}

const ScheduleTeamGrid = ({ scheduleId, setTotalTeams }) => {
  const { total } = useListContext()
  const isEnabled = useFlag();
  const { isPools: showPools, isGroups: showGroups } = useGroupingType(scheduleId);

  useEffect(() => {
    setTotalTeams(total)
  }, [total, setTotalTeams])

  return <ScheduleTeamDatagrid size="medium">
    {isEnabled(FF_SCHEDULE_TEAMS_ORDER) && <NumberField sortable={false} source="order" />}
    <ScheduleTeamField source="teamId" sortable={false} includeId="inline" includeCategory="inline" />
    {showGroups && <GroupField source="groupId" sortable={false} />}
    {showPools && <PoolField source="poolId" sortable={false} />}
  </ScheduleTeamDatagrid>
}

const getFilters = (schedule) => {
  const scheduleId = schedule?.id;
  const scheduleType = schedule?.type;
  const officeId = schedule?.officeId;
  const categoryIds = schedule && [schedule.categoryId]

  return [
    <GroupInput
      key="groupId"
      source="groupId"
      variant="outlined"
      scheduleId={scheduleId}
      scheduleType={scheduleType}
      officeId={officeId}
      categoryIds={categoryIds}
      allowEmpty
      alwaysOn
    />,
    <PoolInput
      key="poolId"
      source="poolId"
      variant="outlined"
      scheduleId={scheduleId}
      allowEmpty
      alwaysOn
    />
  ].filter(Boolean)
}

const TeamsActions = props => {
  const schedule = useRecordContext(props);
  const { data } = useListContext();
  const isEnabled = useFlag();
  const scopes = useScopes();

  const { id: scheduleId, categoryId, officeId } = schedule;

  const hasScheduleTeams = !!Object.keys(data).length;
  const isSynced = schedule.externalId && isEnabled(FF_SCHEDULE_TEAMS_DISABLE_ADD_SYNCED) && !scopes.includes('internal');

  const canAddTeams = isAuthorized(schedule, 'scheduleteams', 'create');
  const canOrderTeams = isEnabled(FF_SCHEDULE_TEAMS_ORDER) && hasScheduleTeams;

  if (!canAddTeams) return null;

  return <>
    {!isSynced && <AddTeamsButton size="small" initialValues={{ scheduleId, categoryId, scheduleCategoryId: categoryId }} />}
    {!isSynced && <TeamImportButton initialValues={{ officeId, categoryId }} size="small" />}
    {canOrderTeams && <EditOrderButton size="small" initialValues={{ scheduleId }} />}
  </>
}

export default props => {
  const schedule = useRecordContext(props);
  const translate = useTranslate();
  const isEnabled = useFlag();
  const [ totalTeams, setTotalTeams ] = useState(null);
  const [ isSynced, toggle ] = useRecordSynced(props);

  const isOrdered = isEnabled(FF_SCHEDULE_TEAMS_ORDER);

  return <>
    <SyncAlertCustomMessage isSynced={isSynced} toggle={toggle} message="resources.scheduleteams.messages.synced_by_id" />
    <ListCard
      title={`${translate("resources.teams.labels.card.title")}${totalTeams ? ` (${totalTeams})` : ''}`}
      icon={<TeamIcon />}
      reference="scheduleteams"
      target="scheduleId"
      sort={{
        field: ['pool.name', 'group.name', isOrdered ? 'order' : 'team.name'],
        order: ['ASC', 'ASC', 'ASC'],
      }}
      filters={getFilters(schedule)}
      actions={<TeamsActions />}
    >
      <ScheduleTeamGrid scheduleId={schedule?.id} setTotalTeams={setTotalTeams} />
    </ListCard>
  </>
}
