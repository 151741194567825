import React, { useState } from 'react'
import { RecordContextProvider, useNotify, useRecordContext, useTranslate } from 'react-admin'
import { Button, makeStyles, TableCell } from '@material-ui/core';
import { Form, useForm, useFormState } from 'react-final-form';
import { isEmpty } from '@hisports/parsers';
import { FF_MANUAL_RANKINGS } from '@hisports/common/featureFlags';

import InfoCard from '../../../common/cards/InfoCard';
import { apiClient, useFlag } from '../../../http';
import { TeamField } from '../../teams/TeamField';

import { ScheduleStatsTable } from '../ScheduleStatsTable';
import { GroupSelect, StandingsTypeSelect } from '../ScheduleStatsTableFilters';
import { useGroupingType } from '../../scheduleSettings';

const useStyles = makeStyles((theme) => ({
  clearButton: {
    color: theme.palette.error.main,
  },
}));

const validate = (values) => {
  const errors = {};
  const teamPoolCount = [];

  Object.keys(values).forEach((key) => {
    const poolId = key.split('_')[0];
    const teamId = key.split('_')[1];

    if (!teamPoolCount[poolId]) {
      teamPoolCount[poolId] = [];
    }

    teamPoolCount[poolId].push(teamId);
  });

  Object.keys(values).forEach((key) => {
    const poolId = key.split('_')[0];

    if (values[key] != null && values[key] <= 0) {
      errors[key] = 'ra.validation.invalid';
    }
    if (values[key] != null && values[key] > teamPoolCount[poolId]?.length) {
      errors[key] = 'ra.validation.too_high';
    }
  });

  return errors;
}

const sanitizeRankings = (values) => Object.keys(values).reduce((acc, key) => {
  const newKey = key.includes('_') ? key.split('_')[1] : key;
  acc[newKey] = values[key];
  return acc;
}, {});

const ManualRankingsForm = ({ children, standingsType, onClose, onSave }) => {
  const schedule = useRecordContext();
  const notify = useNotify();

  const onSubmit = async (values) => {
    if (isEmpty(values)) return;

    const newRankings = sanitizeRankings(values);
    const isPoolRanking = standingsType !== 'Overall';

    try {
      await apiClient(`schedules/${schedule.id}/updateManualRankings`, {
        method: 'POST',
        data: {
          manualRankings: newRankings,
          isPoolRanking,
        },
      });

      const notificationMessage = `components.scheduleStats.notifications.${isPoolRanking ? 'manual_pool_standings_updated' : 'manual_standings_updated'}`;
      notify(notificationMessage, 'success');
      onSave();
    } catch (error) {
      const errorMessage = error.response?.data?.error?.message || `components.scheduleStats.notifications.${isPoolRanking ? 'manual_pool_standings_error' : 'manual_standings_error'}`;
      notify(errorMessage, 'error');
    } finally {
      onClose();
    }
  };

  return <Form onSubmit={onSubmit} validate={validate}>
    {props => (
      <form onSubmit={props.handleSubmit}>
        <RecordContextProvider value={{}}>
          {children}
        </RecordContextProvider>
      </form>
    )}
  </Form>
}

const ManualRankingsButton = ({ open, setOpen }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { change, reset } = useForm();
  const { values } = useFormState();
  const isEnabled = useFlag();

  if (!isEnabled(FF_MANUAL_RANKINGS)) return null;

  const handleClearAll = () => {
    Object.keys(values).forEach((key) => {
      change(key, null);
    });
  };

  const handleEditClick = () => {
    reset();
    setOpen(true);
  }

  return <>
    {!open && <Button color="primary" onClick={handleEditClick}>
      {translate('components.scheduleStats.actions.edit_manual_standings')}
    </Button>}
    {open && <>
      <Button onClick={() => setOpen(false)}>
        {translate('ra.action.cancel')}
      </Button>
      <Button onClick={handleClearAll} className={classes.clearButton}>
        {translate('ra.action.clear')}
      </Button>
    </>
    }
    {/* Submit button needs to be rendered when form is rendered initially */}
    <Button type="submit" color="primary" style={{ display: !open ? 'none' : undefined }}>
      {translate('ra.action.save')}
    </Button>
  </>
}

const renderColumns = (row, schedule) =>
  <TableCell>
    <RecordContextProvider value={{ teamId: row.teamId, ...schedule }}>
      <TeamField source="teamId" />
    </RecordContextProvider>
  </TableCell>

const renderHeaders = ({ translate }) =>
  <TableCell style={{ width: 325 }}>{translate('resources.teams.name', 1)}</TableCell>

export default function TeamScheduleStandingsCard() {
  const schedule = useRecordContext();
  const [open, setOpen] = useState(false);
  const [standingsType, setStandingsType] = useState('Overall');
  const [triggerRefetch, setTriggerRefetch] = useState(0);
  const { isPools, isGroups } = useGroupingType(schedule?.id);

  const filters = [
    isGroups && <GroupSelect key="group" />,
    isPools && <StandingsTypeSelect key="standings-type" handleStandingsType={setStandingsType} />,
  ].filter(Boolean);

  const onClose = () => setOpen(false);
  const onSave = () => {
    setTriggerRefetch(prev => prev + 1);
    onClose();
  }

  return <ManualRankingsForm standingsType={standingsType} onClose={onClose} onSave={onSave}>
    <InfoCard
      title="components.scheduleStats.titles.TeamScheduleStats"
      headerAction={<ManualRankingsButton open={open} setOpen={setOpen} />}
    >
      <ScheduleStatsTable
        resource="TeamScheduleStats"
        filters={filters}
        filter={{ standingsType }}
        sort={{ field: 'ranking', order: 'asc' }}
        renderColumns={renderColumns}
        renderHeaders={renderHeaders}
        schedule={schedule}
        isFormOpen={open}
        triggerRefetch={triggerRefetch}
        disablePagination
      />
    </InfoCard>
  </ManualRankingsForm>
}
