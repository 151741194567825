import french from 'ra-language-french';

import { appName } from '../../flags';

const TYPES = {
  Exhibition: 'Hors Concours',
  Placement: 'Évaluation',
  League: 'Ligue',
  Playoffs: 'Séries Éliminatoires',
  Tournament: 'Tournoi',
  Championship: 'Championnat',
  Cup: 'Coupe',
}

const STATUSES = {
  Active: 'Actif',
  Rescheduled: 'Reprogrammé',
  'Rink Changed': 'Patinoire changée',
  Cancelled: 'Annulé',
  Postponed: 'Reporté',
  Conflict: 'Conflit',
  Forfeited: 'Forfait',
  DoubleForfeit: 'Double forfait',
  Completed: 'Complété',
  Certified: 'Certifié',
}

const ACTIVITY_TYPES = {
  Practice: 'Pratique',
  Training: 'Entrainement',
  Meeting: 'Réunion',
  Meal: 'Repas',
  Accomodation: 'Hébergement',
}

const REGIONS = {
  Canada: 'Canada',
  'United States': 'États Unis',
  Europe: 'Europe',
}

const messages = {
  ra: {
    ...french.ra,
    action: {
      ...french.ra.action,
      ok: 'Ok',
      yes: 'Oui',
      no: 'Non',
      back: 'Retour',
      ignore: 'Ignorer',
      change: 'Modifier',
      register: "S'inscrire",
      continue: 'Continuer',
      subscribe: "S'abonner",
      learn: 'En apprendre plus',
      analyze: 'Analyser',
      accept: 'Accepter',
      summary: 'Sommaire',
      switch: 'Changer',
      swap: 'Échanger',
      delegate: 'Déléguer',
      undelegate: 'supprimer la délégation',
      generate: 'Générer',
      publish: 'Publier',
      finish: 'Finir',
      select: 'Sélectionner',
      discard: 'Annuler',
      decline: 'Refuser',
      cancel: 'Annuler',
      next: 'Suivant',
      skip: 'Sauter',
      new: 'Nouveau',
      replace: 'Remplacer',
      request: 'Demander',
      keep: 'Garder',
      send: 'Envoyer',
      sign: 'Signer',
      directions: 'Directions',
      approve: 'Approuver',
      assign: 'Assigner',
      assign_anyway: 'Assigner quand même',
      reassign: 'Réassigner',
      accept_other: "Accepter l'Autre",
      click_here: 'Cliquez ici',
      click_to_verify: 'Cliquez pour vérifier',
      send_verification: 'Envoyer la vérification',
      copy_to_clipboard: 'Copier dans le presse-papiers',
      confirm_removal: 'Confirmer la suppression',
      tap_for_more: 'Appuyez pour plus',
      repeat: 'Répéter',
      invite: 'Inviter',
      send_invite: 'Envoyer une invitation',
      retry: 'Réessayer',
      open_full_page: 'Ouvrir en pleine page',
      clear: 'Effacer',
      clear_filters: 'Effacer les filtres',
      update: 'Mise à jour',
      import: 'Importer',
      save: 'Sauvegarder',
      apply: 'Appliquer',
      open: 'Ouvrir',
      upload: 'Transférer',
      select_columns: 'Sélectionner les colonnes',
      review: 'Réviser',
      view: 'Afficher',
      share: 'Partager',
      unshare: 'Annuler le partage',
      fix: 'Réparer',
      search_in: 'Rechercher dans',
      open_in_id: 'Ouvrir dans Spordle ID',
      certify: 'Certifier',
      pend: 'En attente',
      undo: 'Annuler',
      start: 'Démarrer',
      respond: 'Répondre',
      reset: 'Réinitialiser',
      load_more: 'Charger plus',
      refresh: 'Cliquez ici pour rafraîchir',
      show: 'Afficher',
      hide: 'Cacher',
      manage: 'Gérer',
      recalculate: 'Recalculer',
    },
    auth: {
      ...french.ra.auth,
      login: 'Se connecter',
      login_error: 'Impossible de se connecter pour le moment',
      login_error_retry: 'Impossible de se connecter pour le moment. Réessayez dans quelques minutes.',
      unauthorized: 'Non autorisé',
      unauthorized_long: "Votre compte (%{user}) ne dispose d'aucune autorisation pour le moment.",
      recovery_email: 'Envoyer un courriel de récupération',
      your_username: "Ceci sera votre nom d'utilisateur",
      youre_logged_in_as: 'Vous êtes connecté en tant que %{user}',
    },
    page: {
      ...french.ra.page,
      loading: `${french.ra.page.loading}...`,
      error_try_again: `${french.ra.page.error}, réessayez plus tard`,
      error_try_again_refresh: `${french.ra.page.error}, essayez d'actualiser ou réessayez plus tard`,
      create: 'Créer %{name}',
      edit: `Éditer %{name}`,
      editing: 'Éditer %{name}',
      show: `%{name}`,
      not_available: 'N/A',
    },
    message: {
      ...french.ra.message,
      created: 'Créer',
      updated: 'A été mis à jour',
      draft: 'Brouillon',
      not_published: 'Non publié',
      not_applicable: "N'est pas applicable",
      delete_title: "Confirmer la suppression",
      delete_content: 'Voulez-vous vraiment supprimer le/la %{name}?',
      discard_changes: 'Voulez-vous vraiment annuler vos modifications?',
      error: `Cette erreur a été automatiquement envoyée à ${appName}. Vous devrez peut-être revenir en arrière ou réessayer plus tard.`,
      version_error: `Il semble que vous utilisiez une ancienne version de ${appName}. Vous devrez vider votre cache puis réessayer.`,
      version_mismatch: `Il semble que vous utilisez une ancienne version de ${appName}. Veuillez rafraîchir votre navigateur.`,
      report_details: 'Rapport détaillé',
      digits: "C'est environ %{number} chiffres",
      redirecting: 'Redirection',
      optional: 'Optionnel',
      no_options: 'Aucune option',
      none: 'Aucun(e)',
      unverified: 'Non vérifié',
      verified: 'Vérifié',
      unverified_email: 'Adresse courriel non vérifiée',
      verified_email: 'Adresse courriel vérifiée',
      verification_email: 'Un courriel de vérification a été envoyé',
      available: 'Disponible',
      unavailable: 'Indisponible',
      unknown: 'Inconnue',
      show_more: 'Afficher %{number} de plus...',
      no_results: 'Aucun résultat',
      no_results_filtered: 'Aucun résultat (%{smart_count} filtré) |||| Aucun résultat (%{smart_count} filtrés)',
      no_rows: 'Aucune rangée',
      filtered: '%{smart_count} filtré |||| %{smart_count} filtrés',
      filter_by_attributes: 'Filtrer par attributs',
      filter_by_list: 'Filtrer par liste',
      filter_by_name: 'Filtrer par nom',
      filter_by_group: 'Filtrer par groupe',
      filter_by_pool: 'Filtrer par pool',
      filter_by_category: 'Filtrer par catégorie',
      no_group: 'Aucun groupe',
      total: 'Total',
      all: 'Tout',
      settings: 'Paramètres',
      default: 'Défaut',
      select_columns: "Sélectionnez vos colonnes ci-dessous pour qu'elles correspondent aux données de votre fichier.",
      no_valid_rows: 'Ce fichier ne contient pas de lignes valides à importer.',
      managed_by_id: 'Certains champs sont gérés par Spordle ID',
      synced_with_id: 'Synchronisé de Spordle ID',
      unsupported: 'Fonctionnalité indisponible',
      shared_slot: 'Partagée'
    },
    input: {
      ...french.ra.input,
      image: {
        upload_several: 'Déposez les images à télécharger ou cliquez pour en sélectionner une',
        upload_single: "Déposez l'image à télécharger ou cliquez pour la sélectionner.",
      },
      field: {
        name: 'Nom',
        email: 'Adresse courriel',
        password: 'Mot de passe',
      },
      file: {
        ...french.ra.input.file,
        upload_several: 'Déposez les fichiers à télécharger ou cliquer pour en sélectionner un',
        upload_single: 'Déposez le fichier à télécharger ou cliquez pour le sélectionner',
        upload: 'Télécharger un fichier',
        uploading: 'Téléchargement...',
        upload_csv: 'Télécharger un fichier CSV',
        download_template: 'Télécharger le modèle',
        only_csv: 'Seuls les fichiers CSV sont pris en charge pour le moment.',
        error_parsing: "Une erreur s'est produite lors de l'analyse de votre fichier.",
        invalid_value: 'Certaines lignes ont des valeurs non valides',
        enter_new_title: 'Entrez un nouveau titre',
        confirm_remove_attachment: 'Voulez-vous vraiment supprimer cette pièce jointe?',
        import: 'Importer',
        importing: 'Importation...',
        columns: 'Colonnes',
        error_too_large: 'Le fichier dépasse la limite de taille',
        drop: {
          logo: 'Déposez un nouveau logo ici',
          image: 'Déposez une nouvelle image ici',
        }
      },
      autocomplete: {
        ...french.ra.input.autocomplete,
        type_to_search: 'Écrire pour rechercher...',
        none: 'Aucun'
      },
      password: {
        ...french.ra.input.password,
        forgot: 'Mot de passe oublié?',
        change: 'Changer le mot de passe',
        change_success: 'Mot de passe mis à jour avec succès',
        old: 'Mot de passe actuel',
        new: 'Nouveau mot de passe',
        confirm: 'Confirmez le mot de passe',
        invalid: 'Mot de passe actuel incorrect',
        too_long: 'Le nouveau mot de passe est trop long',
        toggle_visibility: 'Afficher le mot de passe'
      },
      adornments: {
        seconds: 'secs',
        minutes: 'mins',
        kilometers: 'km'
      },
      i18n: {
        label: 'Traduction',
        values: {
          en: 'EN',
          fr: 'FR',
        }
      }
    },
    notification: {
      ...french.ra.notification,
      updated: 'Mise à jour |||| %{smart_count} mise à jour',
      created: 'Créer',
      deleted: 'Supprimé |||| %{smart_count} supprimé',
      bad_item: 'Article incorrect',
      item_doesnt_exist: "L'article n'existe pas",
      cancelled: 'Annulé',
      logged_out: 'Votre authentification a expiré, veuillez vous reconnecter.',
    },
    validation: {
      ...french.ra.validation,
      email: 'Doit être un courriel',
      invalid: 'Non valide',
      invalid_number: 'Nombre non valide',
      invalid_number_format: 'Format de nombre non valide',
      invalid_email_format: 'Format de courriel non valide',
      different: 'Vous ne pouvez pas utiliser le même mot de passe',
      match: 'Cela ne correspond pas au nouveau mot de passe que vous avez entré',
      incorrect_email_password: 'Courriel ou mot de passe incorrect',
      values_not_unique: 'Certaines valeurs ne sont pas uniques',
      spaces_not_allowed: 'Les espaces ne sont pas autorisées',
      more_characters: 'Manque %{smart_count} caractère |||| Manque %{smart_count} caractères',
      positive: 'Doit être positif',
      positive_leave_empty: 'Doit être positif. Laissez vide pour toujours.',
      negative: 'Doit être negatif',
      whole: 'Doit être entier',
      longer_than: 'Ne peut pas contenir plus que %{smart_count} caractères (%{current_length})',
      greater_than_zero: 'Doit être supérieur à 0',
      greater_than_or_equal_zero: 'Doit être supérieur ou égal à 0',
      greater_than_zero_leave_empty: 'Doit être supérieur à 0. Laissez vide pour toujours.',
      greater_than_zero_leave_empty_limit: 'Doit être supérieur à 0. Laissez vide pour aucune de limite.',
      title_too_long: 'Le titre doit comporter moins de 80 caractères',
      min: 'Min',
      max: 'Max',
      absence: 'Doit être retiré',
      length: 'Longueur invalide',
      numericality: 'Doit être un nombre',
      inclusion: 'Selection invalide',
      exclusion: 'Selection invalide',
      format: 'Format invalide',
      uniqueness: 'Doit être unique',
      date: "La date n'est pas valide",
      already_exists: 'Existe déjà dans le système',
      duplicate: 'Doublon',
      end_after_start: 'La date de fin doit être après la date de début',
      end_before_start: "l'heure de fin doit être après l'heure de début",
      unauthorized: 'Non autorisé',
      unable_to_delete: 'Impossible de supprimer',
      claim_maximum_amount: 'Ne doit pas dépasser le montant maximum: %{amount}',
      too_high: 'Trop élevé',
    },
    date: {
      name: 'Date',
      minute: 'Minute |||| Minutes',
      hour: 'Heure |||| Heures',
      day: 'Jour |||| Jours',
      week: 'Semaine |||| Semaines',
      month: 'Mois |||| Mois',
      range: '%{date1} à %{date2}',
      time: 'Heure',
      phrase: {
        minute: '%{smart_count} minute |||| %{smart_count} minutes',
        hour: 'une heure |||| %{smart_count} heures',
        week: '%{smart_count} semaine |||| %{smart_count} semaines',
      },
      lexical: {
        today: "Aujourd'hui",
        all_day: 'Toute la journée',
        until_time: "jusqu'à %{time}",
        after_time: 'après %{time}',
        before: 'Avant',
        between: 'Entre',
        after: 'Après',
      },
      days: {
        short: {
          All: 'Tous',
          Sun: 'Dim',
          Mon: 'Lun',
          Tue: 'Mar',
          Wed: 'Mer',
          Thu: 'Jeu',
          Fri: 'Ven',
          Sat: 'Sam',
        },
        long: {
          All: 'Tous les jours',
          Sun: 'Dimanche',
          Mon: 'Lundi',
          Tue: 'Mardi',
          Wed: 'Mercredi',
          Thu: 'Jeudi',
          Fri: 'Vendredi',
          Sat: 'Samedi',
        }
      },
    },
    function: {
      and: 'et',
      for: 'pour',
      is: 'est',
      was: 'était',
      a: 'un',
      youre: 'Tu es',
      by: 'par',
      or: 'ou',
    }
  },
  resources: {
    categories: {
      name: 'Catégorie |||| Catégorie',
      fields: {
        name: 'Nom',
        divisionId: 'Division',
        gender: 'Identité de genre',
        classId: 'Class',
        officeId: 'Organisation',
      },
      values: {
        gender: {
          Male: 'Masculin',
          Female: 'Féminin',
          Integrated: 'Mixte',
        },
      },
      actions: {
        add: 'Ajouter une catégorie',
        edit: 'Modifier la catégorie',
        scheduleCategory: "Utiliser la catégorie de l'horaire",
      },
      labels: {
        card: {
          title: 'Categories',
        },
        edit: 'Ajouter une catégorie',
        add: 'Nouvelle catégorie',
      },
    },
    divisions: {
      name: 'Division |||| Divisions',
      fields: {
        name: 'Nom',
        shortName: 'Nom court',
        parentId: 'Division parente',
        officeId: 'Organisation',
      },
      actions: {
        add: 'Ajouter une division',
        edit: 'Modifier la division',
      },
      labels: {
        card: {
          title: 'Divisions',
        },
        edit: 'Ajouter une division',
        add: 'Nouvelle division',
      },
    },
    classes: {
      name: 'Class |||| Classes',
      fields: {
        name: 'Nom',
        shortName: 'Nom court',
        officeId: 'Organisation',
      },
      actions: {
        add: 'Ajouter une classe',
        edit: 'Modifier la classe',
      },
      labels: {
        card: {
          title: 'Classes',
        },
        edit: 'Ajouter une classe',
        add: 'Nouvelle classe',
      },
    },
    surfaces: {
      name: 'Surface |||| Surfaces',
      fields: {
        name: 'Nom',
        city: 'Ville',
        country: 'Pays',
        address: 'Adresse',
        alias: 'Alias',
        timezone: 'Fuseau Horaire',
        sports: 'Sports',
        venueId: 'Lieu',
        fraction: 'Nom de la séparation',
        type: 'Type',
        size: 'Taille',
        parentId: 'Parent',
        split: 'Séparation'
      },
      values: {
        sports: {
          Hockey: 'Hockey',
          Soccer: 'Soccer',
          Baseball: 'Baseball'
        },
        type: {
          Ice: 'Glace',
          Grass: 'Gazon',
          Synthetic: 'Synthétique',
          Wood: 'Bois',
          Gym: 'Gym',
          Clay: 'Terre Battue',
          Dirt: 'Poussière de Brique',
          'Grass/Clay': 'Gazon/Terre Battue',
          'Grass/Dirt': 'Gazon/Poussière de Brique',
        },
        size: {
          all_sizes: 'Voir toutes les tailles',
          XL: 'Extra Large',
          L: 'Large',
          M: 'Moyenne',
          S: 'Petite',
          XS: 'Très Petite'
        },
      },
      actions: {
        add: 'Ajouter une surface',
        edit: "Modifier la surface",
      },
      labels: {
        card: {
          title: 'Surfaces',
          splitTitle: 'Séparations de surface',
          splitSubtitle: 'Une surface peut être séparée pour permettre plusieurs événements simultanément',
        },
        alerts: {
          message: "Cette surface est une séparation de"
        },
        tabs: {
          summary: 'Sommaire',
          games: 'Matchs',
          time_slots: 'Plages Horaires',
        },
        edit: "Mettre à jour la surface",
        add: 'Nouvelle surface',
        street_address: 'Adresse de la rue',
        name: "Nom de la surface",
        availability: {
          card: {
            title: 'Disponibilité',
            subtitle: "Sélectionnez les dates et les heures disponibles, ou bloquez les heures non disponibles.",
          },
          title: "Disponibilités de l'organisation",
          note: 'Note de disponibilité',
          updated: 'Disponibilité mise à jour!',
        },
      },
      messages: {
        surfaces: 'surfaces',
        availability: {
          available: 'Cette surface %{input} disponible',
        }
      },
      helpers: {
        alias: 'Optionnel - utilisé pour la recherche',
        fraction: 'Optionnel - par défaut une surface entière',
        availability: {
          note: "Cette note sera visible lorsqu'une partie est déjà prévue dans cette plage horaire.",
        }
      }
    },
    venues: {
      name: 'Lieu |||| Lieux',
      fields: {
        name: 'Nom',
        city: 'Ville',
        region: "Région",
        country: 'Pays',
        address: 'Adresse',
        alias: 'Alias',
        timezone: 'Fuseau Horaire',
      },
      actions: {
        add: 'Ajouter un lieu',
        edit: "Modifier le lieu",
      },
      labels: {
        name: 'Nom du lieu',
        street_address: 'Adresse de la rue',
        my: 'Mes lieux',
      },
      messages: {
        add_venues: 'Ajouter des lieux',
        search_venue: "Recherche par nom de lieu ou par ville",
        enter_name_city: 'Entrez un nom ou une ville pour lancer la recherche',
      },
      alerts: {
        loading_venues: 'Chargement des lieux...',
      }
    },
    activities: {
      name: 'Activité |||| Activités',
      fields: {
        name: 'Nom',
        teamId: 'Équipe',
        arenaId: 'Surface',
        officeId: 'Organisation',
        location: 'localisation',
        type: 'Type',
        status: 'Statut',
        recurrence: 'Récurrence',
        startTime: 'Heure de début',
        endTime: 'Heure de fin',
        pastEvents: 'Activités passées',
        comments: 'Commentaires',
      },
      filters: {
        startTime: 'Date prévue',
        endTime: 'Date de fin',
        pastEvents: 'Afficher les activités passées',
      },
      actions: {
        add: 'Ajouter une activité',
      },
      labels: {
        card: {
          name: 'Activité',
        },
        add: 'Nouvelle activité',
        edit: "Mettre à jour l'activité",
        frequency: 'Fréquence',
        interval: 'Intervalle',
        weekdays: 'Jours de la semaine',
        end_date: 'Date de fin',
      },
      messages: {
        schedule_no_occurences: "Les paramètres de récurrence spécifiés n'entraînent aucune activité",
        event_repeat: 'Ces paramètres de récurrence entraîneront %{smart_count} activité |||| Ces paramètres de récurrence entraîneront %{smart_count} activités',
        additional_instances: '... %{smart_count} activité additionnelle ... |||| ... %{smart_count} activités additionnelles ...',
      },
      helpers: {
        frequency: 'Toutes les autres instances seront supprimées',
      },
      values: {
        status: STATUSES,
        type: ACTIVITY_TYPES,
        frequency: {
          Once: 'Ne se répète pas',
          Daily: 'Quotidienne',
          Weekly: 'Hebdomadaire',
          Monthly: 'Mensuelle',
        }
      },
      alerts: {
        activity_explanation: 'Les activités sont uniquement des événements hors-surface, à ne pas confondre avec des matchs ou des pratiques.'
      }
    },
    practices: {
      name: 'Pratique |||| Pratiques',
      fields: {
        name: 'Nom',
        teamIds: 'Équipes',
        arenaId: 'Surface',
        officeId: 'Organisation',
        location: 'localisation',
        type: 'Type',
        status: 'Statut',
        recurrence: 'Récurrence',
        startTime: 'Heure de début',
        endTime: 'Heure de fin',
        pastEvents: 'Pratiques passées',
        comments: 'Commentaires',
      },
      filters: {
        startTime: 'Date prévue',
        endTime: 'Date de fin',
        pastEvents: 'Afficher les pratiques passées',
      },
      actions: {
        add: 'Ajouter la pratique',
        edit: 'Modifier la pratique',
      },
      labels: {
        card: {
          name: 'Pratique',
        },
        add: 'Nouvelle pratique',
        edit: 'Mettre à jour la pratique',
        frequency: 'Fréquence',
        interval: 'Intervalle',
        weekdays: 'Jours de la semaine',
        end_date: 'Date de fin',
        tbd: 'À déterminer',
        practice_status: 'Statut de pratique',
        set_practice_status: 'Changer le statut des pratiques',
        change_practice_status: 'Changer le statut',
      },
      messages: {
        schedule_no_occurences: "Les paramètres de récurrence spécifiés n'entraînent aucune pratique",
        event_repeat: 'Ces paramètres de récurrence entraîneront %{smart_count} pratique |||| Ces paramètres de récurrence entraîneront %{smart_count} pratiques',
        additional_instances: '... %{smart_count} pratique additionnelle ... |||| ... %{smart_count} pratiques additionnelles ...',
      },
      helpers: {
        frequency: 'Toutes les autres instances seront supprimées',
        conflicts: 'Résoudre les conflits ou le statut passera à Conflit',
      },
      values: {
        status: STATUSES,
        frequency: {
          Once: 'Ne se répète pas',
          Daily: 'Quotidienne',
          Weekly: 'Hebdomadaire',
          Monthly: 'Mensuelle',
        }
      },
      notifications: {
        game_status_changed: 'Statut changé',
        change_game_status: 'Changement de %{smart_count} pratique au statut %{status} |||| Changement de %{smart_count} pratiques au statut %{status}',
        change_game_status_error: "Impossible de changer le statut  |||| Impossible de changer les statuts",
      },
      validations: {
        conflicts: 'Résoudre les conflits ou passer à un statut inactif',
      }
    },
    events: {
      name: 'Événement |||| Événements',
      fields: {
        arenaId: 'Surface',
        status: 'Statut',
        startTime: 'Heure de début',
        endTime: 'Heure de fin',
      },
      labels: {
        card: {
          title: 'Horaire',
        },
        details: 'Détails',
        location: 'Lieu',
        upcoming_schedule: 'Horaire à venir',
        team_events: "Événements d'équipe",
        show_calendar: 'Afficher le calendrier',
      },
    },
    games: {
      name: 'Match |||| Matchs',
      fields: {
        id: 'ID',
        number: 'Numéro de match',
        seasonId: 'Saison',
        scheduleId: 'Horaire',
        scheduleType: 'Type',
        crossScheduleId: 'Horaire croisée',
        categoryId: 'Catégorie',
        homeTeamId: 'Local',
        awayTeamId: 'Visiteur',
        date: 'Date',
        startTime: 'Heure de début',
        endTime: 'Heure de fin',
        arenaId: 'Surface',
        status: 'Statut',
        comments: 'Commentaires',
        isApproved: 'Approuvé',
        officeId: 'Organisation',
        groupId: 'Groupe',
        crossGroupId: 'Groupe croisée',
        pastEvents: 'Parties Passées',
        timezone: 'Fuseau Horaire',
        effectiveSurfaces: 'Surface',
        resetAssigner: "Réinitialiser l'assignateur",
      },
      filters: {
        startTime: 'Date de début',
        endTime: 'Date de fin',
        scheduleOffices: "Organisation d'horaire",
        teamOffices: "Organisation des équipes",
        homeTeamOffices: 'Organisation locale',
        awayTeamOffices: "Organisation visiteuse",
        surfaceOffices: 'Organisation de la surface',
        assignOffices: "Organisation d'assignation",
        isApproved: 'Complété',
        isCertified: 'Certifié',
        pastEvents: 'Afficher les parties passées',
        conflictsOnly: 'Afficher uniquement les conflits',
        bracketGamesOnly: 'Afficher uniquement les matchs de bracket',
      },
      actions: {
        add: 'Ajouter un match',
        edit: 'Modifier le match',
        edit_completed: 'Le match ne peut être modifié, car celui-ci est déjà complété ou certifié',
        internal: 'Actions internes',
        applyAssignRules: "Appliquer les règles d'assignation",
        recalculateStats: 'Recalculer les statistiques',
        recalculateScoresheet: 'Recalculer la feuille de match',
        recalculateTransactions: 'Recalculer les transactions',
      },
      labels: {
        card: {
          title: 'Matchs',
        },
        tabs: {
          summary: 'Sommaire',
          officials: 'Officiels',
          scoresheet: 'Feuille de match',
          bracket: 'Bracket',
          events: 'Événements',
        },
        edit: 'Mettre à jour le match',
        add: 'Nouveau match',
        time: 'Heure du match',
        report: 'Rapport de match',
        tbd: 'À déterminer',
        bracket_game: 'Match de bracket',
        quick_assign_dialog_title: 'Match %{gameNumber} - %{position}',
        game_number: 'Match %{number}',
        game_number_officials: 'Officiels du Match %{number}',
        game_number_lineup: 'Alignement du Match %{number}',
        game_number_certify: 'Certification du Match %{number}',
        editing_game_number: 'Modification du match %{number}',
        team_lineups: "Alignements d'équipe",
        all_games: 'Tous les matchs',
        score: 'Pointage',
        scorekeeping: 'Entrée des résultats',
        forfeiting_team: 'Équipe qui perd par forfait',
        forfeit: 'Match par forfait',
        forfeitWin: 'Victoire par forfait',
        forfeitLoss: 'Défaite par forfait',
        forfeitTie: 'Double Forfait',
        home: 'Local',
        away: 'Visiteur',
        game_number_history: 'Historique du match %{number}',
        notes: 'Note |||| Notes',
        scoresheet_notes: 'Notes de match',
        admin_notes: "Notes d'officiels",
        select_team: 'Sélectionner une équipe',
        certify_game: 'Certifier le match',
        view_scoresheet: 'Afficher la feuille de match',
        active: 'Actif',
        inactive: 'Inactif',
        confirm_changes: 'Confirmer les changements',
        game_status: 'Statut de match',
        set_game_status: 'Changer le statut des matchs',
        change_game_status: 'Changer le statut',
        view_options: "Options d'affichage",
        draftUpdates: {
          create: 'Créer un brouillon',
          request: 'Demander un changement',
          card: {
            title: 'Brouillons de mises à jour',
            subtitle: 'Les matchs suivants sont des matchs brouillons non-publiés. Une fois publiées, les modifications seront appliquées à ce match.'
          },
        },
        cross_scheduling_add: 'Ajouter un horaire croisée',
        cross_scheduling_remove: "Supprimer l'horaire croisée",
        lineup: {
          name: 'Alignement',
          approved: 'Alignement approuvé',
          incomplete: 'Alignement incomplet',
          game: 'Alignement du match',
        },
        assignment: {
          history: 'Histoire',
          confirm: "Confirmer l'assignation",
          decline: "Refuser l'assignation",
          confirm_reassignment: 'Confirmer la réassignation',
          confirm_request: 'Confirmer la demande',
          expired_qualification: 'Qualification expirée',
          flagged_official: 'Officiel signalé',
          discard_changes: 'Annuler les modifications',
          request_game: 'Demander ce match',
          this_game: 'Ce match',
          delegate: "Délégation de l'assignation",
          swap_assignment: "Échanger un assignation",
          swap_assignment_official: "Changer l'assignation de cet officiel",
          search_names: 'Recherche par noms',
          search_attributes: 'Recherche par attributs',
          unassigned: 'Non assigné',
          hidden: 'Cachée',
          adhoc: 'Bénévole',
          update_availability: 'Disponibilité de la mise à jour',
          game_officials: 'Officiels du match',
          assign_officials: 'Assigner des officiels',
          team_officials: "Officiels d'équipe",
          assigners: 'Assignateurs',
          show_attributes: 'Afficher les attributs',
          hide_attributes: 'Cacher les attributs',
          undo_no_show: 'Annuler pas présenté',
          delegate_pay: 'Déléguez également la paie',
          status: {
            ready: 'Prêt',
            confirmed: 'Confirmé',
            pending: 'En attente',
            declined: 'Refusé',
            requested: 'Demandé',
            no_show: 'Pas présenté',
            removed: 'Supprimé',
            expired: 'Expiré',
            ineligible: 'Inéligible',
            conflicts: 'Conflits',
            schedule: 'Horaire',
            travel: 'En déplacement',
            assigned_today: "Assigné aujourd'hui",
            available: 'Disponible',
            unavailable: 'Indisponible',
            account: 'Compte',
            assigned: 'Assigné',
            local: 'Locaux',
          }
        },
        certification: 'Certification',
        general: 'General',
        summary: 'Sommaire',
        flags: {
          'Referee Entered Result': "L'arbitre a inscrit les résultats",
          'Coach Entered Result': "L'entraîneur a inscrit les résultats",
          'Unfinished': "Le match ne s'est pas terminé",
          'Lineup Max Limit': 'Trop de joueurs',
          'Lineup Min Limit': 'Pas assez de joueurs',
          'Extra': 'Membre additionnel |||| Membres additionnels',
          'Serving': 'Membre suspendu purgeant |||| Membres suspendus purgeant',
          'Secondary Team': "Membre d'équipe secondaire |||| Membres d'équipes secondaires",
          'Not Rostered': "Membre manquant sur l'alignement |||| Membres manquants sur l'alignement",
          'Not Serving': 'Membre suspendus qui a joué |||| Membres suspendus qui ont joués',
          'Missing Suspended Member': "Membre suspendus manquant sur l'alignement",
          'Low Severity Penalties': 'Pénalité avec sévérité faible |||| Pénalités avec sévérité faible',
          'High Severity Penalties': 'Pénalité avec sévérité élevé |||| Pénalités avec sévérité élevé',
          'Yellow': 'Carton jaune |||| Cartons jaunes',
          'Indirect Red': 'Carton rouge indirect |||| Cartons rouges indirects',
          'Direct Red': 'Carton rouge direct |||| Cartons rouges directs',
          'Notes': 'Notes de match',
          'Unvalidated Members': 'Membre non validé |||| Membres non validés',
          'Faulty Players': 'Joueur fautif |||| Joueurs fautifs',
          'Transferred': 'Muté',
          'Suspension': 'Suspension |||| Suspensions',
        },
        flagsDetails: {
          'Missing Suspended Member': "Un membre suspendu doit être ajouté sur l'alignement pour purger un match",
          'Not Rostered': "Un membre a joué sans être sur l'alignement initial",
          'Extra': 'Membre additionnel',
          'Transferred': "Ce membre a été muté d'une autre organisation",
          'Secondary Team': "Cette équipe n'est pas l'équipe principale de ce membre",
          'Unvalidated Members': "Ce membre doit être validé dans Spordle ID",
          'Faulty Players': {
            'hasInningsRest': "Ce membre n'avait pas le droit de lancer en raison d'un repos causé par les manches lancées",
            'hasThrowsRest': "Ce membre n'avait pas le droit de lancer en raison d'un repos causé par les balles lancées",
            'consecutiveDays': 'Ce membre a lancé trop de jours consécutifs',
            'dailyInnings': 'Ce membre a lancé trop de manches cette journée',
            'weeklyInnings': 'Ce membre a lancé trop de manches cette semaine',
          }
        }
      },
      messages: {
        forfeit_help: "L'abandon d'un match complètera la feuille de match. Sélectionnez l'équipe qui a perdu le match.",
        show_assignments: 'Afficher les assignations',
        show_flags: 'Afficher les notifications',
        reverse_home_away: "Inverser les équipes",
        show_more_game_info: "Afficher plus d'informations sur le match",
        group_games_by_arena: 'Grouper les matchs par surface',
        group_games_by_round: 'Grouper les matchs par étape',
        group_games_by_date: 'Grouper les matchs par date',
        confirm_delegate: "Les matchs dont les officiels ne sont pas assignés sur certains rôles ou les matchs qui ont été assignés seront ignorés. Si aucune position n'est sélectionnée, l'organisation d'assignation des matchs sera changée.",
        and_more: 'Et %{smart_count} autre... |||| Et %{smart_count} autres...',
        game_status: "Cela mettra à jour l'horaire et remplacera le statut actuel ainsi que les commentaires du match.",
        calendar: {
          subscribe: 'Abonnez-vous au calendrier',
          subscribe_description: 'Vous pouvez vous abonner à votre flux de calendrier personnel dans la plupart des applications de calendrier.',
          subscribe_description_extra: "Cela inclut vos <strong>assignations officielles uniquement</strong>. Les changements de l'horaire peuvent être retardés dans votre application de calendrier.",
        },
        apply_assign_rules: "Mettre à jour les paramètres d'assignation pour ce match en appliquant les règles d'assignation.",
        assignment: {
          accept_game_assignment: "Accepter l'assignation du match",
          decline_game_assignment: "Refuser l'assignation du match",
          are_you_sure_accept_assignment: "Voulez-vous vraiment accepter cette assignation? Vous ne pourrez accepter aucune assignation tant que vous n'aurez pas refusé cette assignation.",
          are_you_sure_accept_assignment_disabled: "Voulez-vous vraiment accepter cette assignation? Vous ne pourrez pas refuser cette assignation et accepter toute autre assignation.",
          accepting_assignment: "L'acceptation de cette assignation entraînera également le refus des assignations suivantes:",
          you_cannot_accept_assignment: "Vous ne pouvez pas accepter cette assignation, car vous avez d'autres assignations en conflit:",
          consecutive_game: 'Matchs consécutifs',
          accept_consecutive_assignments: "L'acceptation de cette assignation entraîne l'acceptation de toutes les assignations consécutives.",
          decline_consecutive_assignments: 'Le refus de cette assignation entraîne le refus de toute autre assignations consécutive.',
          already_assigned: '%{name} est déjà assigné à ce poste.',
          continuing_replacement: 'Si vous continuez, %{name1} sera supprimé de ce match et %{name2} sera assigné à sa place.',
          invalid_qualification: "%{name} n'a pas de qualification valide pour le moment.",
          verified_eligibility: "Assurez-vous d'avoir vérifié l'éligibilité avant d'assigner.",
          unable_accept_assignment: "%{name} peut ne pas être en mesure d'accepter cette assignation pour les raisons répertoriées dans l'onglet Sommaire.",
          assign_official_to_game: 'Assigner un officiel à ce match',
          reason_for_declining: 'Raison du refus',
          both_assignments: 'les deux assignations',
          assignments: 'cette assignation |||| toutes les %{smart_count} assignations',
          decline_as_unavailable: 'Refuser %{assignments} comme indisponible',
          decline_as_available: 'Refuser %{assignments} comme disponible',
          contact_assigner: 'Ce match vous oblige à contacter un assignateur pour être supprimé.',
          decline_assignment: "Si vous refusez cette assignation, vous pourrez changer d'avis jusqu'à ce que vous soyez supprimé par un assignateur",
          other_assignments: "Vous avez également d'autres assignation en ce moment qui entrent en conflit avec ce match.",
          accept_another_assignment: 'Acceptez une autre tâche à ce moment-là à la place',
          removed_from_game: '%{name} sera supprimé de ce match',
          request_removed: 'Votre demande sera supprimée',
          remove_request_from_game: "Supprimer l'officiel de cette assignation",
          remove_official_from_assignment: "Supprimer l'agent de cette assignation",
          assigners_will_be_notified: "Les assignateurs seront informés que vous avez demandé ce match. Vous devrez toujours confirmer votre assignation si vous avez été assigné à ce match.",
          select_new_assignment: 'Sélectionnez la nouvelle assignation pour %{name}',
          swap_choice: '%{name} deviendra %{position}',
          swap_current_position: "C'est la position actuelle",
          swap_delegated: 'Cette assignation est déléguée à un autre assignateur',
          swap_settings_help: "Vous pouvez également modifier les positions disponibles en modifiant le système d'arbitrage dans les paramètres du match ci-dessous.",
          position_unassigned: "Ce poste n'est pas assigné.",
          official_already_assigned: 'Un officiel est déjà assigné à ce poste.',
          select_official_to_view_availability: 'Sélectionnez un officiel pour voir sa disponibilité.',
          hidden_by_filter: 'officiels sont masqués par des filtres',
          official_from: 'officiel de',
          scorekeeper_from: 'marqueur de',
          contact_game_assigners_officials: "Contactez les assignateur et les officiels de ce match",
          all_assignments_confirmed: 'Toutes les assignations sont confirmées.',
          draft_status_description: 'Non visible pour les officiels',
          assigned_by: `Assigné par %{name}`,
          time_display: `Toutes les heures sont affichées en <strong>%{timezone}</strong>`,
          time_display_local: `Les heures sont dans votre fuseau horaire local (%{timezone})`,
          time_display_other: `Les heures sont dans %{timezone}`,
          delegate: "Ce poste peut être délégué à un autre assignateur. En tant qu'assignateur du match, vous pouvez modifier ou supprimer la délégation à tout moment.",
          update_official_presence_status: 'Mettre à jour le statut de présence',
          mark_as_no_show: 'Marquer %{name} comme non présent',
          undo_no_show: "Cela restaurera le statut de %{name} comme confirmé."
        }
      },
      alerts: {
        loading_game_error: "Erreur lors du chargement de l'historique du match. Vous devrez peut-être actualiser ou réessayer plus tard.",
        no_changes_made: "Aucune modification n'a été apportée à ce match.",
        no_edit_permission: "Vous n'êtes pas autorisé à modifier ce match.",
        no_change_date_permission: "L'organisation de l'horaire a désactivé le changement de date pour les matchs en brouillon partagés.",
        loading: {
          assignments: 'Chargement des assignations...',
          assignment_history: "Chargement de l'historique des assignations...",
          availability: 'Chargement de la disponibilité...',
        },
        status: {
          completed: 'Ce match est terminé.',
          assigning_not_configured: "L'assignation n'est pas configurée pour ce match",
          integration: `Ce match est assigné en dehors de ${appName}.`,
          conflict: "Ce match a un conflit d'horaire",
          cancelled: 'Ce match est annulé',
          postponed: 'Ce match est reporté',
          incomplete: 'Ceci est un match incomplet dans le passé',
          rescheduled: 'Ce match est reprogrammé',
          rink_changed: "La surface de ce match a changé",
        },
        assigning_reason: {
          no_office: "L'assignation n'est pas possible pour ce match car aucune \"détermination des assignateurs\" n'a pu être résolue.",
          no_system: "L'assignation n'est pas possible pour ce match car %{name} ou l'un de ses organisations parentaux ne dispose d'aucun système d'assignation pour cette catégorie.",
          assign_settings_disabled: "L'assignation n'est pas possible pour ce match car l'assignation est désactivée",
        },
        assignment: {
          load_assignment_panel_error: "Impossible de télécharger le panneau d'assignation",
          load_assignments_error: 'Impossible de télécharger les assignations',
          status_of_assignments: "Les assignations sont actuellement à l'état %{status}. Les responsables ne seront pas encore informés.",
          official_history_error: "Échec du chargement de l'historique de l'officiel.",
          no_assignment: 'Aucune assignation cette saison.',
          this_position_already_assigned: "Ce poste est déjà assigné à quelqu'un d'autre.",
          already_assigned_to_game: 'Vous êtes déjà assigné à ce match. Vous devrez refuser votre assignation actuelle avant de pouvoir en faire la demande.',
          official_details_error: "Impossible de télécharger les détails de l'officiels.",
          load_official_schedule_error: "Échec du chargement de l'horaire de l'officiel.",
          no_game_and_availability: "Cet officiel n'a pas de matchs et de disponibilités ce jour-là.",
          missing_settings: 'Paramètres manquants',
          no_pay_structure: "Ce match n'a pas de structure de rémunération configurée. Cela peut être modifié dans les paramètres ci-dessous.",
          no_pay_structure_delegated: "Cette assignation déléguée n'a pas de structure de rémunération configurée.",
          assigned_by: 'Ce poste est assigné par %{office} et payé par %{payOffice}',
          assigned_by_delegated: 'Ce poste est délégué pour être assigné par %{office} et payé par %{payOffice}',
          suggestions: {
            title: 'Suggestions',
            title_alt: 'Pas de suggestions',
            no_requests_or_ready_officials: "Il n'y a pas de demandes ou d'officiels prêts pour ce match",
            officials_requested_this_game_already: 'Certains officiels ont demandé ce match ou sont déjà dans ce lieu',
            no_request_officials_ready: 'Pas de demande, mais certains officiels sont prêts',
            no_ready_officials: "Il n'y a pas d'officiel prêt pour ce match",
            officials_already_arena: 'Certains officiels sont déjà dans ce lieu',
            no_officials_this_arena: "Aucun officiel n'est déjà dans ce lieu, mais certains sont prêts",
          },
          no_officials_required: {
            title: "Aucun officiel n'est requis pour ce match",
            assign_official: "Pour assigner des officiels, modifiez le paramètre Système d'arbitrage ci-dessous",
          },
          assigner: {
            ready: {
              title: 'Prêt',
              officiate_game: "Cet officiel est capable d'arbitrer ou marquer ce match",
            },
            confirmed: {
              title: 'Confirmé',
              accepted: 'Cet officiel a accepté ce poste',
              accepted_other: 'Cet officiel a accepté un autre poste pour ce match',
            },
            pending: {
              title: 'En attente',
              unconfirmed: "Cet officiels a été assigné à cette position et n'a pas encore accepté",
              unconfirmed_other: "Cet officiels a été assigné à un autre poste pour ce match et n'a pas encore accepté",
            },
            declined: {
              title: 'Refusé',
              change_response: "Cet officiel a <strong>refusé</strong> ce poste. Ils peuvent toujours changer d'avis et accepter jusqu'à ce que vous les supprimiez",
              change_response_other: "Cet officiel a <strong>refusé</strong> un autre poste pour ce match. Ils peuvent toujours changer d'avis et accepter jusqu'à ce que vous les supprimiez",
            },
            no_show: {
              title: 'Pas présenté',
              removed: "Le marqueur a retiré cet officiel de la feuille de match car il ne s'est pas présenté",
            },
            requested: {
              title: 'Demandé',
              requested: 'Cet officiel a demandé ce match',
            },
            removed: {
              title: 'Supprimé',
              removed: 'Officiel a été supprimé après avoir précédemment %{message} ce match',
            },
            expired: {
              title: 'Expiré',
              expired: "La qualification d'officiel a <strong>expiré</strong> pour le moment",
            },
            suspended: {
              title: 'Suspendu',
              suspended: "Cet officiel est <strong>suspendu</strong> en tant que %{positionGroup} depuis le %{date} %{duration}",
              duration: 'pour %{smart_count} match |||| pour %{smart_count} matchs',
              duration_expiry: " ou jusqu'au %{date}",
              duration_indefinite: 'pour une durée indéterminée'
            },
            eligibility: {
              title: 'Inéligible',
              minimum_level: "Le <strong>niveau minimum de %{position}</strong> n'a pas été atteint pour ce match",
              no_grade: "Aucune qualification n'a encore été saisie pour cet officiel",
              minimum_grade: "La <strong>qualification minimale de %{position}</strong> n'a pas été atteinte pour ce match",
              minimum_age: "<strong>L'âge minimum</strong> n'a pas été atteint pour ce match",
              no_age: "L'officiel n'a pas encore précisé sa date de naissance",
              limit_reached: "L'officiel a atteint la limite quotidienne de %{smart_count} match |||| L'officiel a atteint la limite quotidienne de %{smart_count} matchs",
            },
            conflicts: {
              title: 'Conflits',
              team_conflict: "L'officiel a un <strong>conflit d'équipe</strong> pour ce match",
              rostered: "L'officiel est <strong>inscrit</strong> dans une équipe jouant à ce match",
            },
            schedule: {
              title: 'Horaire',
              roster_conflict: "L'officiel est <strong>inscrit</strong> dans une équipe <strong>jouant un autre match</strong> en ce moment.",
              affiliate_conflict: "L'officiel est un <strong>joueur affilié</strong> dans une équipe <strong>jouant un autre match</strong> en ce moment.",
              game_conflict: "L'officiel a <strong>accepté une autre assignation</strong> pour le moment",
              game_overlap: "L'officiel a une autre assignation en ce moment mais n'a pas encore répondu",
            },
            travel: {
              title: 'En déplacement',
              title_assigned_today: "Assigné aujourd'hui",
              another_venue: "Cet officiel sera <strong>dans un autre lieu</strong> dans l'heure qui suit ce match",
              this_venue: 'Cet officiel est assigné à ce lieu dans les 3 heures suivant ce match',
              assigned_today: "Cet officiel est assigné sur un match <strong>aujourd'hui</strong>",
            },
            available: {
              title: 'Disponible',
              assigned: "L'officiel a indiqué qu'il est disponible pendant cette période",
            },
            unavailable: {
              title: 'Indisponible',
              unavailable: "L'officiel s'est rendu <strong>indisponible</strong> pour le moment",
              no_availability: "L'officiel n'a défini aucune disponibilité pour le moment",
              no_availability_office: "L'officiel n'a pas inclus cette organisation dans ses paramètres de disponibilité",
              no_availability_arena: "L'officiel n'a pas inclus ce lieu dans ses paramètres de disponibilité",
            },
            account: {
              title: 'Compte',
              unverified: "Cet officiel n'a pas d'adresse courriel de contact vérifiée et <strong>ne recevra pas de notifications d'assignation</strong>",
              invited: "Cet officiel n'a pas de compte et est <strong>incapable de répondre aux assignations</strong>. Une invitation a été envoyée à cet officiel",
              unregistered: "Cet officiel n'a pas de compte et est <strong>incapable de répondre aux assignations</strong>",
            }
          },
          assignee: {
            ready: {
              title: 'Prêt',
              officiate_game: "Vous êtes capable d'arbitrer ou marquer ce match",
            },
            confirmed: {
              title: 'Confirmé',
              accepted: 'Vous avez accepté cette assignation de match',
            },
            pending: {
              title: 'En attente',
              confirm_conflict: "Vous avez été assigné à ce match - acceptez ou refusez afin de confirmer le changement d'horaire",
              confirm: 'Vous avez été assigné à ce match - acceptez pour confirmer la participation',
            },
            declined: {
              title: 'Refusé',
              change_response: "Vous êtes toujours assigné et pouvez modifier votre réponse jusqu'à ce qu'un assignateur vous retire de ce match",
              change_response_other: "Vous avez <strong>refusé</strong> un autre poste pour ce match. Vous pouvez toujours changer d'avis et accepter jusqu'à ce qu'un assignateur vous retire de ce match",
            },
            no_show: {
              title: 'Pas présenté',
              removed: 'Le marqueur vous a retiré de la feuille de match comme vous ne vous êtes pas présenté',
            },
            requested: {
              title: 'Demandé',
              requested: 'Vous avez demandé ce match',
            },
            removed: {
              title: 'Supprimé',
              removed: 'Vous avez été supprimé après avoir %{message} ce match',
            },
            expired: {
              title: 'Expired',
              expired: "Votre qualification d'arbitre a <strong>expiré</strong> à ce moment",
            },
            suspended: {
              title: 'Suspendu',
              suspended: "Vous avez été <strong>suspendu</strong> en tant que %{positionGroup} depuis %{date} %{duration}",
            },
            eligibility: {
              title: 'Inéligible',
              minimum_level: "Vous n'avez pas atteint le <strong>niveau minimum %{position}</strong> pour ce match",
              no_grade: "Aucune qualification n'a encore été saisie pour vous",
              minimum_grade: "Vous n'avez pas atteint le <strong>sous-niveau minimale de %{position}</strong> pour ce match",
              minimum_age: "Vous n'avez pas atteint <strong>l'âge minimum</strong> pour ce match",
              no_age: "Vous n'avez pas encore défini votre date de naissance. Veuillez mettre à jour votre profil",
              limit_reached: "Vous avez atteint la limite quotidienne de %{smart_count} match |||| Vous avez atteint la limite quotidienne de %{smart_count} matchs",
            },
            conflicts: {
              title: 'Conflits',
              team_conflict: "Vous avez un <strong>conflit d'équipe</strong> pour ce match",
              rostered: "Vous êtes <strong>inscrit</strong> dans une équipe qui joue à ce match",
            },
            schedule: {
              title: 'Horaire',
              roster_conflict: "Vous êtes inscrit dans une équipe <strong>jouant à un autre match</strong> en ce moment",
              game_conflict: "Vous avez <strong>accepté un autre assignation</strong> pour le moment",
              game_overlap: "Vous avez actuellement une autre assignation auquel vous n'avez pas encore répondu",
            },
            travel: {
              title: 'En déplacement',
              title_assigned_today: "Assigné aujourd'hui",
              another_venue: "Vous êtes <strong>assigné à un autre lieu</strong> dans l'heure qui suit ce match",
              this_venue: "Vous êtes assigné à ce lieu dans les 3 heures suivant ce match",
              assigned_today: "Vous êtes assigné sur un match <strong>aujourd'hui</strong>",
            },
            available: {
              title: 'Disponible',
              assigned: "Votre calendrier indique que vous êtes disponible à cette heure et lieu",
            },
            unavailable: {
              title: 'Indisponible',
              unavailable: "Vous vous êtes rendu <strong>indisponible</strong> pour le moment",
              no_availability: "Vous n'avez défini aucune disponibilité pour le moment",
              no_availability_office: "Vous n'avez pas inclus cette assignation dans vos paramètres de disponibilité",
              no_availability_arena: "Vous n'avez pas inclus ce lieu dans vos paramètres de disponibilité",
            },
            account: {
              title: 'Compte',
              unverified: "Vous devez <strong>valider votre adresse courriel de contact</strong> pour recevoir des notifications d'assignation",
              invited: "Vous n'avez pas de compte et ne serez pas en mesure de répondre à l'assignation. Une invitation vous a été envoyée",
              unregistered: "Vous n'avez pas de compte et vous ne pourrez pas répondre aux assignations",
            }
          }
        }
      },
      helpers: {
        split: 'Optionnel - utiliser pour diviser la surface',
        number: 'Généré par le brouillon',
        team: "Optionnel - À déterminer si aucune équipe n'est spécifiée",
        comments: 'Optionnel - affiché sur le calendrier public',
        conflicts: 'Résoudre les conflits ou le statut passera à Conflit',
        reset_assigner: "Remplacer l'assignateur existant en fonction des règles de détermination des assignateurs",
        assignment: {
          delegated_tooltip: 'Délégué à %{office}',
          pay_delegated_tooltip: 'Payé par %{office}',
          notification_will_be_sent: "Une notification sera envoyée aux %{recipients} de ce match et aux officiels du match assignés.",
          note_for_accepted_game: 'Cette note sera utilisée pour vos matchs acceptés',
          delegated_pay_office: 'Le paiement sera géré par %{office}',
        },
        scoresheet_notes: 'Optionnel - ces notes seront visibles publiquement sur la feuille de match',
        admin_notes: 'Optionnel - ces notes seront réservées aux marqueurs, aux officiels et aux administrateurs uniquement',
        using_slot: "Utilisation d'une plage horaire",
        use_next_sequence: 'Utiliser le prochain numéro de séquence disponible',
      },
      notifications: {
        assignment: {
          accepted_game: 'Match %{number} accepté',
          declined_game: 'Match %{number} refusé',
          requested_game: 'Match %{number} demandé',
          official_assigned_to_game: 'Officiel assigné au match %{number}',
          remove_official_from_game: 'Officiel retiré du match %{number}',
          official_set_to_no_show: 'Officiel marqué comme non présent',
          request_removed_from_game: 'Votre demande a été supprimée du match %{number}',
          status: {
            assignee: {
              requested: 'Vous avez demandé ce match',
              pending: "Assignation en attente, en attente de votre réponse.",
              confirmed: 'Votre assignation est confirmée.',
              declined: 'Vous avez refusé cette assignation.',
            }
          },
          errors: {
            accept_overlaps: "Impossible d'accepter en raison d'une assignation qui se chevauche",
            accept_consecutive: "Impossible d'accepter en raison d'une assignation consécutive",
            accept_consecutive_overlaps: "Impossible d'accepter en raison d'une assignation consécutive qui se chevauche",
            decline_consecutive: "Impossible de refuser en raison d'une assignation consécutive",
          }
        },
        recalculating: 'Recalculer...',
        game_status_changed: 'Statut changé',
        change_game_status: 'Changement de %{smart_count} match au statut %{status} |||| Changement de %{smart_count} matchs au statut %{status}',
        change_game_status_error: "Impossible de changer le statut  |||| Impossible de changer les statuts",
        delegated_games: '%{smart_count} match délégués avec succès |||| %{smart_count} matchs délégués avec succès',
        delegated_games_error: 'Impossible de déléguer les match |||| Impossible de déléguer les matchs',
        changed_assigner_games: "Changement d'assignateur pour %{smart_count} match avec succès |||| Changement d'assignateur pour %{smart_count} matchs avec succès",
        changed_assigner_games_error: "Impossible de changer l'assignateur de match |||| Impossible de changer les assignateurs de match.",
        certified_games: '%{smart_count} match certifié |||| %{smart_count} matchs certifiés',
        certified_games_error: "Une erreur s'est produite lors de la certification du match |||| Une erreur s'est produite lors de la certification des matchs",
      },
      validations: {
        invalid_date: 'Date non valide',
        invalid_time: 'Heure non valide',
        must_have_date: "Doit toujours avoir une date s'il y a une heure",
        must_have_date_both_times: "Doit toujours avoir à la fois une heure de début et une heure de fin s'il y a une heure",
        end_before_start: "L'heure de fin doit toujours être après l'heure de début",
        invalid_time_check_am_pm: 'Heure non valide - vérifier AM/PM',
        invalid_must_be_same_day: 'Doit être la même date',
        spaces_not_allowed: 'Les espaces ne sont pas autorisés',
        after_start_time: "Doit être après l'heure de début",
        overlaps: 'Ce match chevauche un match existant',
        number_exists: 'Existe déjà dans cet horaire',
        conflicts: 'Résoudre les conflits ou passer à un statut inactif',
        before_schedule_date: "Avant la date de début de l'horaire",
        after_schedule_date: "Après la date de fin de l'horaire",
        team_not_in_cross_scheduling_groups: "L'équipe ne fait partie d'aucun des groupes sélectionnés",
        team_in_both_cross_scheduling_groups: "L'équipe est dans les deux groupes sélectionnés",
        teams_in_same_cross_scheduling_group: "L'équipe local et visiteur sont toutes les deux dans un des groupes sélectionnés",
        unavailable_slot: "Cette plage horaire n'est pas disponible",
        overlapping_slot: 'Plage horaire chevauchant',
        limit_date_change: "Vous n'êtes pas autorisé à modifier la date"
      },
      values: {
        status: STATUSES,
        assignment: {
          status: {
            Active: 'Actif',
            Draft: 'Brouillon',
          },
          position: {
            Referee: 'Arbitre',
            Linesperson: 'Juge de lignes',
            LinespersonTwo: 'Juge de lignes 2',
            LinespersonThree: 'Juge de lignes 3',
            Official: 'Officiel',
            Scorekeeper: 'Marqueur',
            Timekeeper: 'Chronométreur',
            Supervisor: 'Superviseur',
            Participant: 'Participant',
          },
          position_short: {
            Referee: 'A',
            Linesperson: 'JL',
            LinespersonTwo: 'JL2',
            LinespersonThree: 'JL3',
            Official: 'O',
            Scorekeeper: 'M',
            Timekeeper: 'C',
            Supervisor: 'S',
          },
        }
      },
      tooltips: {
        forfeited: 'Ce match a été déclaré forfait par %{team}',
      }
    },
    gameEvents: {
      name: 'Événement du match |||| Événements du match',
      fields: {
        eventType: 'Type',
        event: 'Événement',
        meta: 'Métadonnées',
      },
      labels: {
        card: {
          title: 'Événements du match',
        },
        event_id: "ID d'événement",
        created: 'Créer',
        account: 'Compte',
        device: 'Appareil',
        transaction: 'Transaction',
        received: 'Reçu',
        game_number: 'Numéro de match',
        start_time: 'Heure de début',
        end_time: 'Heure de fin',
        signatory: 'Signataire',
        signature: 'Signature',
        scorekeeper: 'Marqueur',
        timekeeper: 'Chronométreur',
        position: 'Position |||| Positions',
        notes: 'Notes',
        game_time: 'Temps de jeu',
        emails: 'Adresses courriel',
        system: 'Système',
        history: 'Historique',
        homeScore: 'Pointage - Local',
        awayScore: 'Pointage - Visiteur',
        goal: {
          id: 'ID du but',
          scorer: 'Buteur',
          assists: 'Aide(s)',
          type: 'Type',
          powerplay: 'Jeu de puissance',
          shorthanded: 'Désavantage numérique',
          empty_net: 'Filet vide',
          penalty_shot: 'Tir de pénalité',
          own_goal: 'But contre son camp'
        },
        penalty: {
          id: 'ID de pénalité',
          infraction: 'Infraction',
          served_by: 'Servi par',
        },
        goalie_change: {
          id: 'ID du changement de gardien de but',
          goalie: 'Gardien de but',
          new_goalie: 'Nouveau gardien mis en place',
        },
        periods: {
          1: '1re période',
          2: '2e période',
          3: '3e période',
          OT: 'Période de prolongation',
          SO: 'Tirs de barrage',
        },
        delete_events: "Supprimer l'événement du match |||| Supprimer les événements du match"
      },
      messages: {
        offline_for_duration: 'Hors ligne pendant %{duration}',
        time_skew_duration: 'Temps décalé de %{duration}',
        game_cancelled: 'Match annulé',
        game_completed: 'Feuille de match complétée',
        game_disapproved: 'Feuille de match désapprouvée',
        game_certified: 'Feuille de match certifiée',
        game_decertified: 'Feuille de match décertifié',
        game_deleted: 'Le match a été supprimé',
        game_reset: 'Feuille de match réinitialisée',
        requested: 'demandé %{position}',
        assigned_as: 'assigné en tant que %{position}',
        accepted_assignment: 'assignation acceptée',
        declined_assignment: 'assignation refusée',
        added_as: 'ajouté en tant que %{position}',
        changed_to: 'changé en %{position}',
        removed: 'supprimé',
        confirm_delete_events: 'Voulez-vous vraiment supprimer cet événement du match? |||| Voulez-vous vraiment supprimer ces événements du match?'
      },
      values: {
        eventType: {
          gameCreated: 'Match créé',
          gameRescheduled: 'Match reprogrammé',
          gameUpdated: 'Match mis à jour',
          gameDeleted: 'Match supprimé',
          gameForfeited: 'Match forfait',
          gameStarted: 'Match commencé',
          gameEnded: 'Match terminé',
          assignSettingsUpdated: "Paramètres d'assignation mis à jour",
          lineupUpdated: "Alignement mis à jour",
          lineupApproved: 'Alignement approuvé',
          lineupExtraAdded: 'Alignement extra ajouté',
          lineupExtraRemoved: 'Alignement extra supprimé',
          officialDelegated: 'Official délégué',
          officialRequested: 'Officiel demandé',
          officialAssigned: 'Officiel assigné',
          officialAccepted: 'Officiel accepté',
          officialDeclined: 'Officiel refusé',
          officialAdded: 'Officiel ajouté',
          officialUpdated: "Mise à jour de l'officiel",
          officialRemoved: 'Officiel supprimé',
          keepersUpdated: 'Mise à jour des gardiens',
          goalScored: 'But marqué',
          goalEdited: 'But mis à jour',
          goalDeleted: 'But supprimé',
          penaltyStarted: 'Pénalité commencée',
          penaltyEnded: 'Pénalité terminée',
          penaltyEdited: 'Pénalité mise à jour',
          penaltyDeleted: 'Pénalité supprimée',
          goalieChanged: 'Changement de gardien',
          goalieChangeDeleted: 'Changement de gardien supprimé',
          goalieChangeEdited: 'Changement de gardien mis à jour',
          settingsUpdated: 'Paramètres mis à jour',
          notesUpdated: 'Notes mises à jour',
          goalieShotsUpdated: 'Tirs contre mis à jour',
          scoreUpdated: 'Pointage mis à jour',
          scoresheetApproved: 'Feuille de match complétée',
          scoresheetDisapproved: 'Feuille de match désapprouvée',
          throwsInningsUpdated: 'Mise à jour des balles et manches lancées',
          certificationFlagsUpdated: 'Mise à jour des notifications',
          scoresheetCertified: 'Feuille de match certifiée',
          scoresheetDecertified: 'Feuille de match décertifiée',
          scoresheetReset: 'Feuille de match réinitialisée'
        }
      },
      notifications: {
        deleted: '%{smart_count} événement du match supprimé |||| %{smart_count} événements du match supprimés',
        delete_error: 'Impossible de supprimer les événements du match'
      }
    },
    gameAffiliates: {
      name: 'Affilié |||| Affiliés',
      fields: {
        gameId: 'Match',
        teamId: 'Équipe',
        participantId: 'Participant',
        positions: 'Position',
        number: 'Numéro',
        officeId: 'Organisation'
      },
      actions: {
        add: 'Ajouter un affilié',
      },
      labels: {
        card: {
          title: 'Affiliés'
        },
        edit: "Mettre à jour l'affilié",
      }
    },
    participants: {
      name: 'Participant |||| Participants',
      fields: {
        participantId: 'Participant',
        fullName: 'Prénom et Nom',
        firstName: 'Prénom',
        lastName: 'Nom',
        name: 'Nom',
        HCRId: 'Numéro de membre',
        email: 'Adresse courriel',
        accountEmail: 'Courriel du compte',
        phone: 'Numéro de Téléphone',
        isPrimary: 'Primaire',
      },
      actions: {
        edit: 'Modifier le participant',
        invite_sso: 'Inviter SMA',
      },
      labels: {
        card: {
          title: 'Participant',
        },
        tabs: {
          summary: 'Sommaire',
          profile: 'Profil',
          schedule: 'Horaire',
          registrations: 'Ligue',
          officiating: 'Officiel',
          settings: 'Paramètres',
        },
        tooltips: {
          create_participant: 'Le participant sera créé',
          picture_update_in_id: 'Pour modifier la photo, veuillez mettre à jour dans Spordle ID'
        },
        add: 'Nouveau participant',
        account_invitation: 'Invitation de compte',
        account_invitation_sso: 'Invitation de compte Spordle My Account',
        availability: {
          card: {
            title: 'Disponibilité',
            subtitle: "Sélectionnez les dates et les heures auxquelles vous êtes disponible pour arbitrer ou marquer des matchs, ou bloquez les heures où vous n'êtes pas disponible. Ouvrez les paramètres pour sélectionner vos assignation et vos lieux.",
          },
          title: 'Mes disponibilités',
          updated: 'Disponibilité mise à jour!',
          saved: 'Sauvegardé',
          saving: 'Sauvegarde en cours...',
          note: 'Note de disponibilité',
          add_time: 'Ajouter des disponibilités',
          multiple: 'Plusieurs',
          settings: 'Paramètres de disponibilité',
          add_associations: 'Ajouter des associations',
          my_assigners: 'Mes assignations',
        },
        status: {
          roles_title: 'Rôles manquants',
          roles_body: "Ce participant ne peut pas créer de compte tant qu'il n'est pas inscrit dans une équipe, qu'il n'a pas de qualification à titre d'officiel ou qu'il n'a pas un rôle administratif.",
          roles_unauthorized: "Ce participant ne peut pas utiliser son compte tant qu'il n'est pas inscrit dans une équipe, qu'il n'a pas de qualification à titre d'officiel ou qu'il n'a pas un rôle administratif.",
          account_title: 'Pas de compte',
          account_body: "Ce participant n'a pas encore créé de compte. Vous pouvez l'inviter à s'inscrire.",
          invited_title: 'Invité',
          invited_body: "Une invitation a été envoyée à ce participant. Si nécessaire, vous pouvez retourner l'invitation.",
          contacts_title: 'Pas de contact',
          contacts_body: "Ce participant ne peut pas recevoir de notifications tant qu'un contact n'aura pas été ajouté et vérifié. Cela n'affecte pas la capacité d'utiliser leur compte.",
          unverified_title: 'Ne pas recevoir de notifications',
          unverified_body: "Ce participant ne recevra pas de notifications tant qu'il n'aura pas un contact vérifié avec les notifications activées. Cela n'affecte pas sa capacité à utiliser son compte.",
          roles: 'Rôles',
          types: {
            Admin: 'Administrateur',
            League: 'Ligue',
            Assigner: 'Assignateur',
            Staff: "Personnel d'équipe",
            Player: 'Joueur',
            Official: 'Officiel',
            Coach: 'Entraîneur',
            Scorekeeper: 'Marqueur',
            Monitor: 'Monitor',
          }
        },
      },
      messages: {
        search_by_name: 'Entrez un nom pour lancer la recherche',
        send_link: 'Cela enverra un lien qui pourra être utilisé pour créer un nouveau compte ou connecter ce profil à un compte existant.',
        link_sso: 'Cela créera un nouveau compte Play et le reliera à Spordle My Account',
        create_sma: 'Voulez-vous aussi créer un nouveau utilisateur Spordle My Account avec ce courriel?',
        availability: {
          placeholder: `<strong>Choisissez une date sur le calendrier pour voir et mettre à jour la disponibilité.</strong>
          <p>Sélectionnez <em>Plusieurs</em> pour définir et copier les mêmes heures sur plusieurs dates.</p>`,
          empty_slot: "Vous n'avez pas encore ajouté de disponibilité",
          save_for: 'Sauvegarder pour ',
          number_dates: '%{number} dates',
          available: 'Je %{input} disponible',
          thing_will: 'sera',
          thing_will_not: 'ne sera pas',
          person_will: 'serai',
          person_will_not: 'ne serai pas',
          to: 'à',
          select_venues_travel_to: "Sélectionnez les lieux dans lesquelles vous êtes prêt à vous rendre régulièrement. Si vous n'en sélectionnez aucun, vous serez disponible pour toutes les lieux.",
          search_by_association: "Recherche par nom d'association",
          enter_name_search: 'Entrez un nom pour lancer la recherche',
          select_associations: 'Sélectionnez les associations pour lesquelles vous vous rendrez disponible pour arbitrer ou marquer des matchs.',
        },
      },
      errors: {
        no_sma_user_found: 'Aucun utilisateur Spordle My Account trouvé',
        failed_sma_creation: 'Impossible de créer un utilisateur Spordle My Account',
        failed_to_get_user: "Impossible de récupérer l'utilisateur Spordle My Account",
        failed_sma_retrieval: "Impossible de récupérer l'entité Spordle My Account",
        email_exists: 'Ce courriel est déjà enregistré dans Play',
        already_linked: 'Un compte Play est déjà lié à ce participant',
        sma_exists: 'Cet utilisateur Spordle My Account est déjà lié',
      },
      alerts: {
        search_by_name_hockeyid: 'Entrez un nom ou un numéro de membre ci-dessus pour lancer la recherche...',
        availability: {
          loading_associations: 'Chargement des associations...',
          selection: {
            title: 'Disponible dans tous les lieux',
            available_to_all_arenas: "Recherchez ci-dessus si vous souhaitez éventuellement limiter les lieux auxquelles vous êtes disponible.",
          },
          no_associations: {
            title: 'Aucune association sélectionnée',
            search_make_yourself_available: "Faites une recherche ci-dessus pour vous mettre disponible pour une association.",
            not_available_to_assigners: "Vous ne serez pas disponible pour les assignateurs ou ne pourrez voir aucun match tant que vous n'aurez pas sélectionné une association.",
          }
        }
      },
      helpers: {
        note: "Optionnel - sera inclus dans l'invitation",
        availability: {
          note: "Cette note sera visible dans le panneau d'assignation des matchs pendant cette période.",
        }
      },
      notifications: {
        invitation_sent: 'Invitation envoyée!',
        sso_created_and_linked: 'Compte Play créé et lié à Spordle My Account avec succès',
        sma_created_and_linked: 'Les comptes Spordle My Account et Play ont été créés et liés avec succès'
      }
    },
    qualifications: {
      name: 'Qualification |||| Qualifications',
      fields: {
        participantId: 'Participant',
        level: 'Niveau',
        grade: 'Grade',
        grades: {
          Referee: "Grade d'arbitre",
          Linesperson: 'Grade de juge de ligne',
        },
        qualificationCategory: {
          types: 'Qualification'
        },
        officeId: 'Organisation',
        seasonId: 'Saison',
        expiry: "Date d'expiration",
        age: 'Âge',
        qualificationCategoryId: 'Catégorie',
      },
      actions: {
        add: 'Ajouter une qualification',
      },
      labels: {
        card: {
          title: 'Qualifications',
        },
        edit: 'Mettre à jour la qualification',
        grades_file: 'Fichier des grades',
        qualifications_file: 'Fichier de qualifications',
        import_grades: 'Importer des grades',
        import_qualifications: 'Importer des qualifications',
        official_level: 'Niveau officiel',
        qualification_category: 'Catégorie de la qualification',
      },
      messages: {
        drop_qualifications_csv: 'Déposez votre fichier de qualifications (.csv) ici',
        drop_grades_csv: 'Déposez votre fichier de grade (.csv) ici',
        import_tool_qualifications: "Pour utiliser cet outil d'importation, téléchargez un fichier CSV avec des colonnes pour le numéro de membre unique de l'officiel, le prénom, le nom de famille, le niveau officiel, grade d'arbitre (optionnel), grade de juge de ligne (optionnel) et l'expiration.",
        import_tool_grades: "Pour utiliser cet outil d'importation, téléchargez un fichier CSV avec des colonnes pour le numéro de membre unique de l'officiel, le grade de l'arbitre et le grade du juge de ligne.",
      },
      alerts: {
        create_new_participants: "<strong>Utiliser avec précaution.</strong> Cet outil d'importation créera de nouveaux participants qui ne correspondent pas aux numéros de membre existants.",
      },
      helpers: {
        select_columns: "Il vous sera demandé de sélectionner des colonnes après avoir sélectionné votre fichier",
        valid_grades: '%{number}/%{total} lignes ont des grades valides',
        valid_qualifications: '%{number}/%{total} lignes ont des qualifications valides',
        participant_not_matched: "Les participants seront ignorés si une qualification n'est pas trouvée sous cette organisation.",
        match_existing_participant: 'Ce numéro de membre est utilisé pour correspondre à un participant existant ou créer un nouveau participant pour cette qualification.',
      },
      notifications: {
        grades_updated: "%{smart_count} grade d'officiel a été mis à jour. |||| %{smart_count} grades d'officiel ont été mis à jour.",
        qualifications_updated: "%{smart_count} qualification d'officiel a été mis à jour. |||| %{smart_count} qualifications d'officiel ont été mis à jour.",
        import_grades_error: "Impossible d'importer les grades.",
        import_qualifications_error: "Impossible d'importer les qualifications.",
      },
      validations: {
        invalid_grade: 'Certaines lignes ont des grades non valide',
        level: 'Doit être supérieur ou égal à 0.',
        invalid_date: "Certaines valeurs n'incluent pas de date valide (YYYY-MM-DD)",
      },
      values: {
        level: {
          zero: '0',
          level_zero: 'Niveau 0',
        },
      },
    },
    qualificationCategories: {
      values: {
        types: {
          Official: 'Officiel',
          Scorekeeper: 'Marqueur'
        }
      }
    },
    officialOffices: {
      filters: {
        officialName: "Nom de l'officiel"
      },
      fields: {
        types: 'Types',
      }
    },
    officials: {
      name: 'Officiel |||| Officiels',
      fields: {
        participantId: 'Participant',
        payOfficeId: 'Organisation responsable de la paye',
        officeId: 'Organisation',
        seasonId: 'Saison'
      },
      actions: {
        add: 'Ajouter un officiel',
      },
      labels: {
        card: {
          title: 'Officiels',
          subtitle: "Officiels disponibles pour l'assignation des matchs."
        },
        edit: 'Mettre à jour un officiel',
        official_id: "ID de l'officiel",
        position: 'Position',
        team: 'Équipe',
        file: 'Fichier des officiels',
        import: 'Importer des officiels',
      },
      messages: {
        drop_csv: "Déposez votre fichier d'officiel (.csv) ici",
        import_tool: "Pour utiliser cet outil d'importation, téléchargez un fichier CSV avec des colonnes pour le numéro de membre unique de l'officiel.",
      },
      filters: {
        unavailable: 'Officiels indisponibles',
        ineligible: 'Officiels non éligibles',
        expired: 'Officiels expirés',
        unregistered: 'Officiels non enregistrés',
        new: 'Nouveaux officiels',
        local: 'Officiels locaux',
        ignore_arena: "Ignorer les disponibilités du lieu",
        match_all: "Tous",
        match_any: "Au moins un",
      },
      helpers: {
        match_existing_participant: "Les participants seront ignorés s'ils ne sont pas des officiels qualifiés.",
      },
      notifications: {
        added: "%{smart_count} officiel a été ajouté. |||| %{smart_count} officiels ont été ajouté.",
        unqualified: "%{smart_count} ont échoué parce qu'ils n'étaient pas qualifiés.",
        import_error: "Impossible d'importer les officiels.",
      },
    },
    scorekeepers: {
      name: 'Marqueur |||| Marqueurs',
      labels: {
        card: {
          title: 'Marqueurs',
          subtitle: "Marqueurs disponibles pour l'assignation des matchs."
        }
      }
    },
    officialConflicts: {
      name: 'Conflit |||| Conflits',
      fields: {
        seasonId: 'Saison',
        participantId: 'Participant',
        targetId: 'Conflit',
      },
      actions: {
        add: 'Ajouter un conflit',
      },
      labels: {
        card: {
          title: 'Conflits',
          subtitle: "Les matchs suivants sont programmés en même temps avec les mêmes équipes ou le même lieu. Si aucun match actif n'est répertorié, le statut de ce match peut être changé en Actif.",
        },
        edit: "Mise à jour d'un conflit",
        add_team: 'Ajouter une équipe',
      }
    },
    offices: {
      name: 'Organisation |||| Organisations',
      fields: {
        officeId: 'Organisation',
        name: 'Nom',
        type: 'Type',
        HCRId: "Numéro d'organisation",
        parentId: 'Organisation parent',
        logo: 'Logo',
        timezone: 'Fuseau Horaire'
      },
      actions: {
        add: 'Ajouter une organisation',
        edit: "Modifier l'organisation",
        internal: 'Actions internes',
        syncOffices: 'Sync organisations',
        syncTeams: 'Sync équipes',
        syncOfficials: 'Sync qualifications',
        syncRegistrations: 'Sync inscriptions',
      },
      labels: {
        card: {
          title: 'Organisations',
          sub_offices_title: 'Sous-organisations',
        },
        tabs: {
          summary: 'Sommaire',
          teams: 'Équipes',
          surfaces: 'Surfaces',
          schedules: 'Horaires',
          people: 'Participants',
          assigning: 'Assignation',
          scheduler: 'Céduleur',
          rulebook: 'Livre des règlements',
          settings: 'Paramètres',
        },
        add: 'Nouvelle organisation',
        edit: "Mettre à jour l'organisation",
        members: "Membres de l'organisation",
        availability: {
          card: {
            title: 'Disponibilité',
            subtitle: "Sélectionnez les dates et les heures disponibles, ou bloquez les heures non disponibles.",
          },
          title: "Disponibilités de l'organisation",
          note: 'Note de disponibilité',
          updated: 'Disponibilité mise à jour!',
        },
        analyzeSchedules: 'Analyser les horaires'
      },
      messages: {
        availability: {
          available: 'Cette organisation %{input} disponible',
        }
      },
      helpers: {
        name: 'Les organisations ne doivent généralement pas contenir la division.',
        availability: {
          note: "Cette note sera visible lorsqu'une partie est déjà prévue dans cette plage horaire.",
        }
      },
      validations: {
        same_office: 'Le parent ne peut pas être la même organisation',
      },
      values: {
        type: {
          Branch: 'Département',
          Region: 'Région',
          District: 'District',
          Association: 'Association',
          Administrative: 'Administratif',
          Historical: 'Historique',
          League: 'Ligue',
          Tournament: 'Tournoi',
          Zone: 'Zone',
          Organization: 'Fédération',
          Cup: 'Coupe',
        },
        scheduleType: TYPES,
      }
    },
    officeMembers: {
      name: "Membre de l'organisation |||| Membres de l'organisation",
      fields: {
        officeId: 'Organisation',
      },
      actions: {
        add: 'Ajouter des organisations',
      },
      labels: {
        card: {
          title: "Membres de l'organisation",
        },
        edit: 'Mettre à jour le membre',
      }
    },
    branchOfficeSettings: {
      name: 'Paramètre du département |||| Paramètres du département',
      fields: {
        certifiedOfficialChanges: 'Autoriser le changement des officiels sur un match certifié',
        certifiedOfficialChanges_off: "Désactivé",
        certifiedOfficialChanges_on: "Activé",
        ff_suspensions: 'Suspensions',
        ff_suspension_disciplines: 'Suspension Disciplines',
        ff_incidents: 'Incidents'
      },
      labels: {
        assignments: "Assignation d'officiels",
        tabs: {
          general: 'Général',
          featureFlags: 'Feature Flags',
        },
        card: {
          title: 'Paramètres du département'
        }
      },
      helpers: {
        certifiedOfficialChanges: 'Autorise les organisations sous cette branch à changer les officiels même si un match est certifié par la ligue',
        ff_suspensions: 'Active la gestion des suspensions dans les matchs',
        ff_suspension_disciplines: 'Permettre la gestion de mesures disciplinaires supplémentaires sur les suspensions (Comité de discipline)',
        ff_incidents: "Active les rapports d'incident dans les matchs",
      },
      actions: {
        edit: 'Modifier les paramètres du département'
      }
    },
    officeSettings: {
      name: 'Paramètre |||| Paramètres',
      fields: {
        skaterStatsHiddenDivisionIds: 'Division à cacher pour les statistiques des joueurs',
        goalieStatsHiddenDivisionIds: 'Division à cacher pour les statistiques des gardiens',
        standingsHiddenDivisionIds: 'Division à cacher pour les classements',
        boxScoreHiddenDivisionIds: 'Division à cacher pour les statistiques détaillées des matchs',
        sportsmanshipPoints: "Points d'esprit sportif",
        contactsVisibility: 'Visibilité des contacts',
        adminReports: 'Rapports administratifs',
        teamReports: "Rapports d'équipe",
        syncAttributes: 'Attributs de synchronisation HCR',
        updateRosterFromLineups: "Modifier le cahier d'équipe à partir de la feuille de match",
        gameLength: 'Temps de réservation de la surface de jeu',
        surfaceOfficeId: "L'organisation des surfaces",
        conflictDrafts: "Inclure les matchs en brouillon lors de l'application",
        conflictLegacyDrafts: "Inclure les matchs en brouillon ancien lors de l'application",
        availabilityEnforcement: "Disponibilités",
        conflictEnforcement: 'Matchs',
        constraintEnforcement: 'Contraintes',
        language: 'Langage par défaut',
        homeTeamScorekeeping: "Entrée des résultats par l'équipe à domicile",
        awayTeamScorekeeping: "Entrée des résultats par l'équipe visiteur",
        scoringMode: 'Entrée des résultats',
        penaltyMode: 'Entrée des pénalités',
        requireCertification: 'Certification des matchs',
        requireIncidentReports: "Rapports d'incident",
        suspensionActivation: 'Activation des suspensions',
        includeRegistrations: 'Inclure les inscriptions',
        allowExtras: 'Autoriser les participants supplémentaires',
        homeChangeStatus: "Planification des horaires par l'équipe à domicile",
        maxScoreDiff: 'Différentiel du pointage maximum',
        points: {
          Team: {
            win: 'Victoire',
            loss: 'Défaite ',
            tie: 'Nulle',
            soWin: 'Victoire en fusillade',
            soLoss: 'Défaite en fusillade',
            otWin: 'Victoire en prolongation',
            otLoss: 'Défaite en prolongation',
            forfeitWin: 'Victoire par forfait',
            forfeitLoss: 'Défaite par forfait',
            forfeitTie: 'Double Forfait',
            sportsmanship: 'Esprit sportif',
          },
          Player: {
            goal: 'But',
            assist: 'Passe'
          },
          preview: `Victoire: %{win} Défaite: %{loss} Nulle: %{tie}`
        },
        enforcements: {
          preview: {
            conflictEnforcement: `Matchs: %{enforcementValue}`,
            availabilityEnforcement: `Disponibilités: %{enforcementValue} `,
            constraintEnforcement: `Contraintes: %{enforcementValue}`,
          }
        },
        deferDraftConflict: "Reporter l'application des conflits dans les brouillons",
        deferLegacyDraftConflict: "Reporter l'application des conflits dans les brouillons anciens",
        limitDateChange: 'Limiter le changement de date sur les matchs en brouillon partagés',
        rescheduleRequestsEnabled: "Demandes de changements d'horaire",
        requiredRescheduleApprovals: "Approbateurs requis",
        groupingType: "Type de regroupement d'équipes",
      },
      actions: {
        edit: 'Modifier les paramètres',
      },
      labels: {
        card: {
          title: "Paramètres de l'organisation",
        },
        tabs: {
          general: 'Général',
          scheduling: 'Horaires',
          team_stats: "Statistiques d'équipe",
          player_stats: 'Statistiques de joueur',
          scoresheet: 'Feuille de match',
          advanced: 'Avancé',
        },
        schedule_stats: 'Statistiques de calendrier',
        game_stats: 'Statistiques de match',
        stats_schema: {
          team: "Schéma des statistiques d'équipe",
          skater: 'Schéma des statistiques de joueur',
          goalie: 'Schéma des statistiques de gardiens de but',
        },
        standings_order: {
          team: 'Ordre du classement des équipes',
          skater: 'Ordre du classement des joueurs',
          goalie: 'Ordre du classement des gardiens de but',
          wins: 'Victoires',
          losses: 'Défaites',
          otl: 'Défaites en prolongation',
          ties: 'Nulles',
          diff: 'Différentiel de points',
          points: 'Points',
          pointsPct: 'Pourcentage de points',
          pointsPerGame: 'Points par match joué',
          gamesPlayed: 'Matchs joués',
          goalAgainst: 'Buts contre',
          goalAgainstPerGame: 'Buts contre par match joué',
          goalFor: 'Buts pour',
          goalForPerGame: 'Buts pour par match joué',
          shotFor: 'Tirs pour',
          shotAgainst: 'Tirs contre',
          sportsmanship: "Points d'esprit sportif",
          gaa: 'Moyenne de buts alloués par match',
          shutouts: 'Blanchissages',
          gamesStarted: 'Matchs amorcés',
          minutesPlayed: 'Minutes jouées',
          savePercentage: "Pourcentage d'arrêts",
          goals: 'Buts',
          assists: 'Aides',
          gameTyingGoals: 'But égalisateur',
          gameWinningGoals: 'Buts gagnants',
          powerplayGoals: 'Buts en avantage numérique',
          powerplayPoints: 'Points en avantage numérique',
          powerplayAssists: 'Aides en avantage numérique',
          shorthandedGoals: 'Buts en infériorité numérique',
          shorthandedPoints: 'Points en infériorité numérique',
          shorthandedAssists: 'Aides en infériorité numérique',
          pim: 'Minutes de pénalités',
          pimPerGame: 'Minutes de pénalités par match joué',
          penaltyOccurrences: 'Nombre de punition',
          throws: 'Lancés total',
          inningsPitched: 'Manches lancés',
        },
        standings_type: {
          'All Games': 'Tous les matchs',
          'Head-to-head': 'Tête à tête',
        },
        standings_subject: {
          team: 'Équipe',
          skater: 'Joueur',
          goalie: 'Gardien',
        },
        hidden_stats: {
          team: 'Statistiques cachées des équipes',
          skater: 'Statistiques cachées des joueurs',
          goalie: 'Statistiques cachées des gardiens de but',
        },
        reports: 'Rapports',
        contacts: 'Contacts',
        points: 'Points',
        standings: 'Classement',
        rosters: "Cahier d'équipe",
        scheduling: 'Horaires',
        conflict_enforcement: 'Application des conflits',
        public: 'Affichage publique',
        admin: 'Administrateur',
        integration: 'Intégration',
        game_length: 'Durée de match par défaut',
        teamScorekeeping: "Entrée des résultats",
        certification: 'Certification',
        suspensions: 'Suspensions',
        scorekeeping: 'Entrée des résultats',
        periods: 'Périodes par défaut',
        drafts: 'Brouillons',
        points_system: "Système de points d'équipe",
        admin_reports: 'Rapports administratifs',
        team_reports: "Rapports d'équipe",
        edit_office: "Modifier les paramètres de l'organisation",
      },
      messages: {
        load_error: "Impossible de télécharger les paramètres de l'organisation",
      },
      alerts: {
        new_schedules_only: "Il s'agit des valeurs par défaut pour les nouveaux horaires créés. Les horaires existants ne seront pas affectés.",
        advanced: "Il s'agit de paramètres d'intégration avancés qui déterminent la manière dont la synchronisation est gérée.",
      },
      helpers: {
        adminReports: 'Affecte tous les rôles administratifs sauf Admin',
        teamReports: "Les officiels d'équipe ne verront que les rapports basés sur l'équipe",
        sportsmanshipPoints: 'Points supplémentaires basés sur les pénalités',
        statsVisibility: 'Ce paramètre détermine quels horaires auront leurs statistiques masquées sur les pages publiques',
        standingsVisibility: 'Ce paramètre détermine quels horaires auront leurs classements masquées sur les pages publiques',
        boxScoreVisibility: 'Ce paramètre détermine quels horaires auront leurs statistiques détaillées masquées sur les pages publiques',
        hiddenStats: "Masquer des colonnes de statistiques spécifiques sur les pages publiques",
        updateRosterFromLineups: "L'envoi de l'alignement mettra à jour les numéros de chandail dans le cahier d'équipe",
        gameLength: "Optionnel - Temps de réservation total (en minutes) sur la surface de jeu pour tous les matchs de cet horaire",
        surfaceOfficeId: "Optionnel - Les surfaces proviendront de cette organisation au lieu de l'organisation de l'équipe à domicile dans le planificateur de brouillon",
        conflictDrafts: 'Les matchs en brouillon sont exclus lors de la recherche de matchs contre lesquels appliquer, sauf si ce paramètre est activé',
        conflictLegacyDrafts: 'Les matchs en brouillon ancien sont exclus lors de la recherche de matchs contre lesquels appliquer, sauf si ce paramètre est activé',
        homeTeamScorekeeping: "Le personnel d'équipe à domicile pourront modifier la feuille de match jusqu'à l'homologation",
        awayTeamScorekeeping: "Le personnel d'équipe visiteur pourront modifier la feuille de match jusqu'à l'homologation",
        scoringMode: 'Comment les buts sont entrés pour un match',
        penaltyMode: 'Comment les pénalités sont entrées pour un match',
        certification: "Les matchs devront être homologués par la ligue après l'entrée des résultats par les responsables de match",
        incidentReports: "Les matchs nécessiteront des rapports d'incident pour toutes les pénalités majeures avant d'être terminés",
        suspensionActivation: "Quand est-ce qu'une suspension doit devenir automatiquement active?",
        includeRegistrations: "Les participants inscrits en fonction de l'association, de la division et de la saison de l'équipe seront inclus comme options d'alignement.",
        allowExtras: "Les marqueurs et les officiels d'équipe seront autorisés à ajouter des joueurs qui ne sont pas inscrits à leur alignement d'équipe. Ce paramètre n'affecte pas les administrateurs/gestionnaires de la ligue.",
        homeChangeStatus: "Autorise l'organisation de l'équipe locale à reprogrammé les matchs",
        language: 'Langue du contact par défaut pour les notifications',
        deferDraftConflict: "Les conflits ne seront appliqués dans les brouillons qu'une fois publiés",
        deferLegacyDraftConflict: "Les conflits ne seront appliqués dans les brouillons anciens qu'une fois publiés",
        limitDateChange: 'Les associations ne pourront pas changer la date sur les matchs en brouillon partagés',
        rescheduleRequestsEnabled: "Permet aux équipes de demander des changements d'horaire",
        requiredRescheduleApprovals: "Intervenants requis pour approuver les demandes de changement d'horaire",
        required_approvals: {
          'Team Staff': "Nécessite l'approbation des deux équipes",
          'Team Office': "Nécessite l'approbation des deux organisations d'équipe",
          'Schedule Office': "Nécessite l'approbation de l'organisation de l'horaire",
        },
        groupingType: 'Le type de regroupement utilisé pour les équipes dans les horaires nouvellement créés'
      },
      tooltips: {
        groupingType: `
          <div>
            <ul>
              <li>
                <strong>Groupes:</strong> Également connus sous les noms de "Flights" ou "Tiers". Ils permettent de diviser les horaires en plusieurs sous-horaires au sein d'une ligue ou d'une catégorie pour regrouper les équipes selon leur niveau de compétence. Les groupes sont définis au niveau de l'organisation et peuvent être associés à des roles. Les groupes sont présents sur les matchs et les équipes de l'horaire, et divisent l'affichage des matchs et des statistiques de l'horaire.
              </li>
              <li>
                <strong>Pools:</strong> Regroupent les équipes pour les besoins de "Round-Robin" directement dans les horaires. Les pools existent uniquement pour les équipes de l'horaire et sont utilisées pour la planification des horaires et l'affichage des classements.
              </li>
            </ul>
          </div>
        `
      },
      values: {
        sportsmanship_points: {
          none: 'Aucun',
          PCAHA: 'Règlement PCAHA',
          VIAHA: 'Règlement VIAHA',
        },
        sync_attributes: {
          position: 'Position seulement',
          all: 'Position et numéros de chandail',
        },
        contacts: {
          profiles: 'Profils seulement',
          schedules: 'Horaires et profils',
          teams: 'Équipes, horaires et profils',
        },
        reports: {
          penalty: 'Pénalité',
          scoring: 'Pointage',
          ap: 'Joueur affilié',
        },
        stats: {
          name: 'Statistiques',
          skater: 'Statistiques des joueurs',
          goalie: 'Statistiques des gardiens',
        },
        enforcement: {
          none: 'Ne rien faire',
          conflict: 'Enregistrer en tant que conflit',
          validation: 'Afficher une erreur de validation',
        },
        suspension_activation: {
          manual: 'Manuel',
          approval: 'Après match approuvé',
          certification: 'Après match certifié',
        },
        scoring_mode: {
          detailed: 'Détaillé',
          score: 'Pointage seulement',
        },
        penalty_mode: {
          detailed: 'Détaillé',
        },
        required_approvals: {
          'Team Staff': "Personnel d'équipe",
          'Team Office': "Organisation d'équipe",
          'Schedule Office': "Organisation de l'horaire",
        },
        groupingType: {
          Pools: 'Pools',
          Groups: 'Groupes',
        }
      }
    },
    leagues: {
      name: 'Ligue |||| Ligues',
      fields: {
        officeId: 'Ligue',
        name: 'Nom',
        type: 'Type',
        HCRId: 'Numéro de ligue',
        parentId: 'Organisation',
        logo: 'Logo',
      },
      actions: {
        add: 'Ajouter une ligue',
        edit: 'Modifier la ligue',
      },
      labels: {
        card: {
          title: 'Ligues',
        },
        tabs: {
          summary: 'Sommaire',
          teams: 'Équipes',
          surfaces: 'Surfaces',
          settings: 'Paramètres',
        },
        add: 'Nouvelle ligue',
        edit: 'Mettre à jour la ligue',
        editing: 'Modification de la ligue %{name}',
        availability: {
          card: {
            title: 'Disponibilité',
            subtitle: "Sélectionnez les dates et les heures disponibles, ou bloquez les heures non disponibles.",
          },
          title: "Disponibilités de la ligue",
          note: 'Note de disponibilité',
          updated: 'Disponibilité mise à jour!',
        }
      },
      messages: {
        availability: {
          available: 'Cette ligue %{input} disponible',
        }
      },
      helpers: {
        name: 'Les ligues ne doivent généralement pas contenir la division.',
        availability: {
          note: "Cette note sera visible lorsqu'une partie est déjà prévue dans cette plage horaire.",
        }
      },
    },
    tournaments: {
      name: 'Tournoi |||| Tournois',
      fields: {
        officeId: 'Tournoi',
        name: 'Nom',
        type: 'Type',
        HCRId: 'Numéro de tournoi',
        parentId: 'Organisation',
        logo: 'Logo',
      },
      actions: {
        add: 'Ajouter un tournoi',
        edit: 'Modifier le tournoi',
      },
      labels: {
        card: {
          title: 'Tournois',
        },
        tabs: {
          summary: 'Sommaire',
          teams: 'Équipes',
          surfaces: 'Surfaces',
          settings: 'Paramètres',
        },
        add: 'Nouveau tournoi',
        edit: 'Mettre à jour le tournoi',
        editing: 'Modification du tournoi %{name}',
        availability: {
          card: {
            title: 'Disponibilité',
            subtitle: "Sélectionnez les dates et les heures disponibles, ou bloquez les heures non disponibles.",
          },
          title: "Disponibilités du tournoi",
          note: 'Note de disponibilité',
          updated: 'Disponibilité mise à jour!',
        }
      },
      messages: {
        availability: {
          available: 'Ce tournoi %{input} disponible',
        }
      },
      helpers: {
        name: 'Les tournois ne doivent généralement pas contenir la division.',
        availability: {
          note: "Cette note sera visible lorsqu'une partie est déjà prévue dans cette plage horaire.",
        }
      },
    },
    cups: {
      name: 'Coupe |||| Coupes',
      fields: {
        officeId: 'Coupe',
        name: 'Nom',
        type: 'Type',
        HCRId: 'Numéro de coupe',
        parentId: 'Organisation',
        logo: 'Logo',
      },
      actions: {
        add: 'Ajouter une coupe',
        edit: 'Modifier la coupe',
      },
      labels: {
        card: {
          title: 'Coupes',
        },
        tabs: {
          summary: 'Sommaire',
          teams: 'Équipes',
          surfaces: 'Surfaces',
          settings: 'Paramètres',
        },
        add: 'Nouveau coupe',
        edit: 'Mettre à jour la coupe',
        editing: 'Modification de la coupe %{name}',
        availability: {
          card: {
            title: 'Disponibilité',
            subtitle: "Sélectionnez les dates et les heures disponibles, ou bloquez les heures non disponibles.",
          },
          title: "Disponibilités de la coupe",
          note: 'Note de disponibilité',
          updated: 'Disponibilité mise à jour!',
        }
      },
      messages: {
        availability: {
          available: 'Cette coupe %{input} disponible',
        }
      },
      helpers: {
        name: 'Les coupes ne doivent généralement pas contenir la division.',
        availability: {
          note: "Cette note sera visible lorsqu'une partie est déjà prévue dans cette plage horaire.",
        }
      },
    },
    members: {
      name: 'Membre |||| Membres',
      fields: {
        participantId: 'Participant',
        teamId: 'Équipe',
        positions: 'Positions',
        number: 'Numéro',
        batter: 'Frappeur',
        extra: 'Extra',
        isCaptain: 'Capitaine',
        isAlternate: 'Remplaçant',
        isAffiliate: 'Affilié',
        isOverage: 'Sous-classé',
        isUnderage: 'Surclassé',
        isReleased: 'Libéré',
        isStarter: 'Partant',
        status: 'Statut',
        abbreviations: {
          isCaptain: 'C',
          isAlternate: 'A',
          isAffiliate: 'JA',
          isOverage: 'SuC',
          isUnderage: 'SoC',
          isReleased: 'L',
          isStarter: 'P',
        }
      },
      actions: {
        add: 'Ajouter un membre',
      },
      labels: {
        edit: 'Mettre à jour le membre',
        starter_goalie: 'Gardien de but partant',
        suspension: 'Suspension',
        suspension_abbreviation: 'SUS',
        suspended: 'Suspendu',
        position: 'Position',
        jersey: 'Chandail',
        battingOrder: 'Ordre de frappe',
        current_game: 'Match en cours',
        total_games: 'Matchs total',
        player: 'Joueur',
        staff: 'Personnel',
      },
      messages: {
        has_been_added: '%{name} a été ajouté',
      },
      helpers: {
        isCaptain: "Capitaine d'équipe",
        isAlternate: "Capitaine suppléant de l'équipe",
      },
      validations: {
        must_have_number: 'Les joueurs doivent avoir un numéro',
        missing_jersey_number: 'Numéro de chandail manquant',
      },
      values: {
        positions: {
          F: 'A',
          C: 'C',
          D: 'D',
          G: 'G',
          Manager: 'Gérant',
          'Head Coach': "Entraîneur-chef",
          'Assistant Coach': "Entraîneur-adjoint",
          'Goaltending Coach': 'Entraîneur des gardiens',
          'Safety Person': 'Soigneur',
          Trainer: "Préposé à l'équipement",
        }
      }
    },
    schedules: {
      name: 'Horaire |||| Horaires',
      fields: {
        name: 'Nom',
        seasonId: 'Saison',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        teamIds: 'Équipes',
        officeId: 'Organisation',
        categoryId: 'Catégorie',
        type: 'Type',
        isPublic: 'Public',
        scheduleType: "Type d'horaire",
        pastEvents: 'Ancien horaire',
        gameFormat: 'Format',
        comments: 'Commentaires',
        brackets: {
          totalTeams: "Nombre d'équipes",
          format: 'Format',
          orientation: 'Orientation',
          bracketId: 'Bracket',
          groupId: 'Groupe',
        },
      },
      filters: {
        pastEvents: 'Afficher les horaires passés',
      },
      actions: {
        add: 'Ajouter un horaire',
        edit: "Modifier l'horaire",
        show: "Afficher l'horaire",
        internal: 'Actions internes',
        recalculateStats: 'Recalculer les statistiques',
      },
      labels: {
        card: {
          title: 'Horaires',
        },
        tabs: {
          summary: 'Sommaire',
          teams: 'Équipes',
          games: 'Matchs',
          bracket: 'Bracket',
          scheduler: 'Céduleur',
          stats: 'Statistiques',
          standings: 'Classements',
          contacts: 'Contacts',
          settings: 'Paramètres',
        },
        steps: {
          schedule_info: "Informations de l'horaire",
          team_select: 'Sélectionnez des équipes',
          add_sequences: 'Ajouter des séquences de numéros de match',
          schedule_settings: "Paramètres de l'horaire"
        },
        matrix: {
          show_organisation: 'Afficher les organisations',
          show_full_names: 'Afficher les noms complets',
          compact_mode: 'Affichage réduit',
        },
        brackets_viewer: {
          open_in_viewer: "Ouvrir l'aperçu | Télécharger",
          open_new_window: 'Ouvrir dans une nouvelle fenêtre',
          download: 'Télécharger',
          close: 'Fermer',
          zoom_in: 'Zoom avant',
          zoom_out: 'Zoom arrière',
          no_preview: {
            text: 'Aucun aperçu disponible',
            no_data: 'Aucune donnée',
          }
        },
        add: 'Nouveau horaire',
        generate: 'Générer un horaire',
        generate_legacy: 'Générer un horaire (ancien)',
        upload: 'Importer un horaire',
        upload_legacy: 'Importer un horaire (ancien)',
        draft: "Ébaucher un horaire",
        draft_legacy: "Ébaucher un horaire (ancien)",
        league_contacts: 'Coordonnées de la ligue',
        dates: 'Dates',
        competitive: 'Compétitif',
        recreational: 'Récréatif',
        accredited_school: 'École accréditée',
        add_to_group: 'Ajouter au groupe',
        add_to_pool: 'Ajouter au pool',
        bracket_schedule: 'Générer une bracket',
        bracket_reseed: 'Remplir une bracket',
        bracket_reseed_info: "Ajouter ou modifier les équipes sur les matchs avec des positions attribuées en fonction de leur ordre dans le groupe",
        group_bracket_reseed: "Groupe contenant la bracket à remplir",
        reseed_bracket: "Remplissage d'une bracket",
        bracket_info: "Créer des matchs a l'aide d'une bracket",
        create_bracket: "Génération d'une bracket",
        brackets_info: 'Au fur et à mesure que les matchs sont terminés, les équipes passeront automatiquement aux matchs suivants en fonction des résultats.',
        brackets_na: 'Aucun modèle de bracket correspondant',
        preview: 'Aperçu',
        analysis: 'Analyse',
        matchups: 'Affrontements',
        current_matchups: 'Affrontements actuels',
        new_matchups: 'Affrontements des brouillons',
        office_matchups: 'Affrontements par organisation',
        include_drafts: 'Inclure les brouillons',
        byes: 'Byes',
        team: 'Équipe',
        group: 'Groupe',
        pool: 'Pool',
        opponents: 'Adversaires',
        conflicts: 'Conflits',
        home: 'Local',
        away: 'Visiteur',
        total: 'Total',
        week: 'Semaine',
        scheduled_events: "Horaire complet",
        not_public: 'Non public'
      },
      messages: {
        no_byes: 'Pas de byes',
        no_matchups: "Pas d'affrontements",
        too_many_matchups: `Trop de d'affrontements à afficher`,
        unable_to_load: "Impossible de charger l'analyse",
        additional_games: '+ %{smart_count} autres'
      },
      notifications: {
        created: "L'horaire a été créé",
        updated: "L'horaire a été mis à jour",
        recalculating: 'Recalculer...',
        bracket_games_created: 'Matchs de bracket créés',
        bracket_games_reseeded: 'Les matchs de bracket ont été remplis',
        before_schedule_date: "Impossible de programmer un match avant la date de début de l'horaire (%{startDate})",
        after_schedule_date: "Impossible de programmer un match avant la date de fin de l'horaire (%{endDate})"
      },
      alerts: {
        missing_sequence: "La séquence de numéros de match n'a pas été définie pour cet horaire",
      },
      tooltips: {
        hidden_to_public: "Cet horaire n'est pas visible par le public",
      },
      helpers: {
        cohorts: 'Les cohortes peuvent être créées en tant que groupes sous les paramètres de la ligue pour que tout reste dans le même horaire.',
        gameFormat: 'Optionnel',
        isPublic: "En activant cette option, l'horaire sera visible sur Spordle PAGE",
      },
      validations: {
        end_after_start: 'La date de fin doit être après la date de début',
      },
      errors: {
        brackets: {
          existing_brackets: 'Ce groupe a déjà des brackets',
          missing_seeded_games: 'Aucun match avec des positions attribuées trouvé',
          missing_schedule_teams: 'Aucune équipe trouvé dans ce groupe pour le remplissage',
          missing_sequence: 'Aucune séquence trouvée pour la numérotation des matchs',
        },
      },
      values: {
        type: TYPES,
        orientations: {
          Portrait: 'Portrait',
          Landscape: 'Paysage',
        },
        gameFormat: {
          '3x3': '3x3',
          '4x4': '4x4',
          '5x5': '5x5',
          '6x6': '6x6',
          '7x7': '7x7',
          '9x9': '9x9',
          '11x11': '11x11',
        }
      },
    },
    scheduleSettings: {
      name: 'Paramètre |||| Paramètres',
      fields: {
        gameLength: 'Temps de réservation de la surface de jeu',
        surfaceOfficeId: "L'organisation des surfaces",
        surfaceSizes: 'Taille des surfaces',
        includeRegistrations: 'Inclure les inscriptions',
        allowExtras: 'Autoriser les participants supplémentaires',
        sportsmanshipPoints: "Points d'esprit sportif",
        disableSportsmanshipCarry: "Limiter les points d'esprit sportif au même groupe",
        maxScoreDiff: 'Différentiel Maximum',
        scoringMode: "Mode d'entrée des buts",
        penaltyMode: "Mode d'entrée des pénalités",
        points: {
          Team: {
            win: 'Victoire',
            loss: 'Défaite ',
            tie: 'Nulle',
            soWin: 'Victoire en fusillade',
            soLoss: 'Défaite en fusillade',
            otWin: 'Victoire en prolongation',
            otLoss: 'Défaite en prolongation',
            forfeitWin: 'Victoire par forfait',
            forfeitLoss: 'Défaite par forfait',
            forfeitTie: 'Double Forfait',
            sportsmanship: 'Esprit sportif',
          },
          Player: {
            goal: 'But',
            assist: 'Passe'
          },
          preview: `Victoire: %{win} Défaite: %{loss} Nulle: %{tie}`
        },
        groupingType: "Type de regroupement d'équipes",
      },
      actions: {
        edit: 'Modifier les paramètres',
      },
      labels: {
        tabs: {
          scheduling: 'Horaires',
          scoresheet: 'Feuille de match',
          team_stats: "Statistiques d'équipe",
          player_stats: 'Statistiques de joueur',
          advanced: 'Avancé',
        },
        schedule_stats: 'Statistiques de calendrier',
        game_stats: 'Statistiques de match',
        stats_schema: {
          team: "Schéma des statistiques d'équipe",
          skater: 'Schéma des statistiques de joueur',
          goalie: 'Schéma des statistiques de gardiens de but',
        },
        standings_order: {
          team: 'Ordre du classement des équipes',
          skater: 'Ordre du classement des joueurs',
          goalie: 'Ordre du classement des gardiens de but',
        },
        points_system_ranking: {
          property: 'Attribut',
          order: 'Ordre',
          type: 'Type',
        },
        hidden_stats: {
          team: 'Statistiques cachées des équipes',
          skater: 'Statistiques cachées des joueurs',
          goalie: 'Statistiques cachées des gardiens de but',
        },
        points: 'Points',
        edit_schedule: "Modifier les paramètres d'horaire",
        game_length: 'Durée de match par défaut',
        include_registrations: 'Inscriptions',
        allow_extras: 'Autoriser les participants supplémentaires',
        points_system: "Système de points d'équipe",
        standings: 'Classement',
        scheduling: 'Horaires',
        lineups: 'Alignements',
        public: 'Affichage publique',
        scorekeeping: 'Entrée des résultats'
      },
      messages: {
        load_error: "Impossible de télécharger les paramètres d'horaire",
        included: 'Inclus',
        allow_extras_default: "Administrateurs de ligue et officiels d'équipe",
        standard_description: 'V = 2, D = 1, DP = 1',
        '3-2-1_description': 'Point supplémentaire attribué pour les victoires pendant le temps réglementaire ou forfait',
        high_low: 'De haut en bas',
        low_high: 'De bas en haut',
      },
      alerts: {
        advanced: "Il s'agit de paramètres d'intégration avancés qui déterminent la manière dont la synchronisation est gérée.",
        new_scoresheets_only: "Il s'agit des valeurs par défaut pour les nouvelles feuilles de match créés. Les feuilles de match existants ne seront pas affectées.",
      },
      validations: {
        game_length: 'Doit être supérieur à 0',
        scoring_mode_change: "Impossible de changer le mode d'entrée des buts tant qu'il y a des matchs approuvées",
        penalty_mode_change: "Impossible de changer le mode d'entrée des pénalités tant qu'il y a des matchs approuvées"
      },
      helpers: {
        gameLength: "Optionnel - Durée du début à la fin de l'heure planifiée en minutes",
        surfaceOfficeId: "Optionnel - Les surfaces proviendront de cette organisation au lieu de l'organisation de l'équipe à domicile dans le planificateur de brouillon",
        surfaceSizes: "Optionnel - Détermine l'ensemble par défaut de tailles filtrées lors de la planification d'horaire",
        includeRegistrations: "Les participants inscrits en fonction de l'association, de la division et de la saison de l'équipe seront inclus comme options d'alignement.",
        allowExtras: "Les marqueurs et les officiels d'équipe seront autorisés à ajouter des joueurs qui ne sont pas inscrits à leur alignement d'équipe. Ce paramètre n'affecte pas les administrateurs/gestionnaires de la ligue.",
        sportsmanshipPoints: 'Points supplémentaires basés sur les infractions aux pénalités',
        disableSportsmanshipCarry: "Lorsqu'une équipe est déplacée d'un groupe à un autre, selon le cas, des points d'esprit sportif seront accumulés pour tous les groupes, sauf si ce paramètre est activé.",
        schema: 'Schéma requis',
        statsVisibility: 'Ce paramètre détermine quels horaires auront leurs statistiques masquées sur les pages publiques',
        scoringMode: 'Comment les buts sont entrés pour un match',
        penaltyMode: 'Comment les penalités sont entrés pour un match',
        groupingType: 'Le type de regroupement utilisé pour les équipes dans les horaires nouvellement créés'
      },
      values: {
        includeRegistrations: 'Participants inscrits et participants supplémentaires',
        allowExtras: 'Administrateurs de ligue uniquement',
        disableSportsmanshipCarry: "Les points d'esprit sportif sont limités au même groupe",
        ranking_type: {
          'All Games': 'Tous les matchs',
          'Head-to-head': 'Tête à tête'
        },
        ranking_order: {
          DESC: 'Descendant',
          ASC: 'Ascendant',
        },
      }
    },
    schedulingConstraints: {
      fields: {
        resource: 'Ressource',
        periodType: 'Type de période',
        limit: 'Limite',
        categoryIds: "Catégories",
        scheduleTypes: "Type d'horaire",
        period: {
          days: 'Jours',
          weekdays: 'Jours de la semaine',
          dateRange: 'Plage de dates',
          startDate: 'Date de début',
          endDate: 'Date de fin'
        }
      },
      values: {
        scheduleTypes: TYPES,
        Team: 'Équipe',
        Schedule: 'Horaire',
        Arena: 'Lieu'
      },
      actions: {
        add_constraint: 'Ajouter une contrainte',
        edit_constraint: 'Modifier la contrainte'
      },
      labels: {
        card: {
          title: 'Contraintes',
          subtitle: 'Définissez les contraintes pour limiter le nombre de matchs joués. Cela marquera les matchs comme des conflits si les contraintes ne sont pas suivies.'
        },
        restrictions: 'Restrictions',
        period: 'Période'
      },
      helpers: {
        schedules: 'Optionnel - Si vide, tous les horaires',
        limit: `Dépasse %{smart_count} limite de match |||| Dépasse la limite de %{smart_count} matchs`,
        period: `le même jour |||| dans un délai de %{smart_count} jours.`

      },
      messages: {
        summary: `Les %{resource}s de cette organisation ne peuvent pas %{verb} plus de %{smart_count} match |||| Les %{resource}s de cette organisation ne peuvent pas %{verb} plus de %{smart_count} matchs`,
        days: `le même jour. |||| dans un délai de %{smart_count} jours.`,
        host: 'héberger',
        play: 'jouer',
        have: 'avoir',
        between: ' entre %{start} - %{end}.',
        on: ' au %{weekdays}.'
      },
      validations: {
        end_after_start: 'La date de fin doit être après la date de début',
      },
    },
    teams: {
      name: 'Équipe |||| Équipes',
      fields: {
        teamId: 'Équipe',
        name: 'Nom',
        shortName: 'Nom court',
        HCRId: "Numéro d'équipe",
        seasonId: 'Saison',
        categoryId: 'Catégorie',
        officeId: 'Organisation',
        logo: 'Logo',
        arenaId: 'Surface',
      },
      actions: {
        add: 'Ajouter une équipe',
        edit: "Modifier l'équipe",
        internal: 'Actions internes',
        sync: 'Sync',
      },
      labels: {
        card: {
          title: 'Équipes',
        },
        calendar: {
          teamNames: "Noms d'équipe"
        },
        tabs: {
          summary: 'Sommaire',
          roster: "Cahier d'équipe",
          schedule: 'Horaire',
          settings: 'Paramètres',
        },
        edit: "Mettre à jour l'équipe",
        add: 'Nouvelle équipe',
        contacts: "Coordonnées de l'équipe",
        team_roster: "Cahier d'équipe",
        import_roster: "Importer un cahier d'équipe",
        import_profiles: "Importer des profils",
        roster_file: "Fichier de cahier d'équipe",
        profile_file: 'Fichier de profil',
        availability: {
          card: {
            title: 'Disponibilité',
            subtitle: "Sélectionnez les dates et les heures disponibles, ou bloquez les heures non disponibles.",
          },
          title: "Disponibilités de l'équipe",
          note: 'Note de disponibilité',
          updated: 'Disponibilité mise à jour!',
        },
      },
      messages: {
        home_team_hcr_id: "Numéro de l'équipe local",
        valid_members: '%{number}/%{total} lignes ont des membres valides',
        drop_roster_file: "Déposez votre fichier de cahier d'équipe (.csv) ici",
        drop_profile_file: 'Déposez votre fichier de profil (.csv) ici',
        import_tool_roster: "Pour utiliser cet outil d'importation, téléchargez un fichier CSV avec des colonnes pour le numéro de membre du participant, son prénom, son nom, sa position et son numéro de chandail.",
        import_tool_profile: "Pour utiliser cet outil d'importation, téléchargez un fichier CSV avec des colonnes pour le numéro de membre unique du participant, la date de naissance, la hauteur (ft), le poids (lbs), la ville natale et la dextérité.",
        import_tool_warning: "Cet outil d'importation créera de nouveaux participants qui ne correspondent pas aux numéros de membre existants.",
        availability: {
          available: 'Cette équipe %{input} disponible',
        },
        no_teams: 'Aucune équipe sélectionnée'
      },
      helpers: {
        select_columns: "On vous demandera de sélectionner des colonnes après avoir sélectionné votre fichier",
        valid_profiles: '%{number}/%{total} les lignes ont des profils valides',
        participant_not_matched: "Les participants seront ignorés s'ils ne sont pas ajoutés à cette équipe.",
        match_existing_participant: 'Ce numéro de membre est utilisé pour correspondre à un participant existant ou créer un nouveau participant pour cette liste.',
        availability: {
          note: "Cette note sera visible lorsqu'une partie est déjà prévue dans cette plage horaire.",
        }
      },
      alerts: {
        caution: 'Utiliser avec précaution',
      },
      notifications: {
        import_roster_success: "Cahier d'équipe importé avec succès. Actualisez pour voir les modifications.",
        import_roster_error: "Impossible d'importer votre cahier d'équipe",
        import_profiles_error: "Impossible d'importer vos profils",
        profiles_updated: '%{smart_count} profil a été mis à jour. |||| %{smart_count} profils ont été mis à jour.',
      },
      values: {
        types: {
          home: 'Équipe à domicile',
          away: 'Équipe visiteur',
        }
      }
    },
    teamArenas: {
      name: "Surface à domicile |||| Surfaces à domicile",
      fields: {
        order: 'Ordre',
        arenaId: 'Surface',
        slot: 'Plage horaire',
        day: 'Jour',
        startTime: 'Heure de début',
        endTime: 'Heure de fin',
      },
      actions: {
        edit: "Modifier les surfaces à domicile",
      },
      labels: {
        card: {
          title: 'Surfaces à domicile',
          subtitle: "Les surfaces à domicile de l'équipe seront utilisées lors de la création d'un horaire."
        },
        not_set: 'Pas encore défini',
      },
      messages: {
        load_error: "Impossible de télécharger les paramètres de l'équipe",
      },
      validations: {
        end_before_start: "L'heure de fin doit être après l'heure de début",
        invalid_time_check_am_pm: 'Heure non valide - vérifier AM/PM',
      },
      values: {
        order: {
          '1': 'Principal'
        }
      }
    },
    scheduleteams: {
      name: 'Équipe |||| Équipes',
      fields: {
        scheduleId: 'Horaire',
        dates: 'Dates',
        groupId: 'Groupe',
        officeId: 'Organisation',
        teamId: 'Équipe',
        teamIds: 'Équipes',
        type: 'Type',
        categoryId: 'Catégorie',
        order: 'Ordre'
      },
      actions: {
        add: 'Ajouter des équipes',
        edit: "Modifier l'ordre",
      },
      labels: {
        card: {
          title: 'Horaires & Coordonnées',
        },
        edit: "Mettre à jour l'équipe",
        coach: 'Entraîneur',
        manager: 'Gérant',
        rank: 'Rang',
        import_teams: "Sélectionnez des équipes",
        replace_existing_teams: 'Remplacer les équipes existantes'
      },
      messages: {
        replace_existing_teams: "Vous avez sélectionné plus d'équipes que le nombre total d'équipes que vous avez configurées. Voulez-vous remplacer les équipes déjà sélectionnées dans votre brouillon?",
        team_order: "L'ordre des équipes sera utilisé pour générer les matchs et n'aura aucun impact sur les statistiques.",
        synced_by_id: 'Cet horaire est synchronisée via Spordle ID. Pour ajouter de nouvelles équipes, approuvez-les par Spordle ID.'
      },
    },
    schedulesequences: {
      name: 'Séquence de numéros de match |||| Séquences de numéros de match',
      fields: {
        scheduleId: 'Horaire',
        groupId: 'Groupe',
        prefix: 'Préfixe',
        start: 'Numéro de départ',
        suffix: 'Suffixe',
        current: 'Actuel',
        max: 'Numéro de fin',
      },
      labels: {
        starting_sequence: 'Séquence de départ',
        edit: "Mettre à jour la séquence de numéros de match",
        add: 'Ajouter une séquence de numéros de match',
      },
      actions: {
        add: 'Ajouter une séquence',
        go_to: 'Aller à la séquence',
      },
      messages: {
        starting_sequence: 'Séquence de départ: %{sequence}'
      },
      alerts: {
        sequence_in_use: 'Cette séquence de numéros de match est actuellement utilisée et ne peut pas être modifiée',
        sequence_info: 'Les séquences de numéros de match sont utilisées pour numéroter automatiquement les matchs au fur et à mesure de leur création et lors de la publication des brouillons.',
      },
      validations: {
        too_many_characters: 'Trop de caractères',
        cannot_contain_consecutive_dashes: 'Ne peut contenir des tirets consécutifs',
        cannot_contain_special_characters: 'Ne peut contenir des caractères spéciaux ou des espaces',
        must_contain_a_number: 'Doit contenir un nombre',
        sequence_in_use: "Impossible de modifier une séquence de numéros de match en cours d'utilisation",
        delete_scheduleteam: "Impossible de supprimer une équipe inscrite dans des matchs ou des matchs brouillons",
        delete_sequence_in_use: "Impossible de supprimer une séquence en cours d'utilisation",
        unique_prefix_suffix: "Ne peut avoir qu'un seul modèle de préfixe/suffixe de séquence par horaire",
        prefix_cant_end_in_number: 'Le préfixe ne peut pas se terminer par un chiffre',
        greater_than_current: 'Doit être supérieur au nombre actuel',
        schedule_has_sequence: 'Cet horaire a déjà une séquence de numéros de match',
        group_has_sequence: 'Ce groupe a déjà une séquence de numéros de match',
        sequence_overlaps: 'Cette séquence de numéros de match chevauche une plage de séquences existante',
        sequence_exhausted: 'La séquence utilisée pour générer le numéro de match est épuisée',
        excessive_pad_length: 'Le numéro de fin dépasse le format du numéro de départ',
      }
    },
    arenaoffices: {
      name: 'Organisation |||| Organisations',
      fields: {
        arenaId: 'Surface',
        officeId: 'Organisation',
        isPrimary: 'Primaire',
        leagueIds: 'Ligues',
        divisionIds: 'Divisions'
      },
      labels: {
        restrictions: 'Restrictions',
      },
    },
    arenaslots: {
      name: 'Plage horaire |||| Plages horaires',
      fields: {
        name: 'Nom',
        arenaId: 'Surface',
        startTime: 'Date et heure de début',
        endTime: 'Date et heure de fin',
        categoryIds: 'Catégories',
        officeId: 'Organisation',
        comments: 'Commentaires',
        types: 'Types',
        leagueIds: 'Ligues',
        isShared: 'Partager cette plage avec toutes les organisations'
      },
      values: {
        types: {
          Practice: 'Pratique',
          ...TYPES,
        },
      },
      labels: {
        card: {
          title: 'Plages Horaires',
          subtitle: 'Enregistrez vos réservations de surface pour voir vos plages disponible',
          game_slots: 'Plages horaires des matchs',
          practice_slots: 'Plages horaires des pratiques',
        },
        tooltips: {
          slot_replace: `Cette plage horaire chevauche une plage horaire existante:
          %{timeRange1} |||| Cette plage horaire chevauche des plages horaire existantes:
          %{timeRange1}
          %{timeRange2}`,
        },
        restrictions: 'Restrictions',
        recurrence: 'Récurrence',
        import_slots: 'Importer des plages horaires',
        slots_file: 'Fichier de plages horaires',
        slot_splits: 'Séparations de plages horaires',
        slot_duration: 'Durées des plages horaires',
        break_duration: 'Pauses des plages horaires',
        edit: 'Mettre à jour la plage horaire',
        frequency: 'Fréquence',
        interval: 'Intervalle',
        weekdays: 'Jours de la semaine',
        end_date: 'Date de fin',
        delete_slots: 'Supprimer la plage horaire |||| Supprimer les plages horaires',
        slots_restrictions: 'Restrictions des plages horaires',
        set_slots_restrictions: "Modifier les restrictions",
        restricted: 'Restrictions',
        Type: 'Type',
        League: 'Ligue',
        Category: 'Catégorie'
      },
      messages: {
        drop_slots_csv: 'Déposez votre fichier de plages horaires (.csv) ici',
        import_tool_slots: "Pour utiliser cet outil d'importation, téléchargez un fichier CSV avec des colonnes pour l'heure de début et l'heure de fin de la plage horaire.",
        import_slot_warning: 'Les lignes qui chevauchent des plages horaires existantes ne seront pas importées.',
        schedule_no_occurences: "Les paramètres de récurrence spécifiés n'entraînent aucune plage horaire",
        event_repeat: 'Ces paramètres de récurrence générera %{smart_count} journée |||| Ces paramètres de récurrence générera %{smart_count} journées',
        slot_splits: 'Cela générera une plage horaire le %{date} entre %{startTime} et %{endTime} |||| Cela générera %{smart_count} plages horaires le %{date} entre %{startTime} et %{endTime}.',
        additional_instances: '... %{smart_count} plage horaire additionnelle ... |||| ... %{smart_count} plages horaires additionnelles ...',
        confirm_delete: 'Voulez-vous vraiment supprimer cette plage horaire? |||| Voulez-vous vraiment supprimer ces %{smart_count} plages horaires?',
        available_slots: '%{smart_count} plage disponible |||| %{smart_count} plages disponibles',
        slots: '%{smart_count} plage |||| %{smart_count} plages',
        home_available_slots: '%{smart_count} plage locale |||| %{smart_count} plages locales',
        away_available_slots: '%{smart_count} plage visiteuse |||| %{smart_count} plages visiteuses'
      },
      helpers: {
        categoryIds: 'Optionnel - Si vide, toutes les catégories',
        types: 'Optionnel - Si vide, toutes les types',
        leagueIds: 'Optionnel - Si vide, toutes les ligues',
      },
      notifications: {
        import_slots_success: '%{number} plage horaire importée. |||| %{number} plages horaires ont été importées.',
        import_slots_error: "Impossible d'importer les plages horaires.",
        delete_slots_success: '%{smart_count} plage horaire supprimée |||| %{smart_count} plages horaires ont été supprimées',
        delete_slots_error: "Une erreur s'est produite lors de la suppression de la plage horaire |||| Une erreur s'est produite lors de la suppression des plages horaires",
        update_restrictions_success: 'Mise à jour des restrictions pour %{smart_count} plage horaire |||| Mise à jour des restrictions pour %{smart_count} plages horaires',
        update_restrictions_error: "Une erreur s'est produite lors de la mise à jour des restrictions de la plage horaire |||| Une erreur s'est produite lors de la mise à jour des restrictions des plages horaires",
      },
      actions: {
        add: 'Ajouter des plages horaires',
      },
      validations: {
        import_slots_overlaps: 'Certaines lignes contiennent des heures qui se chevauchent',
        overlaps: 'Le temps choisi chevauche une plage horaire existante',
        import_slots_end_before_start: 'Certaines lignes contiennent une heure de fin avant une heure de début',
        end_before_start: "Une heure de fin doit être après l'heure de début",
        duration_exceeds_range: "La durée ne peut pas dépasser l'intervalle entre l'heure de début et l'heure de fin",
        total_duration: 'La durée totale entraîne un nombre inégal de plages horaires',
        include_start_day: 'Doit inclure %{day}'
      },
      alerts: {
        bulk_slots_restrictions: 'Cochez les restrictions que vous souhaitez modifier. Une restriction cocher sans valeur effacera la valeur actuelle.',
        overlaps_title: 'Chevauchements',
        overlaps: 'Cette période de temps présente certains chevauchements. Modifiez les heures de début et de fin et réessayez.'
      }
    },
    availabilities: {
      name: 'Disponibilité |||| Disponibilités',
      fields: {
        officeId: 'Organisation',
        isAvailable: 'Statut',
      },
      values: {
        isAvailable: {
          available: 'Disponible',
          notAvailable: 'Non disponible',
        }
      },
      labels: {
        available: 'Disponible',
        unavailable: 'Non disponible',
        conflicts: `%{smart_count} conflit |||| %{smart_count} conflits`,
        team_conflicts: "Conflits d'équipe",
        surface_conflicts: 'Conflits de surface',
        constraints: 'Contraintes',
        slots: 'Plages horaires',
        more: '+ %{smart_count} supplémentaire |||| + %{smart_count} supplémentaires'
      },
    },
    blockedSurfaces: {
      name: 'Bloqué par',
    },
    accounts: {
      name: 'Compte |||| Comptes',
      fields: {
        email: 'Adresse courriel',
        password: 'Mot de passe',
        participantId: 'Participant'
      },
      actions: {
        add: 'Ajouter un compte',
        sma_reset: 'Réinitialiser mot de passe',
      },
      labels: {
        card: {
          title: 'Comptes',
          subtitle: "Les participants peuvent être liés à un ou plusieurs comptes par des identités."
        },
        my: 'Mon Compte',
        edit: 'Mise à jour du compte',
      },
      messages: {
        enter_email: 'Entrez une adresse courriel pour lancer la recherche',
      },
      alerts: {
        enter_email_above: 'Entrez une adresse courriel ci-dessus pour lancer la recherche...',
        sma_reset_title: 'Réinitialisation de mot de passe Spordle My Account',
        sma_reset: "Le mot de passe temporaire donné est valable pendant 14 jours. Lorsque l'utilisateur se connecte avec le mot de passe temporaire, il lui est demandé de saisir un nouveau mot de passe. Ce nouveau mot de passe devient ensuite le mot de passe du compte.",
      },
      helpers: {
        password: 'Optionnel sauf pour les nouveaux comptes',
      },
      validations: {
        must_be_length: 'Doit être au moins %{length} caractères',
        must_have_number: 'Doit contenir au moins un chiffre',
        must_have_uppercase: 'Doit contenir au moins une lettre majuscule',
        must_have_lowercase: 'Doit contenir au moins une lettre minuscule',
        must_have_special: 'Doit contenir au moins un caractère spécial',
      },
      notifications: {
        sma_reset_success: 'Mot de passe réinitialisé avec succès',
        sma_reset_error: 'Échec de la réinitialisation du mot de passe',
      }
    },
    permissions: {
      name: 'Autorisation |||| Autorisations',
      fields: {
        principalType: 'Type de principal',
        principalId: 'Participant',
        roleId: 'Rôle',
        targetType: 'Type de ressource',
        targetId: 'Organisation / Horaire / Équipe',
        categoryIds: 'Catégories',
        groupIds: 'Groupes',
        expiry: 'Expiration',
        reportsDisabled: 'Désactiver les rapports',
        scorekeepingDisabled: 'Désactiver la modification des feuilles de match',
      },
      actions: {
        add: 'Ajouter un rôle',
      },
      labels: {
        card: {
          title: 'Rôles',
          subtitles: {
            office: "Les rôles de l'organisation accordent l'accès aux ressources dans et sous cette organisation. Vous pouvez restreindre davantage l'accès en créant des groupes.",
            schedule: "Les rôles pour les horaires donnent l'accès aux ressources liées à ces horaires uniquement. Vous pouvez restreindre davantage l'accès en créant des groupes.",
            team: "Les rôles d'équipe accordent l'accès à cette équipe uniquement.",
          }
        },
        edit: 'Mettre à jour le rôle',
        roles: 'Rôles',
        legacy: 'Rôles administratifs (ancien)',
        administrative: 'Rôles administratifs',
        expired: 'Expiré',
      },
      messages: {
        roles: 'Les rôles administratifs sont désormais gérés sur le participant.',
      },
      helpers: {
        principalId: "Il s'agit d'un ancien rôle - associez ce compte à une personne pour le convertir",
        categoryIds: 'Optionnel - Si vide, toutes les catégories',
        groupIds: 'Optionnel - Si vide, tous les groupes',
        expiry: "Optionnel - L'accès sera révoqué à compter de cette date",
      },
      values: {
        targetType: {
          Participant: 'Participant',
          Office: 'Organisation',
          Schedule: 'Horaire',
          Team: 'Équipe',
        },
        context: {
          Administrative: 'Administratif',
          League: 'Ligue',
          Officiating: 'Officiel',
        },
      },
      validations: {
        role_not_activated: "Ce rôle n'a pas été activé pour l'organisation sélectionné"
      }
    },
    profiles: {
      name: 'Profil |||| Profils',
      fields: {
        id: 'Participant',
        birthdate: 'Date de naissance',
        height: 'Hauteur (pi)',
        weight: 'Poids en livres',
        hometown: 'Ville natale',
        dexterity: 'Gaucher/Droitier',
        isPublic: 'Profil public',
      },
      actions: {
        edit: 'Editer le profil',
      },
      labels: {
        card: {
          title: 'Profil',
        },
        tabs: {
          summary: 'Mon profil',
          officiating: 'Disponibilité',
          league: 'Ligue',
          schedule: 'Horaire',
        },
        edit: 'Mettre à jour le profil',
        picture: 'Image',
        personal_calendar_feed: 'Flux de calendrier personnel',
      },
      messages: {
        description: 'Nom, date de naissance et plus',
      },
      alerts: {
        public_profile: 'Ce profil apparaîtra publiquement sur le site Web de la ligue où les statistiques sont affichées. Vous pouvez rendre ces informations privées en modifiant le profil.',
        private_profile: "Ce profil n'apparaîtra qu'aux administrateurs autorisés de la ligue et de l'association et n'apparaîtra pas sur le site Web de la ligue.",
      },
      helpers: {
        isPublic: "Optionnel - Autoriser l'affichage publique des informations de profil sur le site Web de la ligue",
      },
      validations: {
        invalid_birthdate: "Certaines valeurs n'incluent pas de date valide (YYYY-MM-DD)",
        invalid_height: 'Certaines lignes ont une hauteur non valide',
        invalid_weight: 'Certaines lignes ont un poids invalide',
        invalid_hometown: 'Certaines lignes ont une ville natale non valide',
        invalid_dexterity: 'Certaines lignes ont une latéralité non valide'
      },
      values: {
        dexterity: {
          Right: 'Droitier',
          Left: 'Gaucher',
        }
      }
    },
    contacts: {
      name: 'Coordonnées |||| Coordonnées',
      fields: {
        type: 'Type',
        value: 'Valeur',
        isPrimary: 'Primaire',
        isEmergency: "Urgence",
        isNotification: 'Recevoir des notifications',
        isVerified: 'Vérifié',
        notes: 'Notes',
        participantId: 'Personne',
        language: 'Langue'
      },
      actions: {
        add: 'Ajouter un contact',
      },
      labels: {
        card: {
          title: "Carnet d'adresses",
        },
        edit: 'Mettre à jour le contact',
        unverified: 'Contact non vérifié',
        information: 'Informations supplémentaires',
        notifications: 'Notifications',
      },
      messages: {
        contacts: "Les contacts peuvent être utilisés pour vous envoyer des notifications de match et sont disponibles pour aider les utilisateurs autorisés à vous joindre, mais ils n'affectent pas votre connexion à votre compte.",
        description: 'Courriels, numéros de téléphone et adresses',
        already_received_email: 'Si vous avez déjà reçu un courriel de vérification, vous devrez cliquer sur le lien pour vérifier, ou cliquer ci-dessous pour le renvoyer. Le lien expirera après 1 semaine.',
        only_participant_verify: 'Seul le participant peut vérifier son adresse courriel.',
        has_not_been_verified: "%{contact} n'a pas été vérifié.",
        unverified_explain: 'Pour recevoir des notifications, ce contact doit être vérifié. La vérification est non obligatoire et ne limite pas le compte.',
        error_verify: 'Impossible de vérifier votre courriel',
      },
      alerts: {
        contact_accounts: "Les contacts n'affectent pas les comptes.",
        create_via_invite_button: 'Pour créer un nouveau compte, utilisez le bouton "Inviter" sur la page de profil.',
        change_login: "Pour changer le nom d'utilisateur, rendez-vous dans la section des comptes sur la page de profil.",
        add_contact_email: 'Veuillez ajouter un courriel de contact ci-dessous pour recevoir des notifications.',
        verify_contact_email: 'Veuillez vérifier votre courriel de contact pour recevoir des notifications.',
      },
      notifications: {
        not_registered: 'Le participant doit être enregistré avant que ce contact puisse être vérifié'
      },
      helpers: {
        value: 'Une notification sera envoyée pour valider cette adresse',
        isPrimary: 'Les notifications iront à ce contact par défaut',
        isEmergency: "Utilisez ce contact en cas d'urgence",
        language: 'Langue des notifications',
        isNotification: 'Utilisez ce contact pour recevoir des notifications',
      },
      tooltips: {
        isPrimary: 'Contact primaire',
        isEmergency: "Contact d'urgence",
        isVerified: 'Vérifié',
        notificationsActive: 'Reçoit des notifications',
        notificationsDisabled: 'Notifications désactivées',
      },
      values: {
        type: {
          Email: 'Adresse courriel',
          Mobile: 'Portable',
          Home: 'Domicile',
        },
        language: {
          en: 'Anglais',
          fr: 'Français'
        }
      }
    },
    addresses: {
      name: 'Adresse |||| Adresses',
      fields: {
        label: 'Nom',
        address: 'Adresse',
        address2: 'Adresse 2',
        city: 'Ville',
        province: 'Province',
        postal: 'Code Postal',
        type: 'Type',
        isPrimary: 'Primaire'
      },
      actions: {
        add: 'Ajoutez une adresse',
      },
      labels: {
        card: {
          title: 'Adresses',
        },
        edit: "Mettre à jour l'adresse",
      },
      helpers: {
        label: 'Optionnel - Surnom pour cette adresse',
        address2: 'Optionnel - Unité/Suite/etc.',
      },
      values: {
        type: {
          Home: 'Domicile',
          Work: 'Travail',
          Other: 'Autre',
        },
        province: {
          AB: 'Alberta',
          BC: 'Colombie-Britannique',
          MB: 'Manitoba',
          NB: 'Nouveau-Brunswick',
          NL: 'Terre-Neuve-et-Labrador',
          NT: 'Territoires du Nord-Ouest',
          NS: 'Nouvelle-Écosse',
          NU: 'Nunavut',
          ON: 'Ontario',
          PEI: 'Île-du-Prince-Édouard',
          QC: 'Québec',
          SK: 'Saskatchewan',
          YT: 'Yukon',
        }
      }
    },
    notifications: {
      name: 'Notification |||| Notifications',
      fields: {
        participantId: 'Participant',
        contactId: 'Coordonnées des destinataires',
        email: 'Adresse courriel (Ancien)',
        targetId: 'Ressource',
        categoryIds: 'Catégories',
        groupIds: 'Groupes',
        gameReport: 'Feuille de match',
        majorPenalty: 'Sanctions majeures',
        suspension: 'Suspensions',
        assigning: 'Assignation',
        scheduling: 'Horaires',
        activities: 'Activités',
        drafts: 'Partage de brouillon'
      },
      actions: {
        add: 'Ajouter une notification',
      },
      labels: {
        card: {
          title: 'Notifications',
          subtitles: {
            office: "Les notifications de l'organisation s'appliquent à tous les matchs dans et sous cette organisation. Les notifications peuvent être ajoutées à n'importe qui, même s'il n'a pas de rôle.",
            schedule: "Les notifications d'horaire s'appliquent à tous les matchs de ce calendrier. Les notifications peuvent être ajoutées à n'importe qui, même s'il n'a pas de rôle.",
            team: "Les notifications d'équipe s'appliquent aux matchs joués par cette équipe.",
          }
        },
        sources: 'Sources de notification',
        update_source: 'Mettre à jour la source de notification',
        types: 'Types',
      },
      helpers: {
        participantId: 'Rappel: Doit avoir au moins un contact dans le profil pour recevoir des courriels',
        contactId: "Optionnel - Les notifications iront aux contacts dont les notifications sont activées, à moins qu'elles ne soient remplacées par cette option",
        email: "Il s'agit d'une ancienne notification. Veuillez le connecter à un participant ci-dessus.",
        scheduling: "Un résumé est envoyé par courriel lorsqu'il y a eu des changements de match au cours de la dernière heure",
        activities: "Un résumé est envoyé par courriel lorsqu'il y a des activités nouvelles ou mises à jour pour cette équipe",
        drafts: "Un courriel est envoyé lorsque l'accès à un brouillon a été partagé",
        assigning: "Un courriel est immédiatement envoyé lorsqu'un assignation est refusée",
        gameReport: 'Le rapport de match est envoyé après la fin du match',
        majorPenalty: 'Les feuilles de match avec les pénalités majeures seront envoyées après la fin du match',
        suspension: 'Les suspensions seront envoyées après la fin du match et en cas de changement du comité de discipline',
        categoryIds: 'Optionnel - Si vide, toutes les catégories',
        groupIds: 'Optionnel - Si vide, tous les groupes',
        default: "Les notifications par défaut sont activées en raison d'un rôle administratif ou d'une présence dans l'alignement d'une équipe."
      },
    },
    draftGames: {
      name: 'Match Brouillon |||| Matchs Brouillon',
      fields: {
        id: 'ID',
        round: 'Étape',
        seasonId: 'Saison',
        scheduleId: 'Horaire',
        crossScheduleId: 'Horaire croisée',
        categoryId: 'Catégorie',
        homeTeamId: 'Local',
        awayTeamId: 'Visiteur',
        date: 'Date',
        startTime: 'Heure de début',
        endTime: 'Heure de fin',
        arenaId: 'Surface',
        status: 'Statut',
        comments: 'Commentaires',
        requestReason: 'Raison de la demande',
        officeId: 'Organisation',
        groupId: 'Groupe',
        crossGroupId: 'Groupe croisée',
        timezone: 'Fuseau Horaire',
        isShared: 'Partagé',
        sharedAt: 'Date du partage',
        sharedUntil: "Date d'expiration du partage",
        isPublished: 'Publié',
        publishedAt: 'Date de publication',
        requested_by: 'Demande par',
      },
      filters: {
        startTime: 'Date de début',
        endTime: 'Date de fin',
      },
      actions: {
        create: 'Créer',
        request: 'Demander un changement',
        add: 'Ajouter un match brouillon',
        generate: 'Générer un horaire',
        upload: 'Importer un fichier',
        set_date: 'Définir une date',
        clear_date: 'Effacer la date',
        change_status: 'Changer le statut',
        delete_draft_game: 'Supprimer un match brouillon |||| Supprimer plusieurs matchs brouillons'
      },
      labels: {
        card: {
          title: 'Matchs Brouillon'
        },
        edit: 'Modifier le match brouillon',
        edit_number: 'Modifier le match brouillon %{number}',
        draft_request: 'Demande de match brouillon %{number}',
        generate_automatically: 'Générer automatiquement',
        method: 'Méthode',
        type: 'Type',
        round_type: "Type d'étape",
        games_per_team: 'Nombre total de matchs',
        total_rounds: "Nombre total d'étapes",
        limit_byes: 'Limiter les byes',
        weekdays: 'Jours de la semaine',
        weekdays_type: "Mode de distribution",
        start_date: 'Date de début',
        start_round: 'Étape de départ',
        breaks: 'Pauses',
        total_weeks: 'Nombre total de semaines',
        no_round: "Pas d'étape",
        no_date: 'Pas de date',
        no_time: "Pas d'heure",
        no_venue: 'Pas de surface',
        drafts_file: 'Fichier des matchs brouillons',
        import_drafts: 'Importer des matchs brouillons',
        date_format: 'Format de date',
        publish: 'Publier',
        share: 'Partager',
        home: 'Local',
        away: 'Visiteur',
        total: 'Total',
        main_schedule: 'Horaire principal',
        main_group: 'Groupe principal',
        cross_schedule: 'Horaire croisée',
        cross_group: 'Groupe croisée',
        cross_team: 'Équipe croisée',
        add_cross_team: 'Ajouter une équipe intergroupe',
        steps: {
          matchups: 'Affrontements',
          matrix: 'Matrice',
          scheduling: 'Horaires',
          preview: 'Aperçu',
        },
        number_of_teams: "Nombre total d'équipes",
        updateExistingGames: 'Mettre à jour les matchs existants',
        confirm_publish_with_conflicts: 'Confirmer la publication avec des conflits',
      },
      messages: {
        drop_drafts_csv: 'Déposez votre fichier des matchs brouillons (.csv) ici',
        generate_single_double: 'Générer un horaire de rotation simple ou double',
        no_results: 'Aucun match brouillon',
        no_teams_alert: "Des équipes doivent être ajoutées a l'horaire avant que des matchs puissent être générés.",
        one_team_alert: "L'horaire ne contient qu'une seule équipe.",
        gaps_alert: "Des écarts sont présents dans l'ordres des équipes de l'horaire. Cela pourrait entraîner un déséquilibre avec les affrontements.",
        no_teams_group_alert: "Des équipes doivent être ajoutées au groupe de l'horaire avant que des matchs puissent être générés.",
        one_team_group_alert: "Le groupe de l'horaire ne contient qu'une seule équipe.",
        gaps_group_alert: "Des écarts sont présents dans l'ordres des équipes du groupe de l'horaire. Cela pourrait entraîner un déséquilibre avec les affrontements.",
        no_teams_pool_alert: "Des équipes doivent être ajoutées au pools de l'horaire avant que des matchs puissent être générés.",
        one_team_pool_alert: "Les pools de l'horaire ne contiennent qu'une seule équipe.",
        gaps_pool_alert: "Des écarts sont présents dans l'ordres des équipes du des pools de l'horaire. Cela pourrait entraîner un déséquilibre avec les affrontements.",
        unslotted_matchups_alert: "En raison d'une combinaison de Double Rotation et d'un nombre impair d'équipes, certaines équipes devront jouer deux fois le même jour. Ces matchs seront générés sans dates.",
        match_columns: 'Choissisez les équipes et les lieux correspondants à vos colonnes sélectionnées.',
        match_teams: 'Les équipes sélectionnées qui n\'ont pas été trouvés dans l\'horaire, seront ajoutées à l\'horaire. Toutes les équipes qui n\'ont pas été sélectionées seront marqués "à déterminer" sur les matchs.',
        import_tool_drafts: "Pour utiliser cet outil d'importation, téléchargez un fichier contenant des matchs brouillons, puis sélectionnez un groupe d'horaire (le cas échéant).",
        publish_help: "La publication d'un brouillon rendra le match publique et donnera accès à la feuille de pointage. Les matchs publiés doivent avoir une date, une heure et une surface définies, et ils doivent disposer d'une séquence disponible pour le numéro de match.",
        share_help: "Le partage d'un brouillon donnera accès au match à l'organisation de l'équipe locale. Les matchs partagés nécessitent que les deux équipes soient attribuées",
        confirm_clear_date: 'Êtes-vous sûr de vouloir supprimer la date de ce match brouillon? Cette action réinitialisera également les heures et le terrain. |||| Êtes-vous sûr de vouloir supprimer la date de ces matchs brouillons? Cette action réinitialisera également les heures et le terrain.',
        confirm_delete: 'Êtes-vous sûr de vouloir supprimer %{smart_count} match brouillon? |||| Êtes-vous sûr de vouloir supprimer %{smart_count} matchs brouillons?',
        schedule_dates: "Dates de l'horaire: %{startDate} au %{endDate}",
        change_dates_dialog: 'Changer la date réinitialisera également les heures et le terrain des matchs brouillons.',
        teams_to_import: 'L\'importation de ces parties de repêchage ajoutera %{teams} équipes à l\'horaire',
        confirm_publish_with_conflicts: 'Certaines des parties que vous êtes sur le point de publier ont des conflits. Êtes-vous sûr de vouloir continuer?',
      },
      alerts: {
        update_game: 'La publication de ce match brouillon mettra à jour %{game}',
      },
      helpers: {
        round: 'Optionnel',
        comments: 'Optionnel - affiché sur le calendrier public',
        request_reason: 'Visible uniquement par les approbateurs et ne sera pas affiché sur le calendrier public',
        limit_byes: "Chaque étape peut avoir une équipe qui joue deux fois lorsqu'il y a un nombre impair d'équipes.",
        start_round: 'Optionnel - préciser si vous continuez un horaire existant',
        latest_round: "L'étape %{smart_count} est la dernière étape des matchs brouillons générés précédents",
        round_type: {
          'Home Slots': "Les matchs seront générés en utilisant les plages horaires des paramètres de l'équipe à domicile (si disponible)",
          'Weekdays': 'Les matchs seront générés en utilisant une rotation hebdomadaire basée sur les jours de la semaine',
        },
        weekdays_type: {
          'Rotating': 'Les matchs alterneront en semaine (lundi de la semaine 1, mardi de la semaine 2, mercredi de la semaine 3, et ainsi de suite)',
          'Consecutive': 'Les matchs seront répartis dans la semaine (lundi, mardi, mercredi de la semaine 1, et ainsi de suite)'
        },
        weekdays: 'Les jours de la semaine alterneront chaque semaine',
        weekdays_double: "Les jours de la semaine alterneront à l'intérieur de chaque semaine",
        start_date: "Les nouvelles étapes d'horaire commencera les %{date}s",
        date_format: 'Sélectionnez le format de date utilisé dans votre fichier',
        updateExistingGames: `Mettre à jour les matchs existants dans l'horaire en faisant correspondre les numéros de match`,
      },
      validations: {
        must_start_on_day: 'Doit commencer un %{day}',
        must_be_after: 'Doit être après %{date}',
        must_be_before_schedule_end_date: "Doit être avant la date de fin de l'horaire (%{date})",
        max_weekdays: "Max %{smart_count} jours de la semaine pour un type d'affrontement double",
        even_number: 'Doit être un montant pair',
        invalid_date_format: "Certaines lignes n'ont pas un format de date valide (%{format})",
        start_without_end: 'Certaines lignes ont une heure de début, mais sans heure de fin',
        end_without_start: 'Certaines lignes ont une heure de fin, mais sans heure de début',
        date_without_date: 'Certaines lignes ont une heure, mais sans date',
        end_before_start: "Certaines lignes ont l'heure de fin avant l'heure de début",
        manual_odd_numbers: "Le nombre d'équipes et le nombre de matchs par équipe sont tous deux impairs",
        same_group: "Doit être une équipe d'un groupe différent du groupe principal",
        matrix_team_duplicate: 'Équipe déjà dans la matrice',
      },
      notifications: {
        added: '%{smart_count} match brouillon généré |||| %{smart_count} matchs brouillons générés',
        not_added: "Impossible de générer les matchs brouillons",
        uploaded: '%{smart_count} match brouillon importé |||| %{smart_count} matchs brouillons importés',
        not_uploaded: "Impossible d'importer les matchs brouillons",
        slow_upload_warning: "Ce processus peut prendre un certain temps. Veuillez patienter s'il vous plaît.",
        generator_load_error: 'Impossible de télécharger le générateur',
        published_games: '%{smart_count} match publiés avec succès |||| %{smart_count} matchs publiés avec succès',
        published_games_error: 'Impossible de publier le match |||| Impossible de publier les matchs',
        publish_failed: 'Impossible de publier %{smart_count} match |||| Impossible de publier %{smart_count} matchs',
        missing_sequence: 'Aucune séquence trouvée pour la numérotation des matchs',
        invalid_games: 'Le match est invalide |||| %{smart_count} matchs sont invalides',
        sequence_exhausted: 'Il ne reste pas assez de numéros dans la séquence de numéros de match pour publier',
        shared_games: '%{smart_count} match partagé avec succès |||| %{smart_count} matchs partagés avec succès',
        shared_games_error: 'Impossible de partager le match |||| Impossible de partager les matchs',
        set_date: 'La date a été appliquée avec succès sur %{smart_count} match brouillon |||| La date a été appliquée avec succès sur %{smart_count} matchs brouillons',
        set_date_error: 'Erreur de changement de date sur %{smart_count} match brouillon |||| Erreur de changement de date sur %{smart_count} matchs brouillons',
        delete_draft: '%{smart_count} match brouillon supprimé |||| %{smart_count} matchs brouillons supprimés',
        delete_draft_error: 'Erreur de suppression de %{smart_count} match brouillon |||| Erreur de suppression de %{smart_count} matchs brouillons',
        clear_date: 'Date effacée sur %{smart_count} match brouillon |||| Date effacée sur %{smart_count} matchs brouillons',
        clear_date_error: 'Erreur de suppression de la date sur %{smart_count} match brouillon |||| Erreur de suppression de la date sur %{smart_count} matchs brouillons',
        change_status: 'Statut changé sur %{smart_count} match brouillon |||| Statut changé sur %{smart_count} matchs brouillons',
        change_status_error: 'Erreur de changement de statut',
      },
      values: {
        methods: {
          'Games per Team': 'Matchs par équipe',
          'Total Rounds': "Nombre total d'étapes"
        },
        types: {
          'Single Rotation': 'Simple',
          'Double Rotation': 'Double',
          'Matrix': 'Matrice',
        },
        round_types: {
          'Home Slots': "Plages horaires de l'équipe locale",
          'Weekdays': 'Hebdomadaire'
        },
        weekdays_types: {
          'Rotating': 'Rotatif',
          'Consecutive': 'Consécutif'
        },
        dates: {
          'YYYY-MM-DD': 'YYYY-MM-DD',
          'MM-DD-YYYY': 'MM-DD-YYYY',
          'DD-MM-YYYY': 'DD-MM-YYYY',
          'MM/DD/YYYY': 'MM/DD/YYYY',
          'DD/MM/YYYY': 'DD/MM/YYYY'
        }
      },
      steps: {
        match_columns: 'Faire correspondre les colonnes',
      }
    },
    draftGameApprovals: {
      name: 'Approbation |||| Approbations',
      labels: {
        approvals: 'Approbations',
        approve_request: 'Approuver la demande',
        decline_request: 'Refuser la demande',
      },
      messages: {
        request_reschedule: 'Une fois que toutes les équipes et toutes les organisations concernées auront approuvé cette demande, le match sera automatiquement modifié',
        confirm_approve_request: 'Êtes-vous sûr de vouloir approuver ces changements ?',
        confirm_decline_request: 'Êtes-vous sûr de vouloir refuser ces changements ?',
      },
      alerts: {
        publish_game: "L'approbation de cette demande entraînera automatiquement la modification %{game}",
        request_changes: 'Cette demande devra être approuvée par tous les intervenants concernés afin de pouvoir être reprogrammée',
        cancel_draft: "Le refus de cette demande entraînera l'annulation du match brouillon",
      },
      helpers: {
        approval_comments: 'Optionnel - affiché dans la liste des approbations'
      },
      notifications: {
        approved_and_published: 'Demande approuvée et match modifié',
        declined_and_cancelled: 'Demande refusée et match brouillon annulé',
        request_approved: 'Demande approuvée',
        request_declined: 'Demande refusée',
        respond_error: 'Erreur dans le traitement de la demande',
      },
      values: {
        Approved: 'Approuvé',
        Declined: 'Refusé',
        Pending: 'En attente',
      }
    },
    drafts: {
      name: 'Brouillon |||| Brouillons',
      name_legacy: 'Ancien brouillon |||| Anciens brouillons',
      fields: {
        scheduleId: 'Horaire',
        groupId: 'Groupe',
        startDate: 'Date de début',
        totalWeeks: 'Nombre total de semaines',
        totalRounds: "Nombre total d'étapes",
        file: "Fichier d'horaire",
        isPublished: 'Publié',
        updatedAt: 'Dernière mise à jour',
        publishedAt: 'Date de publication',
        options: {
          type: 'Type',
          limitByes: 'Limiter les byes',
          crossGroup: 'Groupes croisés',
          roundType: "Type d'étape",
          method: 'Méthode',
          weekdays: 'Jours de la semaine',
          startDate: 'Date de début',
          totalWeeks: 'Nombre total de semaines',
          totalRounds: "Nombre total d'étapes",
          gamesPerTeam: 'Matchs par équipe',
          startNumber: 'Numéro de départ',
          breaks: 'Pauses',
          previousDraftId: 'Brouillon précédent',
        },
        columns: {
          homeTeamId: 'Local',
          awayTeamId: 'Visiteur',
          arenaId: 'Surface',
          date: 'Date',
          startTime: 'Heure de début',
          endTime: 'Heure de fin',
          status: 'Statut',
          number: 'Numéro du match',
          comments: 'Commentaires',
        }
      },
      actions: {
        add: 'Nouveau brouillon',
      },
      labels: {
        card: {
          title: 'Brouillons enregistrés',
          title_legacy: 'Brouillons enregistrés (ancien)'
        },
        analysis: 'Analyse',
        current_matchups: 'Affrontements actuels',
        new_matchups: 'Nouveaux affrontements',
        office_matchups: 'Affrontements par organisation',
        byes: 'Byes',
        team: 'Équipe',
        group: 'Groupe',
        opponents: 'Adversaires',
        conflicts: 'Conflits',
        home: 'Local',
        away: 'Visiteur',
        total: 'Total',
        shuffle: 'Mélanger',
        swap_matchups: 'Échanger les matchs',
        set_active: 'Activer',
        set_conflict: 'Définir le conflit',
        generate_automatically: 'Générer automatiquement',
        generate_automatically_legacy: 'Générer automatiquement (ancien)',
        manual_entry: 'Saisie manuelle',
        previous_schedule: 'Horaire précédent',
        add_team: 'Ajouter une équipe',
        import_teams: "Sélectionnez des équipes",
        previous_draft_alert: "Recommencer l'horaire",
        cross_group_alert: "Groupes croisés pour l'horaire",
        replace_teams_alert: 'Remplacer les équipes',
        use_previous_teams: 'Utiliser les équipes du brouillon précédent',
        unused_breaks: 'Pauses non utilisées',
        unscheduled_games: 'Matchs non programmés',
        changed_games: 'Matchs modifiés',
        new_games: 'Nouveaux matchs',
        columns: 'Colonnes',
        round: 'Étape |||| Étapes',
        week: 'Semaine',
        no_round: "Pas d'étape",
        no_date: 'Pas de date',
        no_time: "Pas d'heure",
        no_venue: 'Pas de surface',
        set_date: 'Définir une date',
        clear_date: 'Effacer la date',
        saved: 'Sauvegardé',
        shared: 'Partagé',
        conflict_resolution: {
          title: 'Conflit à resoudre',
          subtitle: {
            modified: "Quelqu'un d'autre a apporté des modifications en même temps que vous. Voulez-vous accepter leurs modifications?",
            removed: "Quelqu'un d'autre a supprimé le match auquel vous avez apporté des modifications. Voulez-vous accepter la suppression?",
            added: "Quelqu'un d'autre a apporté des modifications au match que vous avez supprimé. Voulez-vous accepter leurs modifications?"
          },
          keep_mine: 'Garder le mien',
          accept_theirs: 'Accepter les modifications',
          accept_delete: 'Accepter la suppression',
          keep_delete: 'Garder la suppression',
        },
        steps: {
          info: "Informations sur l'horaire",
          teams: 'Sélectionnez les équipes',
          games: 'Réviser les matchs',
          review: 'Publier',
        },
        types: {
          scheduler: "Assignateur d'horaire",
          schedule_upload: "Téléchargement d'horaire",
          draft_schedule: "Ébauche d'horaire",
        },
        general: "Général",
        matchups: 'Affrontements',
        scheduling: 'Horaires',
      },
      messages: {
        no_byes: 'Pas de byes',
        show_conflicts_only: 'Afficher uniquement les conflits',
        games_selected: 'Un match sélectionné |||| %{smart_count} matchs sélectionnés',
        generate_single_double: 'Générer un horaire de rotation simple ou double',
        draft_multiple_games: 'Rédigez individuellement plusieurs matchs à la fois',
        generate_new_schedule: 'Générer un nouvel horaire?',
        draft_already_has_games: "Ce brouillon a déjà des matchs. La génération d'un nouvel horaire les remplacera.",
        draft_already_has_games_share: "Ce brouillon a déjà des matchs. La génération d'un nouvel horaire les remplacera et va annuler le partage du brouillon.",
        want_generate_new_schedule: 'Voulez-vous générer un nouvel horaire?',
        drop_your_schedule_file: "Déposez votre fichier d'horaire (.csv) ici",
        drop_another_schedule_file: "%{num} lignes analysées. Déposez un autre fichier d'horaire (.csv) ici pour le remplacer.",
        replace_existing_games: 'Remplacer les matchs existants?',
        draft_already_has_games_uploader: "Ce brouillon a déjà des matchs. Si vous avez apporté des modifications a le horaire ou aux équipes, vous devez remplacer les matchs existants.",
        want_replace_existing_games: 'Vous souhaitez remplacer les matchs existants?',
        number_of_play: '%{smart_count} étape de jeu |||| %{smart_count} étapes de jeu',
        number_of_games_per_team: '%{smart_count} match par équipe |||| %{smart_count} matchs par équipe',
        number_of_break: '%{smart_count} semaine de pause |||| %{smart_count} semaines de pause',
        publish_schedule: "Publier l'horaire?",
        same_game_number_be_updated: "Les changements d'horaire décrits dans le sommaire seront appliqués. Tous les matchs existants avec le même numéro de match seront mis à jour.",
        discard_draft: 'Supprimer le brouillon?',
        discard_unsaved_draft: 'Quitter supprimera ce brouillon non enregistré.',
        save_changes: 'Sauvegarder les modifications?',
        save_changes_before_leaving: 'Souhaitez-vous enregistrer les modifications avant de quitter?',
        number_teams_added: '%{number} équipes seront ajoutées',
        number_existing_games: '%{number} sur %{total} matchs existants seront modifiés',
        number_games_added: '%{number} nouveaux matchs seront ajoutés',
        no_changes_number_games: 'Aucun changement pour %{number} matchs existants',
        no_changes_number_teams: 'Aucun changement pour %{number} équipes existantes',
        no_additional_games: 'Aucun match supplémentaire ne sera ajouté',
        previous_draft_alert: "Un autre brouillon a déjà été publié mais n'a pas été sélectionné. Cet horaire ne se poursuivra pas à partir de la dernière étape du brouillon précédent.",
        cross_group_alert: "Vous souhaitez créer un horaire à groupes croisés?",
        replace_teams_alert: 'Voulez-vous remplacer les équipes que vous avez déjà sélectionnées dans ce brouillon',
        use_previous_teams: "Voulez-vous remplacer les équipes de ce brouillon par les équipes du brouillon précédent sélectionné?",
        unused_breaks: 'Certaines de vos pauses auront lieu après la fin de tout les matchs. Les pauses après %{date} seront ignorées.',
        unscheduled_games: "Ce brouillon génère plus de matchs qu'il n'est possible d'en programmer avant la date de fin de l'horaire. Les matchs générés après %{date} n'auront donc pas de date attribuée.",
        unbalanced_schedule: {
          title: 'Horaire déséquilibré',
          message: 'Avec %{totalTeams} et %{totalRoundsOrGamesPerTeam}, cet horaire résultera en:',
          games_per_total_teams: '%{totalTeams} avec %{totalGames}',
          suggestions: "Afin de générer un nombre égal de matchs pour chacune des équipes, mettez le <strong>nombre total %{method} à %{suggestion}</strong>, ou utilisez un nombre pair d'équipes.",
          round: "d'étape |||| d'étapes",
          game_per_team: 'de match par équipe |||| de matchs par équipe',
        },
        review_errors: {
          title: 'Corrigez vos erreurs',
          message: 'Les matchs suivants ont des erreurs de validation qui doivent être corrigées:',
        },
        share: {
          title: 'Confirmer le partage',
          title_shared: 'Brouillon partagé',
          messages: {
            list: 'Ce brouillon sera partagé avec les associations suivantes:',
            list_shared: 'Ce brouillon a déjà été partagé avec les associations suivantes:',
            info: 'Les associations ne pourront modifier que les temps et les surfaces de leurs propres matchs et ne pourront pas publier le brouillon.'
          }
        },
        renumber_games: {
          title: 'Renuméroter les matchs',
          actions: {
            renumber_games: 'Renuméroter les matchs',
          },
          messages: {
            description: 'Les matchs seront renumérotés basé sur leur heure de début et les écarts de séquence de numéros de match seront éliminés.',
          }
        }
      },
      alerts: {
        dated_in_past: '%{smart_count} match est daté du passé |||| %{smart_count} matchs sont datés du passé',
      },
      helpers: {
        limitByes: "Chaque étape peut avoir une équipe qui joue deux fois lorsqu'il y a un nombre impair d'équipes.",
        crossGroup: 'Les matchs seront générés entre les équipes des groupes.',
        roundType: {
          'Home Slots': "Les matchs seront générés en utilisant les plages horaires des paramètres de l'équipe à domicile (si disponible)",
          'Weekdays': 'Les matchs seront générés en utilisant une rotation hebdomadaire basée sur les jours de la semaine',
        },
        weekdays: 'Les jours de la semaine alterneront chaque semaine',
        weekdays_double: "Les jours de la semaine alterneront à l'intérieur de chaque semaine",
        startNumber: "Attention: l'horaire précédent comprend %{number}",
        totalWeeks: '%{smart_count} semaine |||| %{smart_count} semaines',
        totalGames: '%{smart_count} match |||| %{smart_count} matchs',
        totalRounds: '%{smart_count} match par équipe |||| %{smart_count} matchs par équipe',
        totalRounds_odd: "%{smart_count} si nombre impair d'équipes",
        previousDraftId: "Le nouvel horaire commencera à l'étape %{round}",
        startDate: "Les nouvelles étapes d'horaire commencera les %{date}s",
        startDate_previous: 'Les étapes précédentes ont commencé les %{date}s',
        previousDraftId_optional: "Optionnel - à utiliser lors de la poursuite d'un horaire",
        no_team_time_slots: "Pas de plage horaire d'équipe définie"
      },
      validations: {
        must_contain_a_number: 'Doit contenir un nombre',
        must_start_on_day: 'Doit commencer un %{day}',
        must_be_after: 'Doit être après %{date}',
        must_be_before: 'Doit être avant %{date}',
        must_be_before_schedule_end_date: "Doit être avant la date de fin de l'horaire (%{date})",
        break_last_week: 'Ne peut pas mettre une pause à la semaine dernière',
        max_weeks: 'Max %{smart_count} semaine (%{date}) |||| Max %{smart_count} semaines (%{date})',
        max_weekdays: "Max %{smart_count} jours de la semaine pour un type d'affrontement double",
        valid_date: "Certaines valeurs n'incluent pas de date valide (AAAA-MM-JJ)",
        valid_time: "Certaines valeurs n'incluent pas d'heure valide (HH:mm)",
        same_column_start_time: "Impossible d'utiliser la même colonne que l'heure de début",
        cross_group_teams: 'Groupes croisés nécessite des équipes dans 2 groupes',
        same_game_number: 'Ce numéro de match existe déjà dans ce brouillon'
      },
      notifications: {
        generating_schedule: "Génération de l'horaire...",
        unable_generate_schedule: "Impossible de générer l'horaire",
        unable_to_load_draft: 'Impossible de télécharger le brouillon',
        unable_to_share_draft: 'Impossible de partager le brouillon',
        unable_to_publish_draft: 'Impossible de publier le brouillon',
        unable_to_publish_draft_games: 'Impossible de publier le brouillon en raison de matchs invalides',
        schedule_published: 'Horaire publié',
        draft_shared: 'Brouillon partagé',
      },
      values: {
        type: {
          Upload: 'Télécharger',
          Generated: 'Généré',
          Manual: 'Manuel',
        },
        draft_type: {
          'Single Rotation': 'Simple',
          'Double Rotation': 'Double',
        },
        round_type: {
          'Home Slots': "Plages horaires de l'équipe locale",
          'Weekdays': 'Hebdomadaire'
        }
      }
    },
    officeAssignSettings: {
      name: 'Paramètre |||| Paramètres',
      fields: {
        officeId: "Organisation d'assignation",
        declineReason: 'Raison requise',
        declineHours: 'Heures minimales',
        visibleDaysEnabled: 'Autoriser les officiels à voir les matchs non assignés',
        visibleDays: 'Jours visibles',
        dailyLimit: 'Limite quotidienne',
        requestsEnabled: 'Autoriser les officiels à demander des matchs',
        declineEnabled: 'Autoriser les officiels à refuser des assignations',
        declineConfirmedDisabled: 'Désactiver les refus une fois confirmé',
        removeAfterDecline: "Supprimer les officiels lorsqu'ils refusent",
        removeAfterRescheduled: "Supprimer les officiels lorsqu'un match est reprogrammé",
        removeOnDateChange: "Supprimer les officiels seulement si la date est changée",
        assignmentVisibility: 'Visibilité des assignations',
        assignerVisible: "Afficher le nom de l'assignateur aux officiels",
        supervisorVisible: "Afficher le superviseur aux officiels",
        claimableDriver: 'Conducteur',
        claimablePassenger: 'Passager',
        claimableBreakfast: 'Déjeuner',
        claimableLunch: 'Dîner',
        claimableDinner: 'Souper',
        driverAmount: 'Taux ($/km)',
        passengerAmount: 'Taux ($/km)',
        breakfastAmount: 'Montant du déjeuner',
        lunchAmount: 'Montant du dîner',
        dinnerAmount: 'Montant du souper',
        driverExcludedDistance: 'Distance exclue',
        passengerExcludedDistance: 'Distance exclue',
        enforceConsecutive: 'Appliquer des assignations consécutives',
        allowEarlyClaims: 'Autoriser les réclamations avant la fin du match.',
        effectiveOfficialsList: "Afficher les listes d'officiels des sous-organisations"
      },
      actions: {
        edit: 'Modifier les paramètres',
      },
      labels: {
        card: {
          title: "Paramètres des assignations",
        },
        edit_assigning: "Modifier les paramètres d'assignations",
        general: 'Général',
        requests: 'Demandes',
        declines: 'Refus',
        travel_claimables: 'Frais de déplacement',
        per_diem_claimables: 'Per diem',
        claims: 'Réclamations',
      },
      messages: {
        load_error: "Impossible de télécharger les paramètres d'assignations",
        visible_days_disabled: 'Les officiels ne peuvent pas voir les matchs non assignés',
        visible_same_days: 'Les officiels ne peuvent voir que les matchs du jour même',
        visible_days_on: "Les officiels peuvent voir jusqu'à %{number} jours à l'avenir",
        visible_days_off: 'Tous les matchs actifs visibles',
        requests_enabled_on: 'Activé',
        requests_enabled_off: 'Assignation uniquement',
        decline_enabled_on: 'Refuser avant %{number} heures',
        decline_enabled_on_anytime: 'Refuser à tout moment',
        decline_enabled_off: 'Désactivé',
        reason_required: 'la raison est requise',
        reason_not_required: 'raison non requise',
        disabled_after_confirmation: 'désactivé après confirmation',
      },
      alerts: {
        assigning_not_enabled: "L'assignation n'est pas activée pour cette organisation",
        visibleDaysEnabled: 'Les jours visibles doivent être activés pour que les officiels puissent demander des matchs'
      },
      helpers: {
        declineReason: "Exiger des officiels qu'ils précisent une raison lorsqu'ils refusent",
        declineHours: "Nombre d'heures avant le début du match qu'un officiel peut refuser",
        visibleDaysEnabled: "S'il est désactivé, les officiels ne pourront pas voir les matchs non assignés",
        visibleDays: "Nombre de jours d'avance pendant lesquels les officiels pourront voir les matchs actifs",
        dailyLimit: 'Nombre maximum de matchs auxquels un officiel peut être assigné en une journée',
        requestsEnabled: "S'il est désactivé, les officiels pourront voir les matchs actifs mais pas faire de demande",
        declineEnabled: "S'il est désactivé, les officiels seront invités à contacter un assignateur pour être retirer",
        declineConfirmedDisabled: 'Les officiels ne pourront pas refuser après avoir accepté',
        removeAfterDecline: "Les officiels ignoreront l'état refusé et seront immédiatement supprimés",
        removeAfterRescheduled: "Des changements a la date, l'heure ou la surface supprimeront tous les officiels",
        removeOnDateChange: "Remplace le paramètre ci-dessus pour supprimer les officiels uniquement si la date a été changée. Les modifications de l'heure ou de la surface uniquement n'entraîneront pas la suppression des officiels",
        assignmentVisibility: "Visibilité du nom et du statut d'un officiel affecté dans le panneau d'assignations",
        assignerVisible: "Les officiels pourront voir qui les a assignés",
        supervisorVisible: "Les officiels pourront voir l'assignation du superviseur",
        excludedDistance: 'Distance qui sera calculée au taux de $0/km',
        enforceConsecutive: "Les officiels seront tenus d'accepter ou de refuser toutes les assignations dans un délai d'une heure",
        allowEarlyClaims: "Les officiels seront autorisés à faire des réclamations avant la fin du match",
        effectiveOfficialsList: "Les listes d'officiels des sous-organisations seront disponibles et visibles pour l'assignation"
      },
      values: {
        assignmentVisibility: {
          Visible: 'Nom et statut',
          Status: 'Statut uniquement',
          Hidden: "Cachée"
        }
      }
    },
    officeClaimsSettings: {
      fields: {
        name: 'Nom',
        type: 'Type',
        amount: 'Montant',
        rate: 'Taux',
        excludedDistance: 'Distance exclue'
      },
      labels: {
        name: 'Paramètres de réclamation'
      },
      helpers: {
        amount: 'Montant maximal pour une dépense ou taux fixe pour un per diem',
        rate: 'Taux ($) par KM',
        excludedDistance: 'Distance exclue pour une réclamation de déplacement',
        no_options: 'Aucune réclamation disponible - Contactez votre assignateur'
      },
      actions: {
        add: 'Paramètre de réclamation'
      },
      values: {
        type: {
          'Expense': 'Dépense',
          'Travel': 'Déplacement',
          'Per Diem': 'Per Diem'
        },
        secondaryText: {
          'Travel': 'Rate per KM',
          'Per Diem': 'Amount',
          'Expense': 'Maximum Amount'
        }

      }
    },
    gameAssignSettings: {
      name: 'Paramètre |||| Paramètres',
      fields: {
        gameId: 'Match',
        systemId: "Système d'arbitrage",
        officeId: "Organisation d'assignation",
        feesId: 'Structure de rémunération',
        minReferee: "Niveau minimum d'arbitre",
        minLinesperson: 'Niveau minimum de juge de ligne',
        minOfficial: "Niveau minimum d'officiel",
        minScorekeeper: 'Niveau minimum de marqueur',
        minTimekeeper: 'Niveau minimum de chronométreur',
        minGrade: "Grade minimale d'officiel",
        minRefereeGrade: "Grade minimale d'arbitre",
        minLinespersonGrade: 'Grade minimale de juge de ligne',
        minAge: 'Âge minimum',
        fees: 'Frais',
        status: 'Statut',
      },
      labels: {
        referee: "Niveau d'arbitre",
        linesperson: 'Niveau de juge de ligne',
        official: "Niveau d'officiel",
        scorekeeper: 'Niveau de marqueur',
        timekeeper: 'Niveau de chronométreur',
        grade: "Grade d'officiel",
        referee_grade: "Grade d'arbitre",
        linesperson_grade: 'Grade du juge de ligne',
        set_assigning_settings: "Modifier les paramètres d'assignation",
        set_assigner: "Modifier l'assignateur",
        assigning_settings: "Paramètres d'assignation",
        change_assigner: "Modifier l'assignateur",
      },
      helpers: {
        status_draft: "Changer le statut de l'assignation d'actif vers brouillon notifiera les officiels assignés qu'ils ont été enlevés du match"
      },
      messages: {
        change_assigner: "Pour changer l'assignateur de tous les positions, sélectionnez l'organisation du nouveau assignateur et ne sélectionnez pas de position.",
        change_assigner_partial: "Pour déléguer partiellement des assignations à un autre responsable, sélectionnez l'organisation et la position. Les matchs pour lesquels il n'y a pas la position sélectionné ou qui ont déjà attribué la position à un officiel seront ignorés.",
      },
      notifications: {
        updated_game_settings: 'Mise à jour des paramètres pour %{smart_count} match |||| Mise à jour des paramètres pour %{smart_count} matchs',
        updating_settings_error: "Une erreur s'est produite lors de la mise à jour des paramètres",
        errors: {
          invalid_fees: 'Structure de rénumération non valide',
        }
      },
      alerts: {
        bulk_assign_settings: 'Cochez les paramètres que vous souhaitez modifier. Un paramètre cocher sans valeur effacera la valeur actuelle.'
      }
    },
    assignFees: {
      name: 'Structure de rémunération |||| Structures de rémunération',
      fields: {
        name: 'Nom',
        officeId: 'Organisation',
        Referee: 'Arbitre',
        Linesperson: 'Juge de ligne',
        LinespersonTwo: 'Juge de ligne 2',
        LinespersonThree: 'Juge de ligne 3',
        Official: 'Officiel',
        Scorekeeper: 'Marqueur',
        Timekeeper: 'Chronométreur',
        Supervisor: 'Superviseur',
        fees: 'Frais',
        isArchived: 'Archivé',
      },
      actions: {
        add: 'Ajouter une structure de rémunération',
      },
      labels: {
        card: {
          title: 'Structures de rémunération',
          subtitle: "Certaines structures salariales peuvent également provenir de l'organisme parent",
        },
        edit: 'Modifier la structure de rémunération',
      },
      values: {
        isArchived: {
          archived: 'Archivé',
          notArchived: 'Actif'
        }
      },
      validations: {
        assign_fee_in_use: 'La structure de rémunération est utilisée. Créer une nouvelle structure pour apporter des modifications',
        delete_assign_fee_in_use: "Impossible de supprimer une structure de rémunération en cours d'utilisation",
        cannot_archive: "Les règles d'assignation doivent être supprimées avant d'archiver cette structure de paie.",
      },
      alerts: {
        cannot_update_amounts: "Afin de modifier les montants, vous devez créer une nouvelle structure de paie. Vous pouvez ensuite archiver cette structure de paie après avoir déplacé ses règles d'assignation vers la nouvelle structure de paie."
      }
    },
    assignRules: {
      name: "Règle d'assignation |||| Règles d'assignation",
      fields: {
        categoryIds: 'Catégories',
        types: "Types d'horaires",
        groupIds: 'Groupes',
        attributes: {
          duration: 'Durée de match',
          days: 'Jours',
          time: 'Avant quelle heure (24h)',
          effectiveDate: "Date d'entrée en vigueur"
        },
        order: 'Ordre',
        officeId: 'Organisation',
        value: 'Valeur',
        type: 'Type',
        stopInheritance: "Arrêter l'héritage des règles"
      },
      options: {
        Active: 'Actif',
        Draft: 'Brouillon',
        Arena: 'Lieu',
        'Arena Primary': "Organisation primaire du lieu",
        Schedule: 'Horaire',
        Home: "Organisation de l'équipe locale",
        Office: 'Organisation',
        'Home Parent': "Organisation parent de l'équipe locale",
        Zone: 'Zone',
        systemId: "Système d'assignation",
        feesId: 'Structure de rémunération',
        officeId: 'Assigné par',
        minReferee: "Niveau d'arbitre",
        minLinesperson: 'Niveau de juge de ligne',
        minOfficial: "Niveau d'officiel",
        minScorekeeper: 'Niveau de marqueur',
        minTimekeeper: 'Niveau de chronométreur',
        minRefereeGrade: "Grade d'arbitre",
        minLinespersonGrade: 'Grade de juge de ligne',
        minAge: 'Âge minimum',
        assignType: "Assigné via",
        status: 'Statut initial',
      },
      actions: {
        add: "Ajouter une règle d'assignation",
      },
      labels: {
        card: {
          title: 'Spécifications du match',
          subtitle: "Lorsqu'un match est créé ou mis à jour, ces règles sont traitées pour déterminer les conditions des officiels."
        },
        alt_card: {
          title: 'Détermination des assignateurs',
          subtitle: "Lorsqu'un match est créé ou mis à jour, ces règles sont traitées pour déterminer quel organisation assignera les matchs."
        },
        edit: "Modifier une règle d'assignation",
        rule: 'Règle',
        default_rules: 'Règles par défaut',
        criteria: 'Critère de match',
        duration: 'Durée: >= %{duration} min',
        days: 'Jours: %{days}',
        time_before: 'Heure: Avant %{time}',
        effective: 'En vigueur: %{date}',
        rules: {
          feesId: 'Frais',
          officeId: 'Assigné par',
          minReferee: "Niveau d'arbitre: %{value}",
          minLinesperson: 'Niveau de juge de lignes: %{value}',
          minOfficial: "Niveau d'officiel: %{value}",
          minScorekeeper: 'Niveau de marqueur: %{value}',
          minTimekeeper: 'Niveau de chronométreur: %{value}',
          minGrade: "Grade d'officiel: %{value}",
          minRefereeGrade: "Grade d'arbitre: %{value}",
          minLinespersonGrade: 'Grade de juge de ligne: %{value}',
          minAge: 'Âge minimum: %{value}',
          assignType: 'Assigné via %{value}',
          status: 'Statut initial: %{value}',
        },
        apply_rules: "Appliquer les règles d'assignation",
        applied_settings: 'Paramètres appliqués',
        assignOffices: "Appliquer les paramètres d'assignation",
        scheduleOffices: 'Mettre à jour les assignateurs de match'
      },
      helpers: {
        assignType: "L'assignateur sera automatiquement déterminé en fonction de l'organisation apparentée",
        assignTypes: {
          Arena: "L'assignateur sera automatiquement déterminé en fonction de l'organisation du lieu apparenté",
          'Arena Primary': "L'assignateur sera automatiquement déterminé en fonction de l'organisation principal du lieu",
          Schedule: "L'assignateur sera automatiquement déterminé en fonction de l'organisation de l'horaire",
          Home: "L'assignateur sera automatiquement déterminé en fonction de l'organisation de l'équipe à domicile",
          'Home Parent': "L'assignateur sera automatiquement déterminé en fonction de l'organisation parent de l'équipe à domicile",
          Office: "L'assignateur sera automatiquement déterminé en fonction de l'organisation du match",
          Zone: "L'assignateur sera automatiquement déterminé en fonction de la zone",
        },
        officeId: "Les matchs seront attribués par l'organisation sélectionnée",
        scheduledBy: "Cette règle s'applique aux matchs programmés par ou sous cette organisation",
        assignedBy: "Cette règle s'applique aux matchs attribués par ou sous cette organisation",
        categoryIds: 'Optionel - Si vide, toutes les catégories',
        types: "Optionel - Si vide, tous les types d'horaires",
        groupIds: 'Optionel - Si vide, tous les groupes',
        duration: 'Optionel - En minutes',
        stopInheritance: "Si cette règle est activée, elle l'emportera sur n'importe quelle règle correspondante des sous-organizations",
        applied_settings: "Optionnel - sélectionnez un sous-ensemble de paramètres à appliquer, sinon, si aucun n'est sélectionné, tous les paramètres seront appliqués"
      },
      values: {
        types: TYPES,
      },
      validations: {
        conflicts: "Modifier l'ordre pour résoudre les critères qui se chevauchent",
        inheritance: "Ce critère chevauche une règle avec une plus grande priorité qui l'empêche d'être remplacé",
        archived_fee: "Impossible d'ajouter ou de modifier une règle d'assignation dans une structure de paie archivée",
      },
      alerts: {
        existing_games: "Sélectionnez les matchs auxquels appliquer les règles d'assignation. Les configurations existantes seront écrasées.",
        scheduleOffices: "Cette action redéfinira l'assignateur en fonction des détails du match et définit les paramètres d'assignation en fonction de cet assignateur.",
        assignOffices: "Cette action définira les paramètres en fonction de l'assignateur existant.",
        new_games_only_title: "Les règles d'assignation ne s'appliquent qu'aux matchs nouvellement créés et reprogrammées",
        new_games_only: "Pour réappliquer les règles d'assignation aux matchs existants, cliquez sur Appliquer les règles d'assignation dans le menu des actions.",
        determined_by: "L'organisation d'assignation a été déterminé par \"%{rule}\" de %{office}."
      },
      notifications: {
        apply_rules_success: "Les règles d'assignation ont été appliquées à %{smart_count} match. |||| Les règles d'assignation ont été appliquées à %{smart_count} matchs."
      },
      tooltips: {
        determine_via: `
          <div>
            <h3>L'assignateur peut être déterminé via les propriétés suivantes du match:</h3>
            <ul>
              <li>
                <strong>Lieu</strong>
                <ul>
                  <li>Basé sur les organisations du lieu.</li>
                  <li>Si l'équipe locale appartient à l'un de ces organisations, l'organisation de cette équipe deviendra l'assignateur.</li>
                  <li>Sinon, ca recherchera un organisation à partir des restrictions de ligue de l'organisation du lieu.</li>
                  <li>Autrement, l'organisation principal du lieu deviendra l'assignateur.</li>
                </ul>
              </li>
              <li>
                <strong>Organisation primaire du lieu</strong>
                <ul>
                  <li>L'organisation principal du lieu deviendra l'assignateur.</li>
                  <li>Sinon, aucun assignateur ne sera choisi.</li>
                </ul>
              </li>
              <li>
                <strong>Horaire</strong>
                <ul>
                  <li>L'organisation de l'horaire deviendra l'assignateur.</li>
                </ul>
              </li>
              <li>
                <strong>Organisation de l'équipe locale</strong>
                <ul>
                  <li>L'organisation de l'équipe locale deviendra l'assignateur.</li>
                  <li>Si indéterminé (TBA/TBD), cela revient à la détermination de l'assignateur par le "Lieu" ci-dessus.</li>
                </ul>
              </li>
              <li>
                <strong>Organisation parent de l'équipe locale</strong>
                <ul>
                  <li>Identique a "Organisation de l'Équipe Locale", mais basé sur l'organisaton parent de l'organisation de l'équipe locale.</li>
                </ul>
              </li>
              <li>
                <strong>Zone</strong>
                <ul>
                  <li>Basé sur les organisations du lieu, si un organisation de type Zone est disponible, il sera utilisé comme assignateur.</li>
                  <li>Autrement, cela revient a l'organisation principal du lieu.</li>
                </ul>
              </li>
            </ul>
            <p>
              Si l'organisation déterminé est un organisation de type Ligue, Tournoi ou Coupe, selon l'une de ces règles, l'organisation parent sera sélectionné à la place.
            </p>
          </div>
        `
      }
    },
    assignSettings: {
      name: 'Paramètre |||| Paramètres',
      labels: {
        required_level: 'Niveau requis',
        required_grade: 'Grade requis',
        required_age: 'Âge requis',
      },
      messages: {
        assigned_by: 'Assigné par %{name}',
      },
      helpers: {
        systemId: 'Les assignation actuelles ne correspondent pas aux postes disponibles',
      },
      validations: {
        invalid_level: 'Niveau non valide',
        invalid_grade: 'Note non valide',
        invalid_age: 'Âge invalide',
        min_setting_range: 'Doit être supérieur ou égal à 0.',
      },
    },
    assignSystems: {
      name: "Système d'assignation |||| Systèmes d'assignation",
      fields: {
        name: 'Nom',
        referees: 'Arbitres',
        linespersons: 'Juges de ligne',
        linespersonsTwo: 'Juges de ligne 2',
        linespersonsThree: 'Juges de ligne 3',
        officials: 'Officiels',
        scorekeepers: 'Marqueurs',
        timekeepers: 'Chronométreurs',
        supervisors: 'Superviseurs',
      },
      actions: {
        add: "Ajouter un système d'assignation",
      },
      labels: {
        card: {
          title: "Système d'assignation",
          subtitle: "Les systèmes d'assignation déterminent le nombre d'officiels nécessaires pour un match.",
        },
        edit: "Modifier un système d'assignation",
      },
    },
    assignTargets: {
      name: "Règle de remplacement de l'assignateur |||| Règles de remplacement de l'assignateur",
      fields: {
        targetType: 'Type',
        targetId: 'Source',
        groupIds: 'Groupes',
        officeId: 'Cédant',
      },
      actions: {
        add: "Ajouter une règle de remplacement de l'assignateur",
      },
      labels: {
        card: {
          title: "Règles de remplacement de l'assignateur",
          subtitle: "Remplacer les règles de remplacement d'assignation pour utiliser un assignateur spécifique pour un horaire ou à la place d'un autre assignateur, éventuellement limité par des groupes.",
        },
        edit: "Mettre à jour une règle de remplacement de l'assignateur",
        add_override: 'Ajouter une règle de remplacement',
      },
      validations: {
        missing_type: 'Type manquant',
        unique_target: 'Une règle de remplacement est déjà définie',
        unique_group: 'Une règle de remplacement est déjà définie pour ce groupe',
      }
    },
    pages: {
      name: 'Page |||| Pages',
      fields: {
        title: 'Titre',
        slug: 'Slug',
        order: 'Ordre',
        content: 'Contenu',
        sectionId: 'Section',
        coverId: 'Image de couverture',
        officeId: 'Organisation',
      },
      actions: {
        edit: 'Modifier la page',
      },
      labels: {
        add: 'Nouvelle page',
      },
      helpers: {
        order: "Optionnel: par défaut, ordre alphabétique",
      },
    },
    posts: {
      name: 'Publications |||| Nouvelles',
      fields: {
        title: 'Titre',
        slug: 'Slug',
        date: 'Date',
        content: 'Contenu',
        coverId: 'Image de couverture',
        officeId: 'Organisation',
      },
      actions: {
        edit: 'Modifier le poste',
      },
      labels: {
        add: 'Nouveau poste',
      },
      messages: {
        start_writing_here: 'Commencez à écrire ici...',
      }
    },
    registrations: {
      name: 'Inscription |||| Inscriptions',
      fields: {
        seasonId: 'Saison',
        divisionId: 'Division',
        participantId: 'Participant',
        officeId: 'Organisation',
      },
      actions: {
        add: 'Ajouter une inscription',
      },
      labels: {
        card: {
          title: 'Inscriptions',
        },
        edit: "Mettre à jour l'inscription",
        import: 'Importer des inscriptions',
        file: "Fichier d'inscriptions",
      },
      messages: {
        drop_registrations_csv: "Déposez ici votre fichier d'inscriptions (.csv)",
        use_import_tool: "Pour utiliser cet outil d'importation, téléchargez un fichier CSV avec des colonnes pour le numéro de membre, le prénom, le nom et la division enregistrée du participant.",
      },
      alerts: {
        create_new_participants: "<strong>Utiliser avec précaution.</strong> Cet outil d'importation créera de nouveaux participants qui ne correspondent pas aux numéros de membre existants.",
      },
      helpers: {
        select_columns: "Il vous sera demandé de sélectionner des colonnes après avoir sélectionné votre fichier",
        valid_registrations: '%{number}/%{total} lignes ont des inscriptions valides',
        match_existing_participant: 'Ce numéro de membre est utilisé pour faire correspondre un participant existant ou créer un nouveau participant pour cette inscription.',
      },
      notifications: {
        registrations_updated: '%{number} inscriptions ont été mises à jour.',
        import_registrations_error: "Impossible d'importer les inscriptions.",
      },
    },
    sections: {
      name: 'Section |||| Sections',
      fields: {
        name: 'Nom',
        order: 'Ordre',
        officeId: 'Organisation',
      },
      actions: {
        add: 'Ajouter une section',
      },
      labels: {
        card: {
          title: 'Sections',
          subtitle: "Sections d'en-tête du site Web pour organiser les pages.",
        },
        edit: 'Mise à jour de la section',
      }
    },
    webevents: {
      name: 'Événement |||| Événements',
      fields: {
        title: 'Titre',
        content: 'Contenu',
        startTime: 'Heure de début',
        endTime: 'Heure de fin',
        allDay: 'Toute la journée',
        location: 'Lieu',
        officeId: 'Organisation'
      },
      actions: {
        edit: "Modifier l'événement",
      },
      labels: {
        card: {
          title: 'Événements',
        },
        add: 'Nouvel événement',
        dates: 'Dates',
      },
    },
    announcements: {
      name: 'Annonce |||| Annonces',
      fields: {
        title: 'Titre',
        seasonId: 'Saison',
        type: 'Type',
        content: 'Contenu',
        targetType: 'Type de destinataires',
        targetId: 'Destinataires',
        participantId: 'Auteur',
        officeId: 'Organisation',
      },
      actions: {
        edit: "Modifier l'annonce",
      },
      labels: {
        officiating: 'Annonces des officiels',
        add: 'Nouvelle annonce',
        edit: "Mise à jour d'une annonce",
      },
      messages: {
        send_email_officials: 'Envoyer un courriel aux officiels',
        recipients: {
          all_team_staff: "tout le personnel de l'équipe",
          all_available_officials: 'tous les officiels disponibles',
          all_team_staff_all_available_game_officials: "tout le personnel de l'équipe et les officiels de match disponibles",
          all_list_members: 'tous les membres de la liste',
        }
      },
      helpers: {
        destination_sent: 'Un courriel sera envoyé à %{recipient} qui ont un contact vérifié',
        destination_not_nsent: 'Les notifications ne sont pas envoyées pour les mises à jour.',
      },
      values: {
        type: {
          General: 'Général',
          League: 'Ligue',
          Officiating: 'Officiel',
        },
        targetType: {
          Office: 'Organisation',
          List: 'Liste',
        },
      }
    },
    lists: {
      name: 'Liste |||| Listes',
      fields: {
        name: 'Nom de la liste',
        officeId: 'Organisation',
        members: 'Membres'
      },
      actions: {
        add: 'Ajouter une liste',
        edit: "Modifier la liste",
      },
      labels: {
        card: {
          title: 'Listes',
          subtitle: "Les assignateurs peuvent utiliser des listes comme filtre lors de l'assignation de matchs.",
        },
        official: 'Liste des officiels',
        import_list_members: "Importer la liste de membres",
        list_members_file: 'Fichier de la liste de membres',
      },
      messages: {
        drop_list_members_csv: 'Déposez le fichier de la liste de membres (.csv) ici',
        import_tool_list_members: "Pour utiliser cet outil d'importation, téléchargez un fichier CSV avec des colonnes pour le numéro de membre des officiels.",
      },
      validation: {
        already_exists: 'Déjà dans cette liste',
      },
      helpers: {
        match_existing_participant: "Les participants seront ignorés s'ils ne sont pas des officiels qualifiés.",
        create_list: "Une liste doit être créée avant d'importer des membres.",
        quick_assign_tooltip: `Ce filtre est utilisé pour l'assignation. Sélectionnez un organisation d'assignation pour l'utiliser.`,
      },
      notifications: {
        list_members_added: "%{smart_count} officiel a été ajouté à la liste. |||| %{smart_count} officiels ont été ajoutés à la liste.",
        import_list_members_error: "Impossible d'importer la liste de membres.",
      },
    },
    infractions: {
      name: 'Infraction |||| Infractions',
      fields: {
        seasonId: 'Saison',
        officeId: 'Organisation',
        ruleId: 'Règle',
        code: 'Code',
        typeId: 'Type',
        severity: 'Sévérité',
        parentId: 'Infraction parente',
        externalId: 'ID externe',
        legacyName: 'Ancien nom',
        legacyDuration: 'Ancienne durée'
      },
      actions: {
        add: 'Ajouter une infraction',
      },
      labels: {
        card: {
          title: 'Infractions',
          subtitle: "Une pénalité est le résultat d'une infraction.",
        },
        add: 'Nouvelle infraction',
        edit: 'Modifier une infraction',
        parent_code: 'Code parent',
        parent_office: 'Organisation parent'
      },
      helpers: {
        parentId: "Optionnel - Lier cette infraction à une infraction de l'organisation parent",
      },
      values: {
        severity: {
          Low: 'Basse',
          Medium: 'Moyenne',
          High: 'Haute',
          Severe: 'Sévère',
        },
      }
    },
    infractionTypes: {
      name: "Type d'infraction |||| Types d'infractions",
      fields: {
        officeId: 'Organisation',
        name: 'Nom',
        abbreviation: 'Abbréviation',
        description: 'Description',
        duration: 'Durée',
        isShorthanded: 'Équipe en infériorité numérique',
        isEjection: 'Joueur fautif éjecté',
        order: "Ordre d'affichage",
        servingOrder: "Ordre d'exécution",
      },
      helpers: {
        isShorthanded: "Si l'équipe se retrouve en infériorité numérique ou si une substitution est autorisée",
        isEjection: "La pénalité entraîne l'expulsion du joueur fautif du match.",
      },
      actions: {
        add: 'Ajouter un type',
      },
      labels: {
        card: {
          title: "Types d'infraction",
          subtitle: 'Les types déterminent le comportement des infractions.',
        },
        add: 'Nouveau type',
        edit: 'Modifier le type',
      },
    },
    ruleSections: {
      name: 'Section de règle |||| Sections de régle',
      fields: {
        seasonId: 'Saison',
        officeId: 'Organisation',
        name: 'Nom',
        code: 'Code',
        order: "Ordre d'affichage",
      },
      actions: {
        add: 'Ajouter une section de règle',
      },
      labels: {
        card: {
          title: 'Règles de jeu',
          subtitle: "Ensemble de règles détaillant les infractions et les options correspondantes disponibles pour l'entrée des résultats.",
        },
        add: 'Nouvelle section',
        edit: 'Modifer une section',
      },
    },
    rules: {
      name: 'Règle |||| Règles',
      fields: {
        seasonId: 'Saison',
        officeId: 'Organisation',
        sectionId: 'Section',
        name: 'Nom',
        code: 'Code',
        order: "Ordre d'affichage",
      },
      actions: {
        add: 'Ajouter une règle',
      },
      labels: {
        add: 'Nouvelle règle',
        edit: 'Modifier une règle',
      },
    },
    ruleOptions: {
      name: 'Option de règle |||| Options de règle',
      fields: {
        seasonId: 'Saison',
        officeId: 'Organisation',
        ruleId: 'Règle',
        members: 'Membres',
        parentId: 'Option parente',
        order: "Ordre d'affichage",
      },
      actions: {
        add: 'Ajouter une option de règle'
      },
      labels: {
        card: {
          title: 'Options de règles',
          subtitle: "Les choix disponibles lors de l'entrée des résultats.",
        },
        add: 'Nouvelle option de règle',
        edit: 'Modifier une option de règle',
      },
    },
    ruleOptionMembers: {
      name: "Membre d'une option |||| Membres d'une option",
      fields: {
        optionId: 'Option de règle',
        infractionId: 'Infraction',
        order: 'Ordre',
      },
      actions: {
        add: 'Ajouter une infraction'
      },
      labels: {
        add: 'Ajouter une option de règle',
        edit: 'Modifier une option de règle',
      },
    },
    infractionAccumulations: {
      name: 'Infraction automatique |||| Infractions automatiques',
      fields: {
        seasonId: 'Saison',
        officeId: 'Organisation',
        name: 'Nom',
        count: "Compte d'accumulation",
        members: "Infractions cumulatives",
        infractionId: 'Infraction résultante',
        isReplacement: 'Remplacement'
      },
      actions: {
        add: "Ajouter une règle d'infraction automatique"
      },
      labels: {
        card: {
          title: "Règles d'infraction automatique",
          subtitle: "Règles d'accumulation pour déclencher une infraction lorsque les conditions sont remplies au cours du même match.",
        },
        add: "Ajouter une règle d'infraction automatique",
        edit: "Modifier une règle d'infraction automatique",
      },
      helpers: {
        isReplacement: "Si activé, l'infraction résultante remplacera la pénalité originale au lieu d'en créer une nouvelle."
      }
    },
    infractionAccumulatiomMembers: {
      name: "Membre d'une accumulation |||| Membres d'une accumulation",
      fields: {
        accumulationId: 'Accumulation',
        infractionId: 'Infraction',
      },
      actions: {
        add: 'Ajouter une infraction'
      },
      labels: {
        add: 'Ajouter un membre',
        edit: 'Modifier un membre',
      },
    },
    rulebooks: {
      name: 'Livre des règlements |||| Livre des règlements',
      fields: {
        name: 'Nom',
        infractions: 'Infractions',
        ejections: 'Expulsion automatiques du match',
        doubleminors: 'Doubles Mineurs',
        variances: "Changements",
        officeId: 'Organisation',
        seasonId: 'Saison',
        category: 'Catégorie',
        categoryIds: 'Catégories',
        code: 'Code',
        choices: 'Choix',
        durations: 'Durées',
        duration: 'Durée',
        isStick: 'Infraction au bâton',
        isArchived: 'Archivé',
        description: 'Brève description',
        length: 'Durée (secondes)',
        requireIncidentReport: "Rapport d'incident requis",
        timed: 'Durée temporisée',
        lineupLimits: "Limites d'alignement",
        scheduleTypes: "Types d'horaire",
        pitcherLimits: "Limites des lanceurs",
        throwsLimits: "Repos des balles lancées",
        inningsPitchedLimits: "Limites des manches lancées",
        inningsPitchedRests: "Repos des manches lancées",
        limitType: 'Type de limite',
        restingDays: "Repos",
        startDate: "Date de début",
        endDate: "Date de fin",
      },
      actions: {
        add: 'Ajouter un livre des règlements',
        add_infraction: 'Ajouter une infraction',
        add_duration: 'Ajouter une durée',
        add_variance: 'Ajouter un changement',
        add_limits: "Ajouter des limites",
      },
      labels: {
        card: {
          title: "Limites d'alignement",
          baseball_title: "Limites d'alignement / manches",
          subtitle: "Politiques pour le nombre minimum / maximum de membres autorisés lors des matchs.",
          baseball_subtitle: "Politiques pour les limites de lancer et le nombre minimum / maximum de membres autorisés lors des matchs.",
        },
        add: 'Nouveau livre des règlements',
        add_limits: 'Ajouter des limits',
        edit: 'Mettre à jour le livre des règlements',
        edit_infraction: "Mise à jour de l'infraction",
        edit_duration: 'Mise à jour de la durée',
        edit_variance: 'Mise à jour du changement',
        edit_limits: "Mise à jour des limites",
        position: 'Position',
        players: 'Joueurs',
        skaters: 'Patineurs',
        goalies: 'Gardiens de but',
        staff: 'Personnel',
        durations: 'Durées',
        infractions: 'Infractions',
        lineup_limits: "Limites d'alignement",
        pitcher_limits: "Limites des lanceurs",
        variances: 'Changements',
        additional_policies: 'Politiques supplémentaires',
        throws_limits: "Repos des balles lancées",
        innings_pitched_limits: "Limites des manches lancées",
        innings_pitched_rests: "Repos des manches lancées"
      },
      alerts: {
        durations: 'Les durées sont utilisées pour créer des infractions',
        infractions: "Les infractions dans les livres des règlements des organisations parents seront héritées (mais ne sont pas affichées ici). Cette organisation peut les remplacer en utilisant le même nom d'infraction.",
        lineup_limits: 'La valeur par défaut est illimitée, sauf indication contraire ou héritée des organisations parents.',
        pitcher_limits: 'La valeur par défaut est illimitée, sauf indication contraire ou héritée des organisations parents.',
        additional_policies: 'Les politiques supplémentaires peuvent être laissées en blanc pour être héritées des organisations parents, le cas échéant.',
        rulebook_independence: "Si aucune règle n'est définie au niveau de cette organisation, les matchs hériteront des règles de la fédération."
      },
      helpers: {
        ejections: 'Une infraction "Expulsion du match" sera automatiquement ajoutée après 3 pénalités des types sélectionnés',
        name: 'Remarque: Le nom ne peut pas être modifié une fois le livre des règlements enregistré',
        abbreviation: 'Remplace la durée des pénalités dans la feuille de match officielle',
        requireIncidentReport: "Un rapport d'incident sera nécessaire pour les pénalités de cette durée.",
        timed: 'Les pénalités avec des durées temporisées prend en consideration le temps que le joueur dois servire',
        isStick: "L'infraction sera comptabilisée dans la politique d'expulsion d'infraction de bâton, le cas échéant.",
        isArchived: "Les infractions archivées ne sont pas affichées lors de l'ajout d'une pénalité. Utilisez cette option pour retirer une infraction au lieu de la supprimer.",
      },
      validation: {
        min: "Min doit être plus petit que max",
        max: "Max doit être plus grand que min",
        startDate: "La date de début doit être avant la date de fin",
        endDate: "La date de fin doit être après la date de début",
      },
      values: {
        lineupLimits: {
          between: 'Entre %{min} et %{max} %{position}',
          min: 'Minimum de %{min} %{position}',
          max: "Maximum de %{max} %{position}",
        },
        pitcherLimits: {
          scheduleTypes: {
            ...TYPES,
            League: 'Saison régulière'
          },
          limitType: {
            Daily: 'Journalière',
            Weekly: 'Hebdomadaire',
          },
          throwsLimits: {
            between: 'Entre %{min} et %{max} lancers = %{restingDays} jour(s) de repos',
          },
          inningsPitchedLimits: {
            always: '%{limitType}: %{limit} manche(s) max',
            from: '%{limitType}: %{limit} manche(s) max à partir du %{startDate}',
            between: '%{limitType}: %{limit} manche(s) max entre %{startDate} et %{endDate}',
            until: "%{limitType}: %{limit} manche(s) max jusqu'au %{endDate}",
          },
          inningsPitchedRests: {
            between: 'Entre %{min} et %{max} manche(s) = %{restingDays} jour(s) de repos',
          },
        },
        ejections: {
          None: 'Rien',
          Contact: 'Contact à la tête et infractions de bâton',
          All: 'Toutes les infractions',
        },
        severity: {
          low: 'Faible',
          medium: 'Moyen',
          high: 'Élevé',
          severe: 'Sévère',
        }
      }
    },
    help: {
      name: 'Article |||| Aide',
      fields: {
        title: 'Titre',
        content: 'Contenu',
        category: 'Catégorie',
        roles: "S'applique à",
        isPublished: 'Publié',
        updatedAt: 'Dernière mise à jour',
      },
      actions: {
        edit: "Modifier l'article",
      },
      labels: {
        add: 'Nouvel article',
        documentation: 'Documentation',
        help_center: "Centre d'aide",
      },
      alerts: {
        article_not_published_yet: "Cet article n'a pas encore été publié.",
      },
      helpers: {
        roles: "L'article ne sera visible que pour les rôles sélectionnés",
      },
      values: {
        roles: {
          'super-admin': 'Super Administrateurs',
          admin: 'Administrateurs',
          registrar: 'Registraires',
          director: 'Directeurs de ligue',
          manager: 'Gestionnaires de ligue',
          organizer: 'Organisateurs de cédule',
          'assigning-manager': 'Gestionnaires des assignateurs',
          assigner: 'Assignateurs',
          official: 'Arbitres',
          player: 'Joueurs',
          coach: "Personnel d'équipe",
          assistant: "Assistants d'équipe",
          association: 'Associations',
          editor: 'Editeurs de Site Web',
          scorekeeper: 'Marqueurs',
        }
      }
    },
    reports: {
      name: 'Rapport |||| Rapports',
      messages: {
        empty: "Votre ligue n'a rendu aucun rapport disponible.",
        error: 'Impossible de télécharger vos rapports (%{error}). Vous devrez réessayer plus tard.',
      },
      values: {
        scheduleType: TYPES,
        types: {
          AP: "Affiliation",
          Admin: "Administration",
          Assigning: "Assignation",
          Penalty: "Pénalité",
          Scoring: "Statistique"
        },
      }
    },
    seasons: {
      name: 'Saison |||| Saisons',
      fields: {
        seasonId: 'Saison',
      },
      alerts: {
        viewing_inactive_season: "Vous êtes présentement en train de voir la saison %{season}, qui n'est pas la saison courante.",
      },
      values: {
        seasonId: {
          '2025-26': '2025-26',
          '2024-25': '2024-25',
          '2023-24': '2023-24',
          '2022-23': '2022-23',
          '2021-22': '2021-22',
          '2020-21': '2020-21',
          '2019-20': '2019-20',
          '2018-19': '2018-19',
        }
      }
    },
    identities: {
      name: 'Identité |||| Identités',
      fields: {
        accountId: 'Compte',
        participantId: 'Participant',
        isPrimary: 'Primaire',
      },
      actions: {
        add: 'Ajouter une identité',
      },
      labels: {
        edit: "Mettre à jour l'identité",
      },
      helpers: {
        isPrimary: "L'identité primaire est le participant par défaut que le compte verra après s'être connecté.",
      }
    },
    groups: {
      name: 'Groupe |||| Groupes',
      fields: {
        seasonId: 'Saison',
        name: 'Nom',
        type: 'Type',
        categoryIds: 'Catégories',
      },
      actions: {
        add: 'Ajouter un groupe',
      },
      labels: {
        card: {
          title: 'Groupes',
          subtitle: "Divisez les horaires en plusieurs cohortes au sein de cette ligue. Lors de l'ajout d'équipes ou de matchs à un horaire, il vous sera demandé de sélectionner un groupe.",
        },
        edit: 'Mettre à jour le groupe',
      },
      messages: {
        all_groups: 'Tous les groupes',
      },
      helpers: {
        categoryIds: 'Optionel - Si vide, toutes les catégories',
      },
      values: {
        type: {
          ...TYPES,
          Jamboree: 'Jamboree',
          Challenge: 'Défi',
          Invitational: 'Invitation',
          'Program Of Excellence': "Programme d'excellence",
          'Evaluation Camp': "Camp d'évaluation",
        },
      },
    },
    suspensions: {
      name: 'Suspension |||| Suspensions',
      fields: {
        penaltyId: 'Pénalité',
        teamId: 'Équipe',
        participantId: 'Participant',
        requiredGames: 'Matchs à servir',
        positionGroup: 'Position',
        code: 'Code',
        gameId: 'Match',
        status: 'Statut',
        durationType: 'Type',
        expiry: "Date d'expiration",
        additionalPurgingTeamId: 'Équipe additionnelle éligible de purger',
        disciplineStatus: 'Statut de discipline',
        isAutomatic: 'Automatique',
      },
      labels: {
        edit: 'Modifier la suspension',
        playing: 'Joue quand même',
        played: 'Joué quand même',
        purging: 'Purge',
        purged: 'Purgé',
        not_serving: 'Ne purge pas',
        suspended: 'Suspendu',
        not_suspended: 'Non suspendu',
        admin: 'Administrative',
        indefinite: 'Indéterminée',
        manual_direct: 'Autre',
        type: 'Type',
        manual: 'Suspension manuel',
        automatic: 'Suspension automatique',
        details: 'Détails',
        participant_history: 'Historique des suspensions du participant',
        requiredGames: 'Match(s) Initial(aux)',
        disciplineAdditionalGames: 'Match(s) supplémentaire(s)',
        effectiveRequiredGames: 'Match(s) total(aux)'
      },
      actions: {
        add: 'Suspension',
      },
      messages: {
        playing: 'Joue quand même en étant suspendu',
        unpurgeable: "Ne purge pas en raison d'une équipe différente",
        required_games: '%{smart_count} Match |||| %{smart_count} Matchs'
      },
      alerts: {
        automatic: {
          title: 'Suspension automatique',
          message: 'Cette suspension a été automatiquement attribuée par une règle de sanction et ne peut pas être modifiée. Des matchs supplémentaires peuvent être ajoutés par le comité de discipline ou en ajoutant une nouvelle suspension pour le même participant et le même match.',
        },
      },
      values: {
        positionGroup: {
          Player: 'Joueur',
          Staff: 'Personnel'
        },
        code: {
          'Game Red': '2e Avertissement du match',
          'Game Red Direct': 'Expulsion du match',
          'Game Ejection': '1ere Expulsion du match',
          'Schedule Yellow': "3e Avertissement dans l'horaire",
          supplemental: 'Supplémentaire',
          supplemental_long: 'Suspension supplémentaire'
        },
        status: {
          Pending: 'En attente',
          Appealed: 'En contestation',
          Active: 'Actif',
          Completed: 'Complété',
          Cancelled: 'Annulé'
        },
        durationType: {
          Definite: 'Définie',
          Indefinite: 'Indéterminée'
        }
      }
    },
    suspensionDisciplines: {
      name: 'Discipline |||| Disciplines',
      fields: {
        suspensionId: 'Suspension',
        additionalGames: 'Match supplémentaires',
        status: 'Statut',
        durationType: 'Type de durée',
        comments: 'Commentaires',
      },
      labels: {
        edit: 'Modifier la discipline',
        confirm_trigger: 'Déclencher le comité de discipline',
        discipline_committee: 'Comité de discipline',
      },
      messages: {
        confirm_trigger: "Cela déclenchera le comité de discipline pour qu'il examine cette suspension et prenne les mesures nécessaires. Êtes-vous sûr de vouloir faire cela?",
      },
      alerts: {
        additionalGames: 'Les matchs supplémentaires ne sont appliqués que lorsque le statut est défini à <strong>Actif</strong>. Pour réduire le nombre total de matchs, utilisez une valeur négative.',
      },
      actions: {
        add: 'Discipline supplémentaire',
        trigger: 'Déclencher le comité de discipline',
      },
      values: {
        status: {
          Pending: 'En attente',
          Active: 'Actif',
          Inactive: 'Inactif',
        },
        durationType: {
          Definite: 'Définie',
          Indefinite: 'Indéterminée'
        }
      },
      validations: {
        must_be_empty: "Ce champ doit être vide quand type de durée est indéterminée",
        must_be_greater_than_zero: 'Le nombre total de matchs doit être supérieur à 0',
      },
      notifications: {
        trigger_success: 'Le comité de discipline a été déclenché',
        trigger_error: 'Impossible de déclencher le comité de discipline',
      },
      helpers: {
        totalGames: 'Matchs total: %{totalGames}',
      }
    },
    sanctions: {
      name: 'Sanction |||| Sanctions',
      fields: {
        name: 'Nom',
        code: 'Code',
        order: 'Ordre',
        sectionId: 'Section',
        parentId: 'Parent',
        accumulationCount: "Compte d'accumulation",
        positionGroups: 'Groupes de position',
        isActive: 'Actif',
      },
      labels: {
        card: {
          title: "Règles de sanction",
          subtitle: "Règles d'accumulations de sanction pour déclencher une suspension automatique lorsque les conditions sont remplies sur un ou plusieurs matchs."
        },
        edit: 'Modifer la sanction',
        parent_code: 'Code parent',
        parent_office: 'Organisation parent'
      },
      actions: {
        add: 'Ajouter une sanction',
      },
      helpers: {
        parentId: "Optionnel - Lier cette sanction à une sanction de l'organisation parent"
      },
      values: {
        positionGroup: {
          Player: 'Joueur',
          Staff: 'Personnel'
        },
        durationType: {
          Definite: 'Définie',
          Indefinite: 'Indéterminée'
        }
      }
    },
    sanctionMembers: {
      name: 'Sanction Member |||| Sanction Members',
      fields: {
        sanctionId: 'Sanction',
        optionId: "Option de règle",
        infractionId: 'Infraction'
      },
      labels: {
        card: {
          title: "Accumulations",
          subtitle: 'Options de règles ou infractions qui déclenchent une offense.'
        },
        edit: "Modifier une règle",
        rule: 'Règle',
        code: 'Code',
      },
      actions: {
        add: 'Ajouter une règle',
      },
    },
    sanctionOffenses: {
      name: 'Offense de sanction |||| Offenses de sanction',
      fields: {
        offense: "Numéro d'offense",
        sanctionId: 'Sanction',
        durationType: 'Type de durée',
        games: 'Matchs',
        lastMinutesGames: 'Matchs dernières minutes',
        injuryGames: 'Matchs additionnel blessures',
        injuryDays: 'Jours additionnel blessures',
        days: 'Jours',
        lastMinutesDays: 'Jours dernières minutes',
        requireDisciplineCommittee: 'Nécessite le comité de discipline',
      },
      labels: {
        card: {
          title: 'Offenses',
          subtitle: 'Nombre de matchs pour la suspension générée.'
        },
        edit: 'Modifier une offense',
      },
      actions: {
        add: 'Ajouter une offense',
      },
      helpers: {
        games: 'Nombre de matchs pour la suspension générée.',
        days: "Nombre de jours pour calculer la date d'expiration de la suspension générée.",
        lastMinutesGames: "Nombre de matchs pour la suspension générée si l'infraction s'est produite dans les %{minutes} dernières minutes du match",
        lastMinutesDays: "Nombre de jours pour calculer la date d'expiration de la suspension générée si l'infraction s'est produite dans les %{minutes} dernières minutes du match",
        injuryGames: "Nombre de matchs additionnel pour la suspension générée si une blessure est survenue lors de l'infraction",
        injuryDays: "Nombre de jours additionnel pour calculer la date d'expiration de la suspension générée si une blessure est survenue lors de l'infraction"
      },
      values: {
        durationType: {
          Definite: 'Définie',
          Indefinite: 'Indéterminée'
        }
      }
    },
    officialClaimDocuments: {
      name: 'Documents de réclamation',
      fields: {
        gameId: 'Match',
        name: 'Nom du document',
        file: 'Fichier'
      },
      labels: {
        delete: 'Supprimer'
      },
      notifications: {
        deleted: 'Ce fichier a été supprimé'
      },
      messages: {
        info_creating_claim: 'Pour ajouter des documents, veuillez compléter, sauvegarder et ouvrir la réclamation.'
      },
    },
    officialClaims: {
      name: 'Réclamations',
      fields: {
        participantId: 'Participant',
        gameId: 'Match',
        officeId: 'Organisation',
        amount: 'Montant',
        distance: 'Distance',
        type: 'Type',
        note: 'Note',
        status: 'Statut',
        roundtrip: 'Aller-retour',
        addressId: 'Adresse',
        createdAt: 'Réclamé le',
        addressFrom: 'Adresse de départ',
        addressTo: "Adresse d'arrivée",
        intermediateStop: 'Adresse intermédiaire',
        hasIntermediateStop: 'Arrêt intermédiaire',
        calculateDistance: 'Calculer la distance',
        claimSettingId: 'Paramètre de réclamation'
      },
      labels: {
        card: {
          title: 'Réclamations'
        },
        edit: 'Modifier la réclamation |||| Modifier les réclamations',
        'Pending': 'Rendre la réclamation en attente |||| Rendre les réclamations en attente',
        'Approved': 'Approuver la réclamation |||| Approuver les réclamations',
        'Declined': 'Refuser la réclamation |||| Refuser les réclamations',
      },
      values: {
        type: {
          'Driver': 'Conducteur',
          'Passenger': 'Passager',
          'Breakfast': 'Déjeuner',
          'Lunch': 'Dîner',
          'Dinner': 'Souper',
          'Travel': 'Déplacement',
          'Expense': 'Dépense',
          'Per Diem': 'Per Diem'
        },
        status: {
          'Approved': 'Approuvée',
          'Pending': 'En attente',
          'Declined': 'Refusée',
        }
      },
      actions: {
        add: 'Réclamation',
        'Pending': 'En attente',
        'Approved': 'Approuver',
        'Declined': 'Refuser'
      },
      notifications: {
        'Pending': 'Réclamation en attente |||| Réclamations en attentes',
        'Approved': 'Réclamation approuvée |||| Réclamations approuvées',
        'Declined': 'Réclamation refusée |||| Réclamations refusées',
        pending_error: 'Impossible de rendre la réclamation en attente |||| Impossible de rendre les réclamations en attentes',
        approved_error: "Impossible d'approuver la réclamation |||| Impossible d'approuver les réclamations",
        declined_error: 'Impossible de refuser la réclamation |||| Impossible de refuser les réclamations'
      },
      helpers: {
        'Pending': 'Voulez-vous vraiment mettre cette réclamation en attente?',
        'Approved': "Approuver une réclamation créera une transaction correspondante.",
        'Declined': "Voulez-vous vraiment refuser cette réclamation?",
        no_claim_types: 'Aucun type de réclamations disponible pour cet assignateur',
        distance: 'Entrez la distance complète; les %{smart_count} premiers km sont automatiquement exclus',
        excluded_amount: 'après %{distance} km',
      },
      validations: {
        claim_approved: 'Impossible de modifier une réclamation approuvée',
      },
      messages: {
        claim_unavailable: 'Les réclamations seront activées seulement après la partie'
      }
    },
    officialTransactions: {
      name: "Transactions",
      fields: {
        participantId: 'Participant',
        gameId: 'Match',
        officeId: 'Organisation',
        date: 'Date',
        type: 'Type',
        subtype: 'Sous-type',
        amount: 'Montant',
        payPeriodId: 'Période de paie',
        description: 'Description',
        gameScheduleId: 'Horaire',
      },
      values: {
        type: {
          "Base": 'Base',
          "Travel": 'Frais de déplacement',
          "Per Diem": 'Per diem',
          "Evaluation": "Frais d'évaluation",
          "Exhibition Game": 'Match hors concours',
          "Internship": 'Frais de stage',
          "Withhold": 'Retenue de paiement',
          "Other": 'Autre paiement'
        },
        subtype: {
          'Driver': 'Conducteur',
          'Passenger': 'Passager',
          'Breakfast': 'Déjeuner',
          'Lunch': 'Dîner',
          'Dinner': 'Souper',
          'Travel': 'Déplacement',
          'Expense': 'Dépense',
          'Per Diem': 'Per Diem'
        }
      },
      messages: {
        select_all: 'Tout sélectionner',
        unselect_all: 'Tout déselectionner',
        group_by_game: 'Grouper par match',
        group_by_participant: 'Grouper par participant',
      },
      filters: {
        startTime: 'Date de début',
        endTime: 'Date de fin',
      },
      notifications: {
        assigned_pay_period: 'Transactions ajoutées à la période de paie',
        assign_pay_period_error: "Impossible d'ajouter à la période de paie",
        pay_period_removed: 'Période de paie retirée',
        remove_pay_period_error: 'Impossible de retirer la période de paie',
      },
      actions: {
        add: 'Transaction',
        edit: 'Modifier la transaction',
        assign_pay_period: 'Attribuer une période de paie',
        remove_pay_period: 'Retirer la période de paie'
      },
      validations: {
        from_pay_period_closed: "Impossible de déplacer une transaction hors d'une période de paie fermée",
        pay_period_closed: "Impossible de déplacer une transaction dans une période de paie fermée"
      }
    },
    payPeriods: {
      name: 'Période de paie |||| Périodes de paie',
      fields: {
        officeId: 'Organisation',
        name: 'Nom',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        isClosed: 'Fermé',
        closedAt: 'Date de fermeture',
      },
      labels: {
        add_transactions: 'Ajouter des transactions',
        close_period: 'Fermer la période de paie',
        dates: 'Dates',
        card: {
          title: 'Périodes de paie',
        },
        status: 'Statut',
        active: 'Actif',
        closed: 'Fermé (%{date})',
      },
      actions: {
        add: 'Période de paie',
        edit: 'Modifier la période de paie',
        add_transactions: 'Transactions',
      },
      filters: {
        isClosed: 'Fermé',
      },
      alerts: {
        period_closed: 'Période fermée',
        period_active: 'Période active',
        period_closed_message: 'Cette période a été clôturée le %{date} et ne peut plus être modifiée.',
        period_active_message: 'Lorsque toutes les transactions sont ajoutées, fermez la période pour empêcher les modifications.',
      },
      helpers: {
        close_period: 'La fermeture de la période de paie est irréversible et les transactions seront verrouillées.',
      },
      notifications: {
        pay_period_close: 'Period fermé',
        close_pay_period_error: 'Impossible de fermer la période de paie'
      },
      validations: {
        pay_period_closed: "Impossible d'apporter des modifications à une période de paie fermée"
      },
      values: {
        type: {
          "Base": 'Base',
          "Travel": 'Frais de déplacement',
          "Per Diem": 'Per diem',
          "Evaluation": "Frais d'évaluation",
          "Exhibition Game": 'Match hors concours',
          "Internship": 'Frais de stage',
          "Withhold": 'Retenue de paiement',
          "Other": 'Autre paiement'
        },
        subtype: {
          'Driver': 'Conducteur',
          'Passenger': 'Passager',
          'Breakfast': 'Déjeuner',
          'Lunch': 'Dîner',
          'Dinner': 'Souper',
        }
      }
    },
    gameIncidents: {
      name: "Rapport d'incident",
      fields: {
        penaltyId: 'Pénalité',
        description: 'Description',
        injured: 'Blessé',
        returnedToGame: 'Retourné au match',
        status: 'Statut',
        createdAt: 'Création',
        updatedAt: 'Mise à jour'
      },
      labels: {
        participantId: 'Participant',
        edit: 'Modifier un incident',
        injury: 'Blessure',
        returned: 'Retourné',
        notInjured: "Pas blessé"
      },
      actions: {
        add: 'Ajouter un incident',
      },
    },
    gamePurges: {
      name: 'Matchs purgés',
      fields: {
        gameId: 'Match',
        teamId: 'Équipe',
        participantId: 'Participant',
        suspensionId: 'Suspension'
      },
      labels: {
        games_purged: 'Matchs purgés'
      },
      messages: {
        no_results: 'Aucun matchs purgés'
      }
    },
    gamepenalties: {
      name: 'Pénalité |||| Pénalités',
      fields: {
        gameId: 'Match',
        teamId: 'Équipe',
        participantId: 'Participant',
        infraction: 'Infraction',
        gameTime: 'Temps',
      },
    },
    gameMessages: {
      name: 'Message |||| Messages',
      fields: {
        type: 'Type',
      },
      labels: {
        delete: 'Supprimer le message',
      },
      messages: {
        no_messages: 'Aucun message pour le moment',
      },
      helpers: {
        notification_sent_to: 'Une notification sera envoyée aux %{recipients} de ce match et aux officiels du match assignés.',
      },
      notifications: {
        send_messages_error: "Impossible d'envoyer le message",
        deleted: 'Message supprimé',
        delete_error: 'Impossible de supprimer le message',
      },
      values: {
        type: {
          League: 'Ligue',
          Officiating: 'Officiel',
        }
      }
    },
    scoresheets: {
      name: 'Feuille de match |||| Feuilles de match',
      fields: {
        gameId: 'Match',
        scoresheet: 'Feuille de match',
      },
      actions: {
        add_penalties: 'Pénalités',
        add_goals: 'Buts',
      },
      labels: {
        print: 'Imprimer',
        add_extra: 'Ajouter un extra',
        add_extra_same_office: 'Même organisation',
        photos: 'Photos',
        suspended: 'Suspendu',
        summary: 'Sommaire',
        transferred: 'Muté',
        add_penalties: 'Ajouter pénalités',
        add_goals: 'Ajouter buts',
        edit_penalty: 'Modifier pénalité',
        edit_goal: 'Modifier but',
        penalties: 'Pénalités',
        goals: 'Buts',
        players: 'Joueurs',
        goalies: 'Gardiens de but',
        coach: 'Entraîneur',
        bench_staff: 'Personnel de banc',
        official: 'Officiel',
        complete: 'Compléter',
        undo_approval: 'Désapprouver',
        sign_and_approve: "Signer et approuver l'alignement",
        start_game: 'Démarrer le match',
        approve_game: 'Approuver le match',
        certify: 'Certifier le match |||| Certifier les matchs',
        undo_certify: 'Décertifier',
        more_info: "Plus d'infos",
        less_info: "Moins d'infos",
        signed: 'Signé',
        start: 'Début',
        ended: 'Terminé',
        period: 'Période',
        official_attendance: 'Présence des officiels',
        additional_emails: 'Adresse courriels additionnels',
        clock: 'Temps',
        reset_scoresheet: 'Réinitialiser la feuille de match',
        home: {
          lineup: 'Local',
          goal: 'But - Local',
          penalty: 'Pénalité - Local',
          goalie: 'Gardien - Local',
        },
        away: {
          lineup: 'Visiteur',
          goal: 'But - Visiteur',
          penalty: 'Pénalité - Visiteur',
          goalie: 'Gardien - Visiteur',
        },
        penalty: {
          offender: 'Joueur fautif',
          served_by: 'Servi par',
          infraction: 'Infraction',
          duration: 'Durée',
          game_time: 'Temps',
          start_time: 'Temps de début',
          inning: 'Manche',
          half: 'Demi',
          delete: 'Supprimer la penalité',
          delete_additional: 'Supprimer les penalités',
          accumulation: "Règle d'accumulation",
        },
        goal: {
          type: 'Type',
          scored_by: 'Marqué par',
          assisted_by: 'Assisté par',
          game_time: 'Temps',
          delete: 'Supprimer le but',
        },
        edit_score: 'Éditer le pointage',
        add_notes_title: 'Ajouter des notes',
        add_notes: 'Notes',
        edit_notes_title: 'Éditer les notes',
        edit_notes: 'Notes',
        scoresheet_notes: 'Notes de match',
        admin_notes: "Notes d'officiels",
        period_settings: 'Périodes',
        periods: {
          1: '1re',
          2: '2e',
          3: '3e',
          OT: 'Prolongation',
        },
      },
      messages: {
        your_team_roster: "votre cahier d'équipe",
        lineup_change_apply: "Toute modification de l'alignement ne s'appliquera qu'à ce match et n'affectera pas les prochains matchs de",
        lineup_change_apply_part_2: "ou d'autres feuilles de match.",
        select_all: 'Tout sélectionner',
        unselect_all: 'Tout déselectionner',
        show_more: 'Afficher plus',
        show_registrations: 'Afficher les inscriptions',
        minimum_not_met: 'Minimum non atteint',
        over_limit: 'Au-dessus de la limite',
        lineup_approved_by: 'Alignement approuvé par',
        start_game: 'Êtes-vous sûr de vouloir commencer le match?',
        end_game: "A quelle temps dans le match la partie a t'elle terminée?",
        confirm_sign: 'En signant, je confirme que mon alignement est complet et conforme à la réglementation.',
        confirm_sign_game: 'En approuvant, je confirme que le match a été rempli correctement.',
        additional_emails: "Les feuilles de match sont automatiquement envoyées au personnel de l'équipe, aux administrateurs de la ligue et aux officiels du match",
        confirm_certify: 'Voulez-vous vraiment certifier ce match? |||| Voulez-vous vraiment certifier ces matchs?',
        undo_approval: {
          title: 'Désapprouver la feuille de match',
          message: 'La feuille de match redeviendra incomplète et le marqueur pourra apporter des modifications.',
        },
        undo_certify: {
          title: 'Décertifier la feuille de match',
          message: "Cela permettra à la ligue d'apporter des modifications à la feuille de match et permettra à l'officiel d'annuler l'approbation.",
        },
        reset_scoresheet: "Ceci réinitialisera la feuille de match. Les officiels et l'horaire ne seront pas affectés.",
        put_in_goalie: 'Mis dans le filet',
        empty_net: 'Filet vide',
        assist_abbr: 'Passes',
        served_abbr: 'Servi',
        shg: 'IN',
        ppg: 'AN',
        eng: 'FD',
        psg: 'Tir de pénalité',
        og: 'But contre son camp',
        successful_game_end: 'Match terminé',
        successful_game_approve: 'Match approuvé',
        successful_game_undo_approval: 'Match désapprouvé',
        successful_game_undo_certified: 'Match décertifié',
        successful_game_certify: 'Match certifié',
        successful_game_start: 'Match commencé',
        successful_save_flags: 'Notifications sauvegardées',
        successful_save_score: 'Pointage sauvegardé',
        successful_save_notes: 'Notes sauvegardé',
        successful_update_settings: 'Paramètres mis à jour',
        loading_flags: 'Chargement des notifications',
        penalty: {
          added: `Pénalité ajoutée |||| %{smart_count} pénalités ajoutés`,
          not_added: "Aucune pénalité n'a été ajoutée",
          updated: `La pénalité a été mis à jour |||| %{smart_count} pénalités ont été mis à jour`,
          not_updated: "La pénalité n'a pas été mis à jour",
          delete_confirm: 'Voulez-vous vraiment supprimer cette pénalité?',
          delete_additional: '%{smart_count} pénalité supplémentaire sera supprimée. |||| %{smart_count} pénalités supplémentaires seront supprimées.',
          subsequent_penalties: 'Les pénalités subséquentes existantes seront affectées',
          subsequent_penalties_edit: 'Seul le temps peut être modifié pour les pénalités subséquentes'
        },
        goal: {
          added: `But ajouté |||| %{smart_count} buts ajoutés`,
          not_added: "Aucun but n'a été ajouté",
          updated: `Le but a été mis à jour |||| %{smart_count} buts ont été mis à jour`,
          not_updated: "Le but n'a pas été mis à jour",
          delete_confirm: 'Voulez-vous vraiment supprimer ce but?'
        }
      },
      alerts: {
        early_lineup_entry: "L'alignement ne peut pas être entré tant que le match précédent n'est pas terminé ou après le %{date}. Vous pouvez imprimer votre cahier d'équipe en avance.",
        duplicate_batting_order: "Certains joueurs possèdent le même ordre de frappe",
        unselected_suspended_players: 'Les membres suspendus doivent être sélectionnés',
        cannot_remove_members: 'Impossible de retirer les membres qui ont des buts, des pénalités ou des nouvelles suspensions',
        lineup: 'Pour pouvoir remplir la feuille de match, les alignements doivent être complétés.',
        start: "Le match n'est pas encore commencé.",
        approve: "Le match n'est pas encore terminé.",
        completed: 'Feuille de match complétée.',
        not_certified: "Cette feuille de match n'a pas été certifiée.",
        certified: 'Feuille de match certifiée.',
        missing_incidents: "Il y a des pénalités majeures qui manquent des rapports d'incident",
        not_authorized: "Vous n'êtes pas autorisé à gérer les alignements pour ce match.",
        load_error: 'Impossible de charger la feuille de match',
        lineup_change_post_game_started: "L'alignement ne peut pas être changé une fois que le match a commencé, sauf par le marqueur.",
        unauthorized_manage_lineup: "Vous n'êtes pas autorisé à gérer cet alignement.",
        team_has_no_members: "Cette équipe n'a pas de membres",
        lineup_throwsinnings: "Pour pouvoir remplir les manches et balles lancées, l'alignement de cette équipe doit être complété et signé.",
        lineup_throwsinnings_certified: "Les manches et balles lancées ne peuvent plus être modifiées après la certification d'un match",
        or_registrations: 'ou inscriptions',
        no_members_match_filters: 'Aucun membre ne correspond à votre filtre',
        cannot_reset: 'Les feuilles de match complétées ne peuvent pas être réinitialisées',
        missingIncidents: {
          title: "Rapports d'incident manquants",
          message: "Certaines pénalités nécessitent un rapport d'incident qui doit être soumis avant que le match ne puisse être terminé.",
        },
        loading: {
          scoresheet: 'Chargement de la feuille de match...',
          validatingMembers: 'Validation des membres'
        },
        requirements: {
          title: "Exigences d'alignement",
          players: {
            min: 'Minimum de %{min} joueurs',
            max: "Maximum de %{max} joueurs",
            missing_jersey_number: "Il manque à certains joueurs un numéro de chandail",
          },
          skaters: {
            min: 'Minimum de %{min} joueurs',
            max: "Maximum de %{max} joueurs",
          },
          goalies: {
            min: 'Minimum de %{min} gardiens',
            max: "Maximum de %{max} gardiens",
          },
          staff: {
            min: 'Minimum de %{min} membres du personnel',
            max: "Maximum de %{max} membres du personnel",
          }
        },
      },
      values: {
        periods: {
          1: '1',
          2: '2',
          3: '3',
          OT: 'Prolongation',
          SO: 'Fusillade'
        },
        half: {
          first: 'Première moitié',
          second: 'Deuxième moitié',
        }
      },
      validations: {
        too_many_assists: 'Numéro de passes est trop élevé',
        out_of_range: 'Temps hors de la période',
        invalid_extra: 'Les minutes supplémentaires sont un nombre invalide',
        start_time_before_game_time: "Le temps de début est avant le temps de la penalité",
        confirm: 'Je comprends que cette action ne peut pas être annulée'
      },
      helpers: {
        scoresheet_notes: 'Ces notes seront visibles publiquement sur la feuille de match',
        admin_notes: 'Ces notes seront réservées aux marqueurs, aux officiels et aux administrateurs uniquement',
      },
      notifications: {
        reset: 'La feuille de match a été réinitialisée avec succès',
        reset_error: "La reinitialisation de la feuille de match n'a pas fonctionné",
        errors: {
          cannot_reset: 'Les feuilles de match complétées ne peuvent pas être réinitialisées',
        }
      }
    },
    gameDocuments: {
      name: 'Document |||| Documents',
      fields: {
        name: 'Nom',
        type: 'Type'
      },
      values: {
        type: {
          Lineup: "Alignement d'équipe",
          Scoresheet: 'Feuille de match',
          Other: 'Autre',
        }
      },
      labels: {
        file: 'Fichier',
        delete: 'Supprimer le document',
      },
      actions: {
        add: 'Ajouter un document',
        edit: "Modifier le document",
      },
      messages: {
        max_size: 'Taille maximale de téléchargement du fichier: 10 Mo'
      },
      notifications: {
        deleted: 'Document supprimé',
        delete_error: 'Impossible de supprimer le document',
      }
    },
    attributeTypes: {
      name: "Type d'attribut |||| Types d'attributs",
      fields: {
        name: 'Nom',
        officeId: 'Organisation',
        type: "Type d'attribut",
        targetType: 'Type cible',
        valueType: 'Type de valeur',
        options: 'Options',
        min: 'Minimum',
        max: 'Maximum',
      },
      helpers: {
        valueType: {
          has_data: "Impossible de modifier le type de valeur lorsqu'il y a des données liés",
        },
        optional: "Optionnel"
      },
      validation: {
        min: "Le minimum doit être plus petit que le maximum",
        max: "Le maximum doit être plus grand que le minimum",
        no_dropdown_options: "Au moins une option est requise",
      },
      type: {
        Officiating: 'Officiel',
        League: 'Ligue',
      },
      targetType: {
        Participant: 'Participant',
        Venue: 'Complexe',
        Surface: 'Surface',
        Team: 'Équipe',
      },
      valueType: {
        Dropdown: 'Liste déroulante',
        Number: 'Numéro',
        Text: 'Champ texte',
      },
      actions: {
        add: "Ajouter un type d'attribut",
      },
      labels: {
        card: {
          title: "Attributs",
          subtitle: "Les assignateurs peuvent utiliser des attributs comme filtre lors d'assignation de match.",
        },
        edit: "Modifier l'attribut",
      },
    },
    gameBrackets: {
      name: 'Bracket',
      labels: {
        edit: 'Modifier les têtes'
      },
      actions: {
        edit: 'Modifier les têtes'
      },
      messages: {
        edit_seeds: "La modification des têtes de séries mettra à jour les commentaires du match pour l'adapter aux nouvelles positions."
      },
      fields: {
        bracket: {
          view: 'Voir la bracket',
          link: 'Bracket',
        },
        seeds: {
          title: 'Têtes de série',
          away: 'Équipe Visiteuse',
          home: 'Équipe Locale'
        }
      }
    },
    attributeValues: {
      name: 'Attribut |||| Attributs',
      fields: {
        attributeId: "Nom",
        officeId: 'Organisation',
        value: "Valeur",
      },
      actions: {
        add: 'Ajouter un attribut',
      },
      labels: {
        card: {
          title: "Attributs",
          subtitle: "",
        },
        edit: "Modifier la valeur de l'attribut",
        attributes_file: "Fichier d'attributs",
        import_attributes: 'Importer des attributs'
      },
      validations: {
        types: {
          Number: 'Certaines lignes ont des nombre non valide',
          Dropdown: 'Certaines lignes ont des valeurs qui ne sont pas dans les options de la liste déroulante'
        }
      },
      helpers: {
        match_existing_participant: 'Ce numéro de membre est utilisé pour correspondre à un participant existant.',
      },
      messages: {
        drop_attributes_csv: "Déposez votre fichier d'attributs (.csv) ici",
        import_tool_attributes: "Pour utiliser cet outil d'importation, téléchargez un fichier CSV avec des colonnes pour le numéro de membre unique de l'officiel, et les types d'attributs de l'organisation respectifs.",
        import_tool_warning: "Cet outil d'importation mettra à jour les valeurs d'attribut officielles existantes dans votre organisation.",
      },
      alerts: {
        caution: 'Utiliser avec précaution',
      },
      notifications: {
        import_attributes_error: "Impossible d'importer les attributs",
        attributes_updated: "%{smart_count} attribut a été mis à jour. |||| %{smart_count} attributs ont été mis à jour.",
      }
    },
    externalProviders: {
      name: 'Fournisseurs externes',
      fields: {
        service: 'Service',
        provider: 'Nom du fournisseur',
        link: 'Lien',
        externalId: 'ID externe',
      },
      actions: {
        add: 'Ajouter un fournisseur',
      },
      labels: {
        edit: 'Modifier le fournisseur',
        services: {
          Streaming: 'Diffusion',
          Ticketing: 'Billetterie',
          Stats: 'Statistiques',
        }
      },
      messages: {
        no_results: 'Aucun fournisseur trouvé',
        deleted: 'Fournisseur supprimé',
      }
    },
    pools: {
      name: 'Pool |||| Pools',
      fields: {
        name: 'Nom',
      },
      actions: {
        add: 'Ajouter un pool',
      },
      labels: {
        card: {
          title: 'Pools',
        },
        edit: 'Modifier le pool',
      },
      messages: {
        all_pools: 'Tout les pools',
        select_highlight: 'Sélectionnez un pool pour la mettre en surbrillance dans la matrice'
      },
      tooltips: {
        pools_unavailable: "La direction de l'organisation a décidé d'utiliser des groupes, par conséquent les pools ne sont pas disponibles.",
        groups_unavailable: "La direction de l'organisation a décidé d'utiliser des pools, par conséquent les groupes ne sont pas disponibles.",
      }
    }
  },
  components: {
    calendar: {
      values: {
        views: {
          Day: 'Jour',
          Week: 'Semaine',
          Month: 'Mois',
          Season: 'Saison',
        },
        game_list_filters: {
          drafts_only: 'Matchs brouillons seulement',
          drafts_no_dates: 'Matchs brouillons sans dates',
          drafts_no_times: 'Matchs brouillons sans heures',
          all_published_games: 'Tous les matchs publié',
          all_games: 'Tous les matchs'
        }
      },
      labels: {
        unschedule: 'Déprogrammer le match',
        selected_game: 'Match sélectionné',
        remove_date: 'Supprimer la date',
      },
      settings: {
        arena_slots: 'Plages horaires',
        away_arena_slots: 'Plages horaires (Visiteur)',
        team_names: "Noms d'équipe",
        condensed_view: 'Afficher la vue condensée'
      },
      messages: {
        select_game: 'Sélectionnez un match dans la liste sur votre gauche',
        no_home_surface: "Aucune surface locale n'a été configurée pour cette équipe",
        no_result: 'Aucune surface trouvée avec le filtre de taille de surface sélectionné',
        schedule_game: "Aucune date n'a été programmée pour le match sélectionné",
        unschedule_game: 'Etes-vous sûr de vouloir annuler la programmation de ce match?',
        remove_time_field: "Êtes-vous sûr de vouloir supprimer l'heure et la surface de ce match?",
        remove_date: 'Êtes-vous sûr de vouloir supprimer la date de ce match?',
        no_schedule_teams: `Aucune équipe trouvée dans l'horaire`,
      },
      tooltips: {
        scheduled_games: '%{count} Match planifié',
        not_scheduled_games: '%{count} Match non planifié',
        scheduled_away_games: '%{count}/%{totalCount} Match visiteur planifié',
        scheduled_home_games: '%{count}/%{totalCount} Match local planifié',
        schedule: 'Programmer le match',
        unschedule: 'Déprogrammer le match',
        both_teams: 'Les deux équipes',
        home_team: 'Équipe locale',
        away_team: 'Équipe visiteuse',
        draft_game: 'Match brouillon',
        game: 'Match',
      },
      validations: {
        end_before_start: "La date de fin doit être après la date de début",
        start_after_end: "La date de début doit être avant la date de fin",
      }
    },
    search: {
      title: 'Mes Recherches',
      save: 'Enregistrer la recherche en cours',
      remove: 'Supprimer la recherche enregistrée',
      specify_name: 'Spécifiez un nom pour votre recherche',
      loading_error: "Une erreur s'est produite lors du chargement de vos recherches",
    },
    login: {
      scorekeeper: 'Marqueur',
      member: 'Membre',
      admin: 'Administrateur',
      edit: 'Modifier',
      messages: {
        im_a: 'Je suis...',
        unauthorized: "Veuillez contacter votre administrateur de ligue pour plus d'informations.",
        redirect: "Si vous n'êtes pas redirigé vers la feuille de match, vous pouvez ",
        helper: {
          scorekeeper: "Utilisez le numéro du match et l'identifiant de l'équipe locale pour accéder à la feuille de match sans votre compte",
          member: "Utilisez votre compte pour gérer vos listes de match, vos alignements d'équipes, et plus encore...",
        },
        offline: {
          you: "Vous n'êtes pas connecté",
          reconnect: 'Une fois que vous aurez reconnecté votre appareil en ligne, vous pourrez vous connecter ici.',
        },
        storage: {
          unavailable: 'Stockage indisponible',
          cookies_disabled: 'Il semble que vos cookies soient désactivés.',
          use_browser_storage: `${appName} utilise le stockage du navigateur pour l'intégrité et l'authentification de la feuille de match.`,
          enable_cookies: 'Pour continuer, veuillez activer les cookies/le stockage dans les paramètres de votre navigateur, puis réessayez.',
        },
        sso: {
          link_error: "Une erreur s'est produite lors de la liaison de votre compte",
          link_success: 'Compte lié avec succès',
        }
      },
      validation: {
        invalid_game_number: 'Le numéro de match est introuvable',
        invalid_game_number_long: "Le numéro de match est introuvable. Veuillez vérifier l'horaire pour confirmer.",
        invalid_team_id: "Le numéro de l'équipe locale est incorrect",
        invalid_team_id_long: "Le numéro de l'équipe locale est incorrect. Assurez-vous d'avoir le bon numéro de match et la bonne équipe.",
        missing_home_or_away_team: 'Équipe locale ou visiteur manquante pour le match',
        missing_home_or_away_team_long: 'Équipe locale ou visiteur manquante pour le match.',
        game_cancelled: 'Match annulé',
        game_cancelled_long: "Ce match a été annulé. Veuillez vérifier l'horaire pour confirmer.",
        unsupported_sport: 'Sport non supporté',
        unsupported_sport_long: 'La comptabilisation des points ne concerne que le hockey.',
        game_already_completed: 'Match déjà terminé',
        game_already_completed_long: 'Ce match est déjà terminé. Pour les corrections, veuillez contacter votre association.',
      },
      alerts: {
        sso_link: 'Lier mon compte Spordle My Account',
      },
      actions: {
        link_account: 'Lier mon compte',
      },
      chip: {
        sso: 'Spordle My Account'
      }
    },
    register: {
      participant: 'Numéro de membre du Participant',
      hockey_identity: 'Votre Identité',
      registration: `Inscription ${appName}`,
      review: 'Révision',
      link_invitation: 'Invitation pour créer le lien',
      messages: {
        link: 'Vous associez un participant au compte suivant:',
        correct_account: "S'il ne s'agit pas du bon compte, vous pouvez vous déconnecter ci-dessous.",
        email_login: `Votre adresse courriel sera votre login pour accéder ${appName}.`,
      },
      validation: {
        account_exists: 'Vous avez déjà un compte. Vous pouvez réinitialiser votre mot de passe ci-dessous ou contacter votre ligue ou assignateur',
        already_linked: 'Un compte est déjà lié à ce participant. Vous devrez peut-être contacter votre ligue ou votre assignateur',
        participant_invalid: 'Impossible de vous trouver avec cet ID. Vous devrez peut-être réessayer plus tard',
        participant_mismatch: 'Impossible de faire correspondre votre identifiant et votre nom. Vous devrez peut-être contacter votre ligue ou votre assignateur',
        ineligible: "Seuls les officiels et le personnel de l'équipe peuvent s'inscrire",
        token_invalid: 'Votre invitation est non valide. Veuillez demander une nouvelle invitation à votre ligue ou à votre assignateur',
      },
    },
    reset_password: {
      messages: {
        invalid_reset_link: "Votre lien de réinitialisation de mot de passe n'est plus valide.",
        check_email: 'Vérifiez votre boîte de réception',
        check_email_error: "Si vous ne recevez pas de courriel sous peu, vous devrez peut-être essayer avec une autre adresse courriel ou contacter votre ligue pour obtenir de l'aide.",
      },
    },
    goalie_shots_against: {
      name: 'Gardiens - Tirs contre',
      labels: {
        goalie: 'Gardien',
        shots: 'Tirs',
        edit_shots: 'Éditer les tirs',
        periods: {
          1: '1re période',
          2: '2e période',
          3: '3e période',
          OT: 'Prolongation',
        }
      },
      messages: {
        enter_shots: "Entrez les tirs contre chaque gardien de but qui a joué ce match. Tous les matchs joués doivent être saisis pour que la statistique %Arr s'affiche afin d'éviter de fausser les statistiques de manière incorrecte.",
        blank: "Les périodes peuvent être laissées vide si le gardien n'a pas joué pendant cette période.",
        error_loading_goalies: "Une erreur s'est produite lors du chargement des gardiens.",
      },
      notifications: {
        updated: 'Les tirs ont été mis à jour',
      }
    },
    throws_innings: {
      name: 'Balles et manches lancées',
      labels: {
        player: 'Joueur',
        throws: 'Balles lancées',
        innings: 'Manches lancées',
        edit: 'Modifier les balles et manches lancées',
        throws_today: 'BLJ',
        throws_weekly: 'BL7J',
        innings_today: 'MLJ',
        innings_weekly: 'ML7J',
        innings_remaining: '⚾ %{count} manche(s) restante(s)',
        cannot_throw: '🚫 Ne peut lancer dans ce match',
        resting: '💤 Repos',
        consecutive_days: 'Limite 2 jours consécutif dépassée',
        over_innings_limit: 'Limite de manche dépassée par'
      },
      messages: {
        error_saving: "Une erreur s'est produite lors de la sauvegarde des balles et manches lancées.",
      },
      notifications: {
        updated: 'Les balles et manches lancées ont été mis à jour',
      }
    },
    office_selector: {
      labels: {
        title: 'Sélectionnez un organisation',
      },
      actions: {
        open: 'Sélectionnez un organisation',
      }
    },
    exporter: {
      actions: {
        details_csv: 'Détails en CSV',
        details_xlsx: 'Détails en Excel',
        summary_csv: 'Sommaire en CSV',
        summary_xlsx: 'Sommaire en Excel',
        export_csv: 'Exporter en CSV',
        export_xlsx: 'Exporter en Excel'
      },
      transactions_exporter: {
        labels: {
          transactions: 'Transactions',
        },
        reports: {
          details: {
            title: 'Détails des transactions',
            columns: {
              pay_period: 'Période de paie',
              game_number: 'Numéro de match',
              game_time: 'Date du match',
              surface: 'Surface',
              schedule_name: 'Horaire',
              schedule_office_name: 'Ligue',
              first_name: 'Prénom',
              last_name: 'Nom',
              member_id: 'Numéro de membre',
              position: 'Position',
              assigner: 'Assignateur',
              transaction_date: 'Date de transaction',
              type: 'Type',
              subtype: 'Sous-type',
              amount: 'Montant'
            }
          },
          summary: {
            title: 'Sommaire des transactions',
            columns: {
              first_name: 'Prénom',
              last_name: 'Nom',
              member_id: 'Numéro de membre',
              travel_fees: 'Frais de déplacement',
              officiating_fees: "Frais d'arbitrage",
              other_fees: 'Autres frais',
              total_fees: 'Total des frais'
            }
          }
        }
      },
      penalties_exporter: {
        labels: {
          penalties: 'Pénalités',
        },
        reports: {
          summary: {
            title: 'Sommaire des Pénalités',
            columns: {
              first_name: 'Prénom',
              last_name: 'Nom',
              member_id: 'Numéro de membre',
              team_name: 'Équipe',
              team_id: "Numéro de l'équipe",
              schedule_name: 'Horaire',
              game_number: 'Numéro de match',
              game_date: 'Date du match',
              infraction_code: 'Code',
              infraction: 'Infraction',
              game_time: 'Temps',
              start_time: 'Heure de début',
              end_time: 'Heure de fin',
              served_by_name: 'Servi par',
              served_by_id: 'Servi par (numéro de membre)',
            }
          }
        },
      },
    },
    feature_flags: {
      title: 'Feature Flags',
      alerts: {
        branch_level: 'Spordle Play a aussi des Feature Flags au niveau de la branche. Ces flags peuvent être trouvés sur les paramètres de la branche sur l\'organisation.',
      }
    },
    roles_and_permissions: {
      title: 'Rôles et Permissions',
      labels: {
        scopes: 'Scopes',
        automatic: 'Automatique',
      },
      alerts: {
        account_permissions: "Les autorisations de compte sont composées d'un principal (participants), d'une cible (organisation, horaire, ou équipe) et d'un rôle. Ces trois parties doivent être prises en compte lors de l’évaluation de l’accès. Par exemple, si vous avez accès à la gestion des horaires, vous ne pouvez pas gérer tous les horaires, uniquement les horaires compris dans la cible de votre autorisation.",
      }
    },
    scheduleStats: {
      titles: {
        ParticipantScheduleStats: 'Statistiques des joueurs',
        TeamScheduleStats: 'Classement des équipes',
      },
      labels: {
        type: 'Type',
        ranking: 'Rang',
        ranking_og: "Rang d'origine",
        poolRanking: 'Rang',
        poolRanking_og: "Rang d'origine",
        manualRanking: 'Rang manuel',
        manualPoolRanking: 'Rang manuel',
        standingsType: 'Type de classement',
        no_pools: 'Aucun pool',
      },
      values: {
        type: {
          Skater: 'Joueurs',
          Goalie: 'Gardiens',
        },
        standingsType: {
          Overall: 'Général',
          Pools: 'Pools',
        }
      },
      alerts: {
        manual_standings: "L'utilisation du classement manuel remplacera le classement régulier affiché sur les pages web publics."
      },
      actions: {
        edit_manual_standings: 'Modifier le classement manuel',
      },
      notifications: {
        manual_standings_updated: 'Classement manuel mis à jour',
        manual_pool_standings_updated: 'Classement manuel des pools mis à jour',
        manual_standings_error: 'Impossible de mettre à jour le classement manuel',
        manual_pool_standings_error: 'Impossible de mettre à jour le classement manuel des pools',
      }
    },
  },
  common: {
    regions: REGIONS,
  },
}

export default messages;
